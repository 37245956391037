!(function () {
  function e(e, t) {
    return (
      (function (e) {
        if (Array.isArray(e)) return e;
      })(e) ||
      (function (e, t) {
        var n =
          null == e
            ? null
            : ("undefined" != typeof Symbol && e[Symbol.iterator]) ||
              e["@@iterator"];
        if (null == n) return;
        var r,
          i,
          o = [],
          a = !0,
          s = !1;
        try {
          for (
            n = n.call(e);
            !(a = (r = n.next()).done) &&
            (o.push(r.value), !t || o.length !== t);
            a = !0
          );
        } catch (u) {
          (s = !0), (i = u);
        } finally {
          try {
            a || null == n.return || n.return();
          } finally {
            if (s) throw i;
          }
        }
        return o;
      })(e, t) ||
      r(e, t) ||
      (function () {
        throw new TypeError(
          "Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
        );
      })()
    );
  }
  function t(e, t) {
    var n =
      ("undefined" != typeof Symbol && e[Symbol.iterator]) || e["@@iterator"];
    if (!n) {
      if (
        Array.isArray(e) ||
        (n = r(e)) ||
        (t && e && "number" == typeof e.length)
      ) {
        n && (e = n);
        var i = 0,
          o = function () {};
        return {
          s: o,
          n: function () {
            return i >= e.length ? { done: !0 } : { done: !1, value: e[i++] };
          },
          e: function (e) {
            throw e;
          },
          f: o,
        };
      }
      throw new TypeError(
        "Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
      );
    }
    var a,
      s = !0,
      u = !1;
    return {
      s: function () {
        n = n.call(e);
      },
      n: function () {
        var e = n.next();
        return (s = e.done), e;
      },
      e: function (e) {
        (u = !0), (a = e);
      },
      f: function () {
        try {
          s || null == n.return || n.return();
        } finally {
          if (u) throw a;
        }
      },
    };
  }
  function n(e) {
    return (
      (function (e) {
        if (Array.isArray(e)) return i(e);
      })(e) ||
      (function (e) {
        if (
          ("undefined" != typeof Symbol && null != e[Symbol.iterator]) ||
          null != e["@@iterator"]
        )
          return Array.from(e);
      })(e) ||
      r(e) ||
      (function () {
        throw new TypeError(
          "Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
        );
      })()
    );
  }
  function r(e, t) {
    if (e) {
      if ("string" == typeof e) return i(e, t);
      var n = Object.prototype.toString.call(e).slice(8, -1);
      return (
        "Object" === n && e.constructor && (n = e.constructor.name),
        "Map" === n || "Set" === n
          ? Array.from(e)
          : "Arguments" === n ||
            /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)
          ? i(e, t)
          : void 0
      );
    }
  }
  function i(e, t) {
    (null == t || t > e.length) && (t = e.length);
    for (var n = 0, r = new Array(t); n < t; n++) r[n] = e[n];
    return r;
  }
  function o(e) {
    var t = "function" == typeof Map ? new Map() : void 0;
    return (o = function (e) {
      if (
        null === e ||
        ((n = e), -1 === Function.toString.call(n).indexOf("[native code]"))
      )
        return e;
      var n;
      if ("function" != typeof e)
        throw new TypeError(
          "Super expression must either be null or a function"
        );
      if (void 0 !== t) {
        if (t.has(e)) return t.get(e);
        t.set(e, r);
      }
      function r() {
        return a(e, arguments, v(this).constructor);
      }
      return (
        (r.prototype = Object.create(e.prototype, {
          constructor: {
            value: r,
            enumerable: !1,
            writable: !0,
            configurable: !0,
          },
        })),
        l(r, e)
      );
    })(e);
  }
  function a(e, t, n) {
    return (a = d()
      ? Reflect.construct
      : function (e, t, n) {
          var r = [null];
          r.push.apply(r, t);
          var i = new (Function.bind.apply(e, r))();
          return n && l(i, n.prototype), i;
        }).apply(null, arguments);
  }
  function s(e, t, n) {
    return (s =
      "undefined" != typeof Reflect && Reflect.get
        ? Reflect.get
        : function (e, t, n) {
            var r = (function (e, t) {
              for (
                ;
                !Object.prototype.hasOwnProperty.call(e, t) &&
                null !== (e = v(e));

              );
              return e;
            })(e, t);
            if (r) {
              var i = Object.getOwnPropertyDescriptor(r, t);
              return i.get ? i.get.call(n) : i.value;
            }
          })(e, t, n || e);
  }
  function u(e, t) {
    if ("function" != typeof t && null !== t)
      throw new TypeError("Super expression must either be null or a function");
    (e.prototype = Object.create(t && t.prototype, {
      constructor: { value: e, writable: !0, configurable: !0 },
    })),
      t && l(e, t);
  }
  function l(e, t) {
    return (l =
      Object.setPrototypeOf ||
      function (e, t) {
        return (e.__proto__ = t), e;
      })(e, t);
  }
  function c(e) {
    var t = d();
    return function () {
      var n,
        r = v(e);
      if (t) {
        var i = v(this).constructor;
        n = Reflect.construct(r, arguments, i);
      } else n = r.apply(this, arguments);
      return h(this, n);
    };
  }
  function h(e, t) {
    if (t && ("object" == typeof t || "function" == typeof t)) return t;
    if (void 0 !== t)
      throw new TypeError(
        "Derived constructors may only return object or undefined"
      );
    return f(e);
  }
  function f(e) {
    if (void 0 === e)
      throw new ReferenceError(
        "this hasn't been initialised - super() hasn't been called"
      );
    return e;
  }
  function d() {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return (
        Boolean.prototype.valueOf.call(
          Reflect.construct(Boolean, [], function () {})
        ),
        !0
      );
    } catch (e) {
      return !1;
    }
  }
  function v(e) {
    return (v = Object.setPrototypeOf
      ? Object.getPrototypeOf
      : function (e) {
          return e.__proto__ || Object.getPrototypeOf(e);
        })(e);
  }
  function p(e, t) {
    if (!(e instanceof t))
      throw new TypeError("Cannot call a class as a function");
  }
  function m(e, t) {
    for (var n = 0; n < t.length; n++) {
      var r = t[n];
      (r.enumerable = r.enumerable || !1),
        (r.configurable = !0),
        "value" in r && (r.writable = !0),
        Object.defineProperty(e, r.key, r);
    }
  }
  function g(e, t, n) {
    return t && m(e.prototype, t), n && m(e, n), e;
  }
  (window.webpackJsonp = window.webpackJsonp || []).push([
    [1],
    {
      0: function (e, t, n) {
        e.exports = n("zUnb");
      },
      zUnb: function (r, i, l) {
        "use strict";
        function d(e) {
          return "function" == typeof e;
        }
        l.r(i);
        var m = !1,
          y = {
            Promise: void 0,
            set useDeprecatedSynchronousErrorHandling(e) {
              if (e) {
                var t = new Error();
                console.warn(
                  "DEPRECATED! RxJS was set to use deprecated synchronous error handling behavior by code at: \n" +
                    t.stack
                );
              } else
                m &&
                  console.log(
                    "RxJS: Back to a better error behavior. Thank you. <3"
                  );
              m = e;
            },
            get useDeprecatedSynchronousErrorHandling() {
              return m;
            },
          };
        function k(e) {
          setTimeout(function () {
            throw e;
          }, 0);
        }
        var b = {
            closed: !0,
            next: function (e) {},
            error: function (e) {
              if (y.useDeprecatedSynchronousErrorHandling) throw e;
              k(e);
            },
            complete: function () {},
          },
          w =
            Array.isArray ||
            function (e) {
              return e && "number" == typeof e.length;
            };
        function _(e) {
          return null !== e && "object" == typeof e;
        }
        var S,
          C = (function () {
            function e(e) {
              return (
                Error.call(this),
                (this.message = e
                  ? ""
                      .concat(
                        e.length,
                        " errors occurred during unsubscription:\n"
                      )
                      .concat(
                        e
                          .map(function (e, t) {
                            return "".concat(t + 1, ") ").concat(e.toString());
                          })
                          .join("\n  ")
                      )
                  : ""),
                (this.name = "UnsubscriptionError"),
                (this.errors = e),
                this
              );
            }
            return (e.prototype = Object.create(Error.prototype)), e;
          })(),
          E =
            (((S = (function () {
              function e(t) {
                p(this, e),
                  (this.closed = !1),
                  (this._parentOrParents = null),
                  (this._subscriptions = null),
                  t && ((this._ctorUnsubscribe = !0), (this._unsubscribe = t));
              }
              return (
                g(e, [
                  {
                    key: "unsubscribe",
                    value: function () {
                      var t;
                      if (!this.closed) {
                        var n = this._parentOrParents,
                          r = this._ctorUnsubscribe,
                          i = this._unsubscribe,
                          o = this._subscriptions;
                        if (
                          ((this.closed = !0),
                          (this._parentOrParents = null),
                          (this._subscriptions = null),
                          n instanceof e)
                        )
                          n.remove(this);
                        else if (null !== n)
                          for (var a = 0; a < n.length; ++a) n[a].remove(this);
                        if (d(i)) {
                          r && (this._unsubscribe = void 0);
                          try {
                            i.call(this);
                          } catch (c) {
                            t = c instanceof C ? T(c.errors) : [c];
                          }
                        }
                        if (w(o))
                          for (var s = -1, u = o.length; ++s < u; ) {
                            var l = o[s];
                            if (_(l))
                              try {
                                l.unsubscribe();
                              } catch (c) {
                                (t = t || []),
                                  c instanceof C
                                    ? (t = t.concat(T(c.errors)))
                                    : t.push(c);
                              }
                          }
                        if (t) throw new C(t);
                      }
                    },
                  },
                  {
                    key: "add",
                    value: function (t) {
                      var n = t;
                      if (!t) return e.EMPTY;
                      switch (typeof t) {
                        case "function":
                          n = new e(t);
                        case "object":
                          if (
                            n === this ||
                            n.closed ||
                            "function" != typeof n.unsubscribe
                          )
                            return n;
                          if (this.closed) return n.unsubscribe(), n;
                          if (!(n instanceof e)) {
                            var r = n;
                            (n = new e())._subscriptions = [r];
                          }
                          break;
                        default:
                          throw new Error(
                            "unrecognized teardown " +
                              t +
                              " added to Subscription."
                          );
                      }
                      var i = n._parentOrParents;
                      if (null === i) n._parentOrParents = this;
                      else if (i instanceof e) {
                        if (i === this) return n;
                        n._parentOrParents = [i, this];
                      } else {
                        if (-1 !== i.indexOf(this)) return n;
                        i.push(this);
                      }
                      var o = this._subscriptions;
                      return (
                        null === o ? (this._subscriptions = [n]) : o.push(n), n
                      );
                    },
                  },
                  {
                    key: "remove",
                    value: function (e) {
                      var t = this._subscriptions;
                      if (t) {
                        var n = t.indexOf(e);
                        -1 !== n && t.splice(n, 1);
                      }
                    },
                  },
                ]),
                e
              );
            })()).EMPTY = (function (e) {
              return (e.closed = !0), e;
            })(new S())),
            S);
        function T(e) {
          return e.reduce(function (e, t) {
            return e.concat(t instanceof C ? t.errors : t);
          }, []);
        }
        var x =
            "function" == typeof Symbol
              ? Symbol("rxSubscriber")
              : "@@rxSubscriber_" + Math.random(),
          I = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i) {
              var o;
              switch (
                (p(this, n),
                ((o = t.call(this)).syncErrorValue = null),
                (o.syncErrorThrown = !1),
                (o.syncErrorThrowable = !1),
                (o.isStopped = !1),
                arguments.length)
              ) {
                case 0:
                  o.destination = b;
                  break;
                case 1:
                  if (!e) {
                    o.destination = b;
                    break;
                  }
                  if ("object" == typeof e) {
                    e instanceof n
                      ? ((o.syncErrorThrowable = e.syncErrorThrowable),
                        (o.destination = e),
                        e.add(f(o)))
                      : ((o.syncErrorThrowable = !0),
                        (o.destination = new A(f(o), e)));
                    break;
                  }
                default:
                  (o.syncErrorThrowable = !0),
                    (o.destination = new A(f(o), e, r, i));
              }
              return o;
            }
            return (
              g(
                n,
                [
                  {
                    key: x,
                    value: function () {
                      return this;
                    },
                  },
                  {
                    key: "next",
                    value: function (e) {
                      this.isStopped || this._next(e);
                    },
                  },
                  {
                    key: "error",
                    value: function (e) {
                      this.isStopped || ((this.isStopped = !0), this._error(e));
                    },
                  },
                  {
                    key: "complete",
                    value: function () {
                      this.isStopped ||
                        ((this.isStopped = !0), this._complete());
                    },
                  },
                  {
                    key: "unsubscribe",
                    value: function () {
                      this.closed ||
                        ((this.isStopped = !0),
                        s(v(n.prototype), "unsubscribe", this).call(this));
                    },
                  },
                  {
                    key: "_next",
                    value: function (e) {
                      this.destination.next(e);
                    },
                  },
                  {
                    key: "_error",
                    value: function (e) {
                      this.destination.error(e), this.unsubscribe();
                    },
                  },
                  {
                    key: "_complete",
                    value: function () {
                      this.destination.complete(), this.unsubscribe();
                    },
                  },
                  {
                    key: "_unsubscribeAndRecycle",
                    value: function () {
                      var e = this._parentOrParents;
                      return (
                        (this._parentOrParents = null),
                        this.unsubscribe(),
                        (this.closed = !1),
                        (this.isStopped = !1),
                        (this._parentOrParents = e),
                        this
                      );
                    },
                  },
                ],
                [
                  {
                    key: "create",
                    value: function (e, t, r) {
                      var i = new n(e, t, r);
                      return (i.syncErrorThrowable = !1), i;
                    },
                  },
                ]
              ),
              n
            );
          })(E),
          A = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o) {
              var a, s;
              p(this, n), ((a = t.call(this))._parentSubscriber = e);
              var u = f(a);
              return (
                d(r)
                  ? (s = r)
                  : r &&
                    ((s = r.next),
                    (i = r.error),
                    (o = r.complete),
                    r !== b &&
                      (d((u = Object.create(r)).unsubscribe) &&
                        a.add(u.unsubscribe.bind(u)),
                      (u.unsubscribe = a.unsubscribe.bind(f(a))))),
                (a._context = u),
                (a._next = s),
                (a._error = i),
                (a._complete = o),
                a
              );
            }
            return (
              g(n, [
                {
                  key: "next",
                  value: function (e) {
                    if (!this.isStopped && this._next) {
                      var t = this._parentSubscriber;
                      y.useDeprecatedSynchronousErrorHandling &&
                      t.syncErrorThrowable
                        ? this.__tryOrSetError(t, this._next, e) &&
                          this.unsubscribe()
                        : this.__tryOrUnsub(this._next, e);
                    }
                  },
                },
                {
                  key: "error",
                  value: function (e) {
                    if (!this.isStopped) {
                      var t = this._parentSubscriber,
                        n = y.useDeprecatedSynchronousErrorHandling;
                      if (this._error)
                        n && t.syncErrorThrowable
                          ? (this.__tryOrSetError(t, this._error, e),
                            this.unsubscribe())
                          : (this.__tryOrUnsub(this._error, e),
                            this.unsubscribe());
                      else if (t.syncErrorThrowable)
                        n
                          ? ((t.syncErrorValue = e), (t.syncErrorThrown = !0))
                          : k(e),
                          this.unsubscribe();
                      else {
                        if ((this.unsubscribe(), n)) throw e;
                        k(e);
                      }
                    }
                  },
                },
                {
                  key: "complete",
                  value: function () {
                    var e = this;
                    if (!this.isStopped) {
                      var t = this._parentSubscriber;
                      if (this._complete) {
                        var n = function () {
                          return e._complete.call(e._context);
                        };
                        y.useDeprecatedSynchronousErrorHandling &&
                        t.syncErrorThrowable
                          ? (this.__tryOrSetError(t, n), this.unsubscribe())
                          : (this.__tryOrUnsub(n), this.unsubscribe());
                      } else this.unsubscribe();
                    }
                  },
                },
                {
                  key: "__tryOrUnsub",
                  value: function (e, t) {
                    try {
                      e.call(this._context, t);
                    } catch (n) {
                      if (
                        (this.unsubscribe(),
                        y.useDeprecatedSynchronousErrorHandling)
                      )
                        throw n;
                      k(n);
                    }
                  },
                },
                {
                  key: "__tryOrSetError",
                  value: function (e, t, n) {
                    if (!y.useDeprecatedSynchronousErrorHandling)
                      throw new Error("bad call");
                    try {
                      t.call(this._context, n);
                    } catch (r) {
                      return y.useDeprecatedSynchronousErrorHandling
                        ? ((e.syncErrorValue = r), (e.syncErrorThrown = !0), !0)
                        : (k(r), !0);
                    }
                    return !1;
                  },
                },
                {
                  key: "_unsubscribe",
                  value: function () {
                    var e = this._parentSubscriber;
                    (this._context = null),
                      (this._parentSubscriber = null),
                      e.unsubscribe();
                  },
                },
              ]),
              n
            );
          })(I),
          O =
            ("function" == typeof Symbol && Symbol.observable) ||
            "@@observable";
        function R(e) {
          return e;
        }
        var P,
          D =
            (((P = (function () {
              function e(t) {
                p(this, e), (this._isScalar = !1), t && (this._subscribe = t);
              }
              return (
                g(e, [
                  {
                    key: "lift",
                    value: function (t) {
                      var n = new e();
                      return (n.source = this), (n.operator = t), n;
                    },
                  },
                  {
                    key: "subscribe",
                    value: function (e, t, n) {
                      var r = this.operator,
                        i = (function (e, t, n) {
                          if (e) {
                            if (e instanceof I) return e;
                            if (e[x]) return e[x]();
                          }
                          return e || t || n ? new I(e, t, n) : new I(b);
                        })(e, t, n);
                      if (
                        (i.add(
                          r
                            ? r.call(i, this.source)
                            : this.source ||
                              (y.useDeprecatedSynchronousErrorHandling &&
                                !i.syncErrorThrowable)
                            ? this._subscribe(i)
                            : this._trySubscribe(i)
                        ),
                        y.useDeprecatedSynchronousErrorHandling &&
                          i.syncErrorThrowable &&
                          ((i.syncErrorThrowable = !1), i.syncErrorThrown))
                      )
                        throw i.syncErrorValue;
                      return i;
                    },
                  },
                  {
                    key: "_trySubscribe",
                    value: function (e) {
                      try {
                        return this._subscribe(e);
                      } catch (t) {
                        y.useDeprecatedSynchronousErrorHandling &&
                          ((e.syncErrorThrown = !0), (e.syncErrorValue = t)),
                          (function (e) {
                            for (; e; ) {
                              var t = e,
                                n = t.closed,
                                r = t.destination,
                                i = t.isStopped;
                              if (n || i) return !1;
                              e = r && r instanceof I ? r : null;
                            }
                            return !0;
                          })(e)
                            ? e.error(t)
                            : console.warn(t);
                      }
                    },
                  },
                  {
                    key: "forEach",
                    value: function (e, t) {
                      var n = this;
                      return new (t = N(t))(function (t, r) {
                        var i;
                        i = n.subscribe(
                          function (t) {
                            try {
                              e(t);
                            } catch (n) {
                              r(n), i && i.unsubscribe();
                            }
                          },
                          r,
                          t
                        );
                      });
                    },
                  },
                  {
                    key: "_subscribe",
                    value: function (e) {
                      var t = this.source;
                      return t && t.subscribe(e);
                    },
                  },
                  {
                    key: O,
                    value: function () {
                      return this;
                    },
                  },
                  {
                    key: "pipe",
                    value: function () {
                      for (
                        var e = arguments.length, t = new Array(e), n = 0;
                        n < e;
                        n++
                      )
                        t[n] = arguments[n];
                      return 0 === t.length
                        ? this
                        : (0 === (r = t).length
                            ? R
                            : 1 === r.length
                            ? r[0]
                            : function (e) {
                                return r.reduce(function (e, t) {
                                  return t(e);
                                }, e);
                              })(this);
                      var r;
                    },
                  },
                  {
                    key: "toPromise",
                    value: function (e) {
                      var t = this;
                      return new (e = N(e))(function (e, n) {
                        var r;
                        t.subscribe(
                          function (e) {
                            return (r = e);
                          },
                          function (e) {
                            return n(e);
                          },
                          function () {
                            return e(r);
                          }
                        );
                      });
                    },
                  },
                ]),
                e
              );
            })()).create = function (e) {
              return new P(e);
            }),
            P);
        function N(e) {
          if ((e || (e = y.Promise || Promise), !e))
            throw new Error("no Promise impl found");
          return e;
        }
        var M,
          j = (function () {
            function e() {
              return (
                Error.call(this),
                (this.message = "object unsubscribed"),
                (this.name = "ObjectUnsubscribedError"),
                this
              );
            }
            return (e.prototype = Object.create(Error.prototype)), e;
          })(),
          F = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this)).subject = e),
                (i.subscriber = r),
                (i.closed = !1),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "unsubscribe",
                  value: function () {
                    if (!this.closed) {
                      this.closed = !0;
                      var e = this.subject,
                        t = e.observers;
                      if (
                        ((this.subject = null),
                        t && 0 !== t.length && !e.isStopped && !e.closed)
                      ) {
                        var n = t.indexOf(this.subscriber);
                        -1 !== n && t.splice(n, 1);
                      }
                    }
                  },
                },
              ]),
              n
            );
          })(E),
          L = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e) {
              var r;
              return p(this, n), ((r = t.call(this, e)).destination = e), r;
            }
            return n;
          })(I),
          W =
            (((M = (function (e) {
              u(n, e);
              var t = c(n);
              function n() {
                var e;
                return (
                  p(this, n),
                  ((e = t.call(this)).observers = []),
                  (e.closed = !1),
                  (e.isStopped = !1),
                  (e.hasError = !1),
                  (e.thrownError = null),
                  e
                );
              }
              return (
                g(n, [
                  {
                    key: x,
                    value: function () {
                      return new L(this);
                    },
                  },
                  {
                    key: "lift",
                    value: function (e) {
                      var t = new z(this, this);
                      return (t.operator = e), t;
                    },
                  },
                  {
                    key: "next",
                    value: function (e) {
                      if (this.closed) throw new j();
                      if (!this.isStopped)
                        for (
                          var t = this.observers,
                            n = t.length,
                            r = t.slice(),
                            i = 0;
                          i < n;
                          i++
                        )
                          r[i].next(e);
                    },
                  },
                  {
                    key: "error",
                    value: function (e) {
                      if (this.closed) throw new j();
                      (this.hasError = !0),
                        (this.thrownError = e),
                        (this.isStopped = !0);
                      for (
                        var t = this.observers,
                          n = t.length,
                          r = t.slice(),
                          i = 0;
                        i < n;
                        i++
                      )
                        r[i].error(e);
                      this.observers.length = 0;
                    },
                  },
                  {
                    key: "complete",
                    value: function () {
                      if (this.closed) throw new j();
                      this.isStopped = !0;
                      for (
                        var e = this.observers,
                          t = e.length,
                          n = e.slice(),
                          r = 0;
                        r < t;
                        r++
                      )
                        n[r].complete();
                      this.observers.length = 0;
                    },
                  },
                  {
                    key: "unsubscribe",
                    value: function () {
                      (this.isStopped = !0),
                        (this.closed = !0),
                        (this.observers = null);
                    },
                  },
                  {
                    key: "_trySubscribe",
                    value: function (e) {
                      if (this.closed) throw new j();
                      return s(v(n.prototype), "_trySubscribe", this).call(
                        this,
                        e
                      );
                    },
                  },
                  {
                    key: "_subscribe",
                    value: function (e) {
                      if (this.closed) throw new j();
                      return this.hasError
                        ? (e.error(this.thrownError), E.EMPTY)
                        : this.isStopped
                        ? (e.complete(), E.EMPTY)
                        : (this.observers.push(e), new F(this, e));
                    },
                  },
                  {
                    key: "asObservable",
                    value: function () {
                      var e = new D();
                      return (e.source = this), e;
                    },
                  },
                ]),
                n
              );
            })(D)).create = function (e, t) {
              return new z(e, t);
            }),
            M),
          z = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this)).destination = e),
                (i.source = r),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "next",
                  value: function (e) {
                    var t = this.destination;
                    t && t.next && t.next(e);
                  },
                },
                {
                  key: "error",
                  value: function (e) {
                    var t = this.destination;
                    t && t.error && this.destination.error(e);
                  },
                },
                {
                  key: "complete",
                  value: function () {
                    var e = this.destination;
                    e && e.complete && this.destination.complete();
                  },
                },
                {
                  key: "_subscribe",
                  value: function (e) {
                    return this.source ? this.source.subscribe(e) : E.EMPTY;
                  },
                },
              ]),
              n
            );
          })(W);
        function q(e) {
          return e && "function" == typeof e.schedule;
        }
        function H(e, t) {
          return function (n) {
            if ("function" != typeof e)
              throw new TypeError(
                "argument is not a function. Are you looking for `mapTo()`?"
              );
            return n.lift(new U(e, t));
          };
        }
        var U = (function () {
            function e(t, n) {
              p(this, e), (this.project = t), (this.thisArg = n);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(new V(e, this.project, this.thisArg));
                  },
                },
              ]),
              e
            );
          })(),
          V = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i) {
              var o;
              return (
                p(this, n),
                ((o = t.call(this, e)).project = r),
                (o.count = 0),
                (o.thisArg = i || f(o)),
                o
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    var t;
                    try {
                      t = this.project.call(this.thisArg, e, this.count++);
                    } catch (n) {
                      return void this.destination.error(n);
                    }
                    this.destination.next(t);
                  },
                },
              ]),
              n
            );
          })(I),
          B = function (e) {
            return function (t) {
              for (var n = 0, r = e.length; n < r && !t.closed; n++)
                t.next(e[n]);
              t.complete();
            };
          };
        var Q =
            "function" == typeof Symbol && Symbol.iterator
              ? Symbol.iterator
              : "@@iterator",
          G = function (e) {
            return e && "number" == typeof e.length && "function" != typeof e;
          };
        function K(e) {
          return (
            !!e &&
            "function" != typeof e.subscribe &&
            "function" == typeof e.then
          );
        }
        var Z = function (e) {
          if (e && "function" == typeof e[O])
            return (
              (r = e),
              function (e) {
                var t = r[O]();
                if ("function" != typeof t.subscribe)
                  throw new TypeError(
                    "Provided object does not correctly implement Symbol.observable"
                  );
                return t.subscribe(e);
              }
            );
          if (G(e)) return B(e);
          if (K(e))
            return (
              (n = e),
              function (e) {
                return (
                  n
                    .then(
                      function (t) {
                        e.closed || (e.next(t), e.complete());
                      },
                      function (t) {
                        return e.error(t);
                      }
                    )
                    .then(null, k),
                  e
                );
              }
            );
          if (e && "function" == typeof e[Q])
            return (
              (t = e),
              function (e) {
                for (var n = t[Q](); ; ) {
                  var r = void 0;
                  try {
                    r = n.next();
                  } catch (i) {
                    return e.error(i), e;
                  }
                  if (r.done) {
                    e.complete();
                    break;
                  }
                  if ((e.next(r.value), e.closed)) break;
                }
                return (
                  "function" == typeof n.return &&
                    e.add(function () {
                      n.return && n.return();
                    }),
                  e
                );
              }
            );
          var t,
            n,
            r,
            i = _(e) ? "an invalid object" : "'".concat(e, "'");
          throw new TypeError(
            "You provided ".concat(
              i,
              " where a stream was expected. You can provide an Observable, Promise, Array, or Iterable."
            )
          );
        };
        function J(e, t) {
          return new D(function (n) {
            var r = new E(),
              i = 0;
            return (
              r.add(
                t.schedule(function () {
                  i !== e.length
                    ? (n.next(e[i++]), n.closed || r.add(this.schedule()))
                    : n.complete();
                })
              ),
              r
            );
          });
        }
        function $(e, t) {
          return t
            ? (function (e, t) {
                if (null != e) {
                  if (
                    (function (e) {
                      return e && "function" == typeof e[O];
                    })(e)
                  )
                    return (function (e, t) {
                      return new D(function (n) {
                        var r = new E();
                        return (
                          r.add(
                            t.schedule(function () {
                              var i = e[O]();
                              r.add(
                                i.subscribe({
                                  next: function (e) {
                                    r.add(
                                      t.schedule(function () {
                                        return n.next(e);
                                      })
                                    );
                                  },
                                  error: function (e) {
                                    r.add(
                                      t.schedule(function () {
                                        return n.error(e);
                                      })
                                    );
                                  },
                                  complete: function () {
                                    r.add(
                                      t.schedule(function () {
                                        return n.complete();
                                      })
                                    );
                                  },
                                })
                              );
                            })
                          ),
                          r
                        );
                      });
                    })(e, t);
                  if (K(e))
                    return (function (e, t) {
                      return new D(function (n) {
                        var r = new E();
                        return (
                          r.add(
                            t.schedule(function () {
                              return e.then(
                                function (e) {
                                  r.add(
                                    t.schedule(function () {
                                      n.next(e),
                                        r.add(
                                          t.schedule(function () {
                                            return n.complete();
                                          })
                                        );
                                    })
                                  );
                                },
                                function (e) {
                                  r.add(
                                    t.schedule(function () {
                                      return n.error(e);
                                    })
                                  );
                                }
                              );
                            })
                          ),
                          r
                        );
                      });
                    })(e, t);
                  if (G(e)) return J(e, t);
                  if (
                    (function (e) {
                      return e && "function" == typeof e[Q];
                    })(e) ||
                    "string" == typeof e
                  )
                    return (function (e, t) {
                      if (!e) throw new Error("Iterable cannot be null");
                      return new D(function (n) {
                        var r,
                          i = new E();
                        return (
                          i.add(function () {
                            r && "function" == typeof r.return && r.return();
                          }),
                          i.add(
                            t.schedule(function () {
                              (r = e[Q]()),
                                i.add(
                                  t.schedule(function () {
                                    if (!n.closed) {
                                      var e, t;
                                      try {
                                        var i = r.next();
                                        (e = i.value), (t = i.done);
                                      } catch (o) {
                                        return void n.error(o);
                                      }
                                      t
                                        ? n.complete()
                                        : (n.next(e), this.schedule());
                                    }
                                  })
                                );
                            })
                          ),
                          i
                        );
                      });
                    })(e, t);
                }
                throw new TypeError(
                  ((null !== e && typeof e) || e) + " is not observable"
                );
              })(e, t)
            : e instanceof D
            ? e
            : new D(Z(e));
        }
        var Y = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e) {
              var r;
              return p(this, n), ((r = t.call(this)).parent = e), r;
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    this.parent.notifyNext(e);
                  },
                },
                {
                  key: "_error",
                  value: function (e) {
                    this.parent.notifyError(e), this.unsubscribe();
                  },
                },
                {
                  key: "_complete",
                  value: function () {
                    this.parent.notifyComplete(), this.unsubscribe();
                  },
                },
              ]),
              n
            );
          })(I),
          X = (function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              return p(this, n), t.apply(this, arguments);
            }
            return (
              g(n, [
                {
                  key: "notifyNext",
                  value: function (e) {
                    this.destination.next(e);
                  },
                },
                {
                  key: "notifyError",
                  value: function (e) {
                    this.destination.error(e);
                  },
                },
                {
                  key: "notifyComplete",
                  value: function () {
                    this.destination.complete();
                  },
                },
              ]),
              n
            );
          })(I);
        function ee(e, t) {
          if (!t.closed) {
            if (e instanceof D) return e.subscribe(t);
            var n;
            try {
              n = Z(e)(t);
            } catch (r) {
              t.error(r);
            }
            return n;
          }
        }
        function te(e, t) {
          var n =
            arguments.length > 2 && void 0 !== arguments[2]
              ? arguments[2]
              : Number.POSITIVE_INFINITY;
          return "function" == typeof t
            ? function (r) {
                return r.pipe(
                  te(function (n, r) {
                    return $(e(n, r)).pipe(
                      H(function (e, i) {
                        return t(n, e, r, i);
                      })
                    );
                  }, n)
                );
              }
            : ("number" == typeof t && (n = t),
              function (t) {
                return t.lift(new ne(e, n));
              });
        }
        var ne = (function () {
            function e(t) {
              var n =
                arguments.length > 1 && void 0 !== arguments[1]
                  ? arguments[1]
                  : Number.POSITIVE_INFINITY;
              p(this, e), (this.project = t), (this.concurrent = n);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(
                      new re(e, this.project, this.concurrent)
                    );
                  },
                },
              ]),
              e
            );
          })(),
          re = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i,
                o =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : Number.POSITIVE_INFINITY;
              return (
                p(this, n),
                ((i = t.call(this, e)).project = r),
                (i.concurrent = o),
                (i.hasCompleted = !1),
                (i.buffer = []),
                (i.active = 0),
                (i.index = 0),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    this.active < this.concurrent
                      ? this._tryNext(e)
                      : this.buffer.push(e);
                  },
                },
                {
                  key: "_tryNext",
                  value: function (e) {
                    var t,
                      n = this.index++;
                    try {
                      t = this.project(e, n);
                    } catch (r) {
                      return void this.destination.error(r);
                    }
                    this.active++, this._innerSub(t);
                  },
                },
                {
                  key: "_innerSub",
                  value: function (e) {
                    var t = new Y(this),
                      n = this.destination;
                    n.add(t);
                    var r = ee(e, t);
                    r !== t && n.add(r);
                  },
                },
                {
                  key: "_complete",
                  value: function () {
                    (this.hasCompleted = !0),
                      0 === this.active &&
                        0 === this.buffer.length &&
                        this.destination.complete(),
                      this.unsubscribe();
                  },
                },
                {
                  key: "notifyNext",
                  value: function (e) {
                    this.destination.next(e);
                  },
                },
                {
                  key: "notifyComplete",
                  value: function () {
                    var e = this.buffer;
                    this.active--,
                      e.length > 0
                        ? this._next(e.shift())
                        : 0 === this.active &&
                          this.hasCompleted &&
                          this.destination.complete();
                  },
                },
              ]),
              n
            );
          })(X);
        function ie() {
          var e =
            arguments.length > 0 && void 0 !== arguments[0]
              ? arguments[0]
              : Number.POSITIVE_INFINITY;
          return te(R, e);
        }
        function oe(e, t) {
          return t ? J(e, t) : new D(B(e));
        }
        function ae() {
          for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
            t[n] = arguments[n];
          var r = Number.POSITIVE_INFINITY,
            i = null,
            o = t[t.length - 1];
          return (
            q(o)
              ? ((i = t.pop()),
                t.length > 1 &&
                  "number" == typeof t[t.length - 1] &&
                  (r = t.pop()))
              : "number" == typeof o && (r = t.pop()),
            null === i && 1 === t.length && t[0] instanceof D
              ? t[0]
              : ie(r)(oe(t, i))
          );
        }
        function se() {
          return function (e) {
            return e.lift(new le(e));
          };
        }
        var ue,
          le = (function () {
            function e(t) {
              p(this, e), (this.connectable = t);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    var n = this.connectable;
                    n._refCount++;
                    var r = new ce(e, n),
                      i = t.subscribe(r);
                    return r.closed || (r.connection = n.connect()), i;
                  },
                },
              ]),
              e
            );
          })(),
          ce = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return p(this, n), ((i = t.call(this, e)).connectable = r), i;
            }
            return (
              g(n, [
                {
                  key: "_unsubscribe",
                  value: function () {
                    var e = this.connectable;
                    if (e) {
                      this.connectable = null;
                      var t = e._refCount;
                      if (t <= 0) this.connection = null;
                      else if (((e._refCount = t - 1), t > 1))
                        this.connection = null;
                      else {
                        var n = this.connection,
                          r = e._connection;
                        (this.connection = null),
                          !r || (n && r !== n) || r.unsubscribe();
                      }
                    } else this.connection = null;
                  },
                },
              ]),
              n
            );
          })(I),
          he = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this)).source = e),
                (i.subjectFactory = r),
                (i._refCount = 0),
                (i._isComplete = !1),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "_subscribe",
                  value: function (e) {
                    return this.getSubject().subscribe(e);
                  },
                },
                {
                  key: "getSubject",
                  value: function () {
                    var e = this._subject;
                    return (
                      (e && !e.isStopped) ||
                        (this._subject = this.subjectFactory()),
                      this._subject
                    );
                  },
                },
                {
                  key: "connect",
                  value: function () {
                    var e = this._connection;
                    return (
                      e ||
                        ((this._isComplete = !1),
                        (e = this._connection = new E()).add(
                          this.source.subscribe(new de(this.getSubject(), this))
                        ),
                        e.closed && ((this._connection = null), (e = E.EMPTY))),
                      e
                    );
                  },
                },
                {
                  key: "refCount",
                  value: function () {
                    return se()(this);
                  },
                },
              ]),
              n
            );
          })(D),
          fe = {
            operator: { value: null },
            _refCount: { value: 0, writable: !0 },
            _subject: { value: null, writable: !0 },
            _connection: { value: null, writable: !0 },
            _subscribe: { value: (ue = he.prototype)._subscribe },
            _isComplete: { value: ue._isComplete, writable: !0 },
            getSubject: { value: ue.getSubject },
            connect: { value: ue.connect },
            refCount: { value: ue.refCount },
          },
          de = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return p(this, n), ((i = t.call(this, e)).connectable = r), i;
            }
            return (
              g(n, [
                {
                  key: "_error",
                  value: function (e) {
                    this._unsubscribe(),
                      s(v(n.prototype), "_error", this).call(this, e);
                  },
                },
                {
                  key: "_complete",
                  value: function () {
                    (this.connectable._isComplete = !0),
                      this._unsubscribe(),
                      s(v(n.prototype), "_complete", this).call(this);
                  },
                },
                {
                  key: "_unsubscribe",
                  value: function () {
                    var e = this.connectable;
                    if (e) {
                      this.connectable = null;
                      var t = e._connection;
                      (e._refCount = 0),
                        (e._subject = null),
                        (e._connection = null),
                        t && t.unsubscribe();
                    }
                  },
                },
              ]),
              n
            );
          })(L);
        function ve() {
          return new W();
        }
        function pe(e) {
          for (var t in e) if (e[t] === pe) return t;
          throw Error("Could not find renamed property on target object.");
        }
        function me(e) {
          if ("string" == typeof e) return e;
          if (Array.isArray(e)) return "[" + e.map(me).join(", ") + "]";
          if (null == e) return "" + e;
          if (e.overriddenName) return "".concat(e.overriddenName);
          if (e.name) return "".concat(e.name);
          var t = e.toString();
          if (null == t) return "" + t;
          var n = t.indexOf("\n");
          return -1 === n ? t : t.substring(0, n);
        }
        function ge(e, t) {
          return null == e || "" === e
            ? null === t
              ? ""
              : t
            : null == t || "" === t
            ? e
            : e + " " + t;
        }
        var ye = pe({ __forward_ref__: pe });
        function ke(e) {
          return (
            (e.__forward_ref__ = ke),
            (e.toString = function () {
              return me(this());
            }),
            e
          );
        }
        function be(e) {
          return "function" == typeof (t = e) &&
            t.hasOwnProperty(ye) &&
            t.__forward_ref__ === ke
            ? e()
            : e;
          var t;
        }
        var we = (function (e) {
          u(n, e);
          var t = c(n);
          function n(e, r) {
            var i;
            return (
              p(this, n),
              ((i = t.call(
                this,
                (function (e, t) {
                  return "".concat(e ? "NG0".concat(e, ": ") : "").concat(t);
                })(e, r)
              )).code = e),
              i
            );
          }
          return n;
        })(o(Error));
        function _e(e) {
          return "string" == typeof e ? e : null == e ? "" : String(e);
        }
        function Se(e) {
          return "function" == typeof e
            ? e.name || e.toString()
            : "object" == typeof e && null != e && "function" == typeof e.type
            ? e.type.name || e.type.toString()
            : _e(e);
        }
        function Ce(e, t) {
          var n = t ? " in ".concat(t) : "";
          throw new we(
            "201",
            "No provider for ".concat(Se(e), " found").concat(n)
          );
        }
        function Ee(e) {
          return {
            token: e.token,
            providedIn: e.providedIn || null,
            factory: e.factory,
            value: void 0,
          };
        }
        function Te(e) {
          return { providers: e.providers || [], imports: e.imports || [] };
        }
        function xe(e) {
          return Ie(e, Re) || Ie(e, De);
        }
        function Ie(e, t) {
          return e.hasOwnProperty(t) ? e[t] : null;
        }
        function Ae(e) {
          return e && (e.hasOwnProperty(Pe) || e.hasOwnProperty(Ne))
            ? e[Pe]
            : null;
        }
        var Oe,
          Re = pe({ "\u0275prov": pe }),
          Pe = pe({ "\u0275inj": pe }),
          De = pe({ ngInjectableDef: pe }),
          Ne = pe({ ngInjectorDef: pe }),
          Me = (function (e) {
            return (
              (e[(e.Default = 0)] = "Default"),
              (e[(e.Host = 1)] = "Host"),
              (e[(e.Self = 2)] = "Self"),
              (e[(e.SkipSelf = 4)] = "SkipSelf"),
              (e[(e.Optional = 8)] = "Optional"),
              e
            );
          })({});
        function je(e) {
          var t = Oe;
          return (Oe = e), t;
        }
        function Fe(e, t, n) {
          var r = xe(e);
          return r && "root" == r.providedIn
            ? void 0 === r.value
              ? (r.value = r.factory())
              : r.value
            : n & Me.Optional
            ? null
            : void 0 !== t
            ? t
            : void Ce(me(e), "Injector");
        }
        function Le(e) {
          return { toString: e }.toString();
        }
        var We = (function (e) {
            return (
              (e[(e.OnPush = 0)] = "OnPush"),
              (e[(e.Default = 1)] = "Default"),
              e
            );
          })({}),
          ze = (function (e) {
            return (
              (e[(e.Emulated = 0)] = "Emulated"),
              (e[(e.None = 2)] = "None"),
              (e[(e.ShadowDom = 3)] = "ShadowDom"),
              e
            );
          })({}),
          qe = "undefined" != typeof globalThis && globalThis,
          He = "undefined" != typeof window && window,
          Ue =
            "undefined" != typeof self &&
            "undefined" != typeof WorkerGlobalScope &&
            self instanceof WorkerGlobalScope &&
            self,
          Ve = "undefined" != typeof global && global,
          Be = qe || Ve || He || Ue,
          Qe = {},
          Ge = [],
          Ke = [],
          Ze = pe({ "\u0275cmp": pe }),
          Je = pe({ "\u0275dir": pe }),
          $e = pe({ "\u0275pipe": pe }),
          Ye = pe({ "\u0275mod": pe }),
          Xe = pe({ "\u0275loc": pe }),
          et = pe({ "\u0275fac": pe }),
          tt = pe({ __NG_ELEMENT_ID__: pe }),
          nt = 0;
        function rt(e) {
          return Le(function () {
            var t = {},
              n = {
                type: e.type,
                providersResolver: null,
                decls: e.decls,
                vars: e.vars,
                factory: null,
                template: e.template || null,
                consts: e.consts || null,
                ngContentSelectors: e.ngContentSelectors,
                hostBindings: e.hostBindings || null,
                hostVars: e.hostVars || 0,
                hostAttrs: e.hostAttrs || null,
                contentQueries: e.contentQueries || null,
                declaredInputs: t,
                inputs: null,
                outputs: null,
                exportAs: e.exportAs || null,
                onPush: e.changeDetection === We.OnPush,
                directiveDefs: null,
                pipeDefs: null,
                selectors: e.selectors || Ke,
                viewQuery: e.viewQuery || null,
                features: e.features || null,
                data: e.data || {},
                encapsulation: e.encapsulation || ze.Emulated,
                id: "c",
                styles: e.styles || Ke,
                _: null,
                setInput: null,
                schemas: e.schemas || null,
                tView: null,
              },
              r = e.directives,
              i = e.features,
              o = e.pipes;
            return (
              (n.id += nt++),
              (n.inputs = ut(e.inputs, t)),
              (n.outputs = ut(e.outputs)),
              i &&
                i.forEach(function (e) {
                  return e(n);
                }),
              (n.directiveDefs = r
                ? function () {
                    return ("function" == typeof r ? r() : r).map(it);
                  }
                : null),
              (n.pipeDefs = o
                ? function () {
                    return ("function" == typeof o ? o() : o).map(ot);
                  }
                : null),
              n
            );
          });
        }
        function it(e) {
          return (
            ct(e) ||
            (function (e) {
              return e[Je] || null;
            })(e)
          );
        }
        function ot(e) {
          return (function (e) {
            return e[$e] || null;
          })(e);
        }
        var at = {};
        function st(e) {
          var t = {
            type: e.type,
            bootstrap: e.bootstrap || Ke,
            declarations: e.declarations || Ke,
            imports: e.imports || Ke,
            exports: e.exports || Ke,
            transitiveCompileScopes: null,
            schemas: e.schemas || null,
            id: e.id || null,
          };
          return (
            null != e.id &&
              Le(function () {
                at[e.id] = e.type;
              }),
            t
          );
        }
        function ut(e, t) {
          if (null == e) return Qe;
          var n = {};
          for (var r in e)
            if (e.hasOwnProperty(r)) {
              var i = e[r],
                o = i;
              Array.isArray(i) && ((o = i[1]), (i = i[0])),
                (n[i] = r),
                t && (t[i] = o);
            }
          return n;
        }
        var lt = rt;
        function ct(e) {
          return e[Ze] || null;
        }
        function ht(e, t) {
          var n = e[Ye] || null;
          if (!n && !0 === t)
            throw new Error(
              "Type ".concat(me(e), " does not have '\u0275mod' property.")
            );
          return n;
        }
        function ft(e) {
          return Array.isArray(e) && "object" == typeof e[1];
        }
        function dt(e) {
          return Array.isArray(e) && !0 === e[1];
        }
        function vt(e) {
          return 0 != (8 & e.flags);
        }
        function pt(e) {
          return 2 == (2 & e.flags);
        }
        function mt(e) {
          return 1 == (1 & e.flags);
        }
        function gt(e) {
          return null !== e.template;
        }
        function yt(e, t) {
          return e.hasOwnProperty(et) ? e[et] : null;
        }
        var kt,
          bt = (function () {
            function e(t, n, r) {
              p(this, e),
                (this.previousValue = t),
                (this.currentValue = n),
                (this.firstChange = r);
            }
            return (
              g(e, [
                {
                  key: "isFirstChange",
                  value: function () {
                    return this.firstChange;
                  },
                },
              ]),
              e
            );
          })();
        function wt() {
          return _t;
        }
        function _t(e) {
          return e.type.prototype.ngOnChanges && (e.setInput = Ct), St;
        }
        function St() {
          var e = Et(this),
            t = null == e ? void 0 : e.current;
          if (t) {
            var n = e.previous;
            if (n === Qe) e.previous = t;
            else for (var r in t) n[r] = t[r];
            (e.current = null), this.ngOnChanges(t);
          }
        }
        function Ct(e, t, n, r) {
          var i =
              Et(e) ||
              (function (e, t) {
                return (e.__ngSimpleChanges__ = t);
              })(e, { previous: Qe, current: null }),
            o = i.current || (i.current = {}),
            a = i.previous,
            s = this.declaredInputs[n],
            u = a[s];
          (o[s] = new bt(u && u.currentValue, t, a === Qe)), (e[r] = t);
        }
        function Et(e) {
          return e.__ngSimpleChanges__ || null;
        }
        function Tt(e) {
          return !!e.listen;
        }
        wt.ngInherit = !0;
        var xt = {
          createRenderer: function (e, t) {
            return void 0 !== kt
              ? kt
              : "undefined" != typeof document
              ? document
              : void 0;
          },
        };
        function It(e) {
          for (; Array.isArray(e); ) e = e[0];
          return e;
        }
        function At(e, t) {
          return It(t[e]);
        }
        function Ot(e, t) {
          return It(t[e.index]);
        }
        function Rt(e, t) {
          return e.data[t];
        }
        function Pt(e, t) {
          var n = t[e];
          return ft(n) ? n : n[0];
        }
        function Dt(e) {
          var t = (function (e) {
            return e.__ngContext__ || null;
          })(e);
          return t ? (Array.isArray(t) ? t : t.lView) : null;
        }
        function Nt(e) {
          return 4 == (4 & e[2]);
        }
        function Mt(e) {
          return 128 == (128 & e[2]);
        }
        function jt(e, t) {
          return null == t ? null : e[t];
        }
        function Ft(e) {
          e[18] = 0;
        }
        function Lt(e, t) {
          e[5] += t;
          for (
            var n = e, r = e[3];
            null !== r && ((1 === t && 1 === n[5]) || (-1 === t && 0 === n[5]));

          )
            (r[5] += t), (n = r), (r = r[3]);
        }
        var Wt = {
          lFrame: sn(null),
          bindingsEnabled: !0,
          isInCheckNoChangesMode: !1,
        };
        function zt() {
          return Wt.bindingsEnabled;
        }
        function qt() {
          return Wt.lFrame.lView;
        }
        function Ht() {
          return Wt.lFrame.tView;
        }
        function Ut(e) {
          Wt.lFrame.contextLView = e;
        }
        function Vt() {
          for (var e = Bt(); null !== e && 64 === e.type; ) e = e.parent;
          return e;
        }
        function Bt() {
          return Wt.lFrame.currentTNode;
        }
        function Qt(e, t) {
          var n = Wt.lFrame;
          (n.currentTNode = e), (n.isParent = t);
        }
        function Gt() {
          return Wt.lFrame.isParent;
        }
        function Kt() {
          Wt.lFrame.isParent = !1;
        }
        function Zt() {
          return Wt.isInCheckNoChangesMode;
        }
        function Jt(e) {
          Wt.isInCheckNoChangesMode = e;
        }
        function $t() {
          var e = Wt.lFrame,
            t = e.bindingRootIndex;
          return (
            -1 === t && (t = e.bindingRootIndex = e.tView.bindingStartIndex), t
          );
        }
        function Yt() {
          return Wt.lFrame.bindingIndex++;
        }
        function Xt(e) {
          Wt.lFrame.currentDirectiveIndex = e;
        }
        function en() {
          return Wt.lFrame.currentQueryIndex;
        }
        function tn(e) {
          Wt.lFrame.currentQueryIndex = e;
        }
        function nn(e) {
          var t = e[1];
          return 2 === t.type ? t.declTNode : 1 === t.type ? e[6] : null;
        }
        function rn(e, t, n) {
          if (n & Me.SkipSelf) {
            for (
              var r = t, i = e;
              !(
                null !== (r = r.parent) ||
                n & Me.Host ||
                ((r = nn(i)), null === r) ||
                ((i = i[15]), 10 & r.type)
              );

            );
            if (null === r) return !1;
            (t = r), (e = i);
          }
          var o = (Wt.lFrame = an());
          return (o.currentTNode = t), (o.lView = e), !0;
        }
        function on(e) {
          var t = an(),
            n = e[1];
          (Wt.lFrame = t),
            (t.currentTNode = n.firstChild),
            (t.lView = e),
            (t.tView = n),
            (t.contextLView = e),
            (t.bindingIndex = n.bindingStartIndex),
            (t.inI18n = !1);
        }
        function an() {
          var e = Wt.lFrame,
            t = null === e ? null : e.child;
          return null === t ? sn(e) : t;
        }
        function sn(e) {
          var t = {
            currentTNode: null,
            isParent: !0,
            lView: null,
            tView: null,
            selectedIndex: -1,
            contextLView: null,
            elementDepthCount: 0,
            currentNamespace: null,
            currentDirectiveIndex: -1,
            bindingRootIndex: -1,
            bindingIndex: -1,
            currentQueryIndex: 0,
            parent: e,
            child: null,
            inI18n: !1,
          };
          return null !== e && (e.child = t), t;
        }
        function un() {
          var e = Wt.lFrame;
          return (
            (Wt.lFrame = e.parent), (e.currentTNode = null), (e.lView = null), e
          );
        }
        var ln = un;
        function cn() {
          var e = un();
          (e.isParent = !0),
            (e.tView = null),
            (e.selectedIndex = -1),
            (e.contextLView = null),
            (e.elementDepthCount = 0),
            (e.currentDirectiveIndex = -1),
            (e.currentNamespace = null),
            (e.bindingRootIndex = -1),
            (e.bindingIndex = -1),
            (e.currentQueryIndex = 0);
        }
        function hn() {
          return Wt.lFrame.selectedIndex;
        }
        function fn(e) {
          Wt.lFrame.selectedIndex = e;
        }
        function dn() {
          var e = Wt.lFrame;
          return Rt(e.tView, e.selectedIndex);
        }
        function vn(e, t) {
          for (var n = t.directiveStart, r = t.directiveEnd; n < r; n++) {
            var i = e.data[n].type.prototype,
              o = i.ngAfterContentInit,
              a = i.ngAfterContentChecked,
              s = i.ngAfterViewInit,
              u = i.ngAfterViewChecked,
              l = i.ngOnDestroy;
            o && (e.contentHooks || (e.contentHooks = [])).push(-n, o),
              a &&
                ((e.contentHooks || (e.contentHooks = [])).push(n, a),
                (e.contentCheckHooks || (e.contentCheckHooks = [])).push(n, a)),
              s && (e.viewHooks || (e.viewHooks = [])).push(-n, s),
              u &&
                ((e.viewHooks || (e.viewHooks = [])).push(n, u),
                (e.viewCheckHooks || (e.viewCheckHooks = [])).push(n, u)),
              null != l && (e.destroyHooks || (e.destroyHooks = [])).push(n, l);
          }
        }
        function pn(e, t, n) {
          yn(e, t, 3, n);
        }
        function mn(e, t, n, r) {
          (3 & e[2]) === n && yn(e, t, n, r);
        }
        function gn(e, t) {
          var n = e[2];
          (3 & n) === t && ((n &= 2047), (n += 1), (e[2] = n));
        }
        function yn(e, t, n, r) {
          for (
            var i = null != r ? r : -1,
              o = t.length - 1,
              a = 0,
              s = void 0 !== r ? 65535 & e[18] : 0;
            s < o;
            s++
          )
            if ("number" == typeof t[s + 1]) {
              if (((a = t[s]), null != r && a >= r)) break;
            } else
              t[s] < 0 && (e[18] += 65536),
                (a < i || -1 == i) &&
                  (kn(e, n, t, s), (e[18] = (4294901760 & e[18]) + s + 2)),
                s++;
        }
        function kn(e, t, n, r) {
          var i = n[r] < 0,
            o = n[r + 1],
            a = e[i ? -n[r] : n[r]];
          if (i) {
            if (e[2] >> 11 < e[18] >> 16 && (3 & e[2]) === t) {
              e[2] += 2048;
              try {
                o.call(a);
              } finally {
              }
            }
          } else
            try {
              o.call(a);
            } finally {
            }
        }
        var bn = function e(t, n, r) {
          p(this, e),
            (this.factory = t),
            (this.resolving = !1),
            (this.canSeeViewProviders = n),
            (this.injectImpl = r);
        };
        function wn(e, t, n) {
          for (var r = Tt(e), i = 0; i < n.length; ) {
            var o = n[i];
            if ("number" == typeof o) {
              if (0 !== o) break;
              i++;
              var a = n[i++],
                s = n[i++],
                u = n[i++];
              r ? e.setAttribute(t, s, u, a) : t.setAttributeNS(a, s, u);
            } else {
              var l = o,
                c = n[++i];
              Sn(l)
                ? r && e.setProperty(t, l, c)
                : r
                ? e.setAttribute(t, l, c)
                : t.setAttribute(l, c),
                i++;
            }
          }
          return i;
        }
        function _n(e) {
          return 3 === e || 4 === e || 6 === e;
        }
        function Sn(e) {
          return 64 === e.charCodeAt(0);
        }
        function Cn(e, t) {
          if (null === t || 0 === t.length);
          else if (null === e || 0 === e.length) e = t.slice();
          else
            for (var n = -1, r = 0; r < t.length; r++) {
              var i = t[r];
              "number" == typeof i
                ? (n = i)
                : 0 === n ||
                  En(e, n, i, null, -1 === n || 2 === n ? t[++r] : null);
            }
          return e;
        }
        function En(e, t, n, r, i) {
          var o = 0,
            a = e.length;
          if (-1 === t) a = -1;
          else
            for (; o < e.length; ) {
              var s = e[o++];
              if ("number" == typeof s) {
                if (s === t) {
                  a = -1;
                  break;
                }
                if (s > t) {
                  a = o - 1;
                  break;
                }
              }
            }
          for (; o < e.length; ) {
            var u = e[o];
            if ("number" == typeof u) break;
            if (u === n) {
              if (null === r) return void (null !== i && (e[o + 1] = i));
              if (r === e[o + 1]) return void (e[o + 2] = i);
            }
            o++, null !== r && o++, null !== i && o++;
          }
          -1 !== a && (e.splice(a, 0, t), (o = a + 1)),
            e.splice(o++, 0, n),
            null !== r && e.splice(o++, 0, r),
            null !== i && e.splice(o++, 0, i);
        }
        function Tn(e) {
          return -1 !== e;
        }
        function xn(e) {
          return 32767 & e;
        }
        function In(e, t) {
          for (var n = e >> 16, r = t; n > 0; ) (r = r[15]), n--;
          return r;
        }
        var An = !0;
        function On(e) {
          var t = An;
          return (An = e), t;
        }
        var Rn = 0;
        function Pn(e, t) {
          var n = Nn(e, t);
          if (-1 !== n) return n;
          var r = t[1];
          r.firstCreatePass &&
            ((e.injectorIndex = t.length),
            Dn(r.data, e),
            Dn(t, null),
            Dn(r.blueprint, null));
          var i = Mn(e, t),
            o = e.injectorIndex;
          if (Tn(i))
            for (var a = xn(i), s = In(i, t), u = s[1].data, l = 0; l < 8; l++)
              t[o + l] = s[a + l] | u[a + l];
          return (t[o + 8] = i), o;
        }
        function Dn(e, t) {
          e.push(0, 0, 0, 0, 0, 0, 0, 0, t);
        }
        function Nn(e, t) {
          return -1 === e.injectorIndex ||
            (e.parent && e.parent.injectorIndex === e.injectorIndex) ||
            null === t[e.injectorIndex + 8]
            ? -1
            : e.injectorIndex;
        }
        function Mn(e, t) {
          if (e.parent && -1 !== e.parent.injectorIndex)
            return e.parent.injectorIndex;
          for (var n = 0, r = null, i = t; null !== i; ) {
            var o = i[1],
              a = o.type;
            if (null === (r = 2 === a ? o.declTNode : 1 === a ? i[6] : null))
              return -1;
            if ((n++, (i = i[15]), -1 !== r.injectorIndex))
              return r.injectorIndex | (n << 16);
          }
          return -1;
        }
        function jn(e, t, n) {
          !(function (e, t, n) {
            var r;
            "string" == typeof n
              ? (r = n.charCodeAt(0) || 0)
              : n.hasOwnProperty(tt) && (r = n[tt]),
              null == r && (r = n[tt] = Rn++);
            var i = 255 & r;
            t.data[e + (i >> 5)] |= 1 << i;
          })(e, t, n);
        }
        function Fn(e, t, n) {
          if (n & Me.Optional) return e;
          Ce(t, "NodeInjector");
        }
        function Ln(e, t, n, r) {
          if (
            (n & Me.Optional && void 0 === r && (r = null),
            0 == (n & (Me.Self | Me.Host)))
          ) {
            var i = e[9],
              o = je(void 0);
            try {
              return i
                ? i.get(t, r, n & Me.Optional)
                : Fe(t, r, n & Me.Optional);
            } finally {
              je(o);
            }
          }
          return Fn(r, t, n);
        }
        function Wn(e, t, n) {
          var r =
              arguments.length > 3 && void 0 !== arguments[3]
                ? arguments[3]
                : Me.Default,
            i = arguments.length > 4 ? arguments[4] : void 0;
          if (null !== e) {
            var o = (function (e) {
              if ("string" == typeof e) return e.charCodeAt(0) || 0;
              var t = e.hasOwnProperty(tt) ? e[tt] : void 0;
              return "number" == typeof t ? (t >= 0 ? 255 & t : qn) : t;
            })(n);
            if ("function" == typeof o) {
              if (!rn(t, e, r))
                return r & Me.Host ? Fn(i, n, r) : Ln(t, n, r, i);
              try {
                var a = o();
                if (null != a || r & Me.Optional) return a;
                Ce(n);
              } finally {
                ln();
              }
            } else if ("number" == typeof o) {
              var s = null,
                u = Nn(e, t),
                l = -1,
                c = r & Me.Host ? t[16][6] : null;
              for (
                (-1 === u || r & Me.SkipSelf) &&
                (-1 !== (l = -1 === u ? Mn(e, t) : t[u + 8]) && Qn(r, !1)
                  ? ((s = t[1]), (u = xn(l)), (t = In(l, t)))
                  : (u = -1));
                -1 !== u;

              ) {
                var h = t[1];
                if (Bn(o, u, h.data)) {
                  var f = Hn(u, t, n, s, r, c);
                  if (f !== zn) return f;
                }
                -1 !== (l = t[u + 8]) &&
                Qn(r, t[1].data[u + 8] === c) &&
                Bn(o, u, t)
                  ? ((s = h), (u = xn(l)), (t = In(l, t)))
                  : (u = -1);
              }
            }
          }
          return Ln(t, n, r, i);
        }
        var zn = {};
        function qn() {
          return new Gn(Vt(), qt());
        }
        function Hn(e, t, n, r, i, o) {
          var a = t[1],
            s = a.data[e + 8],
            u = Un(
              s,
              a,
              n,
              null == r ? pt(s) && An : r != a && 0 != (3 & s.type),
              i & Me.Host && o === s
            );
          return null !== u ? Vn(t, a, u, s) : zn;
        }
        function Un(e, t, n, r, i) {
          for (
            var o = e.providerIndexes,
              a = t.data,
              s = 1048575 & o,
              u = e.directiveStart,
              l = o >> 20,
              c = i ? s + l : e.directiveEnd,
              h = r ? s : s + l;
            h < c;
            h++
          ) {
            var f = a[h];
            if ((h < u && n === f) || (h >= u && f.type === n)) return h;
          }
          if (i) {
            var d = a[u];
            if (d && gt(d) && d.type === n) return u;
          }
          return null;
        }
        function Vn(e, t, n, r) {
          var i = e[n],
            o = t.data;
          if (i instanceof bn) {
            var a = i;
            a.resolving &&
              (function (e, t) {
                throw new we(
                  "200",
                  "Circular dependency in DI detected for ".concat(e)
                );
              })(Se(o[n]));
            var s = On(a.canSeeViewProviders);
            a.resolving = !0;
            var u = a.injectImpl ? je(a.injectImpl) : null;
            rn(e, r, Me.Default);
            try {
              (i = e[n] = a.factory(void 0, o, e, r)),
                t.firstCreatePass &&
                  n >= r.directiveStart &&
                  (function (e, t, n) {
                    var r = t.type.prototype,
                      i = r.ngOnChanges,
                      o = r.ngOnInit,
                      a = r.ngDoCheck;
                    if (i) {
                      var s = _t(t);
                      (n.preOrderHooks || (n.preOrderHooks = [])).push(e, s),
                        (
                          n.preOrderCheckHooks || (n.preOrderCheckHooks = [])
                        ).push(e, s);
                    }
                    o &&
                      (n.preOrderHooks || (n.preOrderHooks = [])).push(
                        0 - e,
                        o
                      ),
                      a &&
                        ((n.preOrderHooks || (n.preOrderHooks = [])).push(e, a),
                        (
                          n.preOrderCheckHooks || (n.preOrderCheckHooks = [])
                        ).push(e, a));
                  })(n, o[n], t);
            } finally {
              null !== u && je(u), On(s), (a.resolving = !1), ln();
            }
          }
          return i;
        }
        function Bn(e, t, n) {
          return !!(n[t + (e >> 5)] & (1 << e));
        }
        function Qn(e, t) {
          return !(e & Me.Self || (e & Me.Host && t));
        }
        var Gn = (function () {
          function e(t, n) {
            p(this, e), (this._tNode = t), (this._lView = n);
          }
          return (
            g(e, [
              {
                key: "get",
                value: function (e, t) {
                  return Wn(this._tNode, this._lView, e, void 0, t);
                },
              },
            ]),
            e
          );
        })();
        function Kn(e) {
          return (function (e, t) {
            if ("class" === t) return e.classes;
            if ("style" === t) return e.styles;
            var n = e.attrs;
            if (n)
              for (var r = n.length, i = 0; i < r; ) {
                var o = n[i];
                if (_n(o)) break;
                if (0 === o) i += 2;
                else if ("number" == typeof o)
                  for (i++; i < r && "string" == typeof n[i]; ) i++;
                else {
                  if (o === t) return n[i + 1];
                  i += 2;
                }
              }
            return null;
          })(Vt(), e);
        }
        function Zn(e, t, n) {
          return Le(function () {
            var r = (function (e) {
              return function () {
                if (e) {
                  var t = e.apply(void 0, arguments);
                  for (var n in t) this[n] = t[n];
                }
              };
            })(t);
            function i() {
              for (
                var e = arguments.length, t = new Array(e), n = 0;
                n < e;
                n++
              )
                t[n] = arguments[n];
              if (this instanceof i) return r.apply(this, t), this;
              var o = a(i, t);
              return (s.annotation = o), s;
              function s(e, t, n) {
                for (
                  var r = e.hasOwnProperty("__parameters__")
                    ? e.__parameters__
                    : Object.defineProperty(e, "__parameters__", { value: [] })
                        .__parameters__;
                  r.length <= n;

                )
                  r.push(null);
                return (r[n] = r[n] || []).push(o), e;
              }
            }
            return (
              n && (i.prototype = Object.create(n.prototype)),
              (i.prototype.ngMetadataName = e),
              (i.annotationCls = i),
              i
            );
          });
        }
        var Jn = (function () {
            function e(t, n) {
              p(this, e),
                (this._desc = t),
                (this.ngMetadataName = "InjectionToken"),
                (this.ɵprov = void 0),
                "number" == typeof n
                  ? (this.__NG_ELEMENT_ID__ = n)
                  : void 0 !== n &&
                    (this.ɵprov = Ee({
                      token: this,
                      providedIn: n.providedIn || "root",
                      factory: n.factory,
                    }));
            }
            return (
              g(e, [
                {
                  key: "toString",
                  value: function () {
                    return "InjectionToken ".concat(this._desc);
                  },
                },
              ]),
              e
            );
          })(),
          $n = new Jn("AnalyzeForEntryComponents"),
          Yn = Function;
        function Xn(e, t) {
          void 0 === t && (t = e);
          for (var n = 0; n < e.length; n++) {
            var r = e[n];
            Array.isArray(r)
              ? (t === e && (t = e.slice(0, n)), Xn(r, t))
              : t !== e && t.push(r);
          }
          return t;
        }
        function er(e, t) {
          e.forEach(function (e) {
            return Array.isArray(e) ? er(e, t) : t(e);
          });
        }
        function tr(e, t, n) {
          t >= e.length ? e.push(n) : e.splice(t, 0, n);
        }
        function nr(e, t) {
          return t >= e.length - 1 ? e.pop() : e.splice(t, 1)[0];
        }
        function rr(e, t) {
          for (var n = [], r = 0; r < e; r++) n.push(t);
          return n;
        }
        function ir(e, t) {
          var n = or(e, t);
          if (n >= 0) return e[1 | n];
        }
        function or(e, t) {
          return (function (e, t, n) {
            for (var r = 0, i = e.length >> 1; i !== r; ) {
              var o = r + ((i - r) >> 1),
                a = e[o << 1];
              if (t === a) return o << 1;
              a > t ? (i = o) : (r = o + 1);
            }
            return ~(i << 1);
          })(e, t);
        }
        var ar,
          sr = {},
          ur = /\n/gm,
          lr = pe({ provide: String, useValue: pe });
        function cr(e) {
          var t = ar;
          return (ar = e), t;
        }
        function hr(e) {
          var t =
            arguments.length > 1 && void 0 !== arguments[1]
              ? arguments[1]
              : Me.Default;
          if (void 0 === ar)
            throw new Error(
              "inject() must be called from an injection context"
            );
          return null === ar
            ? Fe(e, void 0, t)
            : ar.get(e, t & Me.Optional ? null : void 0, t);
        }
        function fr(e) {
          var t =
            arguments.length > 1 && void 0 !== arguments[1]
              ? arguments[1]
              : Me.Default;
          return (Oe || hr)(be(e), t);
        }
        function dr(e) {
          for (var t = [], n = 0; n < e.length; n++) {
            var r = be(e[n]);
            if (Array.isArray(r)) {
              if (0 === r.length)
                throw new Error("Arguments array must have arguments.");
              for (var i = void 0, o = Me.Default, a = 0; a < r.length; a++) {
                var s = r[a],
                  u = s.__NG_DI_FLAG__;
                "number" == typeof u
                  ? -1 === u
                    ? (i = s.token)
                    : (o |= u)
                  : (i = s);
              }
              t.push(fr(i, o));
            } else t.push(fr(r));
          }
          return t;
        }
        function vr(e, t) {
          return (e.__NG_DI_FLAG__ = t), (e.prototype.__NG_DI_FLAG__ = t), e;
        }
        var pr = vr(
            Zn("Inject", function (e) {
              return { token: e };
            }),
            -1
          ),
          mr = vr(Zn("Optional"), 8),
          gr = vr(Zn("SkipSelf"), 4),
          yr = (function () {
            function e(t) {
              p(this, e), (this.changingThisBreaksApplicationSecurity = t);
            }
            return (
              g(e, [
                {
                  key: "toString",
                  value: function () {
                    return "SafeValue must use [property]=binding: ".concat(
                      this.changingThisBreaksApplicationSecurity,
                      " (see https://g.co/ng/security#xss)"
                    );
                  },
                },
              ]),
              e
            );
          })();
        function kr(e) {
          return e instanceof yr ? e.changingThisBreaksApplicationSecurity : e;
        }
        var br =
            /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi,
          wr =
            /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i,
          _r = (function (e) {
            return (
              (e[(e.NONE = 0)] = "NONE"),
              (e[(e.HTML = 1)] = "HTML"),
              (e[(e.STYLE = 2)] = "STYLE"),
              (e[(e.SCRIPT = 3)] = "SCRIPT"),
              (e[(e.URL = 4)] = "URL"),
              (e[(e.RESOURCE_URL = 5)] = "RESOURCE_URL"),
              e
            );
          })({});
        function Sr(e) {
          var t,
            n,
            r = (t = qt()) && t[12];
          return r
            ? r.sanitize(_r.URL, e) || ""
            : (function (e, t) {
                var n = (function (e) {
                  return (e instanceof yr && e.getTypeName()) || null;
                })(e);
                if (null != n && n !== t) {
                  if ("ResourceURL" === n) return !0;
                  throw new Error(
                    "Required a safe "
                      .concat(t, ", got a ")
                      .concat(n, " (see https://g.co/ng/security#xss)")
                  );
                }
                return n === t;
              })(e, "URL")
            ? kr(e)
            : ((n = _e(e)),
              (n = String(n)).match(br) || n.match(wr) ? n : "unsafe:" + n);
        }
        function Cr(e) {
          return e.ngDebugContext;
        }
        function Er(e) {
          return e.ngOriginalError;
        }
        function Tr(e) {
          for (
            var t = arguments.length, n = new Array(t > 1 ? t - 1 : 0), r = 1;
            r < t;
            r++
          )
            n[r - 1] = arguments[r];
          e.error.apply(e, n);
        }
        var xr = (function () {
          function e() {
            p(this, e), (this._console = console);
          }
          return (
            g(e, [
              {
                key: "handleError",
                value: function (e) {
                  var t = this._findOriginalError(e),
                    n = this._findContext(e),
                    r = (function (e) {
                      return e.ngErrorLogger || Tr;
                    })(e);
                  r(this._console, "ERROR", e),
                    t && r(this._console, "ORIGINAL ERROR", t),
                    n && r(this._console, "ERROR CONTEXT", n);
                },
              },
              {
                key: "_findContext",
                value: function (e) {
                  return e ? (Cr(e) ? Cr(e) : this._findContext(Er(e))) : null;
                },
              },
              {
                key: "_findOriginalError",
                value: function (e) {
                  for (var t = Er(e); t && Er(t); ) t = Er(t);
                  return t;
                },
              },
            ]),
            e
          );
        })();
        function Ir(e, t) {
          e.__ngContext__ = t;
        }
        var Ar = (
          ("undefined" != typeof requestAnimationFrame &&
            requestAnimationFrame) ||
          setTimeout
        ).bind(Be);
        function Or(e) {
          return { name: "window", target: e.ownerDocument.defaultView };
        }
        function Rr(e) {
          return e instanceof Function ? e() : e;
        }
        var Pr = (function (e) {
          return (
            (e[(e.Important = 1)] = "Important"),
            (e[(e.DashCase = 2)] = "DashCase"),
            e
          );
        })({});
        function Dr(e, t) {
          return (void 0)(e, t);
        }
        function Nr(e) {
          var t = e[3];
          return dt(t) ? t[3] : t;
        }
        function Mr(e) {
          return Fr(e[13]);
        }
        function jr(e) {
          return Fr(e[4]);
        }
        function Fr(e) {
          for (; null !== e && !dt(e); ) e = e[4];
          return e;
        }
        function Lr(e, t, n, r, i) {
          if (null != r) {
            var o,
              a = !1;
            dt(r) ? (o = r) : ft(r) && ((a = !0), (r = r[0]));
            var s = It(r);
            0 === e && null !== n
              ? null == i
                ? Qr(t, n, s)
                : Br(t, n, s, i || null, !0)
              : 1 === e && null !== n
              ? Br(t, n, s, i || null, !0)
              : 2 === e
              ? (function (e, t, n) {
                  var r = Kr(e, t);
                  r &&
                    (function (e, t, n, r) {
                      Tt(e) ? e.removeChild(t, n, r) : t.removeChild(n);
                    })(e, r, t, n);
                })(t, s, a)
              : 3 === e && t.destroyNode(s),
              null != o &&
                (function (e, t, n, r, i) {
                  var o = n[7];
                  o !== It(n) && Lr(t, e, r, o, i);
                  for (var a = 10; a < n.length; a++) {
                    var s = n[a];
                    ni(s[1], s, e, t, r, o);
                  }
                })(t, e, o, n, i);
          }
        }
        function Wr(e, t, n) {
          return Tt(e)
            ? e.createElement(t, n)
            : null === n
            ? e.createElement(t)
            : e.createElementNS(n, t);
        }
        function zr(e, t) {
          var n = e[9],
            r = n.indexOf(t),
            i = t[3];
          1024 & t[2] && ((t[2] &= -1025), Lt(i, -1)), n.splice(r, 1);
        }
        function qr(e, t) {
          if (!(e.length <= 10)) {
            var n,
              r = 10 + t,
              i = e[r];
            if (i) {
              var o = i[17];
              null !== o && o !== e && zr(o, i), t > 0 && (e[r - 1][4] = i[4]);
              var a = nr(e, 10 + t);
              ni(i[1], (n = i), n[11], 2, null, null),
                (n[0] = null),
                (n[6] = null);
              var s = a[19];
              null !== s && s.detachView(a[1]),
                (i[3] = null),
                (i[4] = null),
                (i[2] &= -129);
            }
            return i;
          }
        }
        function Hr(e, t) {
          if (!(256 & t[2])) {
            var n = t[11];
            Tt(n) && n.destroyNode && ni(e, t, n, 3, null, null),
              (function (e) {
                var t = e[13];
                if (!t) return Ur(e[1], e);
                for (; t; ) {
                  var n = null;
                  if (ft(t)) n = t[13];
                  else {
                    var r = t[10];
                    r && (n = r);
                  }
                  if (!n) {
                    for (; t && !t[4] && t !== e; )
                      ft(t) && Ur(t[1], t), (t = t[3]);
                    null === t && (t = e),
                      ft(t) && Ur(t[1], t),
                      (n = t && t[4]);
                  }
                  t = n;
                }
              })(t);
          }
        }
        function Ur(e, t) {
          if (!(256 & t[2])) {
            (t[2] &= -129),
              (t[2] |= 256),
              (function (e, t) {
                var n;
                if (null != e && null != (n = e.destroyHooks))
                  for (var r = 0; r < n.length; r += 2) {
                    var i = t[n[r]];
                    if (!(i instanceof bn)) {
                      var o = n[r + 1];
                      if (Array.isArray(o))
                        for (var a = 0; a < o.length; a += 2) {
                          var s = i[o[a]],
                            u = o[a + 1];
                          try {
                            u.call(s);
                          } finally {
                          }
                        }
                      else
                        try {
                          o.call(i);
                        } finally {
                        }
                    }
                  }
              })(e, t),
              (function (e, t) {
                var n = e.cleanup,
                  r = t[7],
                  i = -1;
                if (null !== n)
                  for (var o = 0; o < n.length - 1; o += 2)
                    if ("string" == typeof n[o]) {
                      var a = n[o + 1],
                        s = "function" == typeof a ? a(t) : It(t[a]),
                        u = r[(i = n[o + 2])],
                        l = n[o + 3];
                      "boolean" == typeof l
                        ? s.removeEventListener(n[o], u, l)
                        : l >= 0
                        ? r[(i = l)]()
                        : r[(i = -l)].unsubscribe(),
                        (o += 2);
                    } else {
                      var c = r[(i = n[o + 1])];
                      n[o].call(c);
                    }
                if (null !== r) {
                  for (var h = i + 1; h < r.length; h++) (0, r[h])();
                  t[7] = null;
                }
              })(e, t),
              1 === t[1].type && Tt(t[11]) && t[11].destroy();
            var n = t[17];
            if (null !== n && dt(t[3])) {
              n !== t[3] && zr(n, t);
              var r = t[19];
              null !== r && r.detachView(e);
            }
          }
        }
        function Vr(e, t, n) {
          return (function (e, t, n) {
            for (var r = t; null !== r && 40 & r.type; ) r = (t = r).parent;
            if (null === r) return n[0];
            if (2 & r.flags) {
              var i = e.data[r.directiveStart].encapsulation;
              if (i === ze.None || i === ze.Emulated) return null;
            }
            return Ot(r, n);
          })(e, t.parent, n);
        }
        function Br(e, t, n, r, i) {
          Tt(e) ? e.insertBefore(t, n, r, i) : t.insertBefore(n, r, i);
        }
        function Qr(e, t, n) {
          Tt(e) ? e.appendChild(t, n) : t.appendChild(n);
        }
        function Gr(e, t, n, r, i) {
          null !== r ? Br(e, t, n, r, i) : Qr(e, t, n);
        }
        function Kr(e, t) {
          return Tt(e) ? e.parentNode(t) : t.parentNode;
        }
        function Zr(e, t, n) {
          return Jr(e, t, n);
        }
        var Jr = function (e, t, n) {
          return 40 & e.type ? Ot(e, n) : null;
        };
        function $r(e, t, n, r) {
          var i = Vr(e, r, t),
            o = t[11],
            a = Zr(r.parent || t[6], r, t);
          if (null != i)
            if (Array.isArray(n))
              for (var s = 0; s < n.length; s++) Gr(o, i, n[s], a, !1);
            else Gr(o, i, n, a, !1);
        }
        function Yr(e, t) {
          if (null !== t) {
            var n = t.type;
            if (3 & n) return Ot(t, e);
            if (4 & n) return ei(-1, e[t.index]);
            if (8 & n) {
              var r = t.child;
              if (null !== r) return Yr(e, r);
              var i = e[t.index];
              return dt(i) ? ei(-1, i) : It(i);
            }
            if (32 & n) return Dr(t, e)() || It(e[t.index]);
            var o = Xr(e, t);
            return null !== o
              ? Array.isArray(o)
                ? o[0]
                : Yr(Nr(e[16]), o)
              : Yr(e, t.next);
          }
          return null;
        }
        function Xr(e, t) {
          return null !== t ? e[16][6].projection[t.projection] : null;
        }
        function ei(e, t) {
          var n = 10 + e + 1;
          if (n < t.length) {
            var r = t[n],
              i = r[1].firstChild;
            if (null !== i) return Yr(r, i);
          }
          return t[7];
        }
        function ti(e, t, n, r, i, o, a) {
          for (; null != n; ) {
            var s = r[n.index],
              u = n.type;
            if (
              (a && 0 === t && (s && Ir(It(s), r), (n.flags |= 4)),
              64 != (64 & n.flags))
            )
              if (8 & u) ti(e, t, n.child, r, i, o, !1), Lr(t, e, i, s, o);
              else if (32 & u) {
                for (var l = Dr(n, r), c = void 0; (c = l()); )
                  Lr(t, e, i, c, o);
                Lr(t, e, i, s, o);
              } else 16 & u ? ri(e, t, r, n, i, o) : Lr(t, e, i, s, o);
            n = a ? n.projectionNext : n.next;
          }
        }
        function ni(e, t, n, r, i, o) {
          ti(n, r, e.firstChild, t, i, o, !1);
        }
        function ri(e, t, n, r, i, o) {
          var a = n[16],
            s = a[6].projection[r.projection];
          if (Array.isArray(s))
            for (var u = 0; u < s.length; u++) Lr(t, e, i, s[u], o);
          else ti(e, t, s, a[3], i, o, !0);
        }
        function ii(e, t, n) {
          Tt(e) ? e.setAttribute(t, "style", n) : (t.style.cssText = n);
        }
        function oi(e, t, n) {
          Tt(e)
            ? "" === n
              ? e.removeAttribute(t, "class")
              : e.setAttribute(t, "class", n)
            : (t.className = n);
        }
        function ai(e, t, n) {
          for (var r = e.length; ; ) {
            var i = e.indexOf(t, n);
            if (-1 === i) return i;
            if (0 === i || e.charCodeAt(i - 1) <= 32) {
              var o = t.length;
              if (i + o === r || e.charCodeAt(i + o) <= 32) return i;
            }
            n = i + 1;
          }
        }
        function si(e, t, n) {
          for (var r = 0; r < e.length; ) {
            var i = e[r++];
            if (n && "class" === i) {
              if (-1 !== ai((i = e[r]).toLowerCase(), t, 0)) return !0;
            } else if (1 === i) {
              for (; r < e.length && "string" == typeof (i = e[r++]); )
                if (i.toLowerCase() === t) return !0;
              return !1;
            }
          }
          return !1;
        }
        function ui(e) {
          return 4 === e.type && "ng-template" !== e.value;
        }
        function li(e, t, n) {
          return t === (4 !== e.type || n ? e.value : "ng-template");
        }
        function ci(e, t, n) {
          for (
            var r = 4,
              i = e.attrs || [],
              o = (function (e) {
                for (var t = 0; t < e.length; t++) if (_n(e[t])) return t;
                return e.length;
              })(i),
              a = !1,
              s = 0;
            s < t.length;
            s++
          ) {
            var u = t[s];
            if ("number" != typeof u) {
              if (!a)
                if (4 & r) {
                  if (
                    ((r = 2 | (1 & r)),
                    ("" !== u && !li(e, u, n)) || ("" === u && 1 === t.length))
                  ) {
                    if (hi(r)) return !1;
                    a = !0;
                  }
                } else {
                  var l = 8 & r ? u : t[++s];
                  if (8 & r && null !== e.attrs) {
                    if (!si(e.attrs, l, n)) {
                      if (hi(r)) return !1;
                      a = !0;
                    }
                    continue;
                  }
                  var c = fi(8 & r ? "class" : u, i, ui(e), n);
                  if (-1 === c) {
                    if (hi(r)) return !1;
                    a = !0;
                    continue;
                  }
                  if ("" !== l) {
                    var h;
                    h = c > o ? "" : i[c + 1].toLowerCase();
                    var f = 8 & r ? h : null;
                    if ((f && -1 !== ai(f, l, 0)) || (2 & r && l !== h)) {
                      if (hi(r)) return !1;
                      a = !0;
                    }
                  }
                }
            } else {
              if (!a && !hi(r) && !hi(u)) return !1;
              if (a && hi(u)) continue;
              (a = !1), (r = u | (1 & r));
            }
          }
          return hi(r) || a;
        }
        function hi(e) {
          return 0 == (1 & e);
        }
        function fi(e, t, n, r) {
          if (null === t) return -1;
          var i = 0;
          if (r || !n) {
            for (var o = !1; i < t.length; ) {
              var a = t[i];
              if (a === e) return i;
              if (3 === a || 6 === a) o = !0;
              else {
                if (1 === a || 2 === a) {
                  for (var s = t[++i]; "string" == typeof s; ) s = t[++i];
                  continue;
                }
                if (4 === a) break;
                if (0 === a) {
                  i += 4;
                  continue;
                }
              }
              i += o ? 1 : 2;
            }
            return -1;
          }
          return (function (e, t) {
            var n = e.indexOf(4);
            if (n > -1)
              for (n++; n < e.length; ) {
                var r = e[n];
                if ("number" == typeof r) return -1;
                if (r === t) return n;
                n++;
              }
            return -1;
          })(t, e);
        }
        function di(e, t) {
          for (
            var n =
                arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
              r = 0;
            r < t.length;
            r++
          )
            if (ci(e, t[r], n)) return !0;
          return !1;
        }
        function vi(e, t) {
          e: for (var n = 0; n < t.length; n++) {
            var r = t[n];
            if (e.length === r.length) {
              for (var i = 0; i < e.length; i++) if (e[i] !== r[i]) continue e;
              return !0;
            }
          }
          return !1;
        }
        function pi(e, t) {
          return e ? ":not(" + t.trim() + ")" : t;
        }
        function mi(e) {
          for (var t = e[0], n = 1, r = 2, i = "", o = !1; n < e.length; ) {
            var a = e[n];
            if ("string" == typeof a)
              if (2 & r) {
                var s = e[++n];
                i += "[" + a + (s.length > 0 ? '="' + s + '"' : "") + "]";
              } else 8 & r ? (i += "." + a) : 4 & r && (i += " " + a);
            else
              "" === i || hi(a) || ((t += pi(o, i)), (i = "")),
                (r = a),
                (o = o || !hi(r));
            n++;
          }
          return "" !== i && (t += pi(o, i)), t;
        }
        var gi = {};
        function yi(e) {
          ki(Ht(), qt(), hn() + e, Zt());
        }
        function ki(e, t, n, r) {
          if (!r)
            if (3 == (3 & t[2])) {
              var i = e.preOrderCheckHooks;
              null !== i && pn(t, i, n);
            } else {
              var o = e.preOrderHooks;
              null !== o && mn(t, o, 0, n);
            }
          fn(n);
        }
        function bi(e, t) {
          return (e << 17) | (t << 2);
        }
        function wi(e) {
          return (e >> 17) & 32767;
        }
        function _i(e) {
          return 2 | e;
        }
        function Si(e) {
          return (131068 & e) >> 2;
        }
        function Ci(e, t) {
          return (-131069 & e) | (t << 2);
        }
        function Ei(e) {
          return 1 | e;
        }
        function Ti(e, t) {
          var n = e.contentQueries;
          if (null !== n)
            for (var r = 0; r < n.length; r += 2) {
              var i = n[r],
                o = n[r + 1];
              if (-1 !== o) {
                var a = e.data[o];
                tn(i), a.contentQueries(2, t[o], o);
              }
            }
        }
        function xi(e, t, n, r, i, o, a, s, u, l) {
          var c = t.blueprint.slice();
          return (
            (c[0] = i),
            (c[2] = 140 | r),
            Ft(c),
            (c[3] = c[15] = e),
            (c[8] = n),
            (c[10] = a || (e && e[10])),
            (c[11] = s || (e && e[11])),
            (c[12] = u || (e && e[12]) || null),
            (c[9] = l || (e && e[9]) || null),
            (c[6] = o),
            (c[16] = 2 == t.type ? e[16] : c),
            c
          );
        }
        function Ii(e, t, n, r, i) {
          var o,
            a,
            s = e.data[t];
          if (null === s)
            (s = (function (e, t, n, r, i) {
              var o = Bt(),
                a = Gt(),
                s = (e.data[t] = (function (e, t, n, r, i, o) {
                  return {
                    type: n,
                    index: r,
                    insertBeforeIndex: null,
                    injectorIndex: t ? t.injectorIndex : -1,
                    directiveStart: -1,
                    directiveEnd: -1,
                    directiveStylingLast: -1,
                    propertyBindings: null,
                    flags: 0,
                    providerIndexes: 0,
                    value: i,
                    attrs: o,
                    mergedAttrs: null,
                    localNames: null,
                    initialInputs: void 0,
                    inputs: null,
                    outputs: null,
                    tViews: null,
                    next: null,
                    projectionNext: null,
                    child: null,
                    parent: t,
                    projection: null,
                    styles: null,
                    stylesWithoutHost: null,
                    residualStyles: void 0,
                    classes: null,
                    classesWithoutHost: null,
                    residualClasses: void 0,
                    classBindings: 0,
                    styleBindings: 0,
                  };
                })(0, a ? o : o && o.parent, n, t, r, i));
              return (
                null === e.firstChild && (e.firstChild = s),
                null !== o &&
                  (a
                    ? null == o.child && null !== s.parent && (o.child = s)
                    : null === o.next && (o.next = s)),
                s
              );
            })(e, t, n, r, i)),
              Wt.lFrame.inI18n && (s.flags |= 64);
          else if (64 & s.type) {
            (s.type = n), (s.value = r), (s.attrs = i);
            var u =
              ((o = Wt.lFrame),
              (a = o.currentTNode),
              o.isParent ? a : a.parent);
            s.injectorIndex = null === u ? -1 : u.injectorIndex;
          }
          return Qt(s, !0), s;
        }
        function Ai(e, t, n, r) {
          if (0 === n) return -1;
          for (var i = t.length, o = 0; o < n; o++)
            t.push(r), e.blueprint.push(r), e.data.push(null);
          return i;
        }
        function Oi(e, t, n) {
          on(t);
          try {
            var r = e.viewQuery;
            null !== r && oo(1, r, n);
            var i = e.template;
            null !== i && Di(e, t, i, 1, n),
              e.firstCreatePass && (e.firstCreatePass = !1),
              e.staticContentQueries && Ti(e, t),
              e.staticViewQueries && oo(2, e.viewQuery, n);
            var o = e.components;
            null !== o &&
              (function (e, t) {
                for (var n = 0; n < t.length; n++) eo(e, t[n]);
              })(t, o);
          } catch (a) {
            throw (e.firstCreatePass && (e.incompleteFirstPass = !0), a);
          } finally {
            (t[2] &= -5), cn();
          }
        }
        function Ri(e, t, n, r) {
          var i = t[2];
          if (256 != (256 & i)) {
            on(t);
            var o = Zt();
            try {
              Ft(t),
                (Wt.lFrame.bindingIndex = e.bindingStartIndex),
                null !== n && Di(e, t, n, 2, r);
              var a = 3 == (3 & i);
              if (!o)
                if (a) {
                  var s = e.preOrderCheckHooks;
                  null !== s && pn(t, s, null);
                } else {
                  var u = e.preOrderHooks;
                  null !== u && mn(t, u, 0, null), gn(t, 0);
                }
              if (
                ((function (e) {
                  for (var t = Mr(e); null !== t; t = jr(t))
                    if (t[2])
                      for (var n = t[9], r = 0; r < n.length; r++) {
                        var i = n[r],
                          o = i[3];
                        0 == (1024 & i[2]) && Lt(o, 1), (i[2] |= 1024);
                      }
                })(t),
                (function (e) {
                  for (var t = Mr(e); null !== t; t = jr(t))
                    for (var n = 10; n < t.length; n++) {
                      var r = t[n],
                        i = r[1];
                      Mt(r) && Ri(i, r, i.template, r[8]);
                    }
                })(t),
                null !== e.contentQueries && Ti(e, t),
                !o)
              )
                if (a) {
                  var l = e.contentCheckHooks;
                  null !== l && pn(t, l);
                } else {
                  var c = e.contentHooks;
                  null !== c && mn(t, c, 1), gn(t, 1);
                }
              !(function (e, t) {
                var n,
                  r,
                  i,
                  o = e.hostBindingOpCodes;
                if (null !== o)
                  try {
                    for (var a = 0; a < o.length; a++) {
                      var s = o[a];
                      if (s < 0) fn(~s);
                      else {
                        var u = s,
                          l = o[++a],
                          c = o[++a];
                        (n = l),
                          (r = u),
                          (i = void 0),
                          ((i = Wt.lFrame).bindingIndex = i.bindingRootIndex =
                            n),
                          Xt(r),
                          c(2, t[u]);
                      }
                    }
                  } finally {
                    fn(-1);
                  }
              })(e, t);
              var h = e.components;
              null !== h &&
                (function (e, t) {
                  for (var n = 0; n < t.length; n++) Yi(e, t[n]);
                })(t, h);
              var f = e.viewQuery;
              if ((null !== f && oo(2, f, r), !o))
                if (a) {
                  var d = e.viewCheckHooks;
                  null !== d && pn(t, d);
                } else {
                  var v = e.viewHooks;
                  null !== v && mn(t, v, 2), gn(t, 2);
                }
              !0 === e.firstUpdatePass && (e.firstUpdatePass = !1),
                o || (t[2] &= -73),
                1024 & t[2] && ((t[2] &= -1025), Lt(t[3], -1));
            } finally {
              cn();
            }
          }
        }
        function Pi(e, t, n, r) {
          var i = t[10],
            o = !Zt(),
            a = Nt(t);
          try {
            o && !a && i.begin && i.begin(), a && Oi(e, t, r), Ri(e, t, n, r);
          } finally {
            o && !a && i.end && i.end();
          }
        }
        function Di(e, t, n, r, i) {
          var o = hn(),
            a = 2 & r;
          try {
            fn(-1), a && t.length > 20 && ki(e, t, 20, Zt()), n(r, i);
          } finally {
            fn(o);
          }
        }
        function Ni(e, t, n) {
          zt() &&
            ((function (e, t, n, r) {
              var i = n.directiveStart,
                o = n.directiveEnd;
              e.firstCreatePass || Pn(n, t), Ir(r, t);
              for (var a = n.initialInputs, s = i; s < o; s++) {
                var u = e.data[s],
                  l = gt(u);
                l && Ki(t, n, u);
                var c = Vn(t, e, s, n);
                Ir(c, t),
                  null !== a && Zi(0, s - i, c, u, 0, a),
                  l && (Pt(n.index, t)[8] = c);
              }
            })(e, t, n, Ot(n, t)),
            128 == (128 & n.flags) &&
              (function (e, t, n) {
                var r = n.directiveStart,
                  i = n.directiveEnd,
                  o = n.index,
                  a = Wt.lFrame.currentDirectiveIndex;
                try {
                  fn(o);
                  for (var s = r; s < i; s++) {
                    var u = e.data[s],
                      l = t[s];
                    Xt(s),
                      (null === u.hostBindings &&
                        0 === u.hostVars &&
                        null === u.hostAttrs) ||
                        Ui(u, l);
                  }
                } finally {
                  fn(-1), Xt(a);
                }
              })(e, t, n));
        }
        function Mi(e, t) {
          var n =
              arguments.length > 2 && void 0 !== arguments[2]
                ? arguments[2]
                : Ot,
            r = t.localNames;
          if (null !== r)
            for (var i = t.index + 1, o = 0; o < r.length; o += 2) {
              var a = r[o + 1],
                s = -1 === a ? n(t, e) : e[a];
              e[i++] = s;
            }
        }
        function ji(e) {
          var t = e.tView;
          return null === t || t.incompleteFirstPass
            ? (e.tView = Fi(
                1,
                null,
                e.template,
                e.decls,
                e.vars,
                e.directiveDefs,
                e.pipeDefs,
                e.viewQuery,
                e.schemas,
                e.consts
              ))
            : t;
        }
        function Fi(e, t, n, r, i, o, a, s, u, l) {
          var c = 20 + r,
            h = c + i,
            f = (function (e, t) {
              for (var n = [], r = 0; r < t; r++) n.push(r < e ? null : gi);
              return n;
            })(c, h),
            d = "function" == typeof l ? l() : l;
          return (f[1] = {
            type: e,
            blueprint: f,
            template: n,
            queries: null,
            viewQuery: s,
            declTNode: t,
            data: f.slice().fill(null, c),
            bindingStartIndex: c,
            expandoStartIndex: h,
            hostBindingOpCodes: null,
            firstCreatePass: !0,
            firstUpdatePass: !0,
            staticViewQueries: !1,
            staticContentQueries: !1,
            preOrderHooks: null,
            preOrderCheckHooks: null,
            contentHooks: null,
            contentCheckHooks: null,
            viewHooks: null,
            viewCheckHooks: null,
            destroyHooks: null,
            cleanup: null,
            contentQueries: null,
            components: null,
            directiveRegistry: "function" == typeof o ? o() : o,
            pipeRegistry: "function" == typeof a ? a() : a,
            firstChild: null,
            schemas: u,
            consts: d,
            incompleteFirstPass: !1,
          });
        }
        function Li(e, t, n, r) {
          var i = so(t);
          null === n
            ? i.push(r)
            : (i.push(n), e.firstCreatePass && uo(e).push(r, i.length - 1));
        }
        function Wi(e, t, n) {
          for (var r in e)
            if (e.hasOwnProperty(r)) {
              var i = e[r];
              (n = null === n ? {} : n).hasOwnProperty(r)
                ? n[r].push(t, i)
                : (n[r] = [t, i]);
            }
          return n;
        }
        function zi(e, t, n, r, i, o, a, s) {
          var u,
            l,
            c = Ot(t, n),
            h = t.inputs;
          !s && null != h && (u = h[r])
            ? (co(e, n, u, r, i),
              pt(t) &&
                (function (e, t) {
                  var n = Pt(t, e);
                  16 & n[2] || (n[2] |= 64);
                })(n, t.index))
            : 3 & t.type &&
              ((r =
                "class" === (l = r)
                  ? "className"
                  : "for" === l
                  ? "htmlFor"
                  : "formaction" === l
                  ? "formAction"
                  : "innerHtml" === l
                  ? "innerHTML"
                  : "readonly" === l
                  ? "readOnly"
                  : "tabindex" === l
                  ? "tabIndex"
                  : l),
              (i = null != a ? a(i, t.value || "", r) : i),
              Tt(o)
                ? o.setProperty(c, r, i)
                : Sn(r) || (c.setProperty ? c.setProperty(r, i) : (c[r] = i)));
        }
        function qi(e, t, n, r) {
          var i = !1;
          if (zt()) {
            var o = (function (e, t, n) {
                var r = e.directiveRegistry,
                  i = null;
                if (r)
                  for (var o = 0; o < r.length; o++) {
                    var a = r[o];
                    di(n, a.selectors, !1) &&
                      (i || (i = []),
                      jn(Pn(n, t), e, a.type),
                      gt(a) ? (Vi(e, n), i.unshift(a)) : i.push(a));
                  }
                return i;
              })(e, t, n),
              a = null === r ? null : { "": -1 };
            if (null !== o) {
              (i = !0), Qi(n, e.data.length, o.length);
              for (var s = 0; s < o.length; s++) {
                var u = o[s];
                u.providersResolver && u.providersResolver(u);
              }
              for (
                var l = !1, c = !1, h = Ai(e, t, o.length, null), f = 0;
                f < o.length;
                f++
              ) {
                var d = o[f];
                (n.mergedAttrs = Cn(n.mergedAttrs, d.hostAttrs)),
                  Gi(e, n, t, h, d),
                  Bi(h, d, a),
                  null !== d.contentQueries && (n.flags |= 8),
                  (null === d.hostBindings &&
                    null === d.hostAttrs &&
                    0 === d.hostVars) ||
                    (n.flags |= 128);
                var v = d.type.prototype;
                !l &&
                  (v.ngOnChanges || v.ngOnInit || v.ngDoCheck) &&
                  ((e.preOrderHooks || (e.preOrderHooks = [])).push(n.index),
                  (l = !0)),
                  c ||
                    (!v.ngOnChanges && !v.ngDoCheck) ||
                    ((e.preOrderCheckHooks || (e.preOrderCheckHooks = [])).push(
                      n.index
                    ),
                    (c = !0)),
                  h++;
              }
              !(function (e, t) {
                for (
                  var n = t.directiveEnd,
                    r = e.data,
                    i = t.attrs,
                    o = [],
                    a = null,
                    s = null,
                    u = t.directiveStart;
                  u < n;
                  u++
                ) {
                  var l = r[u],
                    c = l.inputs,
                    h = null === i || ui(t) ? null : Ji(c, i);
                  o.push(h), (a = Wi(c, u, a)), (s = Wi(l.outputs, u, s));
                }
                null !== a &&
                  (a.hasOwnProperty("class") && (t.flags |= 16),
                  a.hasOwnProperty("style") && (t.flags |= 32)),
                  (t.initialInputs = o),
                  (t.inputs = a),
                  (t.outputs = s);
              })(e, n);
            }
            a &&
              (function (e, t, n) {
                if (t)
                  for (
                    var r = (e.localNames = []), i = 0;
                    i < t.length;
                    i += 2
                  ) {
                    var o = n[t[i + 1]];
                    if (null == o)
                      throw new we(
                        "301",
                        "Export of name '".concat(t[i + 1], "' not found!")
                      );
                    r.push(t[i], o);
                  }
              })(n, r, a);
          }
          return (n.mergedAttrs = Cn(n.mergedAttrs, n.attrs)), i;
        }
        function Hi(e, t, n, r, i, o) {
          var a = o.hostBindings;
          if (a) {
            var s = e.hostBindingOpCodes;
            null === s && (s = e.hostBindingOpCodes = []);
            var u = ~t.index;
            (function (e) {
              for (var t = e.length; t > 0; ) {
                var n = e[--t];
                if ("number" == typeof n && n < 0) return n;
              }
              return 0;
            })(s) != u && s.push(u),
              s.push(r, i, a);
          }
        }
        function Ui(e, t) {
          null !== e.hostBindings && e.hostBindings(1, t);
        }
        function Vi(e, t) {
          (t.flags |= 2), (e.components || (e.components = [])).push(t.index);
        }
        function Bi(e, t, n) {
          if (n) {
            if (t.exportAs)
              for (var r = 0; r < t.exportAs.length; r++) n[t.exportAs[r]] = e;
            gt(t) && (n[""] = e);
          }
        }
        function Qi(e, t, n) {
          (e.flags |= 1),
            (e.directiveStart = t),
            (e.directiveEnd = t + n),
            (e.providerIndexes = t);
        }
        function Gi(e, t, n, r, i) {
          e.data[r] = i;
          var o = i.factory || (i.factory = yt(i.type)),
            a = new bn(o, gt(i), null);
          (e.blueprint[r] = a),
            (n[r] = a),
            Hi(e, t, 0, r, Ai(e, n, i.hostVars, gi), i);
        }
        function Ki(e, t, n) {
          var r = Ot(t, e),
            i = ji(n),
            o = e[10],
            a = to(
              e,
              xi(
                e,
                i,
                null,
                n.onPush ? 64 : 16,
                r,
                t,
                o,
                o.createRenderer(r, n),
                null,
                null
              )
            );
          e[t.index] = a;
        }
        function Zi(e, t, n, r, i, o) {
          var a = o[t];
          if (null !== a)
            for (var s = r.setInput, u = 0; u < a.length; ) {
              var l = a[u++],
                c = a[u++],
                h = a[u++];
              null !== s ? r.setInput(n, h, l, c) : (n[c] = h);
            }
        }
        function Ji(e, t) {
          for (var n = null, r = 0; r < t.length; ) {
            var i = t[r];
            if (0 !== i)
              if (5 !== i) {
                if ("number" == typeof i) break;
                e.hasOwnProperty(i) &&
                  (null === n && (n = []), n.push(i, e[i], t[r + 1])),
                  (r += 2);
              } else r += 2;
            else r += 4;
          }
          return n;
        }
        function $i(e, t, n, r) {
          return new Array(e, !0, !1, t, null, 0, r, n, null, null);
        }
        function Yi(e, t) {
          var n = Pt(t, e);
          if (Mt(n)) {
            var r = n[1];
            80 & n[2] ? Ri(r, n, r.template, n[8]) : n[5] > 0 && Xi(n);
          }
        }
        function Xi(e) {
          for (var t = Mr(e); null !== t; t = jr(t))
            for (var n = 10; n < t.length; n++) {
              var r = t[n];
              if (1024 & r[2]) {
                var i = r[1];
                Ri(i, r, i.template, r[8]);
              } else r[5] > 0 && Xi(r);
            }
          var o = e[1].components;
          if (null !== o)
            for (var a = 0; a < o.length; a++) {
              var s = Pt(o[a], e);
              Mt(s) && s[5] > 0 && Xi(s);
            }
        }
        function eo(e, t) {
          var n = Pt(t, e),
            r = n[1];
          !(function (e, t) {
            for (var n = t.length; n < e.blueprint.length; n++)
              t.push(e.blueprint[n]);
          })(r, n),
            Oi(r, n, n[8]);
        }
        function to(e, t) {
          return e[13] ? (e[14][4] = t) : (e[13] = t), (e[14] = t), t;
        }
        function no(e) {
          for (; e; ) {
            e[2] |= 64;
            var t = Nr(e);
            if (0 != (512 & e[2]) && !t) return e;
            e = t;
          }
          return null;
        }
        function ro(e, t, n) {
          var r = t[10];
          r.begin && r.begin();
          try {
            Ri(e, t, e.template, n);
          } catch (i) {
            throw (lo(t, i), i);
          } finally {
            r.end && r.end();
          }
        }
        function io(e) {
          !(function (e) {
            for (var t = 0; t < e.components.length; t++) {
              var n = e.components[t],
                r = Dt(n),
                i = r[1];
              Pi(i, r, i.template, n);
            }
          })(e[8]);
        }
        function oo(e, t, n) {
          tn(0), t(e, n);
        }
        var ao = Promise.resolve(null);
        function so(e) {
          return e[7] || (e[7] = []);
        }
        function uo(e) {
          return e.cleanup || (e.cleanup = []);
        }
        function lo(e, t) {
          var n = e[9],
            r = n ? n.get(xr, null) : null;
          r && r.handleError(t);
        }
        function co(e, t, n, r, i) {
          for (var o = 0; o < n.length; ) {
            var a = n[o++],
              s = n[o++],
              u = t[a],
              l = e.data[a];
            null !== l.setInput ? l.setInput(u, i, r, s) : (u[s] = i);
          }
        }
        function ho(e, t, n) {
          var r = n ? e.styles : null,
            i = n ? e.classes : null,
            o = 0;
          if (null !== t)
            for (var a = 0; a < t.length; a++) {
              var s = t[a];
              "number" == typeof s
                ? (o = s)
                : 1 == o
                ? (i = ge(i, s))
                : 2 == o && (r = ge(r, s + ": " + t[++a] + ";"));
            }
          n ? (e.styles = r) : (e.stylesWithoutHost = r),
            n ? (e.classes = i) : (e.classesWithoutHost = i);
        }
        var fo,
          vo = new Jn("INJECTOR", -1),
          po = (function () {
            function e() {
              p(this, e);
            }
            return (
              g(e, [
                {
                  key: "get",
                  value: function (e) {
                    var t =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : sr;
                    if (t === sr) {
                      var n = new Error(
                        "NullInjectorError: No provider for ".concat(me(e), "!")
                      );
                      throw ((n.name = "NullInjectorError"), n);
                    }
                    return t;
                  },
                },
              ]),
              e
            );
          })(),
          mo = new Jn("Set Injector scope."),
          go = {},
          yo = {},
          ko = [];
        function bo() {
          return void 0 === fo && (fo = new po()), fo;
        }
        function wo(e) {
          var t =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : null,
            n =
              arguments.length > 2 && void 0 !== arguments[2]
                ? arguments[2]
                : null,
            r = arguments.length > 3 ? arguments[3] : void 0;
          return new _o(e, n, t || bo(), r);
        }
        var _o = (function () {
          function e(t, n, r) {
            var i = this,
              o =
                arguments.length > 3 && void 0 !== arguments[3]
                  ? arguments[3]
                  : null;
            p(this, e),
              (this.parent = r),
              (this.records = new Map()),
              (this.injectorDefTypes = new Set()),
              (this.onDestroy = new Set()),
              (this._destroyed = !1);
            var a = [];
            n &&
              er(n, function (e) {
                return i.processProvider(e, t, n);
              }),
              er([t], function (e) {
                return i.processInjectorType(e, [], a);
              }),
              this.records.set(vo, Co(void 0, this));
            var s = this.records.get(mo);
            (this.scope = null != s ? s.value : null),
              (this.source = o || ("object" == typeof t ? null : me(t)));
          }
          return (
            g(e, [
              {
                key: "destroyed",
                get: function () {
                  return this._destroyed;
                },
              },
              {
                key: "destroy",
                value: function () {
                  this.assertNotDestroyed(), (this._destroyed = !0);
                  try {
                    this.onDestroy.forEach(function (e) {
                      return e.ngOnDestroy();
                    });
                  } finally {
                    this.records.clear(),
                      this.onDestroy.clear(),
                      this.injectorDefTypes.clear();
                  }
                },
              },
              {
                key: "get",
                value: function (e) {
                  var t =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : sr,
                    n =
                      arguments.length > 2 && void 0 !== arguments[2]
                        ? arguments[2]
                        : Me.Default;
                  this.assertNotDestroyed();
                  var r,
                    i = cr(this);
                  try {
                    if (!(n & Me.SkipSelf)) {
                      var o = this.records.get(e);
                      if (void 0 === o) {
                        var a =
                          ("function" == typeof (r = e) ||
                            ("object" == typeof r && r instanceof Jn)) &&
                          xe(e);
                        (o =
                          a && this.injectableDefInScope(a)
                            ? Co(So(e), go)
                            : null),
                          this.records.set(e, o);
                      }
                      if (null != o) return this.hydrate(e, o);
                    }
                    return (n & Me.Self ? bo() : this.parent).get(
                      e,
                      (t = n & Me.Optional && t === sr ? null : t)
                    );
                  } catch (s) {
                    if ("NullInjectorError" === s.name) {
                      if (
                        ((s.ngTempTokenPath = s.ngTempTokenPath || []).unshift(
                          me(e)
                        ),
                        i)
                      )
                        throw s;
                      return (function (e, t, n, r) {
                        var i = e.ngTempTokenPath;
                        throw (
                          (t.__source && i.unshift(t.__source),
                          (e.message = (function (e, t, n) {
                            var r =
                              arguments.length > 3 && void 0 !== arguments[3]
                                ? arguments[3]
                                : null;
                            e =
                              e &&
                              "\n" === e.charAt(0) &&
                              "\u0275" == e.charAt(1)
                                ? e.substr(2)
                                : e;
                            var i = me(t);
                            if (Array.isArray(t)) i = t.map(me).join(" -> ");
                            else if ("object" == typeof t) {
                              var o = [];
                              for (var a in t)
                                if (t.hasOwnProperty(a)) {
                                  var s = t[a];
                                  o.push(
                                    a +
                                      ":" +
                                      ("string" == typeof s
                                        ? JSON.stringify(s)
                                        : me(s))
                                  );
                                }
                              i = "{".concat(o.join(", "), "}");
                            }
                            return ""
                              .concat(n)
                              .concat(r ? "(" + r + ")" : "", "[")
                              .concat(i, "]: ")
                              .concat(e.replace(ur, "\n  "));
                          })("\n" + e.message, i, "R3InjectorError", r)),
                          (e.ngTokenPath = i),
                          (e.ngTempTokenPath = null),
                          e)
                        );
                      })(s, e, 0, this.source);
                    }
                    throw s;
                  } finally {
                    cr(i);
                  }
                },
              },
              {
                key: "_resolveInjectorDefTypes",
                value: function () {
                  var e = this;
                  this.injectorDefTypes.forEach(function (t) {
                    return e.get(t);
                  });
                },
              },
              {
                key: "toString",
                value: function () {
                  var e = [];
                  return (
                    this.records.forEach(function (t, n) {
                      return e.push(me(n));
                    }),
                    "R3Injector[".concat(e.join(", "), "]")
                  );
                },
              },
              {
                key: "assertNotDestroyed",
                value: function () {
                  if (this._destroyed)
                    throw new Error("Injector has already been destroyed.");
                },
              },
              {
                key: "processInjectorType",
                value: function (e, t, n) {
                  var r = this;
                  if (!(e = be(e))) return !1;
                  var i = Ae(e),
                    o = (null == i && e.ngModule) || void 0,
                    a = void 0 === o ? e : o,
                    s = -1 !== n.indexOf(a);
                  if ((void 0 !== o && (i = Ae(o)), null == i)) return !1;
                  if (null != i.imports && !s) {
                    var u;
                    n.push(a);
                    try {
                      er(i.imports, function (e) {
                        r.processInjectorType(e, t, n) &&
                          (void 0 === u && (u = []), u.push(e));
                      });
                    } finally {
                    }
                    if (void 0 !== u)
                      for (
                        var l = function (e) {
                            var t = u[e],
                              n = t.ngModule,
                              i = t.providers;
                            er(i, function (e) {
                              return r.processProvider(e, n, i || ko);
                            });
                          },
                          c = 0;
                        c < u.length;
                        c++
                      )
                        l(c);
                  }
                  this.injectorDefTypes.add(a);
                  var h =
                    yt(a) ||
                    function () {
                      return new a();
                    };
                  this.records.set(a, Co(h, go));
                  var f = i.providers;
                  if (null != f && !s) {
                    var d = e;
                    er(f, function (e) {
                      return r.processProvider(e, d, f);
                    });
                  }
                  return void 0 !== o && void 0 !== e.providers;
                },
              },
              {
                key: "processProvider",
                value: function (e, t, r) {
                  var i = To((e = be(e))) ? e : be(e && e.provide),
                    o = (function (e, t, r) {
                      return Eo(e)
                        ? Co(void 0, e.useValue)
                        : Co(
                            (function (e, t, r) {
                              var i, o;
                              if (To(e)) {
                                var s = be(e);
                                return yt(s) || So(s);
                              }
                              if (Eo(e))
                                i = function () {
                                  return be(e.useValue);
                                };
                              else if ((o = e) && o.useFactory)
                                i = function () {
                                  return e.useFactory.apply(
                                    e,
                                    n(dr(e.deps || []))
                                  );
                                };
                              else if (
                                (function (e) {
                                  return !(!e || !e.useExisting);
                                })(e)
                              )
                                i = function () {
                                  return fr(be(e.useExisting));
                                };
                              else {
                                var u = be(e && (e.useClass || e.provide));
                                if (
                                  !(function (e) {
                                    return !!e.deps;
                                  })(e)
                                )
                                  return yt(u) || So(u);
                                i = function () {
                                  return a(u, n(dr(e.deps)));
                                };
                              }
                              return i;
                            })(e),
                            go
                          );
                    })(e);
                  if (To(e) || !0 !== e.multi) this.records.get(i);
                  else {
                    var s = this.records.get(i);
                    s ||
                      (((s = Co(void 0, go, !0)).factory = function () {
                        return dr(s.multi);
                      }),
                      this.records.set(i, s)),
                      (i = e),
                      s.multi.push(e);
                  }
                  this.records.set(i, o);
                },
              },
              {
                key: "hydrate",
                value: function (e, t) {
                  var n;
                  return (
                    t.value === go && ((t.value = yo), (t.value = t.factory())),
                    "object" == typeof t.value &&
                      t.value &&
                      null !== (n = t.value) &&
                      "object" == typeof n &&
                      "function" == typeof n.ngOnDestroy &&
                      this.onDestroy.add(t.value),
                    t.value
                  );
                },
              },
              {
                key: "injectableDefInScope",
                value: function (e) {
                  return (
                    !!e.providedIn &&
                    ("string" == typeof e.providedIn
                      ? "any" === e.providedIn || e.providedIn === this.scope
                      : this.injectorDefTypes.has(e.providedIn))
                  );
                },
              },
            ]),
            e
          );
        })();
        function So(e) {
          var t = xe(e),
            n = null !== t ? t.factory : yt(e);
          if (null !== n) return n;
          if (e instanceof Jn)
            throw new Error(
              "Token ".concat(me(e), " is missing a \u0275prov definition.")
            );
          if (e instanceof Function)
            return (function (e) {
              var t = e.length;
              if (t > 0) {
                var n = rr(t, "?");
                throw new Error(
                  "Can't resolve all parameters for "
                    .concat(me(e), ": (")
                    .concat(n.join(", "), ").")
                );
              }
              var r = (function (e) {
                var t = e && (e[Re] || e[De]);
                if (t) {
                  var n = (function (e) {
                    if (e.hasOwnProperty("name")) return e.name;
                    var t = ("" + e).match(/^function\s*([^\s(]+)/);
                    return null === t ? "" : t[1];
                  })(e);
                  return (
                    console.warn(
                      'DEPRECATED: DI is instantiating a token "'
                        .concat(
                          n,
                          '" that inherits its @Injectable decorator but does not provide one itself.\nThis will become an error in a future version of Angular. Please add @Injectable() to the "'
                        )
                        .concat(n, '" class.')
                    ),
                    t
                  );
                }
                return null;
              })(e);
              return null !== r
                ? function () {
                    return r.factory(e);
                  }
                : function () {
                    return new e();
                  };
            })(e);
          throw new Error("unreachable");
        }
        function Co(e, t) {
          var n =
            arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
          return { factory: e, value: t, multi: n ? [] : void 0 };
        }
        function Eo(e) {
          return null !== e && "object" == typeof e && lr in e;
        }
        function To(e) {
          return "function" == typeof e;
        }
        var xo = function (e, t, n) {
            return (function (e) {
              var t =
                  arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : null,
                n =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : null,
                r = arguments.length > 3 ? arguments[3] : void 0,
                i = wo(e, t, n, r);
              return i._resolveInjectorDefTypes(), i;
            })({ name: n }, t, e, n);
          },
          Io = (function () {
            var e = (function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, null, [
                  {
                    key: "create",
                    value: function (e, t) {
                      return Array.isArray(e)
                        ? xo(e, t, "")
                        : xo(e.providers, e.parent, e.name || "");
                    },
                  },
                ]),
                e
              );
            })();
            return (
              (e.THROW_IF_NOT_FOUND = sr),
              (e.NULL = new po()),
              (e.ɵprov = Ee({
                token: e,
                providedIn: "any",
                factory: function () {
                  return fr(vo);
                },
              })),
              (e.__NG_ELEMENT_ID__ = -1),
              e
            );
          })();
        function Ao(e, t) {
          vn(Dt(e)[1], Vt());
        }
        var Oo = null;
        function Ro() {
          if (!Oo) {
            var e = Be.Symbol;
            if (e && e.iterator) Oo = e.iterator;
            else
              for (
                var t = Object.getOwnPropertyNames(Map.prototype), n = 0;
                n < t.length;
                ++n
              ) {
                var r = t[n];
                "entries" !== r &&
                  "size" !== r &&
                  Map.prototype[r] === Map.prototype.entries &&
                  (Oo = r);
              }
          }
          return Oo;
        }
        function Po(e) {
          return (
            !!Do(e) && (Array.isArray(e) || (!(e instanceof Map) && Ro() in e))
          );
        }
        function Do(e) {
          return null !== e && ("function" == typeof e || "object" == typeof e);
        }
        function No(e, t, n) {
          return (e[t] = n);
        }
        function Mo(e, t, n) {
          return !Object.is(e[t], n) && ((e[t] = n), !0);
        }
        function jo(e, t, n, r) {
          var i = qt();
          return (
            Mo(i, Yt(), t) &&
              (Ht(),
              (function (e, t, n, r, i, o) {
                var a = Ot(e, t);
                !(function (e, t, n, r, i, o, a) {
                  if (null == o)
                    Tt(e) ? e.removeAttribute(t, i, n) : t.removeAttribute(i);
                  else {
                    var s = null == a ? _e(o) : a(o, r || "", i);
                    Tt(e)
                      ? e.setAttribute(t, i, s, n)
                      : n
                      ? t.setAttributeNS(n, i, s)
                      : t.setAttribute(i, s);
                  }
                })(t[11], a, o, e.value, n, r, i);
              })(dn(), i, e, t, n, r)),
            jo
          );
        }
        function Fo(e, t, n, r, i, o, a, s) {
          var u = qt(),
            l = Ht(),
            c = e + 20,
            h = l.firstCreatePass
              ? (function (e, t, n, r, i, o, a, s, u) {
                  var l = t.consts,
                    c = Ii(t, e, 4, a || null, jt(l, s));
                  qi(t, n, c, jt(l, u)), vn(t, c);
                  var h = (c.tViews = Fi(
                    2,
                    c,
                    r,
                    i,
                    o,
                    t.directiveRegistry,
                    t.pipeRegistry,
                    null,
                    t.schemas,
                    l
                  ));
                  return (
                    null !== t.queries &&
                      (t.queries.template(t, c),
                      (h.queries = t.queries.embeddedTView(c))),
                    c
                  );
                })(c, l, u, t, n, r, i, o, a)
              : l.data[c];
          Qt(h, !1);
          var f = u[11].createComment("");
          $r(l, u, f, h),
            Ir(f, u),
            to(u, (u[c] = $i(f, u, f, h))),
            mt(h) && Ni(l, u, h),
            null != a && Mi(u, h, s);
        }
        function Lo(e) {
          return (function (e, t) {
            return e[t];
          })(Wt.lFrame.contextLView, 20 + e);
        }
        function Wo(e) {
          var t =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : Me.Default,
            n = qt();
          return null === n ? fr(e, t) : Wn(Vt(), n, be(e), t);
        }
        function zo(e, t, n) {
          var r = qt();
          return Mo(r, Yt(), t) && zi(Ht(), dn(), r, e, t, r[11], n, !1), zo;
        }
        function qo(e, t, n, r, i) {
          var o = i ? "class" : "style";
          co(e, n, t.inputs[o], o, r);
        }
        function Ho(e, t, n, r) {
          var i = qt(),
            o = Ht(),
            a = 20 + e,
            s = i[11],
            u = (i[a] = Wr(s, t, Wt.lFrame.currentNamespace)),
            l = o.firstCreatePass
              ? (function (e, t, n, r, i, o, a) {
                  var s = t.consts,
                    u = Ii(t, e, 2, i, jt(s, o));
                  return (
                    qi(t, n, u, jt(s, a)),
                    null !== u.attrs && ho(u, u.attrs, !1),
                    null !== u.mergedAttrs && ho(u, u.mergedAttrs, !0),
                    null !== t.queries && t.queries.elementStart(t, u),
                    u
                  );
                })(a, o, i, 0, t, n, r)
              : o.data[a];
          Qt(l, !0);
          var c = l.mergedAttrs;
          null !== c && wn(s, u, c);
          var h = l.classes;
          null !== h && oi(s, u, h);
          var f = l.styles;
          null !== f && ii(s, u, f),
            64 != (64 & l.flags) && $r(o, i, u, l),
            0 === Wt.lFrame.elementDepthCount && Ir(u, i),
            Wt.lFrame.elementDepthCount++,
            mt(l) &&
              (Ni(o, i, l),
              (function (e, t, n) {
                if (vt(t))
                  for (
                    var r = t.directiveEnd, i = t.directiveStart;
                    i < r;
                    i++
                  ) {
                    var o = e.data[i];
                    o.contentQueries && o.contentQueries(1, n[i], i);
                  }
              })(o, l, i)),
            null !== r && Mi(i, l);
        }
        function Uo() {
          var e = Vt();
          Gt() ? Kt() : Qt((e = e.parent), !1);
          var t = e;
          Wt.lFrame.elementDepthCount--;
          var n = Ht();
          n.firstCreatePass && (vn(n, e), vt(e) && n.queries.elementEnd(e)),
            null != t.classesWithoutHost &&
              (function (e) {
                return 0 != (16 & e.flags);
              })(t) &&
              qo(n, t, qt(), t.classesWithoutHost, !0),
            null != t.stylesWithoutHost &&
              (function (e) {
                return 0 != (32 & e.flags);
              })(t) &&
              qo(n, t, qt(), t.stylesWithoutHost, !1);
        }
        function Vo(e, t, n, r) {
          Ho(e, t, n, r), Uo();
        }
        function Bo() {
          return qt();
        }
        function Qo(e) {
          return !!e && "function" == typeof e.then;
        }
        function Go(e, t) {
          var n =
              arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
            r = arguments.length > 3 ? arguments[3] : void 0,
            i = qt(),
            o = Ht(),
            a = Vt();
          return (
            (function (e, t, n, r, i, o) {
              var a =
                  arguments.length > 6 &&
                  void 0 !== arguments[6] &&
                  arguments[6],
                s = arguments.length > 7 ? arguments[7] : void 0,
                u = mt(r),
                l = e.firstCreatePass && uo(e),
                c = so(t),
                h = !0;
              if (3 & r.type) {
                var f = Ot(r, t),
                  d = s ? s(f) : Qe,
                  v = d.target || f,
                  p = c.length,
                  m = s
                    ? function (e) {
                        return s(It(e[r.index])).target;
                      }
                    : r.index;
                if (Tt(n)) {
                  var g = null;
                  if (
                    (!s &&
                      u &&
                      (g = (function (e, t, n, r) {
                        var i = e.cleanup;
                        if (null != i)
                          for (var o = 0; o < i.length - 1; o += 2) {
                            var a = i[o];
                            if (a === n && i[o + 1] === r) {
                              var s = t[7],
                                u = i[o + 2];
                              return s.length > u ? s[u] : null;
                            }
                            "string" == typeof a && (o += 2);
                          }
                        return null;
                      })(e, t, i, r.index)),
                    null !== g)
                  )
                    ((g.__ngLastListenerFn__ || g).__ngNextListenerFn__ = o),
                      (g.__ngLastListenerFn__ = o),
                      (h = !1);
                  else {
                    o = Zo(r, t, 0, o, !1);
                    var y = n.listen(d.name || v, i, o);
                    c.push(o, y), l && l.push(i, m, p, p + 1);
                  }
                } else
                  (o = Zo(r, t, 0, o, !0)),
                    v.addEventListener(i, o, a),
                    c.push(o),
                    l && l.push(i, m, p, a);
              } else o = Zo(r, t, 0, o, !1);
              var k,
                b = r.outputs;
              if (h && null !== b && (k = b[i])) {
                var w = k.length;
                if (w)
                  for (var _ = 0; _ < w; _ += 2) {
                    var S = t[k[_]][k[_ + 1]].subscribe(o),
                      C = c.length;
                    c.push(o, S), l && l.push(i, r.index, C, -(C + 1));
                  }
              }
            })(o, i, i[11], a, e, t, n, r),
            Go
          );
        }
        function Ko(e, t, n, r) {
          try {
            return !1 !== n(r);
          } catch (i) {
            return lo(e, i), !1;
          }
        }
        function Zo(e, t, n, r, i) {
          return function n(o) {
            if (o === Function) return r;
            var a = 2 & e.flags ? Pt(e.index, t) : t;
            0 == (32 & t[2]) && no(a);
            for (var s = Ko(t, 0, r, o), u = n.__ngNextListenerFn__; u; )
              (s = Ko(t, 0, u, o) && s), (u = u.__ngNextListenerFn__);
            return (
              i && !1 === s && (o.preventDefault(), (o.returnValue = !1)), s
            );
          };
        }
        function Jo() {
          var e =
            arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1;
          return (function (e) {
            return (Wt.lFrame.contextLView = (function (e, t) {
              for (; e > 0; ) (t = t[15]), e--;
              return t;
            })(e, Wt.lFrame.contextLView))[8];
          })(e);
        }
        function $o(e, t) {
          for (
            var n = null,
              r = (function (e) {
                var t = e.attrs;
                if (null != t) {
                  var n = t.indexOf(5);
                  if (0 == (1 & n)) return t[n + 1];
                }
                return null;
              })(e),
              i = 0;
            i < t.length;
            i++
          ) {
            var o = t[i];
            if ("*" !== o) {
              if (null === r ? di(e, o, !0) : vi(r, o)) return i;
            } else n = i;
          }
          return n;
        }
        function Yo(e) {
          var t = qt()[16][6];
          if (!t.projection)
            for (
              var n = (t.projection = rr(e ? e.length : 1, null)),
                r = n.slice(),
                i = t.child;
              null !== i;

            ) {
              var o = e ? $o(i, e) : 0;
              null !== o &&
                (r[o] ? (r[o].projectionNext = i) : (n[o] = i), (r[o] = i)),
                (i = i.next);
            }
        }
        function Xo(e) {
          var t =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : 0,
            n = arguments.length > 2 ? arguments[2] : void 0,
            r = qt(),
            i = Ht(),
            o = Ii(i, 20 + e, 16, null, n || null);
          null === o.projection && (o.projection = t),
            Kt(),
            64 != (64 & o.flags) &&
              (function (e, t, n) {
                ri(t[11], 0, t, n, Vr(e, n, t), Zr(n.parent || t[6], n, t));
              })(i, r, o);
        }
        function ea(e, t, n, r, i) {
          for (
            var o = e[n + 1], a = null === t, s = r ? wi(o) : Si(o), u = !1;
            0 !== s && (!1 === u || a);

          ) {
            var l = e[s + 1];
            ta(e[s], t) && ((u = !0), (e[s + 1] = r ? Ei(l) : _i(l))),
              (s = r ? wi(l) : Si(l));
          }
          u && (e[n + 1] = r ? _i(o) : Ei(o));
        }
        function ta(e, t) {
          return (
            null === e ||
            null == t ||
            (Array.isArray(e) ? e[1] : e) === t ||
            (!(!Array.isArray(e) || "string" != typeof t) && or(e, t) >= 0)
          );
        }
        function na(e, t, n) {
          return ia(e, t, n, !1), na;
        }
        function ra(e, t) {
          return ia(e, t, null, !0), ra;
        }
        function ia(e, t, n, r) {
          var i,
            o,
            a = qt(),
            s = Ht(),
            u =
              ((i = Wt.lFrame),
              (o = i.bindingIndex),
              (i.bindingIndex = i.bindingIndex + 2),
              o);
          s.firstUpdatePass &&
            (function (e, t, n, r) {
              var i = e.data;
              if (null === i[n + 1]) {
                var o = i[hn()],
                  a = (function (e, t) {
                    return t >= e.expandoStartIndex;
                  })(e, n);
                (function (e, t) {
                  return 0 != (e.flags & (t ? 16 : 32));
                })(o, r) &&
                  null === t &&
                  !a &&
                  (t = !1),
                  (t = (function (e, t, n, r) {
                    var i = (function (e) {
                        var t = Wt.lFrame.currentDirectiveIndex;
                        return -1 === t ? null : e[t];
                      })(e),
                      o = r ? t.residualClasses : t.residualStyles;
                    if (null === i)
                      0 === (r ? t.classBindings : t.styleBindings) &&
                        ((n = aa((n = oa(null, e, t, n, r)), t.attrs, r)),
                        (o = null));
                    else {
                      var a = t.directiveStylingLast;
                      if (-1 === a || e[a] !== i)
                        if (((n = oa(i, e, t, n, r)), null === o)) {
                          var s = (function (e, t, n) {
                            var r = n ? t.classBindings : t.styleBindings;
                            if (0 !== Si(r)) return e[wi(r)];
                          })(e, t, r);
                          void 0 !== s &&
                            Array.isArray(s) &&
                            (function (e, t, n, r) {
                              e[wi(n ? t.classBindings : t.styleBindings)] = r;
                            })(
                              e,
                              t,
                              r,
                              (s = aa(
                                (s = oa(null, e, t, s[1], r)),
                                t.attrs,
                                r
                              ))
                            );
                        } else
                          o = (function (e, t, n) {
                            for (
                              var r,
                                i = t.directiveEnd,
                                o = 1 + t.directiveStylingLast;
                              o < i;
                              o++
                            )
                              r = aa(r, e[o].hostAttrs, n);
                            return aa(r, t.attrs, n);
                          })(e, t, r);
                    }
                    return (
                      void 0 !== o &&
                        (r ? (t.residualClasses = o) : (t.residualStyles = o)),
                      n
                    );
                  })(i, o, t, r)),
                  (function (e, t, n, r, i, o) {
                    var a = o ? t.classBindings : t.styleBindings,
                      s = wi(a),
                      u = Si(a);
                    e[r] = n;
                    var l,
                      c = !1;
                    if (Array.isArray(n)) {
                      var h = n;
                      (null === (l = h[1]) || or(h, l) > 0) && (c = !0);
                    } else l = n;
                    if (i)
                      if (0 !== u) {
                        var f = wi(e[s + 1]);
                        (e[r + 1] = bi(f, s)),
                          0 !== f && (e[f + 1] = Ci(e[f + 1], r)),
                          (e[s + 1] = (131071 & e[s + 1]) | (r << 17));
                      } else
                        (e[r + 1] = bi(s, 0)),
                          0 !== s && (e[s + 1] = Ci(e[s + 1], r)),
                          (s = r);
                    else
                      (e[r + 1] = bi(u, 0)),
                        0 === s ? (s = r) : (e[u + 1] = Ci(e[u + 1], r)),
                        (u = r);
                    c && (e[r + 1] = _i(e[r + 1])),
                      ea(e, l, r, !0),
                      ea(e, l, r, !1),
                      (function (e, t, n, r, i) {
                        var o = i ? e.residualClasses : e.residualStyles;
                        null != o &&
                          "string" == typeof t &&
                          or(o, t) >= 0 &&
                          (n[r + 1] = Ei(n[r + 1]));
                      })(t, l, e, r, o),
                      (a = bi(s, u)),
                      o ? (t.classBindings = a) : (t.styleBindings = a);
                  })(i, o, t, n, a, r);
              }
            })(s, e, u, r),
            t !== gi &&
              Mo(a, u, t) &&
              (function (e, t, n, r, i, o, a, s) {
                if (3 & t.type) {
                  var u = e.data,
                    l = u[s + 1];
                  ua(1 == (1 & l) ? sa(u, t, n, i, Si(l), a) : void 0) ||
                    (ua(o) || (2 == (2 & l) && (o = sa(u, null, n, i, s, a))),
                    (function (e, t, n, r, i) {
                      var o = Tt(e);
                      if (t)
                        i
                          ? o
                            ? e.addClass(n, r)
                            : n.classList.add(r)
                          : o
                          ? e.removeClass(n, r)
                          : n.classList.remove(r);
                      else {
                        var a = -1 === r.indexOf("-") ? void 0 : Pr.DashCase;
                        if (null == i)
                          o
                            ? e.removeStyle(n, r, a)
                            : n.style.removeProperty(r);
                        else {
                          var s =
                            "string" == typeof i && i.endsWith("!important");
                          s && ((i = i.slice(0, -10)), (a |= Pr.Important)),
                            o
                              ? e.setStyle(n, r, i, a)
                              : n.style.setProperty(r, i, s ? "important" : "");
                        }
                      }
                    })(r, a, At(hn(), n), i, o));
                }
              })(
                s,
                s.data[hn()],
                a,
                a[11],
                e,
                (a[u + 1] = (function (e, t) {
                  return (
                    null == e ||
                      ("string" == typeof t
                        ? (e += t)
                        : "object" == typeof e && (e = me(kr(e)))),
                    e
                  );
                })(t, n)),
                r,
                u
              );
        }
        function oa(e, t, n, r, i) {
          var o = null,
            a = n.directiveEnd,
            s = n.directiveStylingLast;
          for (
            -1 === s ? (s = n.directiveStart) : s++;
            s < a && ((r = aa(r, (o = t[s]).hostAttrs, i)), o !== e);

          )
            s++;
          return null !== e && (n.directiveStylingLast = s), r;
        }
        function aa(e, t, n) {
          var r,
            i,
            o,
            a,
            s = n ? 1 : 2,
            u = -1;
          if (null !== t)
            for (var l = 0; l < t.length; l++) {
              var c = t[l];
              "number" == typeof c
                ? (u = c)
                : u === s &&
                  (Array.isArray(e) || (e = void 0 === e ? [] : ["", e]),
                  (r = e),
                  (i = c),
                  (o = !!n || t[++l]),
                  (a = void 0),
                  (a = or(r, i)) >= 0
                    ? (r[1 | a] = o)
                    : (function (e, t, n, r) {
                        var i = e.length;
                        if (i == t) e.push(n, r);
                        else if (1 === i) e.push(r, e[0]), (e[0] = n);
                        else {
                          for (i--, e.push(e[i - 1], e[i]); i > t; )
                            (e[i] = e[i - 2]), i--;
                          (e[t] = n), (e[t + 1] = r);
                        }
                      })(r, (a = ~a), i, o));
            }
          return void 0 === e ? null : e;
        }
        function sa(e, t, n, r, i, o) {
          for (var a, s = null === t; i > 0; ) {
            var u = e[i],
              l = Array.isArray(u),
              c = l ? u[1] : u,
              h = null === c,
              f = n[i + 1];
            f === gi && (f = h ? Ge : void 0);
            var d = h ? ir(f, r) : c === r ? f : void 0;
            if ((l && !ua(d) && (d = ir(u, r)), ua(d) && ((a = d), s)))
              return a;
            var v = e[i + 1];
            i = s ? wi(v) : Si(v);
          }
          if (null !== t) {
            var p = o ? t.residualClasses : t.residualStyles;
            null != p && (a = ir(p, r));
          }
          return a;
        }
        function ua(e) {
          return void 0 !== e;
        }
        function la(e) {
          var t =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : "",
            n = qt(),
            r = Ht(),
            i = e + 20,
            o = r.firstCreatePass ? Ii(r, i, 1, t, null) : r.data[i],
            a = (n[i] = (function (e, t) {
              return Tt(e) ? e.createText(t) : e.createTextNode(t);
            })(n[11], t));
          $r(r, n, a, o), Qt(o, !1);
        }
        function ca(e) {
          return ha("", e, ""), ca;
        }
        function ha(e, t, n) {
          var r = qt(),
            i = (function (e, t, n, r) {
              return Mo(e, Yt(), n) ? t + _e(n) + r : gi;
            })(r, e, t, n);
          return (
            i !== gi &&
              (function (e, t, n) {
                var r = At(t, e);
                !(function (e, t, n) {
                  Tt(e) ? e.setValue(t, n) : (t.textContent = n);
                })(e[11], r, n);
              })(r, hn(), i),
            ha
          );
        }
        function fa(e, t, n) {
          var r = qt();
          return Mo(r, Yt(), t) && zi(Ht(), dn(), r, e, t, r[11], n, !0), fa;
        }
        var da = void 0,
          va = [
            "en",
            [["a", "p"], ["AM", "PM"], da],
            [["AM", "PM"], da, da],
            [
              ["S", "M", "T", "W", "T", "F", "S"],
              ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
              [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ],
              ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            ],
            da,
            [
              ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
              [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ],
            ],
            da,
            [
              ["B", "A"],
              ["BC", "AD"],
              ["Before Christ", "Anno Domini"],
            ],
            0,
            [6, 0],
            ["M/d/yy", "MMM d, y", "MMMM d, y", "EEEE, MMMM d, y"],
            ["h:mm a", "h:mm:ss a", "h:mm:ss a z", "h:mm:ss a zzzz"],
            ["{1}, {0}", da, "{1} 'at' {0}", da],
            [
              ".",
              ",",
              ";",
              "%",
              "+",
              "-",
              "E",
              "\xd7",
              "\u2030",
              "\u221e",
              "NaN",
              ":",
            ],
            ["#,##0.###", "#,##0%", "\xa4#,##0.00", "#E0"],
            "USD",
            "$",
            "US Dollar",
            {},
            "ltr",
            function (e) {
              var t = Math.floor(Math.abs(e)),
                n = e.toString().replace(/^[^.]*\.?/, "").length;
              return 1 === t && 0 === n ? 1 : 5;
            },
          ],
          pa = {};
        function ma(e) {
          return (
            e in pa ||
              (pa[e] =
                Be.ng &&
                Be.ng.common &&
                Be.ng.common.locales &&
                Be.ng.common.locales[e]),
            pa[e]
          );
        }
        var ga = (function (e) {
          return (
            (e[(e.LocaleId = 0)] = "LocaleId"),
            (e[(e.DayPeriodsFormat = 1)] = "DayPeriodsFormat"),
            (e[(e.DayPeriodsStandalone = 2)] = "DayPeriodsStandalone"),
            (e[(e.DaysFormat = 3)] = "DaysFormat"),
            (e[(e.DaysStandalone = 4)] = "DaysStandalone"),
            (e[(e.MonthsFormat = 5)] = "MonthsFormat"),
            (e[(e.MonthsStandalone = 6)] = "MonthsStandalone"),
            (e[(e.Eras = 7)] = "Eras"),
            (e[(e.FirstDayOfWeek = 8)] = "FirstDayOfWeek"),
            (e[(e.WeekendRange = 9)] = "WeekendRange"),
            (e[(e.DateFormat = 10)] = "DateFormat"),
            (e[(e.TimeFormat = 11)] = "TimeFormat"),
            (e[(e.DateTimeFormat = 12)] = "DateTimeFormat"),
            (e[(e.NumberSymbols = 13)] = "NumberSymbols"),
            (e[(e.NumberFormats = 14)] = "NumberFormats"),
            (e[(e.CurrencyCode = 15)] = "CurrencyCode"),
            (e[(e.CurrencySymbol = 16)] = "CurrencySymbol"),
            (e[(e.CurrencyName = 17)] = "CurrencyName"),
            (e[(e.Currencies = 18)] = "Currencies"),
            (e[(e.Directionality = 19)] = "Directionality"),
            (e[(e.PluralCase = 20)] = "PluralCase"),
            (e[(e.ExtraData = 21)] = "ExtraData"),
            e
          );
        })({});
        function ya(e) {
          var t;
          null == (t = e) &&
            (function (e, t, n, r) {
              throw new Error(
                "ASSERTION ERROR: "
                  .concat(
                    "Expected localeId to be defined",
                    " [Expected=> null != "
                  )
                  .concat(t, " <=Actual]")
              );
            })(0, t),
            "string" == typeof e && e.toLowerCase().replace(/_/g, "-");
        }
        var ka = function e() {
            p(this, e);
          },
          ba = (function () {
            function e() {
              p(this, e);
            }
            return (
              g(e, [
                {
                  key: "resolveComponentFactory",
                  value: function (e) {
                    throw (function (e) {
                      var t = Error(
                        "No component factory found for ".concat(
                          me(e),
                          ". Did you add it to @NgModule.entryComponents?"
                        )
                      );
                      return (t.ngComponent = e), t;
                    })(e);
                  },
                },
              ]),
              e
            );
          })(),
          wa = (function () {
            var e = function e() {
              p(this, e);
            };
            return (e.NULL = new ba()), e;
          })();
        function _a() {}
        function Sa(e, t) {
          return new Ea(Ot(e, t));
        }
        var Ca = function () {
            return Sa(Vt(), qt());
          },
          Ea = (function () {
            var e = function e(t) {
              p(this, e), (this.nativeElement = t);
            };
            return (e.__NG_ELEMENT_ID__ = Ca), e;
          })();
        function Ta(e) {
          return e instanceof Ea ? e.nativeElement : e;
        }
        var xa = function e() {
            p(this, e);
          },
          Ia = (function () {
            var e = function e() {
              p(this, e);
            };
            return (
              (e.__NG_ELEMENT_ID__ = function () {
                return Aa();
              }),
              e
            );
          })(),
          Aa = function () {
            var e = qt(),
              t = Pt(Vt().index, e);
            return (function (e) {
              return e[11];
            })(ft(t) ? t : e);
          },
          Oa = (function () {
            var e = function e() {
              p(this, e);
            };
            return (
              (e.ɵprov = Ee({
                token: e,
                providedIn: "root",
                factory: function () {
                  return null;
                },
              })),
              e
            );
          })(),
          Ra = function e(t) {
            p(this, e),
              (this.full = t),
              (this.major = t.split(".")[0]),
              (this.minor = t.split(".")[1]),
              (this.patch = t.split(".").slice(2).join("."));
          },
          Pa = new Ra("11.2.14"),
          Da = (function () {
            function e() {
              p(this, e);
            }
            return (
              g(e, [
                {
                  key: "supports",
                  value: function (e) {
                    return Po(e);
                  },
                },
                {
                  key: "create",
                  value: function (e) {
                    return new Ma(e);
                  },
                },
              ]),
              e
            );
          })(),
          Na = function (e, t) {
            return t;
          },
          Ma = (function () {
            function e(t) {
              p(this, e),
                (this.length = 0),
                (this._linkedRecords = null),
                (this._unlinkedRecords = null),
                (this._previousItHead = null),
                (this._itHead = null),
                (this._itTail = null),
                (this._additionsHead = null),
                (this._additionsTail = null),
                (this._movesHead = null),
                (this._movesTail = null),
                (this._removalsHead = null),
                (this._removalsTail = null),
                (this._identityChangesHead = null),
                (this._identityChangesTail = null),
                (this._trackByFn = t || Na);
            }
            return (
              g(e, [
                {
                  key: "forEachItem",
                  value: function (e) {
                    var t;
                    for (t = this._itHead; null !== t; t = t._next) e(t);
                  },
                },
                {
                  key: "forEachOperation",
                  value: function (e) {
                    for (
                      var t = this._itHead,
                        n = this._removalsHead,
                        r = 0,
                        i = null;
                      t || n;

                    ) {
                      var o = !n || (t && t.currentIndex < Wa(n, r, i)) ? t : n,
                        a = Wa(o, r, i),
                        s = o.currentIndex;
                      if (o === n) r--, (n = n._nextRemoved);
                      else if (((t = t._next), null == o.previousIndex)) r++;
                      else {
                        i || (i = []);
                        var u = a - r,
                          l = s - r;
                        if (u != l) {
                          for (var c = 0; c < u; c++) {
                            var h = c < i.length ? i[c] : (i[c] = 0),
                              f = h + c;
                            l <= f && f < u && (i[c] = h + 1);
                          }
                          i[o.previousIndex] = l - u;
                        }
                      }
                      a !== s && e(o, a, s);
                    }
                  },
                },
                {
                  key: "forEachPreviousItem",
                  value: function (e) {
                    var t;
                    for (
                      t = this._previousItHead;
                      null !== t;
                      t = t._nextPrevious
                    )
                      e(t);
                  },
                },
                {
                  key: "forEachAddedItem",
                  value: function (e) {
                    var t;
                    for (t = this._additionsHead; null !== t; t = t._nextAdded)
                      e(t);
                  },
                },
                {
                  key: "forEachMovedItem",
                  value: function (e) {
                    var t;
                    for (t = this._movesHead; null !== t; t = t._nextMoved)
                      e(t);
                  },
                },
                {
                  key: "forEachRemovedItem",
                  value: function (e) {
                    var t;
                    for (t = this._removalsHead; null !== t; t = t._nextRemoved)
                      e(t);
                  },
                },
                {
                  key: "forEachIdentityChange",
                  value: function (e) {
                    var t;
                    for (
                      t = this._identityChangesHead;
                      null !== t;
                      t = t._nextIdentityChange
                    )
                      e(t);
                  },
                },
                {
                  key: "diff",
                  value: function (e) {
                    if ((null == e && (e = []), !Po(e)))
                      throw new Error(
                        "Error trying to diff '".concat(
                          me(e),
                          "'. Only arrays and iterables are allowed"
                        )
                      );
                    return this.check(e) ? this : null;
                  },
                },
                { key: "onDestroy", value: function () {} },
                {
                  key: "check",
                  value: function (e) {
                    var t = this;
                    this._reset();
                    var n,
                      r,
                      i,
                      o = this._itHead,
                      a = !1;
                    if (Array.isArray(e)) {
                      this.length = e.length;
                      for (var s = 0; s < this.length; s++)
                        (r = e[s]),
                          (i = this._trackByFn(s, r)),
                          null !== o && Object.is(o.trackById, i)
                            ? (a && (o = this._verifyReinsertion(o, r, i, s)),
                              Object.is(o.item, r) ||
                                this._addIdentityChange(o, r))
                            : ((o = this._mismatch(o, r, i, s)), (a = !0)),
                          (o = o._next);
                    } else
                      (n = 0),
                        (function (e, t) {
                          if (Array.isArray(e))
                            for (var n = 0; n < e.length; n++) t(e[n]);
                          else
                            for (var r, i = e[Ro()](); !(r = i.next()).done; )
                              t(r.value);
                        })(e, function (e) {
                          (i = t._trackByFn(n, e)),
                            null !== o && Object.is(o.trackById, i)
                              ? (a && (o = t._verifyReinsertion(o, e, i, n)),
                                Object.is(o.item, e) ||
                                  t._addIdentityChange(o, e))
                              : ((o = t._mismatch(o, e, i, n)), (a = !0)),
                            (o = o._next),
                            n++;
                        }),
                        (this.length = n);
                    return (
                      this._truncate(o), (this.collection = e), this.isDirty
                    );
                  },
                },
                {
                  key: "isDirty",
                  get: function () {
                    return (
                      null !== this._additionsHead ||
                      null !== this._movesHead ||
                      null !== this._removalsHead ||
                      null !== this._identityChangesHead
                    );
                  },
                },
                {
                  key: "_reset",
                  value: function () {
                    if (this.isDirty) {
                      var e;
                      for (
                        e = this._previousItHead = this._itHead;
                        null !== e;
                        e = e._next
                      )
                        e._nextPrevious = e._next;
                      for (
                        e = this._additionsHead;
                        null !== e;
                        e = e._nextAdded
                      )
                        e.previousIndex = e.currentIndex;
                      for (
                        this._additionsHead = this._additionsTail = null,
                          e = this._movesHead;
                        null !== e;
                        e = e._nextMoved
                      )
                        e.previousIndex = e.currentIndex;
                      (this._movesHead = this._movesTail = null),
                        (this._removalsHead = this._removalsTail = null),
                        (this._identityChangesHead = this._identityChangesTail =
                          null);
                    }
                  },
                },
                {
                  key: "_mismatch",
                  value: function (e, t, n, r) {
                    var i;
                    return (
                      null === e
                        ? (i = this._itTail)
                        : ((i = e._prev), this._remove(e)),
                      null !==
                      (e =
                        null === this._unlinkedRecords
                          ? null
                          : this._unlinkedRecords.get(n, null))
                        ? (Object.is(e.item, t) ||
                            this._addIdentityChange(e, t),
                          this._reinsertAfter(e, i, r))
                        : null !==
                          (e =
                            null === this._linkedRecords
                              ? null
                              : this._linkedRecords.get(n, r))
                        ? (Object.is(e.item, t) ||
                            this._addIdentityChange(e, t),
                          this._moveAfter(e, i, r))
                        : (e = this._addAfter(new ja(t, n), i, r)),
                      e
                    );
                  },
                },
                {
                  key: "_verifyReinsertion",
                  value: function (e, t, n, r) {
                    var i =
                      null === this._unlinkedRecords
                        ? null
                        : this._unlinkedRecords.get(n, null);
                    return (
                      null !== i
                        ? (e = this._reinsertAfter(i, e._prev, r))
                        : e.currentIndex != r &&
                          ((e.currentIndex = r), this._addToMoves(e, r)),
                      e
                    );
                  },
                },
                {
                  key: "_truncate",
                  value: function (e) {
                    for (; null !== e; ) {
                      var t = e._next;
                      this._addToRemovals(this._unlink(e)), (e = t);
                    }
                    null !== this._unlinkedRecords &&
                      this._unlinkedRecords.clear(),
                      null !== this._additionsTail &&
                        (this._additionsTail._nextAdded = null),
                      null !== this._movesTail &&
                        (this._movesTail._nextMoved = null),
                      null !== this._itTail && (this._itTail._next = null),
                      null !== this._removalsTail &&
                        (this._removalsTail._nextRemoved = null),
                      null !== this._identityChangesTail &&
                        (this._identityChangesTail._nextIdentityChange = null);
                  },
                },
                {
                  key: "_reinsertAfter",
                  value: function (e, t, n) {
                    null !== this._unlinkedRecords &&
                      this._unlinkedRecords.remove(e);
                    var r = e._prevRemoved,
                      i = e._nextRemoved;
                    return (
                      null === r
                        ? (this._removalsHead = i)
                        : (r._nextRemoved = i),
                      null === i
                        ? (this._removalsTail = r)
                        : (i._prevRemoved = r),
                      this._insertAfter(e, t, n),
                      this._addToMoves(e, n),
                      e
                    );
                  },
                },
                {
                  key: "_moveAfter",
                  value: function (e, t, n) {
                    return (
                      this._unlink(e),
                      this._insertAfter(e, t, n),
                      this._addToMoves(e, n),
                      e
                    );
                  },
                },
                {
                  key: "_addAfter",
                  value: function (e, t, n) {
                    return (
                      this._insertAfter(e, t, n),
                      (this._additionsTail =
                        null === this._additionsTail
                          ? (this._additionsHead = e)
                          : (this._additionsTail._nextAdded = e)),
                      e
                    );
                  },
                },
                {
                  key: "_insertAfter",
                  value: function (e, t, n) {
                    var r = null === t ? this._itHead : t._next;
                    return (
                      (e._next = r),
                      (e._prev = t),
                      null === r ? (this._itTail = e) : (r._prev = e),
                      null === t ? (this._itHead = e) : (t._next = e),
                      null === this._linkedRecords &&
                        (this._linkedRecords = new La()),
                      this._linkedRecords.put(e),
                      (e.currentIndex = n),
                      e
                    );
                  },
                },
                {
                  key: "_remove",
                  value: function (e) {
                    return this._addToRemovals(this._unlink(e));
                  },
                },
                {
                  key: "_unlink",
                  value: function (e) {
                    null !== this._linkedRecords &&
                      this._linkedRecords.remove(e);
                    var t = e._prev,
                      n = e._next;
                    return (
                      null === t ? (this._itHead = n) : (t._next = n),
                      null === n ? (this._itTail = t) : (n._prev = t),
                      e
                    );
                  },
                },
                {
                  key: "_addToMoves",
                  value: function (e, t) {
                    return (
                      e.previousIndex === t ||
                        (this._movesTail =
                          null === this._movesTail
                            ? (this._movesHead = e)
                            : (this._movesTail._nextMoved = e)),
                      e
                    );
                  },
                },
                {
                  key: "_addToRemovals",
                  value: function (e) {
                    return (
                      null === this._unlinkedRecords &&
                        (this._unlinkedRecords = new La()),
                      this._unlinkedRecords.put(e),
                      (e.currentIndex = null),
                      (e._nextRemoved = null),
                      null === this._removalsTail
                        ? ((this._removalsTail = this._removalsHead = e),
                          (e._prevRemoved = null))
                        : ((e._prevRemoved = this._removalsTail),
                          (this._removalsTail =
                            this._removalsTail._nextRemoved =
                              e)),
                      e
                    );
                  },
                },
                {
                  key: "_addIdentityChange",
                  value: function (e, t) {
                    return (
                      (e.item = t),
                      (this._identityChangesTail =
                        null === this._identityChangesTail
                          ? (this._identityChangesHead = e)
                          : (this._identityChangesTail._nextIdentityChange =
                              e)),
                      e
                    );
                  },
                },
              ]),
              e
            );
          })(),
          ja = function e(t, n) {
            p(this, e),
              (this.item = t),
              (this.trackById = n),
              (this.currentIndex = null),
              (this.previousIndex = null),
              (this._nextPrevious = null),
              (this._prev = null),
              (this._next = null),
              (this._prevDup = null),
              (this._nextDup = null),
              (this._prevRemoved = null),
              (this._nextRemoved = null),
              (this._nextAdded = null),
              (this._nextMoved = null),
              (this._nextIdentityChange = null);
          },
          Fa = (function () {
            function e() {
              p(this, e), (this._head = null), (this._tail = null);
            }
            return (
              g(e, [
                {
                  key: "add",
                  value: function (e) {
                    null === this._head
                      ? ((this._head = this._tail = e),
                        (e._nextDup = null),
                        (e._prevDup = null))
                      : ((this._tail._nextDup = e),
                        (e._prevDup = this._tail),
                        (e._nextDup = null),
                        (this._tail = e));
                  },
                },
                {
                  key: "get",
                  value: function (e, t) {
                    var n;
                    for (n = this._head; null !== n; n = n._nextDup)
                      if (
                        (null === t || t <= n.currentIndex) &&
                        Object.is(n.trackById, e)
                      )
                        return n;
                    return null;
                  },
                },
                {
                  key: "remove",
                  value: function (e) {
                    var t = e._prevDup,
                      n = e._nextDup;
                    return (
                      null === t ? (this._head = n) : (t._nextDup = n),
                      null === n ? (this._tail = t) : (n._prevDup = t),
                      null === this._head
                    );
                  },
                },
              ]),
              e
            );
          })(),
          La = (function () {
            function e() {
              p(this, e), (this.map = new Map());
            }
            return (
              g(e, [
                {
                  key: "put",
                  value: function (e) {
                    var t = e.trackById,
                      n = this.map.get(t);
                    n || ((n = new Fa()), this.map.set(t, n)), n.add(e);
                  },
                },
                {
                  key: "get",
                  value: function (e, t) {
                    var n = this.map.get(e);
                    return n ? n.get(e, t) : null;
                  },
                },
                {
                  key: "remove",
                  value: function (e) {
                    var t = e.trackById;
                    return this.map.get(t).remove(e) && this.map.delete(t), e;
                  },
                },
                {
                  key: "isEmpty",
                  get: function () {
                    return 0 === this.map.size;
                  },
                },
                {
                  key: "clear",
                  value: function () {
                    this.map.clear();
                  },
                },
              ]),
              e
            );
          })();
        function Wa(e, t, n) {
          var r = e.previousIndex;
          if (null === r) return r;
          var i = 0;
          return n && r < n.length && (i = n[r]), r + t + i;
        }
        var za = (function () {
            function e() {
              p(this, e);
            }
            return (
              g(e, [
                {
                  key: "supports",
                  value: function (e) {
                    return e instanceof Map || Do(e);
                  },
                },
                {
                  key: "create",
                  value: function () {
                    return new qa();
                  },
                },
              ]),
              e
            );
          })(),
          qa = (function () {
            function e() {
              p(this, e),
                (this._records = new Map()),
                (this._mapHead = null),
                (this._appendAfter = null),
                (this._previousMapHead = null),
                (this._changesHead = null),
                (this._changesTail = null),
                (this._additionsHead = null),
                (this._additionsTail = null),
                (this._removalsHead = null),
                (this._removalsTail = null);
            }
            return (
              g(e, [
                {
                  key: "isDirty",
                  get: function () {
                    return (
                      null !== this._additionsHead ||
                      null !== this._changesHead ||
                      null !== this._removalsHead
                    );
                  },
                },
                {
                  key: "forEachItem",
                  value: function (e) {
                    var t;
                    for (t = this._mapHead; null !== t; t = t._next) e(t);
                  },
                },
                {
                  key: "forEachPreviousItem",
                  value: function (e) {
                    var t;
                    for (
                      t = this._previousMapHead;
                      null !== t;
                      t = t._nextPrevious
                    )
                      e(t);
                  },
                },
                {
                  key: "forEachChangedItem",
                  value: function (e) {
                    var t;
                    for (t = this._changesHead; null !== t; t = t._nextChanged)
                      e(t);
                  },
                },
                {
                  key: "forEachAddedItem",
                  value: function (e) {
                    var t;
                    for (t = this._additionsHead; null !== t; t = t._nextAdded)
                      e(t);
                  },
                },
                {
                  key: "forEachRemovedItem",
                  value: function (e) {
                    var t;
                    for (t = this._removalsHead; null !== t; t = t._nextRemoved)
                      e(t);
                  },
                },
                {
                  key: "diff",
                  value: function (e) {
                    if (e) {
                      if (!(e instanceof Map || Do(e)))
                        throw new Error(
                          "Error trying to diff '".concat(
                            me(e),
                            "'. Only maps and objects are allowed"
                          )
                        );
                    } else e = new Map();
                    return this.check(e) ? this : null;
                  },
                },
                { key: "onDestroy", value: function () {} },
                {
                  key: "check",
                  value: function (e) {
                    var t = this;
                    this._reset();
                    var n = this._mapHead;
                    if (
                      ((this._appendAfter = null),
                      this._forEach(e, function (e, r) {
                        if (n && n.key === r)
                          t._maybeAddToChanges(n, e),
                            (t._appendAfter = n),
                            (n = n._next);
                        else {
                          var i = t._getOrCreateRecordForKey(r, e);
                          n = t._insertBeforeOrAppend(n, i);
                        }
                      }),
                      n)
                    ) {
                      n._prev && (n._prev._next = null),
                        (this._removalsHead = n);
                      for (var r = n; null !== r; r = r._nextRemoved)
                        r === this._mapHead && (this._mapHead = null),
                          this._records.delete(r.key),
                          (r._nextRemoved = r._next),
                          (r.previousValue = r.currentValue),
                          (r.currentValue = null),
                          (r._prev = null),
                          (r._next = null);
                    }
                    return (
                      this._changesTail &&
                        (this._changesTail._nextChanged = null),
                      this._additionsTail &&
                        (this._additionsTail._nextAdded = null),
                      this.isDirty
                    );
                  },
                },
                {
                  key: "_insertBeforeOrAppend",
                  value: function (e, t) {
                    if (e) {
                      var n = e._prev;
                      return (
                        (t._next = e),
                        (t._prev = n),
                        (e._prev = t),
                        n && (n._next = t),
                        e === this._mapHead && (this._mapHead = t),
                        (this._appendAfter = e),
                        e
                      );
                    }
                    return (
                      this._appendAfter
                        ? ((this._appendAfter._next = t),
                          (t._prev = this._appendAfter))
                        : (this._mapHead = t),
                      (this._appendAfter = t),
                      null
                    );
                  },
                },
                {
                  key: "_getOrCreateRecordForKey",
                  value: function (e, t) {
                    if (this._records.has(e)) {
                      var n = this._records.get(e);
                      this._maybeAddToChanges(n, t);
                      var r = n._prev,
                        i = n._next;
                      return (
                        r && (r._next = i),
                        i && (i._prev = r),
                        (n._next = null),
                        (n._prev = null),
                        n
                      );
                    }
                    var o = new Ha(e);
                    return (
                      this._records.set(e, o),
                      (o.currentValue = t),
                      this._addToAdditions(o),
                      o
                    );
                  },
                },
                {
                  key: "_reset",
                  value: function () {
                    if (this.isDirty) {
                      var e;
                      for (
                        this._previousMapHead = this._mapHead,
                          e = this._previousMapHead;
                        null !== e;
                        e = e._next
                      )
                        e._nextPrevious = e._next;
                      for (
                        e = this._changesHead;
                        null !== e;
                        e = e._nextChanged
                      )
                        e.previousValue = e.currentValue;
                      for (e = this._additionsHead; null != e; e = e._nextAdded)
                        e.previousValue = e.currentValue;
                      (this._changesHead = this._changesTail = null),
                        (this._additionsHead = this._additionsTail = null),
                        (this._removalsHead = null);
                    }
                  },
                },
                {
                  key: "_maybeAddToChanges",
                  value: function (e, t) {
                    Object.is(t, e.currentValue) ||
                      ((e.previousValue = e.currentValue),
                      (e.currentValue = t),
                      this._addToChanges(e));
                  },
                },
                {
                  key: "_addToAdditions",
                  value: function (e) {
                    null === this._additionsHead
                      ? (this._additionsHead = this._additionsTail = e)
                      : ((this._additionsTail._nextAdded = e),
                        (this._additionsTail = e));
                  },
                },
                {
                  key: "_addToChanges",
                  value: function (e) {
                    null === this._changesHead
                      ? (this._changesHead = this._changesTail = e)
                      : ((this._changesTail._nextChanged = e),
                        (this._changesTail = e));
                  },
                },
                {
                  key: "_forEach",
                  value: function (e, t) {
                    e instanceof Map
                      ? e.forEach(t)
                      : Object.keys(e).forEach(function (n) {
                          return t(e[n], n);
                        });
                  },
                },
              ]),
              e
            );
          })(),
          Ha = function e(t) {
            p(this, e),
              (this.key = t),
              (this.previousValue = null),
              (this.currentValue = null),
              (this._nextPrevious = null),
              (this._next = null),
              (this._prev = null),
              (this._nextAdded = null),
              (this._nextRemoved = null),
              (this._nextChanged = null);
          };
        function Ua() {
          return new Va([new Da()]);
        }
        var Va = (function () {
          var e = (function () {
            function e(t) {
              p(this, e), (this.factories = t);
            }
            return (
              g(
                e,
                [
                  {
                    key: "find",
                    value: function (e) {
                      var t,
                        n = this.factories.find(function (t) {
                          return t.supports(e);
                        });
                      if (null != n) return n;
                      throw new Error(
                        "Cannot find a differ supporting object '"
                          .concat(e, "' of type '")
                          .concat((t = e).name || typeof t, "'")
                      );
                    },
                  },
                ],
                [
                  {
                    key: "create",
                    value: function (t, n) {
                      if (null != n) {
                        var r = n.factories.slice();
                        t = t.concat(r);
                      }
                      return new e(t);
                    },
                  },
                  {
                    key: "extend",
                    value: function (t) {
                      return {
                        provide: e,
                        useFactory: function (n) {
                          return e.create(t, n || Ua());
                        },
                        deps: [[e, new gr(), new mr()]],
                      };
                    },
                  },
                ]
              ),
              e
            );
          })();
          return (
            (e.ɵprov = Ee({ token: e, providedIn: "root", factory: Ua })), e
          );
        })();
        function Ba() {
          return new Qa([new za()]);
        }
        var Qa = (function () {
          var e = (function () {
            function e(t) {
              p(this, e), (this.factories = t);
            }
            return (
              g(
                e,
                [
                  {
                    key: "find",
                    value: function (e) {
                      var t = this.factories.find(function (t) {
                        return t.supports(e);
                      });
                      if (t) return t;
                      throw new Error(
                        "Cannot find a differ supporting object '".concat(
                          e,
                          "'"
                        )
                      );
                    },
                  },
                ],
                [
                  {
                    key: "create",
                    value: function (t, n) {
                      if (n) {
                        var r = n.factories.slice();
                        t = t.concat(r);
                      }
                      return new e(t);
                    },
                  },
                  {
                    key: "extend",
                    value: function (t) {
                      return {
                        provide: e,
                        useFactory: function (n) {
                          return e.create(t, n || Ba());
                        },
                        deps: [[e, new gr(), new mr()]],
                      };
                    },
                  },
                ]
              ),
              e
            );
          })();
          return (
            (e.ɵprov = Ee({ token: e, providedIn: "root", factory: Ba })), e
          );
        })();
        function Ga(e, t, r, i) {
          for (
            var o =
              arguments.length > 4 && void 0 !== arguments[4] && arguments[4];
            null !== r;

          ) {
            var a = t[r.index];
            if ((null !== a && i.push(It(a)), dt(a)))
              for (var s = 10; s < a.length; s++) {
                var u = a[s],
                  l = u[1].firstChild;
                null !== l && Ga(u[1], u, l, i);
              }
            var c = r.type;
            if (8 & c) Ga(e, t, r.child, i);
            else if (32 & c)
              for (var h = Dr(r, t), f = void 0; (f = h()); ) i.push(f);
            else if (16 & c) {
              var d = Xr(t, r);
              if (Array.isArray(d)) i.push.apply(i, n(d));
              else {
                var v = Nr(t[16]);
                Ga(v[1], v, d, i, !0);
              }
            }
            r = o ? r.projectionNext : r.next;
          }
          return i;
        }
        var Ka = (function () {
            function e(t, n) {
              p(this, e),
                (this._lView = t),
                (this._cdRefInjectingView = n),
                (this._appRef = null),
                (this._attachedToViewContainer = !1);
            }
            return (
              g(e, [
                {
                  key: "rootNodes",
                  get: function () {
                    var e = this._lView,
                      t = e[1];
                    return Ga(t, e, t.firstChild, []);
                  },
                },
                {
                  key: "context",
                  get: function () {
                    return this._lView[8];
                  },
                },
                {
                  key: "destroyed",
                  get: function () {
                    return 256 == (256 & this._lView[2]);
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    if (this._appRef) this._appRef.detachView(this);
                    else if (this._attachedToViewContainer) {
                      var e = this._lView[3];
                      if (dt(e)) {
                        var t = e[8],
                          n = t ? t.indexOf(this) : -1;
                        n > -1 && (qr(e, n), nr(t, n));
                      }
                      this._attachedToViewContainer = !1;
                    }
                    Hr(this._lView[1], this._lView);
                  },
                },
                {
                  key: "onDestroy",
                  value: function (e) {
                    Li(this._lView[1], this._lView, null, e);
                  },
                },
                {
                  key: "markForCheck",
                  value: function () {
                    no(this._cdRefInjectingView || this._lView);
                  },
                },
                {
                  key: "detach",
                  value: function () {
                    this._lView[2] &= -129;
                  },
                },
                {
                  key: "reattach",
                  value: function () {
                    this._lView[2] |= 128;
                  },
                },
                {
                  key: "detectChanges",
                  value: function () {
                    ro(this._lView[1], this._lView, this.context);
                  },
                },
                {
                  key: "checkNoChanges",
                  value: function () {
                    !(function (e, t, n) {
                      Jt(!0);
                      try {
                        ro(e, t, n);
                      } finally {
                        Jt(!1);
                      }
                    })(this._lView[1], this._lView, this.context);
                  },
                },
                {
                  key: "attachToViewContainerRef",
                  value: function () {
                    if (this._appRef)
                      throw new Error(
                        "This view is already attached directly to the ApplicationRef!"
                      );
                    this._attachedToViewContainer = !0;
                  },
                },
                {
                  key: "detachFromAppRef",
                  value: function () {
                    var e;
                    (this._appRef = null),
                      ni(
                        this._lView[1],
                        (e = this._lView),
                        e[11],
                        2,
                        null,
                        null
                      );
                  },
                },
                {
                  key: "attachToAppRef",
                  value: function (e) {
                    if (this._attachedToViewContainer)
                      throw new Error(
                        "This view is already attached to a ViewContainer!"
                      );
                    this._appRef = e;
                  },
                },
              ]),
              e
            );
          })(),
          Za = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e) {
              var r;
              return p(this, n), ((r = t.call(this, e))._view = e), r;
            }
            return (
              g(n, [
                {
                  key: "detectChanges",
                  value: function () {
                    io(this._view);
                  },
                },
                {
                  key: "checkNoChanges",
                  value: function () {
                    !(function (e) {
                      Jt(!0);
                      try {
                        io(e);
                      } finally {
                        Jt(!1);
                      }
                    })(this._view);
                  },
                },
                {
                  key: "context",
                  get: function () {
                    return null;
                  },
                },
              ]),
              n
            );
          })(Ka),
          Ja = function () {
            var e =
              arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
            return (function (e, t, n) {
              if (!n && pt(e)) {
                var r = Pt(e.index, t);
                return new Ka(r, r);
              }
              return 47 & e.type ? new Ka(t[16], t) : null;
            })(Vt(), qt(), e);
          },
          $a = (function () {
            var e = function e() {
              p(this, e);
            };
            return (
              (e.__NG_ELEMENT_ID__ = Ja), (e.__ChangeDetectorRef__ = !0), e
            );
          })(),
          Ya = [new za()],
          Xa = new Va([new Da()]),
          es = new Qa(Ya),
          ts = function () {
            return is(Vt(), qt());
          },
          ns = (function () {
            var e = function e() {
              p(this, e);
            };
            return (e.__NG_ELEMENT_ID__ = ts), e;
          })(),
          rs = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i) {
              var o;
              return (
                p(this, n),
                ((o = t.call(this))._declarationLView = e),
                (o._declarationTContainer = r),
                (o.elementRef = i),
                o
              );
            }
            return (
              g(n, [
                {
                  key: "createEmbeddedView",
                  value: function (e) {
                    var t = this._declarationTContainer.tViews,
                      n = xi(
                        this._declarationLView,
                        t,
                        e,
                        16,
                        null,
                        t.declTNode,
                        null,
                        null,
                        null,
                        null
                      );
                    n[17] =
                      this._declarationLView[this._declarationTContainer.index];
                    var r = this._declarationLView[19];
                    return (
                      null !== r && (n[19] = r.createEmbeddedView(t)),
                      Oi(t, n, e),
                      new Ka(n)
                    );
                  },
                },
              ]),
              n
            );
          })(ns);
        function is(e, t) {
          return 4 & e.type ? new rs(t, e, Sa(e, t)) : null;
        }
        var os = function e() {
            p(this, e);
          },
          as = function e() {
            p(this, e);
          },
          ss = function () {
            return fs(Vt(), qt());
          },
          us = (function () {
            var e = function e() {
              p(this, e);
            };
            return (e.__NG_ELEMENT_ID__ = ss), e;
          })(),
          ls = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i) {
              var o;
              return (
                p(this, n),
                ((o = t.call(this))._lContainer = e),
                (o._hostTNode = r),
                (o._hostLView = i),
                o
              );
            }
            return (
              g(n, [
                {
                  key: "element",
                  get: function () {
                    return Sa(this._hostTNode, this._hostLView);
                  },
                },
                {
                  key: "injector",
                  get: function () {
                    return new Gn(this._hostTNode, this._hostLView);
                  },
                },
                {
                  key: "parentInjector",
                  get: function () {
                    var e = Mn(this._hostTNode, this._hostLView);
                    if (Tn(e)) {
                      var t = In(e, this._hostLView),
                        n = xn(e);
                      return new Gn(t[1].data[n + 8], t);
                    }
                    return new Gn(null, this._hostLView);
                  },
                },
                {
                  key: "clear",
                  value: function () {
                    for (; this.length > 0; ) this.remove(this.length - 1);
                  },
                },
                {
                  key: "get",
                  value: function (e) {
                    var t = cs(this._lContainer);
                    return (null !== t && t[e]) || null;
                  },
                },
                {
                  key: "length",
                  get: function () {
                    return this._lContainer.length - 10;
                  },
                },
                {
                  key: "createEmbeddedView",
                  value: function (e, t, n) {
                    var r = e.createEmbeddedView(t || {});
                    return this.insert(r, n), r;
                  },
                },
                {
                  key: "createComponent",
                  value: function (e, t, n, r, i) {
                    var o = n || this.parentInjector;
                    if (!i && null == e.ngModule && o) {
                      var a = o.get(os, null);
                      a && (i = a);
                    }
                    var s = e.create(o, r, void 0, i);
                    return this.insert(s.hostView, t), s;
                  },
                },
                {
                  key: "insert",
                  value: function (e, t) {
                    var r = e._lView,
                      i = r[1];
                    if (dt(r[3])) {
                      var o = this.indexOf(e);
                      if (-1 !== o) this.detach(o);
                      else {
                        var a = r[3],
                          s = new n(a, a[6], a[3]);
                        s.detach(s.indexOf(e));
                      }
                    }
                    var u = this._adjustIndex(t),
                      l = this._lContainer;
                    !(function (e, t, n, r) {
                      var i = 10 + r,
                        o = n.length;
                      r > 0 && (n[i - 1][4] = t),
                        r < o - 10
                          ? ((t[4] = n[i]), tr(n, 10 + r, t))
                          : (n.push(t), (t[4] = null)),
                        (t[3] = n);
                      var a = t[17];
                      null !== a &&
                        n !== a &&
                        (function (e, t) {
                          var n = e[9];
                          t[16] !== t[3][3][16] && (e[2] = !0),
                            null === n ? (e[9] = [t]) : n.push(t);
                        })(a, t);
                      var s = t[19];
                      null !== s && s.insertView(e), (t[2] |= 128);
                    })(i, r, l, u);
                    var c = ei(u, l),
                      h = r[11],
                      f = Kr(h, l[7]);
                    return (
                      null !== f &&
                        (function (e, t, n, r, i, o) {
                          (r[0] = i), (r[6] = t), ni(e, r, n, 1, i, o);
                        })(i, l[6], h, r, f, c),
                      e.attachToViewContainerRef(),
                      tr(hs(l), u, e),
                      e
                    );
                  },
                },
                {
                  key: "move",
                  value: function (e, t) {
                    return this.insert(e, t);
                  },
                },
                {
                  key: "indexOf",
                  value: function (e) {
                    var t = cs(this._lContainer);
                    return null !== t ? t.indexOf(e) : -1;
                  },
                },
                {
                  key: "remove",
                  value: function (e) {
                    var t = this._adjustIndex(e, -1),
                      n = qr(this._lContainer, t);
                    n && (nr(hs(this._lContainer), t), Hr(n[1], n));
                  },
                },
                {
                  key: "detach",
                  value: function (e) {
                    var t = this._adjustIndex(e, -1),
                      n = qr(this._lContainer, t);
                    return n && null != nr(hs(this._lContainer), t)
                      ? new Ka(n)
                      : null;
                  },
                },
                {
                  key: "_adjustIndex",
                  value: function (e) {
                    var t =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : 0;
                    return null == e ? this.length + t : e;
                  },
                },
              ]),
              n
            );
          })(us);
        function cs(e) {
          return e[8];
        }
        function hs(e) {
          return e[8] || (e[8] = []);
        }
        function fs(e, t) {
          var n,
            r = t[e.index];
          if (dt(r)) n = r;
          else {
            var i;
            if (8 & e.type) i = It(r);
            else {
              var o = t[11];
              i = o.createComment("");
              var a = Ot(e, t);
              Br(
                o,
                Kr(o, a),
                i,
                (function (e, t) {
                  return Tt(e) ? e.nextSibling(t) : t.nextSibling;
                })(o, a),
                !1
              );
            }
            (t[e.index] = n = $i(r, t, i, e)), to(t, n);
          }
          return new ls(n, e, t);
        }
        var ds = {},
          vs = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e) {
              var r;
              return p(this, n), ((r = t.call(this)).ngModule = e), r;
            }
            return (
              g(n, [
                {
                  key: "resolveComponentFactory",
                  value: function (e) {
                    var t = ct(e);
                    return new gs(t, this.ngModule);
                  },
                },
              ]),
              n
            );
          })(wa);
        function ps(e) {
          var t = [];
          for (var n in e)
            e.hasOwnProperty(n) && t.push({ propName: e[n], templateName: n });
          return t;
        }
        var ms = new Jn("SCHEDULER_TOKEN", {
            providedIn: "root",
            factory: function () {
              return Ar;
            },
          }),
          gs = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this)).componentDef = e),
                (i.ngModule = r),
                (i.componentType = e.type),
                (i.selector = e.selectors.map(mi).join(",")),
                (i.ngContentSelectors = e.ngContentSelectors
                  ? e.ngContentSelectors
                  : []),
                (i.isBoundToModule = !!r),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "inputs",
                  get: function () {
                    return ps(this.componentDef.inputs);
                  },
                },
                {
                  key: "outputs",
                  get: function () {
                    return ps(this.componentDef.outputs);
                  },
                },
                {
                  key: "create",
                  value: function (e, t, n, r) {
                    var i,
                      o,
                      a = (r = r || this.ngModule)
                        ? (function (e, t) {
                            return {
                              get: function (n, r, i) {
                                var o = e.get(n, ds, i);
                                return o !== ds || r === ds
                                  ? o
                                  : t.get(n, r, i);
                              },
                            };
                          })(e, r.injector)
                        : e,
                      s = a.get(xa, xt),
                      u = a.get(Oa, null),
                      l = s.createRenderer(null, this.componentDef),
                      c = this.componentDef.selectors[0][0] || "div",
                      h = n
                        ? (function (e, t, n) {
                            if (Tt(e))
                              return e.selectRootElement(t, n === ze.ShadowDom);
                            var r =
                              "string" == typeof t ? e.querySelector(t) : t;
                            return (r.textContent = ""), r;
                          })(l, n, this.componentDef.encapsulation)
                        : Wr(
                            s.createRenderer(null, this.componentDef),
                            c,
                            (function (e) {
                              var t = e.toLowerCase();
                              return "svg" === t
                                ? "http://www.w3.org/2000/svg"
                                : "math" === t
                                ? "http://www.w3.org/1998/MathML/"
                                : null;
                            })(c)
                          ),
                      f = this.componentDef.onPush ? 576 : 528,
                      d = {
                        components: [],
                        scheduler: Ar,
                        clean: ao,
                        playerHandler: null,
                        flags: 0,
                      },
                      v = Fi(0, null, null, 1, 0, null, null, null, null, null),
                      p = xi(null, v, d, f, null, null, s, l, u, a);
                    on(p);
                    try {
                      var m = (function (e, t, n, r, i, o) {
                        var a = n[1];
                        n[20] = e;
                        var s = Ii(a, 20, 2, "#host", null),
                          u = (s.mergedAttrs = t.hostAttrs);
                        null !== u &&
                          (ho(s, u, !0),
                          null !== e &&
                            (wn(i, e, u),
                            null !== s.classes && oi(i, e, s.classes),
                            null !== s.styles && ii(i, e, s.styles)));
                        var l = r.createRenderer(e, t),
                          c = xi(
                            n,
                            ji(t),
                            null,
                            t.onPush ? 64 : 16,
                            n[20],
                            s,
                            r,
                            l,
                            null,
                            null
                          );
                        return (
                          a.firstCreatePass &&
                            (jn(Pn(s, n), a, t.type),
                            Vi(a, s),
                            Qi(s, n.length, 1)),
                          to(n, c),
                          (n[20] = c)
                        );
                      })(h, this.componentDef, p, s, l);
                      if (h)
                        if (n) wn(l, h, ["ng-version", Pa.full]);
                        else {
                          var g = (function (e) {
                              for (
                                var t = [], n = [], r = 1, i = 2;
                                r < e.length;

                              ) {
                                var o = e[r];
                                if ("string" == typeof o)
                                  2 === i
                                    ? "" !== o && t.push(o, e[++r])
                                    : 8 === i && n.push(o);
                                else {
                                  if (!hi(i)) break;
                                  i = o;
                                }
                                r++;
                              }
                              return { attrs: t, classes: n };
                            })(this.componentDef.selectors[0]),
                            y = g.attrs,
                            k = g.classes;
                          y && wn(l, h, y),
                            k && k.length > 0 && oi(l, h, k.join(" "));
                        }
                      if (((o = Rt(v, 20)), void 0 !== t))
                        for (
                          var b = (o.projection = []), w = 0;
                          w < this.ngContentSelectors.length;
                          w++
                        ) {
                          var _ = t[w];
                          b.push(null != _ ? Array.from(_) : null);
                        }
                      (i = (function (e, t, n, r, i) {
                        var o = n[1],
                          a = (function (e, t, n) {
                            var r = Vt();
                            e.firstCreatePass &&
                              (n.providersResolver && n.providersResolver(n),
                              Gi(e, r, t, Ai(e, t, 1, null), n));
                            var i = Vn(t, e, r.directiveStart, r);
                            Ir(i, t);
                            var o = Ot(r, t);
                            return o && Ir(o, t), i;
                          })(o, n, t);
                        if (
                          (r.components.push(a),
                          (e[8] = a),
                          i &&
                            i.forEach(function (e) {
                              return e(a, t);
                            }),
                          t.contentQueries)
                        ) {
                          var s = Vt();
                          t.contentQueries(1, a, s.directiveStart);
                        }
                        var u = Vt();
                        return (
                          !o.firstCreatePass ||
                            (null === t.hostBindings && null === t.hostAttrs) ||
                            (fn(u.index),
                            Hi(n[1], u, 0, u.directiveStart, u.directiveEnd, t),
                            Ui(t, a)),
                          a
                        );
                      })(m, this.componentDef, p, d, [Ao])),
                        Oi(v, p, null);
                    } finally {
                      cn();
                    }
                    return new ys(this.componentType, i, Sa(o, p), p, o);
                  },
                },
              ]),
              n
            );
          })(ka),
          ys = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o, a) {
              var s;
              return (
                p(this, n),
                ((s = t.call(this)).location = i),
                (s._rootLView = o),
                (s._tNode = a),
                (s.instance = r),
                (s.hostView = s.changeDetectorRef = new Za(o)),
                (s.componentType = e),
                s
              );
            }
            return (
              g(n, [
                {
                  key: "injector",
                  get: function () {
                    return new Gn(this._tNode, this._rootLView);
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    this.hostView.destroy();
                  },
                },
                {
                  key: "onDestroy",
                  value: function (e) {
                    this.hostView.onDestroy(e);
                  },
                },
              ]),
              n
            );
          })(
            (function () {
              return function e() {
                p(this, e);
              };
            })()
          ),
          ks = new Map(),
          bs = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              p(this, n),
                ((i = t.call(this))._parent = r),
                (i._bootstrapComponents = []),
                (i.injector = f(i)),
                (i.destroyCbs = []),
                (i.componentFactoryResolver = new vs(f(i)));
              var o = ht(e),
                a = e[Xe] || null;
              return (
                a && ya(a),
                (i._bootstrapComponents = Rr(o.bootstrap)),
                (i._r3Injector = wo(
                  e,
                  r,
                  [
                    { provide: os, useValue: f(i) },
                    { provide: wa, useValue: i.componentFactoryResolver },
                  ],
                  me(e)
                )),
                i._r3Injector._resolveInjectorDefTypes(),
                (i.instance = i.get(e)),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "get",
                  value: function (e) {
                    var t =
                        arguments.length > 1 && void 0 !== arguments[1]
                          ? arguments[1]
                          : Io.THROW_IF_NOT_FOUND,
                      n =
                        arguments.length > 2 && void 0 !== arguments[2]
                          ? arguments[2]
                          : Me.Default;
                    return e === Io || e === os || e === vo
                      ? this
                      : this._r3Injector.get(e, t, n);
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    var e = this._r3Injector;
                    !e.destroyed && e.destroy(),
                      this.destroyCbs.forEach(function (e) {
                        return e();
                      }),
                      (this.destroyCbs = null);
                  },
                },
                {
                  key: "onDestroy",
                  value: function (e) {
                    this.destroyCbs.push(e);
                  },
                },
              ]),
              n
            );
          })(os),
          ws = (function (e) {
            u(r, e);
            var n = c(r);
            function r(e) {
              var i, o, a;
              return (
                p(this, r),
                ((i = n.call(this)).moduleType = e),
                null !== ht(e) &&
                  ((o = e),
                  (a = new Set()),
                  (function e(n) {
                    var r = ht(n, !0),
                      i = r.id;
                    null !== i &&
                      ((function (e, t, n) {
                        if (t && t !== n)
                          throw new Error(
                            "Duplicate module registered for "
                              .concat(e, " - ")
                              .concat(me(t), " vs ")
                              .concat(me(t.name))
                          );
                      })(i, ks.get(i), n),
                      ks.set(i, n));
                    var o,
                      s = t(Rr(r.imports));
                    try {
                      for (s.s(); !(o = s.n()).done; ) {
                        var u = o.value;
                        a.has(u) || (a.add(u), e(u));
                      }
                    } catch (l) {
                      s.e(l);
                    } finally {
                      s.f();
                    }
                  })(o)),
                i
              );
            }
            return (
              g(r, [
                {
                  key: "create",
                  value: function (e) {
                    return new bs(this.moduleType, e);
                  },
                },
              ]),
              r
            );
          })(as);
        function _s(e, t, n) {
          var r = $t() + e,
            i = qt();
          return i[r] === gi
            ? No(i, r, n ? t.call(n) : t())
            : (function (e, t) {
                return e[t];
              })(i, r);
        }
        function Ss(e, t, n, r) {
          return (function (e, t, n, r, i, o) {
            var a = t + n;
            return Mo(e, a, i)
              ? No(e, a + 1, o ? r.call(o, i) : r(i))
              : Es(e, a + 1);
          })(qt(), $t(), e, t, n, r);
        }
        function Cs(e, t, n, r, i) {
          return (function (e, t, n, r, i, o, a) {
            var s = t + n;
            return (function (e, t, n, r) {
              var i = Mo(e, t, n);
              return Mo(e, t + 1, r) || i;
            })(e, s, i, o)
              ? No(e, s + 2, a ? r.call(a, i, o) : r(i, o))
              : Es(e, s + 2);
          })(qt(), $t(), e, t, n, r, i);
        }
        function Es(e, t) {
          var n = e[t];
          return n === gi ? void 0 : n;
        }
        function Ts(e) {
          return function (t) {
            setTimeout(e, void 0, t);
          };
        }
        var xs = (function (e) {
          u(n, e);
          var t = c(n);
          function n() {
            var e,
              r =
                arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
            return p(this, n), ((e = t.call(this)).__isAsync = r), e;
          }
          return (
            g(n, [
              {
                key: "emit",
                value: function (e) {
                  s(v(n.prototype), "next", this).call(this, e);
                },
              },
              {
                key: "subscribe",
                value: function (e, t, r) {
                  var i,
                    o,
                    a,
                    u = e,
                    l =
                      t ||
                      function () {
                        return null;
                      },
                    c = r;
                  if (e && "object" == typeof e) {
                    var h = e;
                    (u =
                      null === (i = h.next) || void 0 === i
                        ? void 0
                        : i.bind(h)),
                      (l =
                        null === (o = h.error) || void 0 === o
                          ? void 0
                          : o.bind(h)),
                      (c =
                        null === (a = h.complete) || void 0 === a
                          ? void 0
                          : a.bind(h));
                  }
                  this.__isAsync &&
                    ((l = Ts(l)), u && (u = Ts(u)), c && (c = Ts(c)));
                  var f = s(v(n.prototype), "subscribe", this).call(this, {
                    next: u,
                    error: l,
                    complete: c,
                  });
                  return e instanceof E && e.add(f), f;
                },
              },
            ]),
            n
          );
        })(W);
        function Is() {
          return this._results[Ro()]();
        }
        var As = (function () {
            function e() {
              var t =
                arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
              p(this, e),
                (this._emitDistinctChangesOnly = t),
                (this.dirty = !0),
                (this._results = []),
                (this._changesDetected = !1),
                (this._changes = null),
                (this.length = 0),
                (this.first = void 0),
                (this.last = void 0);
              var n = Ro(),
                r = e.prototype;
              r[n] || (r[n] = Is);
            }
            return (
              g(e, [
                {
                  key: "changes",
                  get: function () {
                    return this._changes || (this._changes = new xs());
                  },
                },
                {
                  key: "get",
                  value: function (e) {
                    return this._results[e];
                  },
                },
                {
                  key: "map",
                  value: function (e) {
                    return this._results.map(e);
                  },
                },
                {
                  key: "filter",
                  value: function (e) {
                    return this._results.filter(e);
                  },
                },
                {
                  key: "find",
                  value: function (e) {
                    return this._results.find(e);
                  },
                },
                {
                  key: "reduce",
                  value: function (e, t) {
                    return this._results.reduce(e, t);
                  },
                },
                {
                  key: "forEach",
                  value: function (e) {
                    this._results.forEach(e);
                  },
                },
                {
                  key: "some",
                  value: function (e) {
                    return this._results.some(e);
                  },
                },
                {
                  key: "toArray",
                  value: function () {
                    return this._results.slice();
                  },
                },
                {
                  key: "toString",
                  value: function () {
                    return this._results.toString();
                  },
                },
                {
                  key: "reset",
                  value: function (e, t) {
                    this.dirty = !1;
                    var n = Xn(e);
                    (this._changesDetected = !(function (e, t, n) {
                      if (e.length !== t.length) return !1;
                      for (var r = 0; r < e.length; r++) {
                        var i = e[r],
                          o = t[r];
                        if ((n && ((i = n(i)), (o = n(o))), o !== i)) return !1;
                      }
                      return !0;
                    })(this._results, n, t)) &&
                      ((this._results = n),
                      (this.length = n.length),
                      (this.last = n[this.length - 1]),
                      (this.first = n[0]));
                  },
                },
                {
                  key: "notifyOnChanges",
                  value: function () {
                    !this._changes ||
                      (!this._changesDetected &&
                        this._emitDistinctChangesOnly) ||
                      this._changes.emit(this);
                  },
                },
                {
                  key: "setDirty",
                  value: function () {
                    this.dirty = !0;
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    this.changes.complete(), this.changes.unsubscribe();
                  },
                },
              ]),
              e
            );
          })(),
          Os = (function () {
            function e(t) {
              p(this, e), (this.queryList = t), (this.matches = null);
            }
            return (
              g(e, [
                {
                  key: "clone",
                  value: function () {
                    return new e(this.queryList);
                  },
                },
                {
                  key: "setDirty",
                  value: function () {
                    this.queryList.setDirty();
                  },
                },
              ]),
              e
            );
          })(),
          Rs = (function () {
            function e() {
              var t =
                arguments.length > 0 && void 0 !== arguments[0]
                  ? arguments[0]
                  : [];
              p(this, e), (this.queries = t);
            }
            return (
              g(e, [
                {
                  key: "createEmbeddedView",
                  value: function (t) {
                    var n = t.queries;
                    if (null !== n) {
                      for (
                        var r =
                            null !== t.contentQueries
                              ? t.contentQueries[0]
                              : n.length,
                          i = [],
                          o = 0;
                        o < r;
                        o++
                      ) {
                        var a = n.getByIndex(o);
                        i.push(this.queries[a.indexInDeclarationView].clone());
                      }
                      return new e(i);
                    }
                    return null;
                  },
                },
                {
                  key: "insertView",
                  value: function (e) {
                    this.dirtyQueriesWithMatches(e);
                  },
                },
                {
                  key: "detachView",
                  value: function (e) {
                    this.dirtyQueriesWithMatches(e);
                  },
                },
                {
                  key: "dirtyQueriesWithMatches",
                  value: function (e) {
                    for (var t = 0; t < this.queries.length; t++)
                      null !== Bs(e, t).matches && this.queries[t].setDirty();
                  },
                },
              ]),
              e
            );
          })(),
          Ps = function e(t, n) {
            var r =
              arguments.length > 2 && void 0 !== arguments[2]
                ? arguments[2]
                : null;
            p(this, e), (this.predicate = t), (this.flags = n), (this.read = r);
          },
          Ds = (function () {
            function e() {
              var t =
                arguments.length > 0 && void 0 !== arguments[0]
                  ? arguments[0]
                  : [];
              p(this, e), (this.queries = t);
            }
            return (
              g(e, [
                {
                  key: "elementStart",
                  value: function (e, t) {
                    for (var n = 0; n < this.queries.length; n++)
                      this.queries[n].elementStart(e, t);
                  },
                },
                {
                  key: "elementEnd",
                  value: function (e) {
                    for (var t = 0; t < this.queries.length; t++)
                      this.queries[t].elementEnd(e);
                  },
                },
                {
                  key: "embeddedTView",
                  value: function (t) {
                    for (var n = null, r = 0; r < this.length; r++) {
                      var i = null !== n ? n.length : 0,
                        o = this.getByIndex(r).embeddedTView(t, i);
                      o &&
                        ((o.indexInDeclarationView = r),
                        null !== n ? n.push(o) : (n = [o]));
                    }
                    return null !== n ? new e(n) : null;
                  },
                },
                {
                  key: "template",
                  value: function (e, t) {
                    for (var n = 0; n < this.queries.length; n++)
                      this.queries[n].template(e, t);
                  },
                },
                {
                  key: "getByIndex",
                  value: function (e) {
                    return this.queries[e];
                  },
                },
                {
                  key: "length",
                  get: function () {
                    return this.queries.length;
                  },
                },
                {
                  key: "track",
                  value: function (e) {
                    this.queries.push(e);
                  },
                },
              ]),
              e
            );
          })(),
          Ns = (function () {
            function e(t) {
              var n =
                arguments.length > 1 && void 0 !== arguments[1]
                  ? arguments[1]
                  : -1;
              p(this, e),
                (this.metadata = t),
                (this.matches = null),
                (this.indexInDeclarationView = -1),
                (this.crossesNgTemplate = !1),
                (this._appliesToNextNode = !0),
                (this._declarationNodeIndex = n);
            }
            return (
              g(e, [
                {
                  key: "elementStart",
                  value: function (e, t) {
                    this.isApplyingToNode(t) && this.matchTNode(e, t);
                  },
                },
                {
                  key: "elementEnd",
                  value: function (e) {
                    this._declarationNodeIndex === e.index &&
                      (this._appliesToNextNode = !1);
                  },
                },
                {
                  key: "template",
                  value: function (e, t) {
                    this.elementStart(e, t);
                  },
                },
                {
                  key: "embeddedTView",
                  value: function (t, n) {
                    return this.isApplyingToNode(t)
                      ? ((this.crossesNgTemplate = !0),
                        this.addMatch(-t.index, n),
                        new e(this.metadata))
                      : null;
                  },
                },
                {
                  key: "isApplyingToNode",
                  value: function (e) {
                    if (
                      this._appliesToNextNode &&
                      1 != (1 & this.metadata.flags)
                    ) {
                      for (
                        var t = this._declarationNodeIndex, n = e.parent;
                        null !== n && 8 & n.type && n.index !== t;

                      )
                        n = n.parent;
                      return t === (null !== n ? n.index : -1);
                    }
                    return this._appliesToNextNode;
                  },
                },
                {
                  key: "matchTNode",
                  value: function (e, t) {
                    var n = this.metadata.predicate;
                    if (Array.isArray(n))
                      for (var r = 0; r < n.length; r++) {
                        var i = n[r];
                        this.matchTNodeWithReadOption(e, t, Ms(t, i)),
                          this.matchTNodeWithReadOption(
                            e,
                            t,
                            Un(t, e, i, !1, !1)
                          );
                      }
                    else
                      n === ns
                        ? 4 & t.type && this.matchTNodeWithReadOption(e, t, -1)
                        : this.matchTNodeWithReadOption(
                            e,
                            t,
                            Un(t, e, n, !1, !1)
                          );
                  },
                },
                {
                  key: "matchTNodeWithReadOption",
                  value: function (e, t, n) {
                    if (null !== n) {
                      var r = this.metadata.read;
                      if (null !== r)
                        if (r === Ea || r === us || (r === ns && 4 & t.type))
                          this.addMatch(t.index, -2);
                        else {
                          var i = Un(t, e, r, !1, !1);
                          null !== i && this.addMatch(t.index, i);
                        }
                      else this.addMatch(t.index, n);
                    }
                  },
                },
                {
                  key: "addMatch",
                  value: function (e, t) {
                    null === this.matches
                      ? (this.matches = [e, t])
                      : this.matches.push(e, t);
                  },
                },
              ]),
              e
            );
          })();
        function Ms(e, t) {
          var n = e.localNames;
          if (null !== n)
            for (var r = 0; r < n.length; r += 2)
              if (n[r] === t) return n[r + 1];
          return null;
        }
        function js(e, t, n, r) {
          return -1 === n
            ? (function (e, t) {
                return 11 & e.type ? Sa(e, t) : 4 & e.type ? is(e, t) : null;
              })(t, e)
            : -2 === n
            ? (function (e, t, n) {
                return n === Ea
                  ? Sa(t, e)
                  : n === ns
                  ? is(t, e)
                  : n === us
                  ? fs(t, e)
                  : void 0;
              })(e, t, r)
            : Vn(e, e[1], n, t);
        }
        function Fs(e, t, n, r) {
          var i = t[19].queries[r];
          if (null === i.matches) {
            for (
              var o = e.data, a = n.matches, s = [], u = 0;
              u < a.length;
              u += 2
            ) {
              var l = a[u];
              s.push(l < 0 ? null : js(t, o[l], a[u + 1], n.metadata.read));
            }
            i.matches = s;
          }
          return i.matches;
        }
        function Ls(e, t, n, r) {
          var i = e.queries.getByIndex(n),
            o = i.matches;
          if (null !== o)
            for (var a = Fs(e, t, i, n), s = 0; s < o.length; s += 2) {
              var u = o[s];
              if (u > 0) r.push(a[s / 2]);
              else {
                for (var l = o[s + 1], c = t[-u], h = 10; h < c.length; h++) {
                  var f = c[h];
                  f[17] === f[3] && Ls(f[1], f, l, r);
                }
                if (null !== c[9])
                  for (var d = c[9], v = 0; v < d.length; v++) {
                    var p = d[v];
                    Ls(p[1], p, l, r);
                  }
              }
            }
          return r;
        }
        function Ws(e) {
          var t = qt(),
            n = Ht(),
            r = en();
          tn(r + 1);
          var i = Bs(n, r);
          if (e.dirty && Nt(t) === (2 == (2 & i.metadata.flags))) {
            if (null === i.matches) e.reset([]);
            else {
              var o = i.crossesNgTemplate ? Ls(n, t, r, []) : Fs(n, t, i, r);
              e.reset(o, Ta), e.notifyOnChanges();
            }
            return !0;
          }
          return !1;
        }
        function zs(e, t, n) {
          var r = Ht();
          r.firstCreatePass &&
            (Vs(r, new Ps(e, t, n), -1),
            2 == (2 & t) && (r.staticViewQueries = !0)),
            Us(r, qt(), t);
        }
        function qs(e, t, n, r) {
          var i = Ht();
          if (i.firstCreatePass) {
            var o = Vt();
            Vs(i, new Ps(t, n, r), o.index),
              (function (e, t) {
                var n = e.contentQueries || (e.contentQueries = []);
                t !== (n.length ? n[n.length - 1] : -1) &&
                  n.push(e.queries.length - 1, t);
              })(i, e),
              2 == (2 & n) && (i.staticContentQueries = !0);
          }
          Us(i, qt(), n);
        }
        function Hs() {
          return (e = qt()), (t = en()), e[19].queries[t].queryList;
          var e, t;
        }
        function Us(e, t, n) {
          var r = new As(4 == (4 & n));
          Li(e, t, r, r.destroy),
            null === t[19] && (t[19] = new Rs()),
            t[19].queries.push(new Os(r));
        }
        function Vs(e, t, n) {
          null === e.queries && (e.queries = new Ds()),
            e.queries.track(new Ns(t, n));
        }
        function Bs(e, t) {
          return e.queries.getByIndex(t);
        }
        function Qs(e, t) {
          return is(e, t);
        }
        var Gs = new Jn("Application Initializer"),
          Ks = (function () {
            var e = (function () {
              function e(t) {
                var n = this;
                p(this, e),
                  (this.appInits = t),
                  (this.resolve = _a),
                  (this.reject = _a),
                  (this.initialized = !1),
                  (this.done = !1),
                  (this.donePromise = new Promise(function (e, t) {
                    (n.resolve = e), (n.reject = t);
                  }));
              }
              return (
                g(e, [
                  {
                    key: "runInitializers",
                    value: function () {
                      var e = this;
                      if (!this.initialized) {
                        var t = [],
                          n = function () {
                            (e.done = !0), e.resolve();
                          };
                        if (this.appInits)
                          for (var r = 0; r < this.appInits.length; r++) {
                            var i = this.appInits[r]();
                            Qo(i) && t.push(i);
                          }
                        Promise.all(t)
                          .then(function () {
                            n();
                          })
                          .catch(function (t) {
                            e.reject(t);
                          }),
                          0 === t.length && n(),
                          (this.initialized = !0);
                      }
                    },
                  },
                ]),
                e
              );
            })();
            return (
              (e.ɵfac = function (t) {
                return new (t || e)(fr(Gs, 8));
              }),
              (e.ɵprov = Ee({ token: e, factory: e.ɵfac })),
              e
            );
          })(),
          Zs = new Jn("AppId"),
          Js = {
            provide: Zs,
            useFactory: function () {
              return "".concat($s()).concat($s()).concat($s());
            },
            deps: [],
          };
        function $s() {
          return String.fromCharCode(97 + Math.floor(25 * Math.random()));
        }
        var Ys = new Jn("Platform Initializer"),
          Xs = new Jn("Platform ID"),
          eu = new Jn("appBootstrapListener"),
          tu = (function () {
            var e = (function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "log",
                    value: function (e) {
                      console.log(e);
                    },
                  },
                  {
                    key: "warn",
                    value: function (e) {
                      console.warn(e);
                    },
                  },
                ]),
                e
              );
            })();
            return (
              (e.ɵfac = function (t) {
                return new (t || e)();
              }),
              (e.ɵprov = Ee({ token: e, factory: e.ɵfac })),
              e
            );
          })(),
          nu = new Jn("LocaleId"),
          ru = new Jn("DefaultCurrencyCode"),
          iu = function e(t, n) {
            p(this, e),
              (this.ngModuleFactory = t),
              (this.componentFactories = n);
          },
          ou = function (e) {
            return new ws(e);
          },
          au = ou,
          su = function (e) {
            return Promise.resolve(ou(e));
          },
          uu = function (e) {
            var t = ou(e),
              n = Rr(ht(e).declarations).reduce(function (e, t) {
                var n = ct(t);
                return n && e.push(new gs(n)), e;
              }, []);
            return new iu(t, n);
          },
          lu = uu,
          cu = function (e) {
            return Promise.resolve(uu(e));
          },
          hu = (function () {
            var e = (function () {
              function e() {
                p(this, e),
                  (this.compileModuleSync = au),
                  (this.compileModuleAsync = su),
                  (this.compileModuleAndAllComponentsSync = lu),
                  (this.compileModuleAndAllComponentsAsync = cu);
              }
              return (
                g(e, [
                  { key: "clearCache", value: function () {} },
                  { key: "clearCacheFor", value: function (e) {} },
                  { key: "getModuleId", value: function (e) {} },
                ]),
                e
              );
            })();
            return (
              (e.ɵfac = function (t) {
                return new (t || e)();
              }),
              (e.ɵprov = Ee({ token: e, factory: e.ɵfac })),
              e
            );
          })(),
          fu = Promise.resolve(0);
        function du(e) {
          "undefined" == typeof Zone
            ? fu.then(function () {
                e && e.apply(null, null);
              })
            : Zone.current.scheduleMicroTask("scheduleMicrotask", e);
        }
        var vu = (function () {
            function e(t) {
              var n,
                r,
                i = t.enableLongStackTrace,
                o = void 0 !== i && i,
                a = t.shouldCoalesceEventChangeDetection,
                s = void 0 !== a && a,
                u = t.shouldCoalesceRunChangeDetection,
                l = void 0 !== u && u;
              if (
                (p(this, e),
                (this.hasPendingMacrotasks = !1),
                (this.hasPendingMicrotasks = !1),
                (this.isStable = !0),
                (this.onUnstable = new xs(!1)),
                (this.onMicrotaskEmpty = new xs(!1)),
                (this.onStable = new xs(!1)),
                (this.onError = new xs(!1)),
                "undefined" == typeof Zone)
              )
                throw new Error(
                  "In this configuration Angular requires Zone.js"
                );
              Zone.assertZonePatched(),
                (this._nesting = 0),
                (this._outer = this._inner = Zone.current),
                Zone.TaskTrackingZoneSpec &&
                  (this._inner = this._inner.fork(
                    new Zone.TaskTrackingZoneSpec()
                  )),
                o &&
                  Zone.longStackTraceZoneSpec &&
                  (this._inner = this._inner.fork(Zone.longStackTraceZoneSpec)),
                (this.shouldCoalesceEventChangeDetection = !l && s),
                (this.shouldCoalesceRunChangeDetection = l),
                (this.lastRequestAnimationFrameId = -1),
                (this.nativeRequestAnimationFrame = (function () {
                  var e = Be.requestAnimationFrame,
                    t = Be.cancelAnimationFrame;
                  if ("undefined" != typeof Zone && e && t) {
                    var n = e[Zone.__symbol__("OriginalDelegate")];
                    n && (e = n);
                    var r = t[Zone.__symbol__("OriginalDelegate")];
                    r && (t = r);
                  }
                  return {
                    nativeRequestAnimationFrame: e,
                    nativeCancelAnimationFrame: t,
                  };
                })().nativeRequestAnimationFrame),
                (r = function () {
                  !(function (e) {
                    -1 === e.lastRequestAnimationFrameId &&
                      ((e.lastRequestAnimationFrameId =
                        e.nativeRequestAnimationFrame.call(Be, function () {
                          e.fakeTopEventTask ||
                            (e.fakeTopEventTask = Zone.root.scheduleEventTask(
                              "fakeTopEventTask",
                              function () {
                                (e.lastRequestAnimationFrameId = -1),
                                  gu(e),
                                  mu(e);
                              },
                              void 0,
                              function () {},
                              function () {}
                            )),
                            e.fakeTopEventTask.invoke();
                        })),
                      gu(e));
                  })(n);
                }),
                ((n = this)._inner = n._inner.fork({
                  name: "angular",
                  properties: { isAngularZone: !0 },
                  onInvokeTask: function (e, t, i, o, a, s) {
                    try {
                      return yu(n), e.invokeTask(i, o, a, s);
                    } finally {
                      ((n.shouldCoalesceEventChangeDetection &&
                        "eventTask" === o.type) ||
                        n.shouldCoalesceRunChangeDetection) &&
                        r(),
                        ku(n);
                    }
                  },
                  onInvoke: function (e, t, i, o, a, s, u) {
                    try {
                      return yu(n), e.invoke(i, o, a, s, u);
                    } finally {
                      n.shouldCoalesceRunChangeDetection && r(), ku(n);
                    }
                  },
                  onHasTask: function (e, t, r, i) {
                    e.hasTask(r, i),
                      t === r &&
                        ("microTask" == i.change
                          ? ((n._hasPendingMicrotasks = i.microTask),
                            gu(n),
                            mu(n))
                          : "macroTask" == i.change &&
                            (n.hasPendingMacrotasks = i.macroTask));
                  },
                  onHandleError: function (e, t, r, i) {
                    return (
                      e.handleError(r, i),
                      n.runOutsideAngular(function () {
                        return n.onError.emit(i);
                      }),
                      !1
                    );
                  },
                }));
            }
            return (
              g(
                e,
                [
                  {
                    key: "run",
                    value: function (e, t, n) {
                      return this._inner.run(e, t, n);
                    },
                  },
                  {
                    key: "runTask",
                    value: function (e, t, n, r) {
                      var i = this._inner,
                        o = i.scheduleEventTask(
                          "NgZoneEvent: " + r,
                          e,
                          pu,
                          _a,
                          _a
                        );
                      try {
                        return i.runTask(o, t, n);
                      } finally {
                        i.cancelTask(o);
                      }
                    },
                  },
                  {
                    key: "runGuarded",
                    value: function (e, t, n) {
                      return this._inner.runGuarded(e, t, n);
                    },
                  },
                  {
                    key: "runOutsideAngular",
                    value: function (e) {
                      return this._outer.run(e);
                    },
                  },
                ],
                [
                  {
                    key: "isInAngularZone",
                    value: function () {
                      return !0 === Zone.current.get("isAngularZone");
                    },
                  },
                  {
                    key: "assertInAngularZone",
                    value: function () {
                      if (!e.isInAngularZone())
                        throw new Error(
                          "Expected to be in Angular Zone, but it is not!"
                        );
                    },
                  },
                  {
                    key: "assertNotInAngularZone",
                    value: function () {
                      if (e.isInAngularZone())
                        throw new Error(
                          "Expected to not be in Angular Zone, but it is!"
                        );
                    },
                  },
                ]
              ),
              e
            );
          })(),
          pu = {};
        function mu(e) {
          if (0 == e._nesting && !e.hasPendingMicrotasks && !e.isStable)
            try {
              e._nesting++, e.onMicrotaskEmpty.emit(null);
            } finally {
              if ((e._nesting--, !e.hasPendingMicrotasks))
                try {
                  e.runOutsideAngular(function () {
                    return e.onStable.emit(null);
                  });
                } finally {
                  e.isStable = !0;
                }
            }
        }
        function gu(e) {
          e.hasPendingMicrotasks = !!(
            e._hasPendingMicrotasks ||
            ((e.shouldCoalesceEventChangeDetection ||
              e.shouldCoalesceRunChangeDetection) &&
              -1 !== e.lastRequestAnimationFrameId)
          );
        }
        function yu(e) {
          e._nesting++,
            e.isStable && ((e.isStable = !1), e.onUnstable.emit(null));
        }
        function ku(e) {
          e._nesting--, mu(e);
        }
        var bu,
          wu = (function () {
            function e() {
              p(this, e),
                (this.hasPendingMicrotasks = !1),
                (this.hasPendingMacrotasks = !1),
                (this.isStable = !0),
                (this.onUnstable = new xs()),
                (this.onMicrotaskEmpty = new xs()),
                (this.onStable = new xs()),
                (this.onError = new xs());
            }
            return (
              g(e, [
                {
                  key: "run",
                  value: function (e, t, n) {
                    return e.apply(t, n);
                  },
                },
                {
                  key: "runGuarded",
                  value: function (e, t, n) {
                    return e.apply(t, n);
                  },
                },
                {
                  key: "runOutsideAngular",
                  value: function (e) {
                    return e();
                  },
                },
                {
                  key: "runTask",
                  value: function (e, t, n, r) {
                    return e.apply(t, n);
                  },
                },
              ]),
              e
            );
          })(),
          _u = (function () {
            var e = (function () {
              function e(t) {
                var n = this;
                p(this, e),
                  (this._ngZone = t),
                  (this._pendingCount = 0),
                  (this._isZoneStable = !0),
                  (this._didWork = !1),
                  (this._callbacks = []),
                  (this.taskTrackingZone = null),
                  this._watchAngularEvents(),
                  t.run(function () {
                    n.taskTrackingZone =
                      "undefined" == typeof Zone
                        ? null
                        : Zone.current.get("TaskTrackingZone");
                  });
              }
              return (
                g(e, [
                  {
                    key: "_watchAngularEvents",
                    value: function () {
                      var e = this;
                      this._ngZone.onUnstable.subscribe({
                        next: function () {
                          (e._didWork = !0), (e._isZoneStable = !1);
                        },
                      }),
                        this._ngZone.runOutsideAngular(function () {
                          e._ngZone.onStable.subscribe({
                            next: function () {
                              vu.assertNotInAngularZone(),
                                du(function () {
                                  (e._isZoneStable = !0),
                                    e._runCallbacksIfReady();
                                });
                            },
                          });
                        });
                    },
                  },
                  {
                    key: "increasePendingRequestCount",
                    value: function () {
                      return (
                        (this._pendingCount += 1),
                        (this._didWork = !0),
                        this._pendingCount
                      );
                    },
                  },
                  {
                    key: "decreasePendingRequestCount",
                    value: function () {
                      if (((this._pendingCount -= 1), this._pendingCount < 0))
                        throw new Error("pending async requests below zero");
                      return this._runCallbacksIfReady(), this._pendingCount;
                    },
                  },
                  {
                    key: "isStable",
                    value: function () {
                      return (
                        this._isZoneStable &&
                        0 === this._pendingCount &&
                        !this._ngZone.hasPendingMacrotasks
                      );
                    },
                  },
                  {
                    key: "_runCallbacksIfReady",
                    value: function () {
                      var e = this;
                      if (this.isStable())
                        du(function () {
                          for (; 0 !== e._callbacks.length; ) {
                            var t = e._callbacks.pop();
                            clearTimeout(t.timeoutId), t.doneCb(e._didWork);
                          }
                          e._didWork = !1;
                        });
                      else {
                        var t = this.getPendingTasks();
                        (this._callbacks = this._callbacks.filter(function (e) {
                          return (
                            !e.updateCb ||
                            !e.updateCb(t) ||
                            (clearTimeout(e.timeoutId), !1)
                          );
                        })),
                          (this._didWork = !0);
                      }
                    },
                  },
                  {
                    key: "getPendingTasks",
                    value: function () {
                      return this.taskTrackingZone
                        ? this.taskTrackingZone.macroTasks.map(function (e) {
                            return {
                              source: e.source,
                              creationLocation: e.creationLocation,
                              data: e.data,
                            };
                          })
                        : [];
                    },
                  },
                  {
                    key: "addCallback",
                    value: function (e, t, n) {
                      var r = this,
                        i = -1;
                      t &&
                        t > 0 &&
                        (i = setTimeout(function () {
                          (r._callbacks = r._callbacks.filter(function (e) {
                            return e.timeoutId !== i;
                          })),
                            e(r._didWork, r.getPendingTasks());
                        }, t)),
                        this._callbacks.push({
                          doneCb: e,
                          timeoutId: i,
                          updateCb: n,
                        });
                    },
                  },
                  {
                    key: "whenStable",
                    value: function (e, t, n) {
                      if (n && !this.taskTrackingZone)
                        throw new Error(
                          'Task tracking zone is required when passing an update callback to whenStable(). Is "zone.js/dist/task-tracking.js" loaded?'
                        );
                      this.addCallback(e, t, n), this._runCallbacksIfReady();
                    },
                  },
                  {
                    key: "getPendingRequestCount",
                    value: function () {
                      return this._pendingCount;
                    },
                  },
                  {
                    key: "findProviders",
                    value: function (e, t, n) {
                      return [];
                    },
                  },
                ]),
                e
              );
            })();
            return (
              (e.ɵfac = function (t) {
                return new (t || e)(fr(vu));
              }),
              (e.ɵprov = Ee({ token: e, factory: e.ɵfac })),
              e
            );
          })(),
          Su = (function () {
            var e = (function () {
              function e() {
                p(this, e),
                  (this._applications = new Map()),
                  Cu.addToWindow(this);
              }
              return (
                g(e, [
                  {
                    key: "registerApplication",
                    value: function (e, t) {
                      this._applications.set(e, t);
                    },
                  },
                  {
                    key: "unregisterApplication",
                    value: function (e) {
                      this._applications.delete(e);
                    },
                  },
                  {
                    key: "unregisterAllApplications",
                    value: function () {
                      this._applications.clear();
                    },
                  },
                  {
                    key: "getTestability",
                    value: function (e) {
                      return this._applications.get(e) || null;
                    },
                  },
                  {
                    key: "getAllTestabilities",
                    value: function () {
                      return Array.from(this._applications.values());
                    },
                  },
                  {
                    key: "getAllRootElements",
                    value: function () {
                      return Array.from(this._applications.keys());
                    },
                  },
                  {
                    key: "findTestabilityInTree",
                    value: function (e) {
                      var t =
                        !(arguments.length > 1 && void 0 !== arguments[1]) ||
                        arguments[1];
                      return Cu.findTestabilityInTree(this, e, t);
                    },
                  },
                ]),
                e
              );
            })();
            return (
              (e.ɵfac = function (t) {
                return new (t || e)();
              }),
              (e.ɵprov = Ee({ token: e, factory: e.ɵfac })),
              e
            );
          })(),
          Cu = new ((function () {
            function e() {
              p(this, e);
            }
            return (
              g(e, [
                { key: "addToWindow", value: function (e) {} },
                {
                  key: "findTestabilityInTree",
                  value: function (e, t, n) {
                    return null;
                  },
                },
              ]),
              e
            );
          })())(),
          Eu = !0,
          Tu = !1,
          xu = new Jn("AllowMultipleToken"),
          Iu = function e(t, n) {
            p(this, e), (this.name = t), (this.token = n);
          };
        function Au(e, t) {
          var n =
              arguments.length > 2 && void 0 !== arguments[2]
                ? arguments[2]
                : [],
            r = "Platform: ".concat(t),
            i = new Jn(r);
          return function () {
            var t =
                arguments.length > 0 && void 0 !== arguments[0]
                  ? arguments[0]
                  : [],
              o = Ou();
            if (!o || o.injector.get(xu, !1))
              if (e) e(n.concat(t).concat({ provide: i, useValue: !0 }));
              else {
                var a = n
                  .concat(t)
                  .concat(
                    { provide: i, useValue: !0 },
                    { provide: mo, useValue: "platform" }
                  );
                !(function (e) {
                  if (bu && !bu.destroyed && !bu.injector.get(xu, !1))
                    throw new Error(
                      "There can be only one platform. Destroy the previous one to create a new one."
                    );
                  bu = e.get(Ru);
                  var t = e.get(Ys, null);
                  t &&
                    t.forEach(function (e) {
                      return e();
                    });
                })(Io.create({ providers: a, name: r }));
              }
            return (function (e) {
              var t = Ou();
              if (!t) throw new Error("No platform exists!");
              if (!t.injector.get(e, null))
                throw new Error(
                  "A platform with a different configuration has been created. Please destroy it first."
                );
              return t;
            })(i);
          };
        }
        function Ou() {
          return bu && !bu.destroyed ? bu : null;
        }
        var Ru = (function () {
          var e = (function () {
            function e(t) {
              p(this, e),
                (this._injector = t),
                (this._modules = []),
                (this._destroyListeners = []),
                (this._destroyed = !1);
            }
            return (
              g(e, [
                {
                  key: "bootstrapModuleFactory",
                  value: function (e, t) {
                    var n,
                      r,
                      i = this,
                      o =
                        ((n = t ? t.ngZone : void 0),
                        (r = {
                          ngZoneEventCoalescing:
                            (t && t.ngZoneEventCoalescing) || !1,
                          ngZoneRunCoalescing:
                            (t && t.ngZoneRunCoalescing) || !1,
                        }),
                        "noop" === n
                          ? new wu()
                          : ("zone.js" === n ? void 0 : n) ||
                            new vu({
                              enableLongStackTrace: ((Tu = !0), Eu),
                              shouldCoalesceEventChangeDetection: !!(null == r
                                ? void 0
                                : r.ngZoneEventCoalescing),
                              shouldCoalesceRunChangeDetection: !!(null == r
                                ? void 0
                                : r.ngZoneRunCoalescing),
                            })),
                      a = [{ provide: vu, useValue: o }];
                    return o.run(function () {
                      var t = Io.create({
                          providers: a,
                          parent: i.injector,
                          name: e.moduleType.name,
                        }),
                        n = e.create(t),
                        r = n.injector.get(xr, null);
                      if (!r)
                        throw new Error(
                          "No ErrorHandler. Is platform module (BrowserModule) included?"
                        );
                      return (
                        o.runOutsideAngular(function () {
                          var e = o.onError.subscribe({
                            next: function (e) {
                              r.handleError(e);
                            },
                          });
                          n.onDestroy(function () {
                            Mu(i._modules, n), e.unsubscribe();
                          });
                        }),
                        (function (e, t, r) {
                          try {
                            var o =
                              ((a = n.injector.get(Ks)).runInitializers(),
                              a.donePromise.then(function () {
                                return (
                                  ya(n.injector.get(nu, "en-US") || "en-US"),
                                  i._moduleDoBootstrap(n),
                                  n
                                );
                              }));
                            return Qo(o)
                              ? o.catch(function (n) {
                                  throw (
                                    (t.runOutsideAngular(function () {
                                      return e.handleError(n);
                                    }),
                                    n)
                                  );
                                })
                              : o;
                          } catch (s) {
                            throw (
                              (t.runOutsideAngular(function () {
                                return e.handleError(s);
                              }),
                              s)
                            );
                          }
                          var a;
                        })(r, o)
                      );
                    });
                  },
                },
                {
                  key: "bootstrapModule",
                  value: function (e) {
                    var t = this,
                      n =
                        arguments.length > 1 && void 0 !== arguments[1]
                          ? arguments[1]
                          : [],
                      r = Pu({}, n);
                    return (function (e, t, n) {
                      var r = new ws(n);
                      return Promise.resolve(r);
                    })(0, 0, e).then(function (e) {
                      return t.bootstrapModuleFactory(e, r);
                    });
                  },
                },
                {
                  key: "_moduleDoBootstrap",
                  value: function (e) {
                    var t = e.injector.get(Nu);
                    if (e._bootstrapComponents.length > 0)
                      e._bootstrapComponents.forEach(function (e) {
                        return t.bootstrap(e);
                      });
                    else {
                      if (!e.instance.ngDoBootstrap)
                        throw new Error(
                          "The module ".concat(
                            me(e.instance.constructor),
                            ' was bootstrapped, but it does not declare "@NgModule.bootstrap" components nor a "ngDoBootstrap" method. Please define one of these.'
                          )
                        );
                      e.instance.ngDoBootstrap(t);
                    }
                    this._modules.push(e);
                  },
                },
                {
                  key: "onDestroy",
                  value: function (e) {
                    this._destroyListeners.push(e);
                  },
                },
                {
                  key: "injector",
                  get: function () {
                    return this._injector;
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    if (this._destroyed)
                      throw new Error(
                        "The platform has already been destroyed!"
                      );
                    this._modules.slice().forEach(function (e) {
                      return e.destroy();
                    }),
                      this._destroyListeners.forEach(function (e) {
                        return e();
                      }),
                      (this._destroyed = !0);
                  },
                },
                {
                  key: "destroyed",
                  get: function () {
                    return this._destroyed;
                  },
                },
              ]),
              e
            );
          })();
          return (
            (e.ɵfac = function (t) {
              return new (t || e)(fr(Io));
            }),
            (e.ɵprov = Ee({ token: e, factory: e.ɵfac })),
            e
          );
        })();
        function Pu(e, t) {
          return Array.isArray(t)
            ? t.reduce(Pu, e)
            : Object.assign(Object.assign({}, e), t);
        }
        var Du,
          Nu =
            (((Du = (function () {
              function e(t, n, r, i, o) {
                var a = this;
                p(this, e),
                  (this._zone = t),
                  (this._injector = n),
                  (this._exceptionHandler = r),
                  (this._componentFactoryResolver = i),
                  (this._initStatus = o),
                  (this._bootstrapListeners = []),
                  (this._views = []),
                  (this._runningTick = !1),
                  (this._stable = !0),
                  (this.componentTypes = []),
                  (this.components = []),
                  (this._onMicrotaskEmptySubscription =
                    this._zone.onMicrotaskEmpty.subscribe({
                      next: function () {
                        a._zone.run(function () {
                          a.tick();
                        });
                      },
                    }));
                var s = new D(function (e) {
                    (a._stable =
                      a._zone.isStable &&
                      !a._zone.hasPendingMacrotasks &&
                      !a._zone.hasPendingMicrotasks),
                      a._zone.runOutsideAngular(function () {
                        e.next(a._stable), e.complete();
                      });
                  }),
                  u = new D(function (e) {
                    var t;
                    a._zone.runOutsideAngular(function () {
                      t = a._zone.onStable.subscribe(function () {
                        vu.assertNotInAngularZone(),
                          du(function () {
                            a._stable ||
                              a._zone.hasPendingMacrotasks ||
                              a._zone.hasPendingMicrotasks ||
                              ((a._stable = !0), e.next(!0));
                          });
                      });
                    });
                    var n = a._zone.onUnstable.subscribe(function () {
                      vu.assertInAngularZone(),
                        a._stable &&
                          ((a._stable = !1),
                          a._zone.runOutsideAngular(function () {
                            e.next(!1);
                          }));
                    });
                    return function () {
                      t.unsubscribe(), n.unsubscribe();
                    };
                  });
                this.isStable = ae(
                  s,
                  u.pipe(function (e) {
                    return se()(
                      ((t = ve),
                      function (e) {
                        var n;
                        n =
                          "function" == typeof t
                            ? t
                            : function () {
                                return t;
                              };
                        var r = Object.create(e, fe);
                        return (r.source = e), (r.subjectFactory = n), r;
                      })(e)
                    );
                    var t;
                  })
                );
              }
              return (
                g(e, [
                  {
                    key: "bootstrap",
                    value: function (e, t) {
                      var n,
                        r = this;
                      if (!this._initStatus.done)
                        throw new Error(
                          "Cannot bootstrap as there are still asynchronous initializers running. Bootstrap components in the `ngDoBootstrap` method of the root module."
                        );
                      (n =
                        e instanceof ka
                          ? e
                          : this._componentFactoryResolver.resolveComponentFactory(
                              e
                            )),
                        this.componentTypes.push(n.componentType);
                      var i = n.isBoundToModule
                          ? void 0
                          : this._injector.get(os),
                        o = n.create(Io.NULL, [], t || n.selector, i),
                        a = o.location.nativeElement,
                        s = o.injector.get(_u, null),
                        u = s && o.injector.get(Su);
                      return (
                        s && u && u.registerApplication(a, s),
                        o.onDestroy(function () {
                          r.detachView(o.hostView),
                            Mu(r.components, o),
                            u && u.unregisterApplication(a);
                        }),
                        this._loadComponent(o),
                        o
                      );
                    },
                  },
                  {
                    key: "tick",
                    value: function () {
                      var e = this;
                      if (this._runningTick)
                        throw new Error(
                          "ApplicationRef.tick is called recursively"
                        );
                      try {
                        this._runningTick = !0;
                        var n,
                          r = t(this._views);
                        try {
                          for (r.s(); !(n = r.n()).done; )
                            n.value.detectChanges();
                        } catch (i) {
                          r.e(i);
                        } finally {
                          r.f();
                        }
                      } catch (o) {
                        this._zone.runOutsideAngular(function () {
                          return e._exceptionHandler.handleError(o);
                        });
                      } finally {
                        this._runningTick = !1;
                      }
                    },
                  },
                  {
                    key: "attachView",
                    value: function (e) {
                      var t = e;
                      this._views.push(t), t.attachToAppRef(this);
                    },
                  },
                  {
                    key: "detachView",
                    value: function (e) {
                      var t = e;
                      Mu(this._views, t), t.detachFromAppRef();
                    },
                  },
                  {
                    key: "_loadComponent",
                    value: function (e) {
                      this.attachView(e.hostView),
                        this.tick(),
                        this.components.push(e),
                        this._injector
                          .get(eu, [])
                          .concat(this._bootstrapListeners)
                          .forEach(function (t) {
                            return t(e);
                          });
                    },
                  },
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      this._views.slice().forEach(function (e) {
                        return e.destroy();
                      }),
                        this._onMicrotaskEmptySubscription.unsubscribe();
                    },
                  },
                  {
                    key: "viewCount",
                    get: function () {
                      return this._views.length;
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Du)(fr(vu), fr(Io), fr(xr), fr(wa), fr(Ks));
            }),
            (Du.ɵprov = Ee({ token: Du, factory: Du.ɵfac })),
            Du);
        function Mu(e, t) {
          var n = e.indexOf(t);
          n > -1 && e.splice(n, 1);
        }
        var ju = function e() {
            p(this, e);
          },
          Fu = function e() {
            p(this, e);
          },
          Lu = { factoryPathPrefix: "", factoryPathSuffix: ".ngfactory" },
          Wu = (function () {
            var t = (function () {
              function t(e, n) {
                p(this, t), (this._compiler = e), (this._config = n || Lu);
              }
              return (
                g(t, [
                  {
                    key: "load",
                    value: function (e) {
                      return this.loadAndCompile(e);
                    },
                  },
                  {
                    key: "loadAndCompile",
                    value: function (t) {
                      var n = this,
                        r = e(t.split("#"), 2),
                        i = r[0],
                        o = r[1];
                      return (
                        void 0 === o && (o = "default"),
                        l("zn8P")(i)
                          .then(function (e) {
                            return e[o];
                          })
                          .then(function (e) {
                            return zu(e, i, o);
                          })
                          .then(function (e) {
                            return n._compiler.compileModuleAsync(e);
                          })
                      );
                    },
                  },
                  {
                    key: "loadFactory",
                    value: function (t) {
                      var n = e(t.split("#"), 2),
                        r = n[0],
                        i = n[1],
                        o = "NgFactory";
                      return (
                        void 0 === i && ((i = "default"), (o = "")),
                        l("zn8P")(
                          this._config.factoryPathPrefix +
                            r +
                            this._config.factoryPathSuffix
                        )
                          .then(function (e) {
                            return e[i + o];
                          })
                          .then(function (e) {
                            return zu(e, r, i);
                          })
                      );
                    },
                  },
                ]),
                t
              );
            })();
            return (
              (t.ɵfac = function (e) {
                return new (e || t)(fr(hu), fr(Fu, 8));
              }),
              (t.ɵprov = Ee({ token: t, factory: t.ɵfac })),
              t
            );
          })();
        function zu(e, t, n) {
          if (!e)
            throw new Error("Cannot find '".concat(n, "' in '").concat(t, "'"));
          return e;
        }
        var qu = Au(null, "core", [
            { provide: Xs, useValue: "unknown" },
            { provide: Ru, deps: [Io] },
            { provide: Su, deps: [] },
            { provide: tu, deps: [] },
          ]),
          Hu = [
            { provide: Nu, useClass: Nu, deps: [vu, Io, xr, wa, Ks] },
            {
              provide: ms,
              deps: [vu],
              useFactory: function (e) {
                var t = [];
                return (
                  e.onStable.subscribe(function () {
                    for (; t.length; ) t.pop()();
                  }),
                  function (e) {
                    t.push(e);
                  }
                );
              },
            },
            { provide: Ks, useClass: Ks, deps: [[new mr(), Gs]] },
            { provide: hu, useClass: hu, deps: [] },
            Js,
            {
              provide: Va,
              useFactory: function () {
                return Xa;
              },
              deps: [],
            },
            {
              provide: Qa,
              useFactory: function () {
                return es;
              },
              deps: [],
            },
            {
              provide: nu,
              useFactory: function (e) {
                return (
                  ya(
                    (e =
                      e ||
                      ("undefined" != typeof $localize && $localize.locale) ||
                      "en-US")
                  ),
                  e
                );
              },
              deps: [[new pr(nu), new mr(), new gr()]],
            },
            { provide: ru, useValue: "USD" },
          ],
          Uu = (function () {
            var e = function e(t) {
              p(this, e);
            };
            return (
              (e.ɵfac = function (t) {
                return new (t || e)(fr(Nu));
              }),
              (e.ɵmod = st({ type: e })),
              (e.ɵinj = Te({ providers: Hu })),
              e
            );
          })(),
          Vu = null;
        function Bu() {
          return Vu;
        }
        var Qu,
          Gu = new Jn("DocumentToken"),
          Ku =
            (((Qu = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || Qu)();
            }),
            (Qu.ɵprov = Ee({ factory: Zu, token: Qu, providedIn: "platform" })),
            Qu);
        function Zu() {
          return fr(Yu);
        }
        var Ju,
          $u = new Jn("Location Initialized"),
          Yu =
            (((Ju = (function (e) {
              u(n, e);
              var t = c(n);
              function n(e) {
                var r;
                return p(this, n), ((r = t.call(this))._doc = e), r._init(), r;
              }
              return (
                g(n, [
                  {
                    key: "_init",
                    value: function () {
                      (this.location = Bu().getLocation()),
                        (this._history = Bu().getHistory());
                    },
                  },
                  {
                    key: "getBaseHrefFromDOM",
                    value: function () {
                      return Bu().getBaseHref(this._doc);
                    },
                  },
                  {
                    key: "onPopState",
                    value: function (e) {
                      Bu()
                        .getGlobalEventTarget(this._doc, "window")
                        .addEventListener("popstate", e, !1);
                    },
                  },
                  {
                    key: "onHashChange",
                    value: function (e) {
                      Bu()
                        .getGlobalEventTarget(this._doc, "window")
                        .addEventListener("hashchange", e, !1);
                    },
                  },
                  {
                    key: "href",
                    get: function () {
                      return this.location.href;
                    },
                  },
                  {
                    key: "protocol",
                    get: function () {
                      return this.location.protocol;
                    },
                  },
                  {
                    key: "hostname",
                    get: function () {
                      return this.location.hostname;
                    },
                  },
                  {
                    key: "port",
                    get: function () {
                      return this.location.port;
                    },
                  },
                  {
                    key: "pathname",
                    get: function () {
                      return this.location.pathname;
                    },
                    set: function (e) {
                      this.location.pathname = e;
                    },
                  },
                  {
                    key: "search",
                    get: function () {
                      return this.location.search;
                    },
                  },
                  {
                    key: "hash",
                    get: function () {
                      return this.location.hash;
                    },
                  },
                  {
                    key: "pushState",
                    value: function (e, t, n) {
                      Xu()
                        ? this._history.pushState(e, t, n)
                        : (this.location.hash = n);
                    },
                  },
                  {
                    key: "replaceState",
                    value: function (e, t, n) {
                      Xu()
                        ? this._history.replaceState(e, t, n)
                        : (this.location.hash = n);
                    },
                  },
                  {
                    key: "forward",
                    value: function () {
                      this._history.forward();
                    },
                  },
                  {
                    key: "back",
                    value: function () {
                      this._history.back();
                    },
                  },
                  {
                    key: "getState",
                    value: function () {
                      return this._history.state;
                    },
                  },
                ]),
                n
              );
            })(Ku)).ɵfac = function (e) {
              return new (e || Ju)(fr(Gu));
            }),
            (Ju.ɵprov = Ee({ factory: el, token: Ju, providedIn: "platform" })),
            Ju);
        function Xu() {
          return !!window.history.pushState;
        }
        function el() {
          return new Yu(fr(Gu));
        }
        function tl(e, t) {
          if (0 == e.length) return t;
          if (0 == t.length) return e;
          var n = 0;
          return (
            e.endsWith("/") && n++,
            t.startsWith("/") && n++,
            2 == n ? e + t.substring(1) : 1 == n ? e + t : e + "/" + t
          );
        }
        function nl(e) {
          var t = e.match(/#|\?|$/),
            n = (t && t.index) || e.length;
          return e.slice(0, n - ("/" === e[n - 1] ? 1 : 0)) + e.slice(n);
        }
        function rl(e) {
          return e && "?" !== e[0] ? "?" + e : e;
        }
        var il,
          ol =
            (((il = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || il)();
            }),
            (il.ɵprov = Ee({ factory: al, token: il, providedIn: "root" })),
            il);
        function al(e) {
          var t = fr(Gu).location;
          return new hl(fr(Ku), (t && t.origin) || "");
        }
        var sl,
          ul,
          ll,
          cl = new Jn("appBaseHref"),
          hl =
            (((ll = (function (e) {
              u(n, e);
              var t = c(n);
              function n(e, r) {
                var i;
                if (
                  (p(this, n),
                  ((i = t.call(this))._platformLocation = e),
                  null == r && (r = i._platformLocation.getBaseHrefFromDOM()),
                  null == r)
                )
                  throw new Error(
                    "No base href set. Please provide a value for the APP_BASE_HREF token or add a base element to the document."
                  );
                return (i._baseHref = r), h(i);
              }
              return (
                g(n, [
                  {
                    key: "onPopState",
                    value: function (e) {
                      this._platformLocation.onPopState(e),
                        this._platformLocation.onHashChange(e);
                    },
                  },
                  {
                    key: "getBaseHref",
                    value: function () {
                      return this._baseHref;
                    },
                  },
                  {
                    key: "prepareExternalUrl",
                    value: function (e) {
                      return tl(this._baseHref, e);
                    },
                  },
                  {
                    key: "path",
                    value: function () {
                      var e =
                          arguments.length > 0 &&
                          void 0 !== arguments[0] &&
                          arguments[0],
                        t =
                          this._platformLocation.pathname +
                          rl(this._platformLocation.search),
                        n = this._platformLocation.hash;
                      return n && e ? "".concat(t).concat(n) : t;
                    },
                  },
                  {
                    key: "pushState",
                    value: function (e, t, n, r) {
                      var i = this.prepareExternalUrl(n + rl(r));
                      this._platformLocation.pushState(e, t, i);
                    },
                  },
                  {
                    key: "replaceState",
                    value: function (e, t, n, r) {
                      var i = this.prepareExternalUrl(n + rl(r));
                      this._platformLocation.replaceState(e, t, i);
                    },
                  },
                  {
                    key: "forward",
                    value: function () {
                      this._platformLocation.forward();
                    },
                  },
                  {
                    key: "back",
                    value: function () {
                      this._platformLocation.back();
                    },
                  },
                ]),
                n
              );
            })(ol)).ɵfac = function (e) {
              return new (e || ll)(fr(Ku), fr(cl, 8));
            }),
            (ll.ɵprov = Ee({ token: ll, factory: ll.ɵfac })),
            ll),
          fl =
            (((ul = (function (e) {
              u(n, e);
              var t = c(n);
              function n(e, r) {
                var i;
                return (
                  p(this, n),
                  ((i = t.call(this))._platformLocation = e),
                  (i._baseHref = ""),
                  null != r && (i._baseHref = r),
                  i
                );
              }
              return (
                g(n, [
                  {
                    key: "onPopState",
                    value: function (e) {
                      this._platformLocation.onPopState(e),
                        this._platformLocation.onHashChange(e);
                    },
                  },
                  {
                    key: "getBaseHref",
                    value: function () {
                      return this._baseHref;
                    },
                  },
                  {
                    key: "path",
                    value: function () {
                      var e = this._platformLocation.hash;
                      return (
                        null == e && (e = "#"),
                        e.length > 0 ? e.substring(1) : e
                      );
                    },
                  },
                  {
                    key: "prepareExternalUrl",
                    value: function (e) {
                      var t = tl(this._baseHref, e);
                      return t.length > 0 ? "#" + t : t;
                    },
                  },
                  {
                    key: "pushState",
                    value: function (e, t, n, r) {
                      var i = this.prepareExternalUrl(n + rl(r));
                      0 == i.length && (i = this._platformLocation.pathname),
                        this._platformLocation.pushState(e, t, i);
                    },
                  },
                  {
                    key: "replaceState",
                    value: function (e, t, n, r) {
                      var i = this.prepareExternalUrl(n + rl(r));
                      0 == i.length && (i = this._platformLocation.pathname),
                        this._platformLocation.replaceState(e, t, i);
                    },
                  },
                  {
                    key: "forward",
                    value: function () {
                      this._platformLocation.forward();
                    },
                  },
                  {
                    key: "back",
                    value: function () {
                      this._platformLocation.back();
                    },
                  },
                ]),
                n
              );
            })(ol)).ɵfac = function (e) {
              return new (e || ul)(fr(Ku), fr(cl, 8));
            }),
            (ul.ɵprov = Ee({ token: ul, factory: ul.ɵfac })),
            ul),
          dl =
            (((sl = (function () {
              function e(t, n) {
                var r = this;
                p(this, e),
                  (this._subject = new xs()),
                  (this._urlChangeListeners = []),
                  (this._platformStrategy = t);
                var i = this._platformStrategy.getBaseHref();
                (this._platformLocation = n),
                  (this._baseHref = nl(pl(i))),
                  this._platformStrategy.onPopState(function (e) {
                    r._subject.emit({
                      url: r.path(!0),
                      pop: !0,
                      state: e.state,
                      type: e.type,
                    });
                  });
              }
              return (
                g(e, [
                  {
                    key: "path",
                    value: function () {
                      var e =
                        arguments.length > 0 &&
                        void 0 !== arguments[0] &&
                        arguments[0];
                      return this.normalize(this._platformStrategy.path(e));
                    },
                  },
                  {
                    key: "getState",
                    value: function () {
                      return this._platformLocation.getState();
                    },
                  },
                  {
                    key: "isCurrentPathEqualTo",
                    value: function (e) {
                      var t =
                        arguments.length > 1 && void 0 !== arguments[1]
                          ? arguments[1]
                          : "";
                      return this.path() == this.normalize(e + rl(t));
                    },
                  },
                  {
                    key: "normalize",
                    value: function (t) {
                      return e.stripTrailingSlash(
                        (function (e, t) {
                          return e && t.startsWith(e)
                            ? t.substring(e.length)
                            : t;
                        })(this._baseHref, pl(t))
                      );
                    },
                  },
                  {
                    key: "prepareExternalUrl",
                    value: function (e) {
                      return (
                        e && "/" !== e[0] && (e = "/" + e),
                        this._platformStrategy.prepareExternalUrl(e)
                      );
                    },
                  },
                  {
                    key: "go",
                    value: function (e) {
                      var t =
                          arguments.length > 1 && void 0 !== arguments[1]
                            ? arguments[1]
                            : "",
                        n =
                          arguments.length > 2 && void 0 !== arguments[2]
                            ? arguments[2]
                            : null;
                      this._platformStrategy.pushState(n, "", e, t),
                        this._notifyUrlChangeListeners(
                          this.prepareExternalUrl(e + rl(t)),
                          n
                        );
                    },
                  },
                  {
                    key: "replaceState",
                    value: function (e) {
                      var t =
                          arguments.length > 1 && void 0 !== arguments[1]
                            ? arguments[1]
                            : "",
                        n =
                          arguments.length > 2 && void 0 !== arguments[2]
                            ? arguments[2]
                            : null;
                      this._platformStrategy.replaceState(n, "", e, t),
                        this._notifyUrlChangeListeners(
                          this.prepareExternalUrl(e + rl(t)),
                          n
                        );
                    },
                  },
                  {
                    key: "forward",
                    value: function () {
                      this._platformStrategy.forward();
                    },
                  },
                  {
                    key: "back",
                    value: function () {
                      this._platformStrategy.back();
                    },
                  },
                  {
                    key: "onUrlChange",
                    value: function (e) {
                      var t = this;
                      this._urlChangeListeners.push(e),
                        this._urlChangeSubscription ||
                          (this._urlChangeSubscription = this.subscribe(
                            function (e) {
                              t._notifyUrlChangeListeners(e.url, e.state);
                            }
                          ));
                    },
                  },
                  {
                    key: "_notifyUrlChangeListeners",
                    value: function () {
                      var e =
                          arguments.length > 0 && void 0 !== arguments[0]
                            ? arguments[0]
                            : "",
                        t = arguments.length > 1 ? arguments[1] : void 0;
                      this._urlChangeListeners.forEach(function (n) {
                        return n(e, t);
                      });
                    },
                  },
                  {
                    key: "subscribe",
                    value: function (e, t, n) {
                      return this._subject.subscribe({
                        next: e,
                        error: t,
                        complete: n,
                      });
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || sl)(fr(ol), fr(Ku));
            }),
            (sl.normalizeQueryParams = rl),
            (sl.joinWithSlash = tl),
            (sl.stripTrailingSlash = nl),
            (sl.ɵprov = Ee({ factory: vl, token: sl, providedIn: "root" })),
            sl);
        function vl() {
          return new dl(fr(ol), fr(Ku));
        }
        function pl(e) {
          return e.replace(/\/index.html$/, "");
        }
        var ml,
          gl = (function (e) {
            return (
              (e[(e.Zero = 0)] = "Zero"),
              (e[(e.One = 1)] = "One"),
              (e[(e.Two = 2)] = "Two"),
              (e[(e.Few = 3)] = "Few"),
              (e[(e.Many = 4)] = "Many"),
              (e[(e.Other = 5)] = "Other"),
              e
            );
          })({}),
          yl = function e() {
            p(this, e);
          },
          kl =
            (((ml = (function (e) {
              u(n, e);
              var t = c(n);
              function n(e) {
                var r;
                return p(this, n), ((r = t.call(this)).locale = e), r;
              }
              return (
                g(n, [
                  {
                    key: "getPluralCategory",
                    value: function (e, t) {
                      switch (
                        (function (e) {
                          return (function (e) {
                            var t = (function (e) {
                                return e.toLowerCase().replace(/_/g, "-");
                              })(e),
                              n = ma(t);
                            if (n) return n;
                            var r = t.split("-")[0];
                            if ((n = ma(r))) return n;
                            if ("en" === r) return va;
                            throw new Error(
                              'Missing locale data for the locale "'.concat(
                                e,
                                '".'
                              )
                            );
                          })(e)[ga.PluralCase];
                        })(t || this.locale)(e)
                      ) {
                        case gl.Zero:
                          return "zero";
                        case gl.One:
                          return "one";
                        case gl.Two:
                          return "two";
                        case gl.Few:
                          return "few";
                        case gl.Many:
                          return "many";
                        default:
                          return "other";
                      }
                    },
                  },
                ]),
                n
              );
            })(yl)).ɵfac = function (e) {
              return new (e || ml)(fr(nu));
            }),
            (ml.ɵprov = Ee({ token: ml, factory: ml.ɵfac })),
            ml);
        function bl(n, r) {
          r = encodeURIComponent(r);
          var i,
            o = t(n.split(";"));
          try {
            for (o.s(); !(i = o.n()).done; ) {
              var a = i.value,
                s = a.indexOf("="),
                u = e(-1 == s ? [a, ""] : [a.slice(0, s), a.slice(s + 1)], 2),
                l = u[0],
                c = u[1];
              if (l.trim() === r) return decodeURIComponent(c);
            }
          } catch (h) {
            o.e(h);
          } finally {
            o.f();
          }
          return null;
        }
        var wl,
          _l,
          Sl,
          Cl =
            (((wl = (function () {
              function e(t, n, r, i) {
                p(this, e),
                  (this._iterableDiffers = t),
                  (this._keyValueDiffers = n),
                  (this._ngEl = r),
                  (this._renderer = i),
                  (this._iterableDiffer = null),
                  (this._keyValueDiffer = null),
                  (this._initialClasses = []),
                  (this._rawClass = null);
              }
              return (
                g(e, [
                  {
                    key: "klass",
                    set: function (e) {
                      this._removeClasses(this._initialClasses),
                        (this._initialClasses =
                          "string" == typeof e ? e.split(/\s+/) : []),
                        this._applyClasses(this._initialClasses),
                        this._applyClasses(this._rawClass);
                    },
                  },
                  {
                    key: "ngClass",
                    set: function (e) {
                      this._removeClasses(this._rawClass),
                        this._applyClasses(this._initialClasses),
                        (this._iterableDiffer = null),
                        (this._keyValueDiffer = null),
                        (this._rawClass =
                          "string" == typeof e ? e.split(/\s+/) : e),
                        this._rawClass &&
                          (Po(this._rawClass)
                            ? (this._iterableDiffer = this._iterableDiffers
                                .find(this._rawClass)
                                .create())
                            : (this._keyValueDiffer = this._keyValueDiffers
                                .find(this._rawClass)
                                .create()));
                    },
                  },
                  {
                    key: "ngDoCheck",
                    value: function () {
                      if (this._iterableDiffer) {
                        var e = this._iterableDiffer.diff(this._rawClass);
                        e && this._applyIterableChanges(e);
                      } else if (this._keyValueDiffer) {
                        var t = this._keyValueDiffer.diff(this._rawClass);
                        t && this._applyKeyValueChanges(t);
                      }
                    },
                  },
                  {
                    key: "_applyKeyValueChanges",
                    value: function (e) {
                      var t = this;
                      e.forEachAddedItem(function (e) {
                        return t._toggleClass(e.key, e.currentValue);
                      }),
                        e.forEachChangedItem(function (e) {
                          return t._toggleClass(e.key, e.currentValue);
                        }),
                        e.forEachRemovedItem(function (e) {
                          e.previousValue && t._toggleClass(e.key, !1);
                        });
                    },
                  },
                  {
                    key: "_applyIterableChanges",
                    value: function (e) {
                      var t = this;
                      e.forEachAddedItem(function (e) {
                        if ("string" != typeof e.item)
                          throw new Error(
                            "NgClass can only toggle CSS classes expressed as strings, got ".concat(
                              me(e.item)
                            )
                          );
                        t._toggleClass(e.item, !0);
                      }),
                        e.forEachRemovedItem(function (e) {
                          return t._toggleClass(e.item, !1);
                        });
                    },
                  },
                  {
                    key: "_applyClasses",
                    value: function (e) {
                      var t = this;
                      e &&
                        (Array.isArray(e) || e instanceof Set
                          ? e.forEach(function (e) {
                              return t._toggleClass(e, !0);
                            })
                          : Object.keys(e).forEach(function (n) {
                              return t._toggleClass(n, !!e[n]);
                            }));
                    },
                  },
                  {
                    key: "_removeClasses",
                    value: function (e) {
                      var t = this;
                      e &&
                        (Array.isArray(e) || e instanceof Set
                          ? e.forEach(function (e) {
                              return t._toggleClass(e, !1);
                            })
                          : Object.keys(e).forEach(function (e) {
                              return t._toggleClass(e, !1);
                            }));
                    },
                  },
                  {
                    key: "_toggleClass",
                    value: function (e, t) {
                      var n = this;
                      (e = e.trim()) &&
                        e.split(/\s+/g).forEach(function (e) {
                          t
                            ? n._renderer.addClass(n._ngEl.nativeElement, e)
                            : n._renderer.removeClass(n._ngEl.nativeElement, e);
                        });
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || wl)(Wo(Va), Wo(Qa), Wo(Ea), Wo(Ia));
            }),
            (wl.ɵdir = lt({
              type: wl,
              selectors: [["", "ngClass", ""]],
              inputs: { klass: ["class", "klass"], ngClass: "ngClass" },
            })),
            wl),
          El = (function () {
            function e(t, n, r, i) {
              p(this, e),
                (this.$implicit = t),
                (this.ngForOf = n),
                (this.index = r),
                (this.count = i);
            }
            return (
              g(e, [
                {
                  key: "first",
                  get: function () {
                    return 0 === this.index;
                  },
                },
                {
                  key: "last",
                  get: function () {
                    return this.index === this.count - 1;
                  },
                },
                {
                  key: "even",
                  get: function () {
                    return this.index % 2 == 0;
                  },
                },
                {
                  key: "odd",
                  get: function () {
                    return !this.even;
                  },
                },
              ]),
              e
            );
          })(),
          Tl =
            (((_l = (function () {
              function e(t, n, r) {
                p(this, e),
                  (this._viewContainer = t),
                  (this._template = n),
                  (this._differs = r),
                  (this._ngForOf = null),
                  (this._ngForOfDirty = !0),
                  (this._differ = null);
              }
              return (
                g(
                  e,
                  [
                    {
                      key: "ngForOf",
                      set: function (e) {
                        (this._ngForOf = e), (this._ngForOfDirty = !0);
                      },
                    },
                    {
                      key: "ngForTrackBy",
                      get: function () {
                        return this._trackByFn;
                      },
                      set: function (e) {
                        this._trackByFn = e;
                      },
                    },
                    {
                      key: "ngForTemplate",
                      set: function (e) {
                        e && (this._template = e);
                      },
                    },
                    {
                      key: "ngDoCheck",
                      value: function () {
                        if (this._ngForOfDirty) {
                          this._ngForOfDirty = !1;
                          var e = this._ngForOf;
                          if (!this._differ && e)
                            try {
                              this._differ = this._differs
                                .find(e)
                                .create(this.ngForTrackBy);
                            } catch (r) {
                              throw new Error(
                                "Cannot find a differ supporting object '"
                                  .concat(e, "' of type '")
                                  .concat(
                                    (t = e).name || typeof t,
                                    "'. NgFor only supports binding to Iterables such as Arrays."
                                  )
                              );
                            }
                        }
                        var t;
                        if (this._differ) {
                          var n = this._differ.diff(this._ngForOf);
                          n && this._applyChanges(n);
                        }
                      },
                    },
                    {
                      key: "_applyChanges",
                      value: function (e) {
                        var t = this,
                          n = [];
                        e.forEachOperation(function (e, r, i) {
                          if (null == e.previousIndex) {
                            var o = t._viewContainer.createEmbeddedView(
                                t._template,
                                new El(null, t._ngForOf, -1, -1),
                                null === i ? void 0 : i
                              ),
                              a = new xl(e, o);
                            n.push(a);
                          } else if (null == i)
                            t._viewContainer.remove(null === r ? void 0 : r);
                          else if (null !== r) {
                            var s = t._viewContainer.get(r);
                            t._viewContainer.move(s, i);
                            var u = new xl(e, s);
                            n.push(u);
                          }
                        });
                        for (var r = 0; r < n.length; r++)
                          this._perViewChange(n[r].view, n[r].record);
                        for (
                          var i = 0, o = this._viewContainer.length;
                          i < o;
                          i++
                        ) {
                          var a = this._viewContainer.get(i);
                          (a.context.index = i),
                            (a.context.count = o),
                            (a.context.ngForOf = this._ngForOf);
                        }
                        e.forEachIdentityChange(function (e) {
                          t._viewContainer.get(
                            e.currentIndex
                          ).context.$implicit = e.item;
                        });
                      },
                    },
                    {
                      key: "_perViewChange",
                      value: function (e, t) {
                        e.context.$implicit = t.item;
                      },
                    },
                  ],
                  [
                    {
                      key: "ngTemplateContextGuard",
                      value: function (e, t) {
                        return !0;
                      },
                    },
                  ]
                ),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || _l)(Wo(us), Wo(ns), Wo(Va));
            }),
            (_l.ɵdir = lt({
              type: _l,
              selectors: [["", "ngFor", "", "ngForOf", ""]],
              inputs: {
                ngForOf: "ngForOf",
                ngForTrackBy: "ngForTrackBy",
                ngForTemplate: "ngForTemplate",
              },
            })),
            _l),
          xl = function e(t, n) {
            p(this, e), (this.record = t), (this.view = n);
          },
          Il =
            (((Sl = (function () {
              function e(t, n) {
                p(this, e),
                  (this._viewContainer = t),
                  (this._context = new Al()),
                  (this._thenTemplateRef = null),
                  (this._elseTemplateRef = null),
                  (this._thenViewRef = null),
                  (this._elseViewRef = null),
                  (this._thenTemplateRef = n);
              }
              return (
                g(
                  e,
                  [
                    {
                      key: "ngIf",
                      set: function (e) {
                        (this._context.$implicit = this._context.ngIf = e),
                          this._updateView();
                      },
                    },
                    {
                      key: "ngIfThen",
                      set: function (e) {
                        Ol("ngIfThen", e),
                          (this._thenTemplateRef = e),
                          (this._thenViewRef = null),
                          this._updateView();
                      },
                    },
                    {
                      key: "ngIfElse",
                      set: function (e) {
                        Ol("ngIfElse", e),
                          (this._elseTemplateRef = e),
                          (this._elseViewRef = null),
                          this._updateView();
                      },
                    },
                    {
                      key: "_updateView",
                      value: function () {
                        this._context.$implicit
                          ? this._thenViewRef ||
                            (this._viewContainer.clear(),
                            (this._elseViewRef = null),
                            this._thenTemplateRef &&
                              (this._thenViewRef =
                                this._viewContainer.createEmbeddedView(
                                  this._thenTemplateRef,
                                  this._context
                                )))
                          : this._elseViewRef ||
                            (this._viewContainer.clear(),
                            (this._thenViewRef = null),
                            this._elseTemplateRef &&
                              (this._elseViewRef =
                                this._viewContainer.createEmbeddedView(
                                  this._elseTemplateRef,
                                  this._context
                                )));
                      },
                    },
                  ],
                  [
                    {
                      key: "ngTemplateContextGuard",
                      value: function (e, t) {
                        return !0;
                      },
                    },
                  ]
                ),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Sl)(Wo(us), Wo(ns));
            }),
            (Sl.ɵdir = lt({
              type: Sl,
              selectors: [["", "ngIf", ""]],
              inputs: {
                ngIf: "ngIf",
                ngIfThen: "ngIfThen",
                ngIfElse: "ngIfElse",
              },
            })),
            Sl),
          Al = function e() {
            p(this, e), (this.$implicit = null), (this.ngIf = null);
          };
        function Ol(e, t) {
          if (t && !t.createEmbeddedView)
            throw new Error(
              ""
                .concat(e, " must be a TemplateRef, but received '")
                .concat(me(t), "'.")
            );
        }
        var Rl,
          Pl =
            (((Rl = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || Rl)();
            }),
            (Rl.ɵmod = st({ type: Rl })),
            (Rl.ɵinj = Te({ providers: [{ provide: yl, useClass: kl }] })),
            Rl);
        function Dl(e) {
          return "browser" === e;
        }
        var Nl,
          Ml =
            (((Nl = function e() {
              p(this, e);
            }).ɵprov = Ee({
              token: Nl,
              providedIn: "root",
              factory: function () {
                return new jl(fr(Gu), window);
              },
            })),
            Nl),
          jl = (function () {
            function e(t, n) {
              p(this, e),
                (this.document = t),
                (this.window = n),
                (this.offset = function () {
                  return [0, 0];
                });
            }
            return (
              g(e, [
                {
                  key: "setOffset",
                  value: function (e) {
                    this.offset = Array.isArray(e)
                      ? function () {
                          return e;
                        }
                      : e;
                  },
                },
                {
                  key: "getScrollPosition",
                  value: function () {
                    return this.supportsScrolling()
                      ? [this.window.pageXOffset, this.window.pageYOffset]
                      : [0, 0];
                  },
                },
                {
                  key: "scrollToPosition",
                  value: function (e) {
                    this.supportsScrolling() &&
                      this.window.scrollTo(e[0], e[1]);
                  },
                },
                {
                  key: "scrollToAnchor",
                  value: function (e) {
                    if (this.supportsScrolling()) {
                      var t = (function (e, t) {
                        var n =
                          e.getElementById(t) || e.getElementsByName(t)[0];
                        if (n) return n;
                        if (
                          "function" == typeof e.createTreeWalker &&
                          e.body &&
                          (e.body.createShadowRoot || e.body.attachShadow)
                        )
                          for (
                            var r = e.createTreeWalker(
                                e.body,
                                NodeFilter.SHOW_ELEMENT
                              ),
                              i = r.currentNode;
                            i;

                          ) {
                            var o = i.shadowRoot;
                            if (o) {
                              var a =
                                o.getElementById(t) ||
                                o.querySelector('[name="'.concat(t, '"]'));
                              if (a) return a;
                            }
                            i = r.nextNode();
                          }
                        return null;
                      })(this.document, e);
                      t && (this.scrollToElement(t), this.attemptFocus(t));
                    }
                  },
                },
                {
                  key: "setHistoryScrollRestoration",
                  value: function (e) {
                    if (this.supportScrollRestoration()) {
                      var t = this.window.history;
                      t && t.scrollRestoration && (t.scrollRestoration = e);
                    }
                  },
                },
                {
                  key: "scrollToElement",
                  value: function (e) {
                    var t = e.getBoundingClientRect(),
                      n = t.left + this.window.pageXOffset,
                      r = t.top + this.window.pageYOffset,
                      i = this.offset();
                    this.window.scrollTo(n - i[0], r - i[1]);
                  },
                },
                {
                  key: "attemptFocus",
                  value: function (e) {
                    return e.focus(), this.document.activeElement === e;
                  },
                },
                {
                  key: "supportScrollRestoration",
                  value: function () {
                    try {
                      if (!this.supportsScrolling()) return !1;
                      var e =
                        Fl(this.window.history) ||
                        Fl(Object.getPrototypeOf(this.window.history));
                      return !(!e || (!e.writable && !e.set));
                    } catch (t) {
                      return !1;
                    }
                  },
                },
                {
                  key: "supportsScrolling",
                  value: function () {
                    try {
                      return (
                        !!this.window &&
                        !!this.window.scrollTo &&
                        "pageXOffset" in this.window
                      );
                    } catch (e) {
                      return !1;
                    }
                  },
                },
              ]),
              e
            );
          })();
        function Fl(e) {
          return Object.getOwnPropertyDescriptor(e, "scrollRestoration");
        }
        var Ll,
          Wl,
          zl,
          ql,
          Hl = (function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              return p(this, n), t.apply(this, arguments);
            }
            return (
              g(
                n,
                [
                  {
                    key: "getProperty",
                    value: function (e, t) {
                      return e[t];
                    },
                  },
                  {
                    key: "log",
                    value: function (e) {
                      window.console &&
                        window.console.log &&
                        window.console.log(e);
                    },
                  },
                  {
                    key: "logGroup",
                    value: function (e) {
                      window.console &&
                        window.console.group &&
                        window.console.group(e);
                    },
                  },
                  {
                    key: "logGroupEnd",
                    value: function () {
                      window.console &&
                        window.console.groupEnd &&
                        window.console.groupEnd();
                    },
                  },
                  {
                    key: "onAndCancel",
                    value: function (e, t, n) {
                      return (
                        e.addEventListener(t, n, !1),
                        function () {
                          e.removeEventListener(t, n, !1);
                        }
                      );
                    },
                  },
                  {
                    key: "dispatchEvent",
                    value: function (e, t) {
                      e.dispatchEvent(t);
                    },
                  },
                  {
                    key: "remove",
                    value: function (e) {
                      return e.parentNode && e.parentNode.removeChild(e), e;
                    },
                  },
                  {
                    key: "getValue",
                    value: function (e) {
                      return e.value;
                    },
                  },
                  {
                    key: "createElement",
                    value: function (e, t) {
                      return (t = t || this.getDefaultDocument()).createElement(
                        e
                      );
                    },
                  },
                  {
                    key: "createHtmlDocument",
                    value: function () {
                      return document.implementation.createHTMLDocument(
                        "fakeTitle"
                      );
                    },
                  },
                  {
                    key: "getDefaultDocument",
                    value: function () {
                      return document;
                    },
                  },
                  {
                    key: "isElementNode",
                    value: function (e) {
                      return e.nodeType === Node.ELEMENT_NODE;
                    },
                  },
                  {
                    key: "isShadowRoot",
                    value: function (e) {
                      return e instanceof DocumentFragment;
                    },
                  },
                  {
                    key: "getGlobalEventTarget",
                    value: function (e, t) {
                      return "window" === t
                        ? window
                        : "document" === t
                        ? e
                        : "body" === t
                        ? e.body
                        : null;
                    },
                  },
                  {
                    key: "getHistory",
                    value: function () {
                      return window.history;
                    },
                  },
                  {
                    key: "getLocation",
                    value: function () {
                      return window.location;
                    },
                  },
                  {
                    key: "getBaseHref",
                    value: function (e) {
                      var t,
                        n =
                          Ul || (Ul = document.querySelector("base"))
                            ? Ul.getAttribute("href")
                            : null;
                      return null == n
                        ? null
                        : ((t = n),
                          Ll || (Ll = document.createElement("a")),
                          Ll.setAttribute("href", t),
                          "/" === Ll.pathname.charAt(0)
                            ? Ll.pathname
                            : "/" + Ll.pathname);
                    },
                  },
                  {
                    key: "resetBaseElement",
                    value: function () {
                      Ul = null;
                    },
                  },
                  {
                    key: "getUserAgent",
                    value: function () {
                      return window.navigator.userAgent;
                    },
                  },
                  {
                    key: "performanceNow",
                    value: function () {
                      return window.performance && window.performance.now
                        ? window.performance.now()
                        : new Date().getTime();
                    },
                  },
                  {
                    key: "supportsCookies",
                    value: function () {
                      return !0;
                    },
                  },
                  {
                    key: "getCookie",
                    value: function (e) {
                      return bl(document.cookie, e);
                    },
                  },
                ],
                [
                  {
                    key: "makeCurrent",
                    value: function () {
                      var e;
                      (e = new n()), Vu || (Vu = e);
                    },
                  },
                ]
              ),
              n
            );
          })(
            (function (e) {
              u(n, e);
              var t = c(n);
              function n() {
                return p(this, n), t.call(this);
              }
              return (
                g(n, [
                  {
                    key: "supportsDOMEvents",
                    value: function () {
                      return !0;
                    },
                  },
                ]),
                n
              );
            })(
              (function () {
                return function e() {
                  p(this, e);
                };
              })()
            )
          ),
          Ul = null,
          Vl = new Jn("TRANSITION_ID"),
          Bl = [
            {
              provide: Gs,
              useFactory: function (e, t, n) {
                return function () {
                  n.get(Ks).donePromise.then(function () {
                    var n = Bu();
                    Array.prototype.slice
                      .apply(t.querySelectorAll("style[ng-transition]"))
                      .filter(function (t) {
                        return t.getAttribute("ng-transition") === e;
                      })
                      .forEach(function (e) {
                        return n.remove(e);
                      });
                  });
                };
              },
              deps: [Vl, Gu, Io],
              multi: !0,
            },
          ],
          Ql = (function () {
            function e() {
              p(this, e);
            }
            return (
              g(
                e,
                [
                  {
                    key: "addToWindow",
                    value: function (e) {
                      (Be.getAngularTestability = function (t) {
                        var n =
                            !(
                              arguments.length > 1 && void 0 !== arguments[1]
                            ) || arguments[1],
                          r = e.findTestabilityInTree(t, n);
                        if (null == r)
                          throw new Error(
                            "Could not find testability for element."
                          );
                        return r;
                      }),
                        (Be.getAllAngularTestabilities = function () {
                          return e.getAllTestabilities();
                        }),
                        (Be.getAllAngularRootElements = function () {
                          return e.getAllRootElements();
                        }),
                        Be.frameworkStabilizers ||
                          (Be.frameworkStabilizers = []),
                        Be.frameworkStabilizers.push(function (e) {
                          var t = Be.getAllAngularTestabilities(),
                            n = t.length,
                            r = !1,
                            i = function (t) {
                              (r = r || t), 0 == --n && e(r);
                            };
                          t.forEach(function (e) {
                            e.whenStable(i);
                          });
                        });
                    },
                  },
                  {
                    key: "findTestabilityInTree",
                    value: function (e, t, n) {
                      if (null == t) return null;
                      var r = e.getTestability(t);
                      return null != r
                        ? r
                        : n
                        ? Bu().isShadowRoot(t)
                          ? this.findTestabilityInTree(e, t.host, !0)
                          : this.findTestabilityInTree(e, t.parentElement, !0)
                        : null;
                    },
                  },
                ],
                [
                  {
                    key: "init",
                    value: function () {
                      var t;
                      (t = new e()), (Cu = t);
                    },
                  },
                ]
              ),
              e
            );
          })(),
          Gl = new Jn("EventManagerPlugins"),
          Kl =
            (((Wl = (function () {
              function e(t, n) {
                var r = this;
                p(this, e),
                  (this._zone = n),
                  (this._eventNameToPlugin = new Map()),
                  t.forEach(function (e) {
                    return (e.manager = r);
                  }),
                  (this._plugins = t.slice().reverse());
              }
              return (
                g(e, [
                  {
                    key: "addEventListener",
                    value: function (e, t, n) {
                      return this._findPluginFor(t).addEventListener(e, t, n);
                    },
                  },
                  {
                    key: "addGlobalEventListener",
                    value: function (e, t, n) {
                      return this._findPluginFor(t).addGlobalEventListener(
                        e,
                        t,
                        n
                      );
                    },
                  },
                  {
                    key: "getZone",
                    value: function () {
                      return this._zone;
                    },
                  },
                  {
                    key: "_findPluginFor",
                    value: function (e) {
                      var t = this._eventNameToPlugin.get(e);
                      if (t) return t;
                      for (var n = this._plugins, r = 0; r < n.length; r++) {
                        var i = n[r];
                        if (i.supports(e))
                          return this._eventNameToPlugin.set(e, i), i;
                      }
                      throw new Error(
                        "No event manager plugin found for event ".concat(e)
                      );
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Wl)(fr(Gl), fr(vu));
            }),
            (Wl.ɵprov = Ee({ token: Wl, factory: Wl.ɵfac })),
            Wl),
          Zl = (function () {
            function e(t) {
              p(this, e), (this._doc = t);
            }
            return (
              g(e, [
                {
                  key: "addGlobalEventListener",
                  value: function (e, t, n) {
                    var r = Bu().getGlobalEventTarget(this._doc, e);
                    if (!r)
                      throw new Error(
                        "Unsupported event target "
                          .concat(r, " for event ")
                          .concat(t)
                      );
                    return this.addEventListener(r, t, n);
                  },
                },
              ]),
              e
            );
          })(),
          Jl =
            (((ql = (function () {
              function e() {
                p(this, e), (this._stylesSet = new Set());
              }
              return (
                g(e, [
                  {
                    key: "addStyles",
                    value: function (e) {
                      var t = this,
                        n = new Set();
                      e.forEach(function (e) {
                        t._stylesSet.has(e) || (t._stylesSet.add(e), n.add(e));
                      }),
                        this.onStylesAdded(n);
                    },
                  },
                  { key: "onStylesAdded", value: function (e) {} },
                  {
                    key: "getAllStyles",
                    value: function () {
                      return Array.from(this._stylesSet);
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || ql)();
            }),
            (ql.ɵprov = Ee({ token: ql, factory: ql.ɵfac })),
            ql),
          $l =
            (((zl = (function (e) {
              u(n, e);
              var t = c(n);
              function n(e) {
                var r;
                return (
                  p(this, n),
                  ((r = t.call(this))._doc = e),
                  (r._hostNodes = new Map()),
                  r._hostNodes.set(e.head, []),
                  r
                );
              }
              return (
                g(n, [
                  {
                    key: "_addStylesToHost",
                    value: function (e, t, n) {
                      var r = this;
                      e.forEach(function (e) {
                        var i = r._doc.createElement("style");
                        (i.textContent = e), n.push(t.appendChild(i));
                      });
                    },
                  },
                  {
                    key: "addHost",
                    value: function (e) {
                      var t = [];
                      this._addStylesToHost(this._stylesSet, e, t),
                        this._hostNodes.set(e, t);
                    },
                  },
                  {
                    key: "removeHost",
                    value: function (e) {
                      var t = this._hostNodes.get(e);
                      t && t.forEach(Yl), this._hostNodes.delete(e);
                    },
                  },
                  {
                    key: "onStylesAdded",
                    value: function (e) {
                      var t = this;
                      this._hostNodes.forEach(function (n, r) {
                        t._addStylesToHost(e, r, n);
                      });
                    },
                  },
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      this._hostNodes.forEach(function (e) {
                        return e.forEach(Yl);
                      });
                    },
                  },
                ]),
                n
              );
            })(Jl)).ɵfac = function (e) {
              return new (e || zl)(fr(Gu));
            }),
            (zl.ɵprov = Ee({ token: zl, factory: zl.ɵfac })),
            zl);
        function Yl(e) {
          Bu().remove(e);
        }
        var Xl = {
            svg: "http://www.w3.org/2000/svg",
            xhtml: "http://www.w3.org/1999/xhtml",
            xlink: "http://www.w3.org/1999/xlink",
            xml: "http://www.w3.org/XML/1998/namespace",
            xmlns: "http://www.w3.org/2000/xmlns/",
          },
          ec = /%COMP%/g;
        function tc(e, t, n) {
          for (var r = 0; r < t.length; r++) {
            var i = t[r];
            Array.isArray(i)
              ? tc(e, i, n)
              : ((i = i.replace(ec, e)), n.push(i));
          }
          return n;
        }
        function nc(e) {
          return function (t) {
            if ("__ngUnwrap__" === t) return e;
            !1 === e(t) && (t.preventDefault(), (t.returnValue = !1));
          };
        }
        var rc,
          ic,
          oc,
          ac,
          sc =
            (((rc = (function () {
              function e(t, n, r) {
                p(this, e),
                  (this.eventManager = t),
                  (this.sharedStylesHost = n),
                  (this.appId = r),
                  (this.rendererByCompId = new Map()),
                  (this.defaultRenderer = new uc(t));
              }
              return (
                g(e, [
                  {
                    key: "createRenderer",
                    value: function (e, t) {
                      if (!e || !t) return this.defaultRenderer;
                      switch (t.encapsulation) {
                        case ze.Emulated:
                          var n = this.rendererByCompId.get(t.id);
                          return (
                            n ||
                              ((n = new lc(
                                this.eventManager,
                                this.sharedStylesHost,
                                t,
                                this.appId
                              )),
                              this.rendererByCompId.set(t.id, n)),
                            n.applyToHost(e),
                            n
                          );
                        case 1:
                        case ze.ShadowDom:
                          return new cc(
                            this.eventManager,
                            this.sharedStylesHost,
                            e,
                            t
                          );
                        default:
                          if (!this.rendererByCompId.has(t.id)) {
                            var r = tc(t.id, t.styles, []);
                            this.sharedStylesHost.addStyles(r),
                              this.rendererByCompId.set(
                                t.id,
                                this.defaultRenderer
                              );
                          }
                          return this.defaultRenderer;
                      }
                    },
                  },
                  { key: "begin", value: function () {} },
                  { key: "end", value: function () {} },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || rc)(fr(Kl), fr($l), fr(Zs));
            }),
            (rc.ɵprov = Ee({ token: rc, factory: rc.ɵfac })),
            rc),
          uc = (function () {
            function e(t) {
              p(this, e),
                (this.eventManager = t),
                (this.data = Object.create(null));
            }
            return (
              g(e, [
                { key: "destroy", value: function () {} },
                {
                  key: "createElement",
                  value: function (e, t) {
                    return t
                      ? document.createElementNS(Xl[t] || t, e)
                      : document.createElement(e);
                  },
                },
                {
                  key: "createComment",
                  value: function (e) {
                    return document.createComment(e);
                  },
                },
                {
                  key: "createText",
                  value: function (e) {
                    return document.createTextNode(e);
                  },
                },
                {
                  key: "appendChild",
                  value: function (e, t) {
                    e.appendChild(t);
                  },
                },
                {
                  key: "insertBefore",
                  value: function (e, t, n) {
                    e && e.insertBefore(t, n);
                  },
                },
                {
                  key: "removeChild",
                  value: function (e, t) {
                    e && e.removeChild(t);
                  },
                },
                {
                  key: "selectRootElement",
                  value: function (e, t) {
                    var n =
                      "string" == typeof e ? document.querySelector(e) : e;
                    if (!n)
                      throw new Error(
                        'The selector "'.concat(
                          e,
                          '" did not match any elements'
                        )
                      );
                    return t || (n.textContent = ""), n;
                  },
                },
                {
                  key: "parentNode",
                  value: function (e) {
                    return e.parentNode;
                  },
                },
                {
                  key: "nextSibling",
                  value: function (e) {
                    return e.nextSibling;
                  },
                },
                {
                  key: "setAttribute",
                  value: function (e, t, n, r) {
                    if (r) {
                      t = r + ":" + t;
                      var i = Xl[r];
                      i ? e.setAttributeNS(i, t, n) : e.setAttribute(t, n);
                    } else e.setAttribute(t, n);
                  },
                },
                {
                  key: "removeAttribute",
                  value: function (e, t, n) {
                    if (n) {
                      var r = Xl[n];
                      r
                        ? e.removeAttributeNS(r, t)
                        : e.removeAttribute("".concat(n, ":").concat(t));
                    } else e.removeAttribute(t);
                  },
                },
                {
                  key: "addClass",
                  value: function (e, t) {
                    e.classList.add(t);
                  },
                },
                {
                  key: "removeClass",
                  value: function (e, t) {
                    e.classList.remove(t);
                  },
                },
                {
                  key: "setStyle",
                  value: function (e, t, n, r) {
                    r & (Pr.DashCase | Pr.Important)
                      ? e.style.setProperty(
                          t,
                          n,
                          r & Pr.Important ? "important" : ""
                        )
                      : (e.style[t] = n);
                  },
                },
                {
                  key: "removeStyle",
                  value: function (e, t, n) {
                    n & Pr.DashCase
                      ? e.style.removeProperty(t)
                      : (e.style[t] = "");
                  },
                },
                {
                  key: "setProperty",
                  value: function (e, t, n) {
                    e[t] = n;
                  },
                },
                {
                  key: "setValue",
                  value: function (e, t) {
                    e.nodeValue = t;
                  },
                },
                {
                  key: "listen",
                  value: function (e, t, n) {
                    return "string" == typeof e
                      ? this.eventManager.addGlobalEventListener(e, t, nc(n))
                      : this.eventManager.addEventListener(e, t, nc(n));
                  },
                },
              ]),
              e
            );
          })(),
          lc = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o) {
              var a;
              p(this, n), ((a = t.call(this, e)).component = i);
              var s = tc(o + "-" + i.id, i.styles, []);
              return (
                r.addStyles(s),
                (a.contentAttr = "_ngcontent-%COMP%".replace(
                  ec,
                  o + "-" + i.id
                )),
                (a.hostAttr = "_nghost-%COMP%".replace(ec, o + "-" + i.id)),
                a
              );
            }
            return (
              g(n, [
                {
                  key: "applyToHost",
                  value: function (e) {
                    s(v(n.prototype), "setAttribute", this).call(
                      this,
                      e,
                      this.hostAttr,
                      ""
                    );
                  },
                },
                {
                  key: "createElement",
                  value: function (e, t) {
                    var r = s(v(n.prototype), "createElement", this).call(
                      this,
                      e,
                      t
                    );
                    return (
                      s(v(n.prototype), "setAttribute", this).call(
                        this,
                        r,
                        this.contentAttr,
                        ""
                      ),
                      r
                    );
                  },
                },
              ]),
              n
            );
          })(uc),
          cc = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o) {
              var a;
              p(this, n),
                ((a = t.call(this, e)).sharedStylesHost = r),
                (a.hostEl = i),
                (a.shadowRoot = i.attachShadow({ mode: "open" })),
                a.sharedStylesHost.addHost(a.shadowRoot);
              for (var s = tc(o.id, o.styles, []), u = 0; u < s.length; u++) {
                var l = document.createElement("style");
                (l.textContent = s[u]), a.shadowRoot.appendChild(l);
              }
              return a;
            }
            return (
              g(n, [
                {
                  key: "nodeOrShadowRoot",
                  value: function (e) {
                    return e === this.hostEl ? this.shadowRoot : e;
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    this.sharedStylesHost.removeHost(this.shadowRoot);
                  },
                },
                {
                  key: "appendChild",
                  value: function (e, t) {
                    return s(v(n.prototype), "appendChild", this).call(
                      this,
                      this.nodeOrShadowRoot(e),
                      t
                    );
                  },
                },
                {
                  key: "insertBefore",
                  value: function (e, t, r) {
                    return s(v(n.prototype), "insertBefore", this).call(
                      this,
                      this.nodeOrShadowRoot(e),
                      t,
                      r
                    );
                  },
                },
                {
                  key: "removeChild",
                  value: function (e, t) {
                    return s(v(n.prototype), "removeChild", this).call(
                      this,
                      this.nodeOrShadowRoot(e),
                      t
                    );
                  },
                },
                {
                  key: "parentNode",
                  value: function (e) {
                    return this.nodeOrShadowRoot(
                      s(v(n.prototype), "parentNode", this).call(
                        this,
                        this.nodeOrShadowRoot(e)
                      )
                    );
                  },
                },
              ]),
              n
            );
          })(uc),
          hc =
            (((ic = (function (e) {
              u(n, e);
              var t = c(n);
              function n(e) {
                return p(this, n), t.call(this, e);
              }
              return (
                g(n, [
                  {
                    key: "supports",
                    value: function (e) {
                      return !0;
                    },
                  },
                  {
                    key: "addEventListener",
                    value: function (e, t, n) {
                      var r = this;
                      return (
                        e.addEventListener(t, n, !1),
                        function () {
                          return r.removeEventListener(e, t, n);
                        }
                      );
                    },
                  },
                  {
                    key: "removeEventListener",
                    value: function (e, t, n) {
                      return e.removeEventListener(t, n);
                    },
                  },
                ]),
                n
              );
            })(Zl)).ɵfac = function (e) {
              return new (e || ic)(fr(Gu));
            }),
            (ic.ɵprov = Ee({ token: ic, factory: ic.ɵfac })),
            ic),
          fc = ["alt", "control", "meta", "shift"],
          dc = {
            "\b": "Backspace",
            "\t": "Tab",
            "\x7f": "Delete",
            "\x1b": "Escape",
            "Del": "Delete",
            "Esc": "Escape",
            "Left": "ArrowLeft",
            "Right": "ArrowRight",
            "Up": "ArrowUp",
            "Down": "ArrowDown",
            "Menu": "ContextMenu",
            "Scroll": "ScrollLock",
            "Win": "OS",
          },
          vc = {
            "A": "1",
            "B": "2",
            "C": "3",
            "D": "4",
            "E": "5",
            "F": "6",
            "G": "7",
            "H": "8",
            "I": "9",
            "J": "*",
            "K": "+",
            "M": "-",
            "N": ".",
            "O": "/",
            "`": "0",
            "\x90": "NumLock",
          },
          pc = {
            alt: function (e) {
              return e.altKey;
            },
            control: function (e) {
              return e.ctrlKey;
            },
            meta: function (e) {
              return e.metaKey;
            },
            shift: function (e) {
              return e.shiftKey;
            },
          },
          mc =
            (((oc = (function (e) {
              u(n, e);
              var t = c(n);
              function n(e) {
                return p(this, n), t.call(this, e);
              }
              return (
                g(
                  n,
                  [
                    {
                      key: "supports",
                      value: function (e) {
                        return null != n.parseEventName(e);
                      },
                    },
                    {
                      key: "addEventListener",
                      value: function (e, t, r) {
                        var i = n.parseEventName(t),
                          o = n.eventCallback(
                            i.fullKey,
                            r,
                            this.manager.getZone()
                          );
                        return this.manager
                          .getZone()
                          .runOutsideAngular(function () {
                            return Bu().onAndCancel(e, i.domEventName, o);
                          });
                      },
                    },
                  ],
                  [
                    {
                      key: "parseEventName",
                      value: function (e) {
                        var t = e.toLowerCase().split("."),
                          r = t.shift();
                        if (
                          0 === t.length ||
                          ("keydown" !== r && "keyup" !== r)
                        )
                          return null;
                        var i = n._normalizeKey(t.pop()),
                          o = "";
                        if (
                          (fc.forEach(function (e) {
                            var n = t.indexOf(e);
                            n > -1 && (t.splice(n, 1), (o += e + "."));
                          }),
                          (o += i),
                          0 != t.length || 0 === i.length)
                        )
                          return null;
                        var a = {};
                        return (a.domEventName = r), (a.fullKey = o), a;
                      },
                    },
                    {
                      key: "getEventFullKey",
                      value: function (e) {
                        var t = "",
                          n = (function (e) {
                            var t = e.key;
                            if (null == t) {
                              if (null == (t = e.keyIdentifier))
                                return "Unidentified";
                              t.startsWith("U+") &&
                                ((t = String.fromCharCode(
                                  parseInt(t.substring(2), 16)
                                )),
                                3 === e.location &&
                                  vc.hasOwnProperty(t) &&
                                  (t = vc[t]));
                            }
                            return dc[t] || t;
                          })(e);
                        return (
                          " " === (n = n.toLowerCase())
                            ? (n = "space")
                            : "." === n && (n = "dot"),
                          fc.forEach(function (r) {
                            r != n && (0, pc[r])(e) && (t += r + ".");
                          }),
                          (t += n)
                        );
                      },
                    },
                    {
                      key: "eventCallback",
                      value: function (e, t, r) {
                        return function (i) {
                          n.getEventFullKey(i) === e &&
                            r.runGuarded(function () {
                              return t(i);
                            });
                        };
                      },
                    },
                    {
                      key: "_normalizeKey",
                      value: function (e) {
                        switch (e) {
                          case "esc":
                            return "escape";
                          default:
                            return e;
                        }
                      },
                    },
                  ]
                ),
                n
              );
            })(Zl)).ɵfac = function (e) {
              return new (e || oc)(fr(Gu));
            }),
            (oc.ɵprov = Ee({ token: oc, factory: oc.ɵfac })),
            oc),
          gc = Au(qu, "browser", [
            { provide: Xs, useValue: "browser" },
            {
              provide: Ys,
              useValue: function () {
                Hl.makeCurrent(), Ql.init();
              },
              multi: !0,
            },
            {
              provide: Gu,
              useFactory: function () {
                return (
                  (function (e) {
                    kt = e;
                  })(document),
                  document
                );
              },
              deps: [],
            },
          ]),
          yc = [
            [],
            { provide: mo, useValue: "root" },
            {
              provide: xr,
              useFactory: function () {
                return new xr();
              },
              deps: [],
            },
            { provide: Gl, useClass: hc, multi: !0, deps: [Gu, vu, Xs] },
            { provide: Gl, useClass: mc, multi: !0, deps: [Gu] },
            [],
            { provide: sc, useClass: sc, deps: [Kl, $l, Zs] },
            { provide: xa, useExisting: sc },
            { provide: Jl, useExisting: $l },
            { provide: $l, useClass: $l, deps: [Gu] },
            { provide: _u, useClass: _u, deps: [vu] },
            { provide: Kl, useClass: Kl, deps: [Gl, vu] },
            [],
          ],
          kc =
            (((ac = (function () {
              function e(t) {
                if ((p(this, e), t))
                  throw new Error(
                    "BrowserModule has already been loaded. If you need access to common directives such as NgIf and NgFor from a lazy loaded module, import CommonModule instead."
                  );
              }
              return (
                g(e, null, [
                  {
                    key: "withServerTransition",
                    value: function (t) {
                      return {
                        ngModule: e,
                        providers: [
                          { provide: Zs, useValue: t.appId },
                          { provide: Vl, useExisting: Zs },
                          Bl,
                        ],
                      };
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || ac)(fr(ac, 12));
            }),
            (ac.ɵmod = st({ type: ac })),
            (ac.ɵinj = Te({ providers: yc, imports: [Pl, Uu] })),
            ac);
        "undefined" != typeof window && window;
        var bc = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this, e, r)).scheduler = e),
                (i.work = r),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "schedule",
                  value: function (e) {
                    var t =
                      arguments.length > 1 && void 0 !== arguments[1]
                        ? arguments[1]
                        : 0;
                    return t > 0
                      ? s(v(n.prototype), "schedule", this).call(this, e, t)
                      : ((this.delay = t),
                        (this.state = e),
                        this.scheduler.flush(this),
                        this);
                  },
                },
                {
                  key: "execute",
                  value: function (e, t) {
                    return t > 0 || this.closed
                      ? s(v(n.prototype), "execute", this).call(this, e, t)
                      : this._execute(e, t);
                  },
                },
                {
                  key: "requestAsyncId",
                  value: function (e, t) {
                    var r =
                      arguments.length > 2 && void 0 !== arguments[2]
                        ? arguments[2]
                        : 0;
                    return (null !== r && r > 0) ||
                      (null === r && this.delay > 0)
                      ? s(v(n.prototype), "requestAsyncId", this).call(
                          this,
                          e,
                          t,
                          r
                        )
                      : e.flush(this);
                  },
                },
              ]),
              n
            );
          })(
            (function (e) {
              u(n, e);
              var t = c(n);
              function n(e, r) {
                var i;
                return (
                  p(this, n),
                  ((i = t.call(this, e, r)).scheduler = e),
                  (i.work = r),
                  (i.pending = !1),
                  i
                );
              }
              return (
                g(n, [
                  {
                    key: "schedule",
                    value: function (e) {
                      var t =
                        arguments.length > 1 && void 0 !== arguments[1]
                          ? arguments[1]
                          : 0;
                      if (this.closed) return this;
                      this.state = e;
                      var n = this.id,
                        r = this.scheduler;
                      return (
                        null != n && (this.id = this.recycleAsyncId(r, n, t)),
                        (this.pending = !0),
                        (this.delay = t),
                        (this.id =
                          this.id || this.requestAsyncId(r, this.id, t)),
                        this
                      );
                    },
                  },
                  {
                    key: "requestAsyncId",
                    value: function (e, t) {
                      var n =
                        arguments.length > 2 && void 0 !== arguments[2]
                          ? arguments[2]
                          : 0;
                      return setInterval(e.flush.bind(e, this), n);
                    },
                  },
                  {
                    key: "recycleAsyncId",
                    value: function (e, t) {
                      var n =
                        arguments.length > 2 && void 0 !== arguments[2]
                          ? arguments[2]
                          : 0;
                      if (null !== n && this.delay === n && !1 === this.pending)
                        return t;
                      clearInterval(t);
                    },
                  },
                  {
                    key: "execute",
                    value: function (e, t) {
                      if (this.closed)
                        return new Error("executing a cancelled action");
                      this.pending = !1;
                      var n = this._execute(e, t);
                      if (n) return n;
                      !1 === this.pending &&
                        null != this.id &&
                        (this.id = this.recycleAsyncId(
                          this.scheduler,
                          this.id,
                          null
                        ));
                    },
                  },
                  {
                    key: "_execute",
                    value: function (e, t) {
                      var n,
                        r = !1;
                      try {
                        this.work(e);
                      } catch (i) {
                        (r = !0), (n = (!!i && i) || new Error(i));
                      }
                      if (r) return this.unsubscribe(), n;
                    },
                  },
                  {
                    key: "_unsubscribe",
                    value: function () {
                      var e = this.id,
                        t = this.scheduler,
                        n = t.actions,
                        r = n.indexOf(this);
                      (this.work = null),
                        (this.state = null),
                        (this.pending = !1),
                        (this.scheduler = null),
                        -1 !== r && n.splice(r, 1),
                        null != e &&
                          (this.id = this.recycleAsyncId(t, e, null)),
                        (this.delay = null);
                    },
                  },
                ]),
                n
              );
            })(
              (function (e) {
                u(n, e);
                var t = c(n);
                function n(e, r) {
                  return p(this, n), t.call(this);
                }
                return (
                  g(n, [
                    {
                      key: "schedule",
                      value: function (e) {
                        return this;
                      },
                    },
                  ]),
                  n
                );
              })(E)
            )
          ),
          wc = (function () {
            var e = (function () {
              function e(t) {
                var n =
                  arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : e.now;
                p(this, e), (this.SchedulerAction = t), (this.now = n);
              }
              return (
                g(e, [
                  {
                    key: "schedule",
                    value: function (e) {
                      var t =
                          arguments.length > 1 && void 0 !== arguments[1]
                            ? arguments[1]
                            : 0,
                        n = arguments.length > 2 ? arguments[2] : void 0;
                      return new this.SchedulerAction(this, e).schedule(n, t);
                    },
                  },
                ]),
                e
              );
            })();
            return (
              (e.now = function () {
                return Date.now();
              }),
              e
            );
          })(),
          _c = new ((function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              return p(this, n), t.apply(this, arguments);
            }
            return n;
          })(
            (function (e) {
              u(n, e);
              var t = c(n);
              function n(e) {
                var r,
                  i =
                    arguments.length > 1 && void 0 !== arguments[1]
                      ? arguments[1]
                      : wc.now;
                return (
                  p(this, n),
                  ((r = t.call(this, e, function () {
                    return n.delegate && n.delegate !== f(r)
                      ? n.delegate.now()
                      : i();
                  })).actions = []),
                  (r.active = !1),
                  (r.scheduled = void 0),
                  r
                );
              }
              return (
                g(n, [
                  {
                    key: "schedule",
                    value: function (e) {
                      var t =
                          arguments.length > 1 && void 0 !== arguments[1]
                            ? arguments[1]
                            : 0,
                        r = arguments.length > 2 ? arguments[2] : void 0;
                      return n.delegate && n.delegate !== this
                        ? n.delegate.schedule(e, t, r)
                        : s(v(n.prototype), "schedule", this).call(
                            this,
                            e,
                            t,
                            r
                          );
                    },
                  },
                  {
                    key: "flush",
                    value: function (e) {
                      var t = this.actions;
                      if (this.active) t.push(e);
                      else {
                        var n;
                        this.active = !0;
                        do {
                          if ((n = e.execute(e.state, e.delay))) break;
                        } while ((e = t.shift()));
                        if (((this.active = !1), n)) {
                          for (; (e = t.shift()); ) e.unsubscribe();
                          throw n;
                        }
                      }
                    },
                  },
                ]),
                n
              );
            })(wc)
          ))(bc),
          Sc = new D(function (e) {
            return e.complete();
          });
        function Cc(e) {
          return e
            ? (function (e) {
                return new D(function (t) {
                  return e.schedule(function () {
                    return t.complete();
                  });
                });
              })(e)
            : Sc;
        }
        function Ec() {
          for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
            t[n] = arguments[n];
          var r = t[t.length - 1];
          return q(r) ? (t.pop(), J(t, r)) : oe(t);
        }
        var Tc,
          xc =
            (((Tc = (function () {
              function e(t, n, r) {
                p(this, e),
                  (this.kind = t),
                  (this.value = n),
                  (this.error = r),
                  (this.hasValue = "N" === t);
              }
              return (
                g(
                  e,
                  [
                    {
                      key: "observe",
                      value: function (e) {
                        switch (this.kind) {
                          case "N":
                            return e.next && e.next(this.value);
                          case "E":
                            return e.error && e.error(this.error);
                          case "C":
                            return e.complete && e.complete();
                        }
                      },
                    },
                    {
                      key: "do",
                      value: function (e, t, n) {
                        switch (this.kind) {
                          case "N":
                            return e && e(this.value);
                          case "E":
                            return t && t(this.error);
                          case "C":
                            return n && n();
                        }
                      },
                    },
                    {
                      key: "accept",
                      value: function (e, t, n) {
                        return e && "function" == typeof e.next
                          ? this.observe(e)
                          : this.do(e, t, n);
                      },
                    },
                    {
                      key: "toObservable",
                      value: function () {
                        switch (this.kind) {
                          case "N":
                            return Ec(this.value);
                          case "E":
                            return (
                              (e = this.error),
                              new D(function (t) {
                                return t.error(e);
                              })
                            );
                          case "C":
                            return Cc();
                        }
                        var e;
                        throw new Error("unexpected notification kind value");
                      },
                    },
                  ],
                  [
                    {
                      key: "createNext",
                      value: function (t) {
                        return void 0 !== t
                          ? new e("N", t)
                          : e.undefinedValueNotification;
                      },
                    },
                    {
                      key: "createError",
                      value: function (t) {
                        return new e("E", void 0, t);
                      },
                    },
                    {
                      key: "createComplete",
                      value: function () {
                        return e.completeNotification;
                      },
                    },
                  ]
                ),
                e
              );
            })()).completeNotification = new Tc("C")),
            (Tc.undefinedValueNotification = new Tc("N", void 0)),
            Tc),
          Ic = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i,
                o =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : 0;
              return (
                p(this, n),
                ((i = t.call(this, e)).scheduler = r),
                (i.delay = o),
                i
              );
            }
            return (
              g(
                n,
                [
                  {
                    key: "scheduleMessage",
                    value: function (e) {
                      this.destination.add(
                        this.scheduler.schedule(
                          n.dispatch,
                          this.delay,
                          new Ac(e, this.destination)
                        )
                      );
                    },
                  },
                  {
                    key: "_next",
                    value: function (e) {
                      this.scheduleMessage(xc.createNext(e));
                    },
                  },
                  {
                    key: "_error",
                    value: function (e) {
                      this.scheduleMessage(xc.createError(e)),
                        this.unsubscribe();
                    },
                  },
                  {
                    key: "_complete",
                    value: function () {
                      this.scheduleMessage(xc.createComplete()),
                        this.unsubscribe();
                    },
                  },
                ],
                [
                  {
                    key: "dispatch",
                    value: function (e) {
                      var t = e.notification,
                        n = e.destination;
                      t.observe(n), this.unsubscribe();
                    },
                  },
                ]
              ),
              n
            );
          })(I),
          Ac = function e(t, n) {
            p(this, e), (this.notification = t), (this.destination = n);
          },
          Oc = (function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              var e,
                r =
                  arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : Number.POSITIVE_INFINITY,
                i =
                  arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : Number.POSITIVE_INFINITY,
                o = arguments.length > 2 ? arguments[2] : void 0;
              return (
                p(this, n),
                ((e = t.call(this)).scheduler = o),
                (e._events = []),
                (e._infiniteTimeWindow = !1),
                (e._bufferSize = r < 1 ? 1 : r),
                (e._windowTime = i < 1 ? 1 : i),
                i === Number.POSITIVE_INFINITY
                  ? ((e._infiniteTimeWindow = !0),
                    (e.next = e.nextInfiniteTimeWindow))
                  : (e.next = e.nextTimeWindow),
                e
              );
            }
            return (
              g(n, [
                {
                  key: "nextInfiniteTimeWindow",
                  value: function (e) {
                    if (!this.isStopped) {
                      var t = this._events;
                      t.push(e), t.length > this._bufferSize && t.shift();
                    }
                    s(v(n.prototype), "next", this).call(this, e);
                  },
                },
                {
                  key: "nextTimeWindow",
                  value: function (e) {
                    this.isStopped ||
                      (this._events.push(new Rc(this._getNow(), e)),
                      this._trimBufferThenGetEvents()),
                      s(v(n.prototype), "next", this).call(this, e);
                  },
                },
                {
                  key: "_subscribe",
                  value: function (e) {
                    var t,
                      n = this._infiniteTimeWindow,
                      r = n ? this._events : this._trimBufferThenGetEvents(),
                      i = this.scheduler,
                      o = r.length;
                    if (this.closed) throw new j();
                    if (
                      (this.isStopped || this.hasError
                        ? (t = E.EMPTY)
                        : (this.observers.push(e), (t = new F(this, e))),
                      i && e.add((e = new Ic(e, i))),
                      n)
                    )
                      for (var a = 0; a < o && !e.closed; a++) e.next(r[a]);
                    else
                      for (var s = 0; s < o && !e.closed; s++)
                        e.next(r[s].value);
                    return (
                      this.hasError
                        ? e.error(this.thrownError)
                        : this.isStopped && e.complete(),
                      t
                    );
                  },
                },
                {
                  key: "_getNow",
                  value: function () {
                    return (this.scheduler || _c).now();
                  },
                },
                {
                  key: "_trimBufferThenGetEvents",
                  value: function () {
                    for (
                      var e = this._getNow(),
                        t = this._bufferSize,
                        n = this._windowTime,
                        r = this._events,
                        i = r.length,
                        o = 0;
                      o < i && !(e - r[o].time < n);

                    )
                      o++;
                    return (
                      i > t && (o = Math.max(o, i - t)),
                      o > 0 && r.splice(0, o),
                      r
                    );
                  },
                },
              ]),
              n
            );
          })(W),
          Rc = function e(t, n) {
            p(this, e), (this.time = t), (this.value = n);
          };
        function Pc(e, t) {
          return "function" == typeof t
            ? function (n) {
                return n.pipe(
                  Pc(function (n, r) {
                    return $(e(n, r)).pipe(
                      H(function (e, i) {
                        return t(n, e, r, i);
                      })
                    );
                  })
                );
              }
            : function (t) {
                return t.lift(new Nc(e));
              };
        }
        var Dc,
          Nc = (function () {
            function e(t) {
              p(this, e), (this.project = t);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(new Mc(e, this.project));
                  },
                },
              ]),
              e
            );
          })(),
          Mc = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this, e)).project = r),
                (i.index = 0),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    var t,
                      n = this.index++;
                    try {
                      t = this.project(e, n);
                    } catch (r) {
                      return void this.destination.error(r);
                    }
                    this._innerSub(t);
                  },
                },
                {
                  key: "_innerSub",
                  value: function (e) {
                    var t = this.innerSubscription;
                    t && t.unsubscribe();
                    var n = new Y(this),
                      r = this.destination;
                    r.add(n),
                      (this.innerSubscription = ee(e, n)),
                      this.innerSubscription !== n &&
                        r.add(this.innerSubscription);
                  },
                },
                {
                  key: "_complete",
                  value: function () {
                    var e = this.innerSubscription;
                    (e && !e.closed) ||
                      s(v(n.prototype), "_complete", this).call(this),
                      this.unsubscribe();
                  },
                },
                {
                  key: "_unsubscribe",
                  value: function () {
                    this.innerSubscription = void 0;
                  },
                },
                {
                  key: "notifyComplete",
                  value: function () {
                    (this.innerSubscription = void 0),
                      this.isStopped &&
                        s(v(n.prototype), "_complete", this).call(this);
                  },
                },
                {
                  key: "notifyNext",
                  value: function (e) {
                    this.destination.next(e);
                  },
                },
              ]),
              n
            );
          })(X),
          jc = {
            schedule: function (e, t) {
              var n = setTimeout(e, t);
              return function () {
                return clearTimeout(n);
              };
            },
            scheduleBeforeRender: function (e) {
              if ("undefined" == typeof window) return jc.schedule(e, 0);
              if (void 0 === window.requestAnimationFrame)
                return jc.schedule(e, 16);
              var t = window.requestAnimationFrame(e);
              return function () {
                return window.cancelAnimationFrame(t);
              };
            },
          };
        function Fc(e, t, n) {
          var r = n;
          return (
            (function (e) {
              return !!e && e.nodeType === Node.ELEMENT_NODE;
            })(e) &&
              t.some(function (t, n) {
                return !(
                  "*" === t ||
                  !(function (e, t) {
                    if (!Dc) {
                      var n = Element.prototype;
                      Dc =
                        n.matches ||
                        n.matchesSelector ||
                        n.mozMatchesSelector ||
                        n.msMatchesSelector ||
                        n.oMatchesSelector ||
                        n.webkitMatchesSelector;
                    }
                    return e.nodeType === Node.ELEMENT_NODE && Dc.call(e, t);
                  })(e, t) ||
                  ((r = n), 0)
                );
              }),
            r
          );
        }
        var Lc = (function () {
            function e(t, n) {
              p(this, e),
                (this.componentFactory = n.get(wa).resolveComponentFactory(t));
            }
            return (
              g(e, [
                {
                  key: "create",
                  value: function (e) {
                    return new Wc(this.componentFactory, e);
                  },
                },
              ]),
              e
            );
          })(),
          Wc = (function () {
            function e(t, r) {
              p(this, e),
                (this.componentFactory = t),
                (this.injector = r),
                (this.eventEmitters = new Oc(1)),
                (this.events = this.eventEmitters.pipe(
                  Pc(function (e) {
                    return ae.apply(void 0, n(e));
                  })
                )),
                (this.componentRef = null),
                (this.viewChangeDetectorRef = null),
                (this.inputChanges = null),
                (this.hasInputChanges = !1),
                (this.implementsOnChanges = !1),
                (this.scheduledChangeDetectionFn = null),
                (this.scheduledDestroyFn = null),
                (this.initialInputValues = new Map()),
                (this.unchangedInputs = new Set(
                  this.componentFactory.inputs.map(function (e) {
                    return e.propName;
                  })
                )),
                (this.ngZone = this.injector.get(vu)),
                (this.elementZone =
                  "undefined" == typeof Zone
                    ? null
                    : this.ngZone.run(function () {
                        return Zone.current;
                      }));
            }
            return (
              g(e, [
                {
                  key: "connect",
                  value: function (e) {
                    var t = this;
                    this.runInZone(function () {
                      if (null !== t.scheduledDestroyFn)
                        return (
                          t.scheduledDestroyFn(),
                          void (t.scheduledDestroyFn = null)
                        );
                      null === t.componentRef && t.initializeComponent(e);
                    });
                  },
                },
                {
                  key: "disconnect",
                  value: function () {
                    var e = this;
                    this.runInZone(function () {
                      null !== e.componentRef &&
                        null === e.scheduledDestroyFn &&
                        (e.scheduledDestroyFn = jc.schedule(function () {
                          null !== e.componentRef &&
                            (e.componentRef.destroy(),
                            (e.componentRef = null),
                            (e.viewChangeDetectorRef = null));
                        }, 10));
                    });
                  },
                },
                {
                  key: "getInputValue",
                  value: function (e) {
                    var t = this;
                    return this.runInZone(function () {
                      return null === t.componentRef
                        ? t.initialInputValues.get(e)
                        : t.componentRef.instance[e];
                    });
                  },
                },
                {
                  key: "setInputValue",
                  value: function (e, t) {
                    var n = this;
                    this.runInZone(function () {
                      var r, i;
                      null !== n.componentRef
                        ? (((r = t) !== (i = n.getInputValue(e)) &&
                            (r == r || i == i)) ||
                            (void 0 === t && n.unchangedInputs.has(e))) &&
                          (n.recordInputChange(e, t),
                          n.unchangedInputs.delete(e),
                          (n.hasInputChanges = !0),
                          (n.componentRef.instance[e] = t),
                          n.scheduleDetectChanges())
                        : n.initialInputValues.set(e, t);
                    });
                  },
                },
                {
                  key: "initializeComponent",
                  value: function (e) {
                    var t = Io.create({ providers: [], parent: this.injector }),
                      n = (function (e, t) {
                        var n = e.childNodes,
                          r = t.map(function () {
                            return [];
                          }),
                          i = -1;
                        t.some(function (e, t) {
                          return "*" === e && ((i = t), !0);
                        });
                        for (var o = 0, a = n.length; o < a; ++o) {
                          var s = n[o],
                            u = Fc(s, t, i);
                          -1 !== u && r[u].push(s);
                        }
                        return r;
                      })(e, this.componentFactory.ngContentSelectors);
                    (this.componentRef = this.componentFactory.create(t, n, e)),
                      (this.viewChangeDetectorRef =
                        this.componentRef.injector.get($a)),
                      (this.implementsOnChanges =
                        "function" ==
                        typeof this.componentRef.instance.ngOnChanges),
                      this.initializeInputs(),
                      this.initializeOutputs(this.componentRef),
                      this.detectChanges(),
                      this.injector
                        .get(Nu)
                        .attachView(this.componentRef.hostView);
                  },
                },
                {
                  key: "initializeInputs",
                  value: function () {
                    var e = this;
                    this.componentFactory.inputs.forEach(function (t) {
                      var n = t.propName;
                      e.initialInputValues.has(n) &&
                        e.setInputValue(n, e.initialInputValues.get(n));
                    }),
                      this.initialInputValues.clear();
                  },
                },
                {
                  key: "initializeOutputs",
                  value: function (e) {
                    var t = this.componentFactory.outputs.map(function (t) {
                      var n = t.propName,
                        r = t.templateName;
                      return e.instance[n].pipe(
                        H(function (e) {
                          return { name: r, value: e };
                        })
                      );
                    });
                    this.eventEmitters.next(t);
                  },
                },
                {
                  key: "callNgOnChanges",
                  value: function (e) {
                    if (
                      this.implementsOnChanges &&
                      null !== this.inputChanges
                    ) {
                      var t = this.inputChanges;
                      (this.inputChanges = null), e.instance.ngOnChanges(t);
                    }
                  },
                },
                {
                  key: "markViewForCheck",
                  value: function (e) {
                    this.hasInputChanges &&
                      ((this.hasInputChanges = !1), e.markForCheck());
                  },
                },
                {
                  key: "scheduleDetectChanges",
                  value: function () {
                    var e = this;
                    this.scheduledChangeDetectionFn ||
                      (this.scheduledChangeDetectionFn =
                        jc.scheduleBeforeRender(function () {
                          (e.scheduledChangeDetectionFn = null),
                            e.detectChanges();
                        }));
                  },
                },
                {
                  key: "recordInputChange",
                  value: function (e, t) {
                    if (this.implementsOnChanges) {
                      null === this.inputChanges && (this.inputChanges = {});
                      var n = this.inputChanges[e];
                      if (n) n.currentValue = t;
                      else {
                        var r = this.unchangedInputs.has(e),
                          i = r ? void 0 : this.getInputValue(e);
                        this.inputChanges[e] = new bt(i, t, r);
                      }
                    }
                  },
                },
                {
                  key: "detectChanges",
                  value: function () {
                    null !== this.componentRef &&
                      (this.callNgOnChanges(this.componentRef),
                      this.markViewForCheck(this.viewChangeDetectorRef),
                      this.componentRef.changeDetectorRef.detectChanges());
                  },
                },
                {
                  key: "runInZone",
                  value: function (e) {
                    return this.elementZone && Zone.current !== this.elementZone
                      ? this.ngZone.run(e)
                      : e();
                  },
                },
              ]),
              e
            );
          })(),
          zc = (function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              var e;
              return (
                p(this, n),
                ((e = t.apply(this, arguments)).ngElementEventsSubscription =
                  null),
                e
              );
            }
            return n;
          })(o(HTMLElement));
        new Ra("11.2.14");
        var qc = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e) {
              var r;
              return p(this, n), ((r = t.call(this))._value = e), r;
            }
            return (
              g(n, [
                {
                  key: "value",
                  get: function () {
                    return this.getValue();
                  },
                },
                {
                  key: "_subscribe",
                  value: function (e) {
                    var t = s(v(n.prototype), "_subscribe", this).call(this, e);
                    return t && !t.closed && e.next(this._value), t;
                  },
                },
                {
                  key: "getValue",
                  value: function () {
                    if (this.hasError) throw this.thrownError;
                    if (this.closed) throw new j();
                    return this._value;
                  },
                },
                {
                  key: "next",
                  value: function (e) {
                    s(v(n.prototype), "next", this).call(
                      this,
                      (this._value = e)
                    );
                  },
                },
              ]),
              n
            );
          })(W),
          Hc = (function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              return p(this, n), t.apply(this, arguments);
            }
            return (
              g(n, [
                {
                  key: "notifyNext",
                  value: function (e, t, n, r, i) {
                    this.destination.next(t);
                  },
                },
                {
                  key: "notifyError",
                  value: function (e, t) {
                    this.destination.error(e);
                  },
                },
                {
                  key: "notifyComplete",
                  value: function (e) {
                    this.destination.complete();
                  },
                },
              ]),
              n
            );
          })(I),
          Uc = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i) {
              var o;
              return (
                p(this, n),
                ((o = t.call(this)).parent = e),
                (o.outerValue = r),
                (o.outerIndex = i),
                (o.index = 0),
                o
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    this.parent.notifyNext(
                      this.outerValue,
                      e,
                      this.outerIndex,
                      this.index++,
                      this
                    );
                  },
                },
                {
                  key: "_error",
                  value: function (e) {
                    this.parent.notifyError(e, this), this.unsubscribe();
                  },
                },
                {
                  key: "_complete",
                  value: function () {
                    this.parent.notifyComplete(this), this.unsubscribe();
                  },
                },
              ]),
              n
            );
          })(I);
        function Vc(e, t, n, r) {
          var i =
            arguments.length > 4 && void 0 !== arguments[4]
              ? arguments[4]
              : new Uc(e, n, r);
          if (!i.closed) return t instanceof D ? t.subscribe(i) : Z(t)(i);
        }
        var Bc = {},
          Qc = (function () {
            function e(t) {
              p(this, e), (this.resultSelector = t);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(new Gc(e, this.resultSelector));
                  },
                },
              ]),
              e
            );
          })(),
          Gc = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this, e)).resultSelector = r),
                (i.active = 0),
                (i.values = []),
                (i.observables = []),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    this.values.push(Bc), this.observables.push(e);
                  },
                },
                {
                  key: "_complete",
                  value: function () {
                    var e = this.observables,
                      t = e.length;
                    if (0 === t) this.destination.complete();
                    else {
                      (this.active = t), (this.toRespond = t);
                      for (var n = 0; n < t; n++)
                        this.add(Vc(this, e[n], void 0, n));
                    }
                  },
                },
                {
                  key: "notifyComplete",
                  value: function (e) {
                    0 == (this.active -= 1) && this.destination.complete();
                  },
                },
                {
                  key: "notifyNext",
                  value: function (e, t, n) {
                    var r = this.values,
                      i = this.toRespond
                        ? r[n] === Bc
                          ? --this.toRespond
                          : this.toRespond
                        : 0;
                    (r[n] = t),
                      0 === i &&
                        (this.resultSelector
                          ? this._tryResultSelector(r)
                          : this.destination.next(r.slice()));
                  },
                },
                {
                  key: "_tryResultSelector",
                  value: function (e) {
                    var t;
                    try {
                      t = this.resultSelector.apply(this, e);
                    } catch (n) {
                      return void this.destination.error(n);
                    }
                    this.destination.next(t);
                  },
                },
              ]),
              n
            );
          })(Hc),
          Kc = (function () {
            function e() {
              return (
                Error.call(this),
                (this.message = "no elements in sequence"),
                (this.name = "EmptyError"),
                this
              );
            }
            return (e.prototype = Object.create(Error.prototype)), e;
          })();
        function Zc() {
          return ie(1)(Ec.apply(void 0, arguments));
        }
        function Jc(e) {
          return new D(function (t) {
            var n;
            try {
              n = e();
            } catch (r) {
              return void t.error(r);
            }
            return (n ? $(n) : Cc()).subscribe(t);
          });
        }
        var $c = (function () {
          function e() {
            return (
              Error.call(this),
              (this.message = "argument out of range"),
              (this.name = "ArgumentOutOfRangeError"),
              this
            );
          }
          return (e.prototype = Object.create(Error.prototype)), e;
        })();
        function Yc(e) {
          return function (t) {
            return 0 === e ? Cc() : t.lift(new Xc(e));
          };
        }
        var Xc = (function () {
            function e(t) {
              if ((p(this, e), (this.total = t), this.total < 0))
                throw new $c();
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(new eh(e, this.total));
                  },
                },
              ]),
              e
            );
          })(),
          eh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n), ((i = t.call(this, e)).total = r), (i.count = 0), i
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    var t = this.total,
                      n = ++this.count;
                    n <= t &&
                      (this.destination.next(e),
                      n === t &&
                        (this.destination.complete(), this.unsubscribe()));
                  },
                },
              ]),
              n
            );
          })(I);
        function th(e, t) {
          var n = !1;
          return (
            arguments.length >= 2 && (n = !0),
            function (r) {
              return r.lift(new nh(e, t, n));
            }
          );
        }
        var nh = (function () {
            function e(t, n) {
              var r =
                arguments.length > 2 && void 0 !== arguments[2] && arguments[2];
              p(this, e),
                (this.accumulator = t),
                (this.seed = n),
                (this.hasSeed = r);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(
                      new rh(e, this.accumulator, this.seed, this.hasSeed)
                    );
                  },
                },
              ]),
              e
            );
          })(),
          rh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o) {
              var a;
              return (
                p(this, n),
                ((a = t.call(this, e)).accumulator = r),
                (a._seed = i),
                (a.hasSeed = o),
                (a.index = 0),
                a
              );
            }
            return (
              g(n, [
                {
                  key: "seed",
                  get: function () {
                    return this._seed;
                  },
                  set: function (e) {
                    (this.hasSeed = !0), (this._seed = e);
                  },
                },
                {
                  key: "_next",
                  value: function (e) {
                    if (this.hasSeed) return this._tryNext(e);
                    (this.seed = e), this.destination.next(e);
                  },
                },
                {
                  key: "_tryNext",
                  value: function (e) {
                    var t,
                      n = this.index++;
                    try {
                      t = this.accumulator(this.seed, e, n);
                    } catch (r) {
                      this.destination.error(r);
                    }
                    (this.seed = t), this.destination.next(t);
                  },
                },
              ]),
              n
            );
          })(I);
        function ih(e, t) {
          return function (n) {
            return n.lift(new oh(e, t));
          };
        }
        var oh = (function () {
            function e(t, n) {
              p(this, e), (this.predicate = t), (this.thisArg = n);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(new ah(e, this.predicate, this.thisArg));
                  },
                },
              ]),
              e
            );
          })(),
          ah = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i) {
              var o;
              return (
                p(this, n),
                ((o = t.call(this, e)).predicate = r),
                (o.thisArg = i),
                (o.count = 0),
                o
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    var t;
                    try {
                      t = this.predicate.call(this.thisArg, e, this.count++);
                    } catch (n) {
                      return void this.destination.error(n);
                    }
                    t && this.destination.next(e);
                  },
                },
              ]),
              n
            );
          })(I);
        function sh(e) {
          return function (t) {
            var n = new uh(e),
              r = t.lift(n);
            return (n.caught = r);
          };
        }
        var uh = (function () {
            function e(t) {
              p(this, e), (this.selector = t);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(new lh(e, this.selector, this.caught));
                  },
                },
              ]),
              e
            );
          })(),
          lh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i) {
              var o;
              return (
                p(this, n),
                ((o = t.call(this, e)).selector = r),
                (o.caught = i),
                o
              );
            }
            return (
              g(n, [
                {
                  key: "error",
                  value: function (e) {
                    if (!this.isStopped) {
                      var t;
                      try {
                        t = this.selector(e, this.caught);
                      } catch (o) {
                        return void s(v(n.prototype), "error", this).call(
                          this,
                          o
                        );
                      }
                      this._unsubscribeAndRecycle();
                      var r = new Y(this);
                      this.add(r);
                      var i = ee(t, r);
                      i !== r && this.add(i);
                    }
                  },
                },
              ]),
              n
            );
          })(X);
        function ch(e, t) {
          return te(e, t, 1);
        }
        function hh(e) {
          return function (t) {
            return 0 === e ? Cc() : t.lift(new fh(e));
          };
        }
        var fh = (function () {
            function e(t) {
              if ((p(this, e), (this.total = t), this.total < 0))
                throw new $c();
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(new dh(e, this.total));
                  },
                },
              ]),
              e
            );
          })(),
          dh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this, e)).total = r),
                (i.ring = new Array()),
                (i.count = 0),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    var t = this.ring,
                      n = this.total,
                      r = this.count++;
                    t.length < n ? t.push(e) : (t[r % n] = e);
                  },
                },
                {
                  key: "_complete",
                  value: function () {
                    var e = this.destination,
                      t = this.count;
                    if (t > 0)
                      for (
                        var n =
                            this.count >= this.total ? this.total : this.count,
                          r = this.ring,
                          i = 0;
                        i < n;
                        i++
                      ) {
                        var o = t++ % n;
                        e.next(r[o]);
                      }
                    e.complete();
                  },
                },
              ]),
              n
            );
          })(I);
        function vh() {
          var e =
            arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : gh;
          return function (t) {
            return t.lift(new ph(e));
          };
        }
        var ph = (function () {
            function e(t) {
              p(this, e), (this.errorFactory = t);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(new mh(e, this.errorFactory));
                  },
                },
              ]),
              e
            );
          })(),
          mh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this, e)).errorFactory = r),
                (i.hasValue = !1),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    (this.hasValue = !0), this.destination.next(e);
                  },
                },
                {
                  key: "_complete",
                  value: function () {
                    if (this.hasValue) return this.destination.complete();
                    var e;
                    try {
                      e = this.errorFactory();
                    } catch (t) {
                      e = t;
                    }
                    this.destination.error(e);
                  },
                },
              ]),
              n
            );
          })(I);
        function gh() {
          return new Kc();
        }
        function yh() {
          var e =
            arguments.length > 0 && void 0 !== arguments[0]
              ? arguments[0]
              : null;
          return function (t) {
            return t.lift(new kh(e));
          };
        }
        var kh = (function () {
            function e(t) {
              p(this, e), (this.defaultValue = t);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(new bh(e, this.defaultValue));
                  },
                },
              ]),
              e
            );
          })(),
          bh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this, e)).defaultValue = r),
                (i.isEmpty = !0),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    (this.isEmpty = !1), this.destination.next(e);
                  },
                },
                {
                  key: "_complete",
                  value: function () {
                    this.isEmpty && this.destination.next(this.defaultValue),
                      this.destination.complete();
                  },
                },
              ]),
              n
            );
          })(I);
        function wh(e, t) {
          var n = arguments.length >= 2;
          return function (r) {
            return r.pipe(
              e
                ? ih(function (t, n) {
                    return e(t, n, r);
                  })
                : R,
              Yc(1),
              n
                ? yh(t)
                : vh(function () {
                    return new Kc();
                  })
            );
          };
        }
        function _h() {}
        function Sh(e, t, n) {
          return function (r) {
            return r.lift(new Ch(e, t, n));
          };
        }
        var Ch = (function () {
            function e(t, n, r) {
              p(this, e),
                (this.nextOrObserver = t),
                (this.error = n),
                (this.complete = r);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(
                      new Eh(e, this.nextOrObserver, this.error, this.complete)
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Eh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o) {
              var a;
              return (
                p(this, n),
                ((a = t.call(this, e))._tapNext = _h),
                (a._tapError = _h),
                (a._tapComplete = _h),
                (a._tapError = i || _h),
                (a._tapComplete = o || _h),
                d(r)
                  ? ((a._context = f(a)), (a._tapNext = r))
                  : r &&
                    ((a._context = r),
                    (a._tapNext = r.next || _h),
                    (a._tapError = r.error || _h),
                    (a._tapComplete = r.complete || _h)),
                a
              );
            }
            return (
              g(n, [
                {
                  key: "_next",
                  value: function (e) {
                    try {
                      this._tapNext.call(this._context, e);
                    } catch (t) {
                      return void this.destination.error(t);
                    }
                    this.destination.next(e);
                  },
                },
                {
                  key: "_error",
                  value: function (e) {
                    try {
                      this._tapError.call(this._context, e);
                    } catch (e) {
                      return void this.destination.error(e);
                    }
                    this.destination.error(e);
                  },
                },
                {
                  key: "_complete",
                  value: function () {
                    try {
                      this._tapComplete.call(this._context);
                    } catch (e) {
                      return void this.destination.error(e);
                    }
                    return this.destination.complete();
                  },
                },
              ]),
              n
            );
          })(I),
          Th = (function () {
            function e(t) {
              p(this, e), (this.callback = t);
            }
            return (
              g(e, [
                {
                  key: "call",
                  value: function (e, t) {
                    return t.subscribe(new xh(e, this.callback));
                  },
                },
              ]),
              e
            );
          })(),
          xh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return p(this, n), (i = t.call(this, e)).add(new E(r)), i;
            }
            return n;
          })(I),
          Ih = function e(t, n) {
            p(this, e), (this.id = t), (this.url = n);
          },
          Ah = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i,
                o =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : "imperative",
                a =
                  arguments.length > 3 && void 0 !== arguments[3]
                    ? arguments[3]
                    : null;
              return (
                p(this, n),
                ((i = t.call(this, e, r)).navigationTrigger = o),
                (i.restoredState = a),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "toString",
                  value: function () {
                    return "NavigationStart(id: "
                      .concat(this.id, ", url: '")
                      .concat(this.url, "')");
                  },
                },
              ]),
              n
            );
          })(Ih),
          Oh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i) {
              var o;
              return (
                p(this, n), ((o = t.call(this, e, r)).urlAfterRedirects = i), o
              );
            }
            return (
              g(n, [
                {
                  key: "toString",
                  value: function () {
                    return "NavigationEnd(id: "
                      .concat(this.id, ", url: '")
                      .concat(this.url, "', urlAfterRedirects: '")
                      .concat(this.urlAfterRedirects, "')");
                  },
                },
              ]),
              n
            );
          })(Ih),
          Rh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i) {
              var o;
              return p(this, n), ((o = t.call(this, e, r)).reason = i), o;
            }
            return (
              g(n, [
                {
                  key: "toString",
                  value: function () {
                    return "NavigationCancel(id: "
                      .concat(this.id, ", url: '")
                      .concat(this.url, "')");
                  },
                },
              ]),
              n
            );
          })(Ih),
          Ph = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i) {
              var o;
              return p(this, n), ((o = t.call(this, e, r)).error = i), o;
            }
            return (
              g(n, [
                {
                  key: "toString",
                  value: function () {
                    return "NavigationError(id: "
                      .concat(this.id, ", url: '")
                      .concat(this.url, "', error: ")
                      .concat(this.error, ")");
                  },
                },
              ]),
              n
            );
          })(Ih),
          Dh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o) {
              var a;
              return (
                p(this, n),
                ((a = t.call(this, e, r)).urlAfterRedirects = i),
                (a.state = o),
                a
              );
            }
            return (
              g(n, [
                {
                  key: "toString",
                  value: function () {
                    return "RoutesRecognized(id: "
                      .concat(this.id, ", url: '")
                      .concat(this.url, "', urlAfterRedirects: '")
                      .concat(this.urlAfterRedirects, "', state: ")
                      .concat(this.state, ")");
                  },
                },
              ]),
              n
            );
          })(Ih),
          Nh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o) {
              var a;
              return (
                p(this, n),
                ((a = t.call(this, e, r)).urlAfterRedirects = i),
                (a.state = o),
                a
              );
            }
            return (
              g(n, [
                {
                  key: "toString",
                  value: function () {
                    return "GuardsCheckStart(id: "
                      .concat(this.id, ", url: '")
                      .concat(this.url, "', urlAfterRedirects: '")
                      .concat(this.urlAfterRedirects, "', state: ")
                      .concat(this.state, ")");
                  },
                },
              ]),
              n
            );
          })(Ih),
          Mh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o, a) {
              var s;
              return (
                p(this, n),
                ((s = t.call(this, e, r)).urlAfterRedirects = i),
                (s.state = o),
                (s.shouldActivate = a),
                s
              );
            }
            return (
              g(n, [
                {
                  key: "toString",
                  value: function () {
                    return "GuardsCheckEnd(id: "
                      .concat(this.id, ", url: '")
                      .concat(this.url, "', urlAfterRedirects: '")
                      .concat(this.urlAfterRedirects, "', state: ")
                      .concat(this.state, ", shouldActivate: ")
                      .concat(this.shouldActivate, ")");
                  },
                },
              ]),
              n
            );
          })(Ih),
          jh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o) {
              var a;
              return (
                p(this, n),
                ((a = t.call(this, e, r)).urlAfterRedirects = i),
                (a.state = o),
                a
              );
            }
            return (
              g(n, [
                {
                  key: "toString",
                  value: function () {
                    return "ResolveStart(id: "
                      .concat(this.id, ", url: '")
                      .concat(this.url, "', urlAfterRedirects: '")
                      .concat(this.urlAfterRedirects, "', state: ")
                      .concat(this.state, ")");
                  },
                },
              ]),
              n
            );
          })(Ih),
          Fh = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o) {
              var a;
              return (
                p(this, n),
                ((a = t.call(this, e, r)).urlAfterRedirects = i),
                (a.state = o),
                a
              );
            }
            return (
              g(n, [
                {
                  key: "toString",
                  value: function () {
                    return "ResolveEnd(id: "
                      .concat(this.id, ", url: '")
                      .concat(this.url, "', urlAfterRedirects: '")
                      .concat(this.urlAfterRedirects, "', state: ")
                      .concat(this.state, ")");
                  },
                },
              ]),
              n
            );
          })(Ih),
          Lh = (function () {
            function e(t) {
              p(this, e), (this.route = t);
            }
            return (
              g(e, [
                {
                  key: "toString",
                  value: function () {
                    return "RouteConfigLoadStart(path: ".concat(
                      this.route.path,
                      ")"
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Wh = (function () {
            function e(t) {
              p(this, e), (this.route = t);
            }
            return (
              g(e, [
                {
                  key: "toString",
                  value: function () {
                    return "RouteConfigLoadEnd(path: ".concat(
                      this.route.path,
                      ")"
                    );
                  },
                },
              ]),
              e
            );
          })(),
          zh = (function () {
            function e(t) {
              p(this, e), (this.snapshot = t);
            }
            return (
              g(e, [
                {
                  key: "toString",
                  value: function () {
                    return "ChildActivationStart(path: '".concat(
                      (this.snapshot.routeConfig &&
                        this.snapshot.routeConfig.path) ||
                        "",
                      "')"
                    );
                  },
                },
              ]),
              e
            );
          })(),
          qh = (function () {
            function e(t) {
              p(this, e), (this.snapshot = t);
            }
            return (
              g(e, [
                {
                  key: "toString",
                  value: function () {
                    return "ChildActivationEnd(path: '".concat(
                      (this.snapshot.routeConfig &&
                        this.snapshot.routeConfig.path) ||
                        "",
                      "')"
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Hh = (function () {
            function e(t) {
              p(this, e), (this.snapshot = t);
            }
            return (
              g(e, [
                {
                  key: "toString",
                  value: function () {
                    return "ActivationStart(path: '".concat(
                      (this.snapshot.routeConfig &&
                        this.snapshot.routeConfig.path) ||
                        "",
                      "')"
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Uh = (function () {
            function e(t) {
              p(this, e), (this.snapshot = t);
            }
            return (
              g(e, [
                {
                  key: "toString",
                  value: function () {
                    return "ActivationEnd(path: '".concat(
                      (this.snapshot.routeConfig &&
                        this.snapshot.routeConfig.path) ||
                        "",
                      "')"
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Vh = (function () {
            function e(t, n, r) {
              p(this, e),
                (this.routerEvent = t),
                (this.position = n),
                (this.anchor = r);
            }
            return (
              g(e, [
                {
                  key: "toString",
                  value: function () {
                    return "Scroll(anchor: '"
                      .concat(this.anchor, "', position: '")
                      .concat(
                        this.position
                          ? ""
                              .concat(this.position[0], ", ")
                              .concat(this.position[1])
                          : null,
                        "')"
                      );
                  },
                },
              ]),
              e
            );
          })(),
          Bh = (function () {
            function e(t) {
              p(this, e), (this.params = t || {});
            }
            return (
              g(e, [
                {
                  key: "has",
                  value: function (e) {
                    return Object.prototype.hasOwnProperty.call(this.params, e);
                  },
                },
                {
                  key: "get",
                  value: function (e) {
                    if (this.has(e)) {
                      var t = this.params[e];
                      return Array.isArray(t) ? t[0] : t;
                    }
                    return null;
                  },
                },
                {
                  key: "getAll",
                  value: function (e) {
                    if (this.has(e)) {
                      var t = this.params[e];
                      return Array.isArray(t) ? t : [t];
                    }
                    return [];
                  },
                },
                {
                  key: "keys",
                  get: function () {
                    return Object.keys(this.params);
                  },
                },
              ]),
              e
            );
          })();
        function Qh(e) {
          return new Bh(e);
        }
        function Gh(e) {
          var t = Error("NavigationCancelingError: " + e);
          return (t.ngNavigationCancelingError = !0), t;
        }
        function Kh(e, t, n) {
          var r = n.path.split("/");
          if (r.length > e.length) return null;
          if (
            "full" === n.pathMatch &&
            (t.hasChildren() || r.length < e.length)
          )
            return null;
          for (var i = {}, o = 0; o < r.length; o++) {
            var a = r[o],
              s = e[o];
            if (a.startsWith(":")) i[a.substring(1)] = s;
            else if (a !== s.path) return null;
          }
          return { consumed: e.slice(0, r.length), posParams: i };
        }
        function Zh(e, t) {
          var n,
            r = e ? Object.keys(e) : void 0,
            i = t ? Object.keys(t) : void 0;
          if (!r || !i || r.length != i.length) return !1;
          for (var o = 0; o < r.length; o++)
            if (!Jh(e[(n = r[o])], t[n])) return !1;
          return !0;
        }
        function Jh(e, t) {
          if (Array.isArray(e) && Array.isArray(t)) {
            if (e.length !== t.length) return !1;
            var r = n(e).sort(),
              i = n(t).sort();
            return r.every(function (e, t) {
              return i[t] === e;
            });
          }
          return e === t;
        }
        function $h(e) {
          return Array.prototype.concat.apply([], e);
        }
        function Yh(e) {
          return e.length > 0 ? e[e.length - 1] : null;
        }
        function Xh(e, t) {
          for (var n in e) e.hasOwnProperty(n) && t(e[n], n);
        }
        function ef(e) {
          return (t = e) && "function" == typeof t.subscribe
            ? e
            : Qo(e)
            ? $(Promise.resolve(e))
            : Ec(e);
          var t;
        }
        function tf(e, t, n) {
          return n
            ? (function (e, t) {
                return Zh(e, t);
              })(e.queryParams, t.queryParams) && nf(e.root, t.root)
            : (function (e, t) {
                return (
                  Object.keys(t).length <= Object.keys(e).length &&
                  Object.keys(t).every(function (n) {
                    return Jh(e[n], t[n]);
                  })
                );
              })(e.queryParams, t.queryParams) && rf(e.root, t.root);
        }
        function nf(e, t) {
          if (!lf(e.segments, t.segments)) return !1;
          if (e.numberOfChildren !== t.numberOfChildren) return !1;
          for (var n in t.children) {
            if (!e.children[n]) return !1;
            if (!nf(e.children[n], t.children[n])) return !1;
          }
          return !0;
        }
        function rf(e, t) {
          return of(e, t, t.segments);
        }
        function of(e, t, n) {
          if (e.segments.length > n.length)
            return !!lf(e.segments.slice(0, n.length), n) && !t.hasChildren();
          if (e.segments.length === n.length) {
            if (!lf(e.segments, n)) return !1;
            for (var r in t.children) {
              if (!e.children[r]) return !1;
              if (!rf(e.children[r], t.children[r])) return !1;
            }
            return !0;
          }
          var i = n.slice(0, e.segments.length),
            o = n.slice(e.segments.length);
          return (
            !!lf(e.segments, i) &&
            !!e.children.primary &&
            of(e.children.primary, t, o)
          );
        }
        var af = (function () {
            function e(t, n, r) {
              p(this, e),
                (this.root = t),
                (this.queryParams = n),
                (this.fragment = r);
            }
            return (
              g(e, [
                {
                  key: "queryParamMap",
                  get: function () {
                    return (
                      this._queryParamMap ||
                        (this._queryParamMap = Qh(this.queryParams)),
                      this._queryParamMap
                    );
                  },
                },
                {
                  key: "toString",
                  value: function () {
                    return ff.serialize(this);
                  },
                },
              ]),
              e
            );
          })(),
          sf = (function () {
            function e(t, n) {
              var r = this;
              p(this, e),
                (this.segments = t),
                (this.children = n),
                (this.parent = null),
                Xh(n, function (e, t) {
                  return (e.parent = r);
                });
            }
            return (
              g(e, [
                {
                  key: "hasChildren",
                  value: function () {
                    return this.numberOfChildren > 0;
                  },
                },
                {
                  key: "numberOfChildren",
                  get: function () {
                    return Object.keys(this.children).length;
                  },
                },
                {
                  key: "toString",
                  value: function () {
                    return df(this);
                  },
                },
              ]),
              e
            );
          })(),
          uf = (function () {
            function e(t, n) {
              p(this, e), (this.path = t), (this.parameters = n);
            }
            return (
              g(e, [
                {
                  key: "parameterMap",
                  get: function () {
                    return (
                      this._parameterMap ||
                        (this._parameterMap = Qh(this.parameters)),
                      this._parameterMap
                    );
                  },
                },
                {
                  key: "toString",
                  value: function () {
                    return bf(this);
                  },
                },
              ]),
              e
            );
          })();
        function lf(e, t) {
          return (
            e.length === t.length &&
            e.every(function (e, n) {
              return e.path === t[n].path;
            })
          );
        }
        var cf = function e() {
            p(this, e);
          },
          hf = (function () {
            function e() {
              p(this, e);
            }
            return (
              g(e, [
                {
                  key: "parse",
                  value: function (e) {
                    var t = new Ef(e);
                    return new af(
                      t.parseRootSegment(),
                      t.parseQueryParams(),
                      t.parseFragment()
                    );
                  },
                },
                {
                  key: "serialize",
                  value: function (e) {
                    var t, n, r;
                    return "/"
                      .concat(vf(e.root, !0))
                      .concat(
                        ((n = e.queryParams),
                        (r = Object.keys(n).map(function (e) {
                          var t = n[e];
                          return Array.isArray(t)
                            ? t
                                .map(function (t) {
                                  return "".concat(mf(e), "=").concat(mf(t));
                                })
                                .join("&")
                            : "".concat(mf(e), "=").concat(mf(t));
                        })),
                        r.length ? "?".concat(r.join("&")) : "")
                      )
                      .concat(
                        "string" == typeof e.fragment
                          ? "#".concat(((t = e.fragment), encodeURI(t)))
                          : ""
                      );
                  },
                },
              ]),
              e
            );
          })(),
          ff = new hf();
        function df(e) {
          return e.segments
            .map(function (e) {
              return bf(e);
            })
            .join("/");
        }
        function vf(e, t) {
          if (!e.hasChildren()) return df(e);
          if (t) {
            var n = e.children.primary ? vf(e.children.primary, !1) : "",
              r = [];
            return (
              Xh(e.children, function (e, t) {
                "primary" !== t && r.push("".concat(t, ":").concat(vf(e, !1)));
              }),
              r.length > 0 ? "".concat(n, "(").concat(r.join("//"), ")") : n
            );
          }
          var i = (function (e, t) {
            var n = [];
            return (
              Xh(e.children, function (e, r) {
                "primary" === r && (n = n.concat(t(e, r)));
              }),
              Xh(e.children, function (e, r) {
                "primary" !== r && (n = n.concat(t(e, r)));
              }),
              n
            );
          })(e, function (t, n) {
            return "primary" === n
              ? [vf(e.children.primary, !1)]
              : ["".concat(n, ":").concat(vf(t, !1))];
          });
          return 1 === Object.keys(e.children).length &&
            null != e.children.primary
            ? "".concat(df(e), "/").concat(i[0])
            : "".concat(df(e), "/(").concat(i.join("//"), ")");
        }
        function pf(e) {
          return encodeURIComponent(e)
            .replace(/%40/g, "@")
            .replace(/%3A/gi, ":")
            .replace(/%24/g, "$")
            .replace(/%2C/gi, ",");
        }
        function mf(e) {
          return pf(e).replace(/%3B/gi, ";");
        }
        function gf(e) {
          return pf(e)
            .replace(/\(/g, "%28")
            .replace(/\)/g, "%29")
            .replace(/%26/gi, "&");
        }
        function yf(e) {
          return decodeURIComponent(e);
        }
        function kf(e) {
          return yf(e.replace(/\+/g, "%20"));
        }
        function bf(e) {
          return "".concat(gf(e.path)).concat(
            ((t = e.parameters),
            Object.keys(t)
              .map(function (e) {
                return ";".concat(gf(e), "=").concat(gf(t[e]));
              })
              .join(""))
          );
          var t;
        }
        var wf = /^[^\/()?;=#]+/;
        function _f(e) {
          var t = e.match(wf);
          return t ? t[0] : "";
        }
        var Sf = /^[^=?&#]+/,
          Cf = /^[^?&#]+/,
          Ef = (function () {
            function e(t) {
              p(this, e), (this.url = t), (this.remaining = t);
            }
            return (
              g(e, [
                {
                  key: "parseRootSegment",
                  value: function () {
                    return (
                      this.consumeOptional("/"),
                      "" === this.remaining ||
                      this.peekStartsWith("?") ||
                      this.peekStartsWith("#")
                        ? new sf([], {})
                        : new sf([], this.parseChildren())
                    );
                  },
                },
                {
                  key: "parseQueryParams",
                  value: function () {
                    var e = {};
                    if (this.consumeOptional("?"))
                      do {
                        this.parseQueryParam(e);
                      } while (this.consumeOptional("&"));
                    return e;
                  },
                },
                {
                  key: "parseFragment",
                  value: function () {
                    return this.consumeOptional("#")
                      ? decodeURIComponent(this.remaining)
                      : null;
                  },
                },
                {
                  key: "parseChildren",
                  value: function () {
                    if ("" === this.remaining) return {};
                    this.consumeOptional("/");
                    var e = [];
                    for (
                      this.peekStartsWith("(") || e.push(this.parseSegment());
                      this.peekStartsWith("/") &&
                      !this.peekStartsWith("//") &&
                      !this.peekStartsWith("/(");

                    )
                      this.capture("/"), e.push(this.parseSegment());
                    var t = {};
                    this.peekStartsWith("/(") &&
                      (this.capture("/"), (t = this.parseParens(!0)));
                    var n = {};
                    return (
                      this.peekStartsWith("(") && (n = this.parseParens(!1)),
                      (e.length > 0 || Object.keys(t).length > 0) &&
                        (n.primary = new sf(e, t)),
                      n
                    );
                  },
                },
                {
                  key: "parseSegment",
                  value: function () {
                    var e = _f(this.remaining);
                    if ("" === e && this.peekStartsWith(";"))
                      throw new Error(
                        "Empty path url segment cannot have parameters: '".concat(
                          this.remaining,
                          "'."
                        )
                      );
                    return (
                      this.capture(e), new uf(yf(e), this.parseMatrixParams())
                    );
                  },
                },
                {
                  key: "parseMatrixParams",
                  value: function () {
                    for (var e = {}; this.consumeOptional(";"); )
                      this.parseParam(e);
                    return e;
                  },
                },
                {
                  key: "parseParam",
                  value: function (e) {
                    var t = _f(this.remaining);
                    if (t) {
                      this.capture(t);
                      var n = "";
                      if (this.consumeOptional("=")) {
                        var r = _f(this.remaining);
                        r && ((n = r), this.capture(n));
                      }
                      e[yf(t)] = yf(n);
                    }
                  },
                },
                {
                  key: "parseQueryParam",
                  value: function (e) {
                    var t = (function (e) {
                      var t = e.match(Sf);
                      return t ? t[0] : "";
                    })(this.remaining);
                    if (t) {
                      this.capture(t);
                      var n = "";
                      if (this.consumeOptional("=")) {
                        var r = (function (e) {
                          var t = e.match(Cf);
                          return t ? t[0] : "";
                        })(this.remaining);
                        r && ((n = r), this.capture(n));
                      }
                      var i = kf(t),
                        o = kf(n);
                      if (e.hasOwnProperty(i)) {
                        var a = e[i];
                        Array.isArray(a) || ((a = [a]), (e[i] = a)), a.push(o);
                      } else e[i] = o;
                    }
                  },
                },
                {
                  key: "parseParens",
                  value: function (e) {
                    var t = {};
                    for (
                      this.capture("(");
                      !this.consumeOptional(")") && this.remaining.length > 0;

                    ) {
                      var n = _f(this.remaining),
                        r = this.remaining[n.length];
                      if ("/" !== r && ")" !== r && ";" !== r)
                        throw new Error(
                          "Cannot parse url '".concat(this.url, "'")
                        );
                      var i = void 0;
                      n.indexOf(":") > -1
                        ? ((i = n.substr(0, n.indexOf(":"))),
                          this.capture(i),
                          this.capture(":"))
                        : e && (i = "primary");
                      var o = this.parseChildren();
                      (t[i] =
                        1 === Object.keys(o).length
                          ? o.primary
                          : new sf([], o)),
                        this.consumeOptional("//");
                    }
                    return t;
                  },
                },
                {
                  key: "peekStartsWith",
                  value: function (e) {
                    return this.remaining.startsWith(e);
                  },
                },
                {
                  key: "consumeOptional",
                  value: function (e) {
                    return (
                      !!this.peekStartsWith(e) &&
                      ((this.remaining = this.remaining.substring(e.length)),
                      !0)
                    );
                  },
                },
                {
                  key: "capture",
                  value: function (e) {
                    if (!this.consumeOptional(e))
                      throw new Error('Expected "'.concat(e, '".'));
                  },
                },
              ]),
              e
            );
          })(),
          Tf = (function () {
            function e(t) {
              p(this, e), (this._root = t);
            }
            return (
              g(e, [
                {
                  key: "root",
                  get: function () {
                    return this._root.value;
                  },
                },
                {
                  key: "parent",
                  value: function (e) {
                    var t = this.pathFromRoot(e);
                    return t.length > 1 ? t[t.length - 2] : null;
                  },
                },
                {
                  key: "children",
                  value: function (e) {
                    var t = xf(e, this._root);
                    return t
                      ? t.children.map(function (e) {
                          return e.value;
                        })
                      : [];
                  },
                },
                {
                  key: "firstChild",
                  value: function (e) {
                    var t = xf(e, this._root);
                    return t && t.children.length > 0
                      ? t.children[0].value
                      : null;
                  },
                },
                {
                  key: "siblings",
                  value: function (e) {
                    var t = If(e, this._root);
                    return t.length < 2
                      ? []
                      : t[t.length - 2].children
                          .map(function (e) {
                            return e.value;
                          })
                          .filter(function (t) {
                            return t !== e;
                          });
                  },
                },
                {
                  key: "pathFromRoot",
                  value: function (e) {
                    return If(e, this._root).map(function (e) {
                      return e.value;
                    });
                  },
                },
              ]),
              e
            );
          })();
        function xf(e, n) {
          if (e === n.value) return n;
          var r,
            i = t(n.children);
          try {
            for (i.s(); !(r = i.n()).done; ) {
              var o = xf(e, r.value);
              if (o) return o;
            }
          } catch (a) {
            i.e(a);
          } finally {
            i.f();
          }
          return null;
        }
        function If(e, n) {
          if (e === n.value) return [n];
          var r,
            i = t(n.children);
          try {
            for (i.s(); !(r = i.n()).done; ) {
              var o = If(e, r.value);
              if (o.length) return o.unshift(n), o;
            }
          } catch (a) {
            i.e(a);
          } finally {
            i.f();
          }
          return [];
        }
        var Af = (function () {
          function e(t, n) {
            p(this, e), (this.value = t), (this.children = n);
          }
          return (
            g(e, [
              {
                key: "toString",
                value: function () {
                  return "TreeNode(".concat(this.value, ")");
                },
              },
            ]),
            e
          );
        })();
        function Of(e) {
          var t = {};
          return (
            e &&
              e.children.forEach(function (e) {
                return (t[e.value.outlet] = e);
              }),
            t
          );
        }
        var Rf = (function (e) {
          u(n, e);
          var t = c(n);
          function n(e, r) {
            var i;
            return (
              p(this, n), ((i = t.call(this, e)).snapshot = r), Ff(f(i), e), i
            );
          }
          return (
            g(n, [
              {
                key: "toString",
                value: function () {
                  return this.snapshot.toString();
                },
              },
            ]),
            n
          );
        })(Tf);
        function Pf(e, t) {
          var n = (function (e, t) {
              var n = new Mf(
                [],
                {},
                {},
                "",
                {},
                "primary",
                t,
                null,
                e.root,
                -1,
                {}
              );
              return new jf("", new Af(n, []));
            })(e, t),
            r = new qc([new uf("", {})]),
            i = new qc({}),
            o = new qc({}),
            a = new qc({}),
            s = new qc(""),
            u = new Df(r, i, a, s, o, "primary", t, n.root);
          return (u.snapshot = n.root), new Rf(new Af(u, []), n);
        }
        var Df = (function () {
          function e(t, n, r, i, o, a, s, u) {
            p(this, e),
              (this.url = t),
              (this.params = n),
              (this.queryParams = r),
              (this.fragment = i),
              (this.data = o),
              (this.outlet = a),
              (this.component = s),
              (this._futureSnapshot = u);
          }
          return (
            g(e, [
              {
                key: "routeConfig",
                get: function () {
                  return this._futureSnapshot.routeConfig;
                },
              },
              {
                key: "root",
                get: function () {
                  return this._routerState.root;
                },
              },
              {
                key: "parent",
                get: function () {
                  return this._routerState.parent(this);
                },
              },
              {
                key: "firstChild",
                get: function () {
                  return this._routerState.firstChild(this);
                },
              },
              {
                key: "children",
                get: function () {
                  return this._routerState.children(this);
                },
              },
              {
                key: "pathFromRoot",
                get: function () {
                  return this._routerState.pathFromRoot(this);
                },
              },
              {
                key: "paramMap",
                get: function () {
                  return (
                    this._paramMap ||
                      (this._paramMap = this.params.pipe(
                        H(function (e) {
                          return Qh(e);
                        })
                      )),
                    this._paramMap
                  );
                },
              },
              {
                key: "queryParamMap",
                get: function () {
                  return (
                    this._queryParamMap ||
                      (this._queryParamMap = this.queryParams.pipe(
                        H(function (e) {
                          return Qh(e);
                        })
                      )),
                    this._queryParamMap
                  );
                },
              },
              {
                key: "toString",
                value: function () {
                  return this.snapshot
                    ? this.snapshot.toString()
                    : "Future(".concat(this._futureSnapshot, ")");
                },
              },
            ]),
            e
          );
        })();
        function Nf(e) {
          var t =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : "emptyOnly",
            n = e.pathFromRoot,
            r = 0;
          if ("always" !== t)
            for (r = n.length - 1; r >= 1; ) {
              var i = n[r],
                o = n[r - 1];
              if (i.routeConfig && "" === i.routeConfig.path) r--;
              else {
                if (o.component) break;
                r--;
              }
            }
          return (function (e) {
            return e.reduce(
              function (e, t) {
                return {
                  params: Object.assign(Object.assign({}, e.params), t.params),
                  data: Object.assign(Object.assign({}, e.data), t.data),
                  resolve: Object.assign(
                    Object.assign({}, e.resolve),
                    t._resolvedData
                  ),
                };
              },
              { params: {}, data: {}, resolve: {} }
            );
          })(n.slice(r));
        }
        var Mf = (function () {
            function e(t, n, r, i, o, a, s, u, l, c, h) {
              p(this, e),
                (this.url = t),
                (this.params = n),
                (this.queryParams = r),
                (this.fragment = i),
                (this.data = o),
                (this.outlet = a),
                (this.component = s),
                (this.routeConfig = u),
                (this._urlSegment = l),
                (this._lastPathIndex = c),
                (this._resolve = h);
            }
            return (
              g(e, [
                {
                  key: "root",
                  get: function () {
                    return this._routerState.root;
                  },
                },
                {
                  key: "parent",
                  get: function () {
                    return this._routerState.parent(this);
                  },
                },
                {
                  key: "firstChild",
                  get: function () {
                    return this._routerState.firstChild(this);
                  },
                },
                {
                  key: "children",
                  get: function () {
                    return this._routerState.children(this);
                  },
                },
                {
                  key: "pathFromRoot",
                  get: function () {
                    return this._routerState.pathFromRoot(this);
                  },
                },
                {
                  key: "paramMap",
                  get: function () {
                    return (
                      this._paramMap || (this._paramMap = Qh(this.params)),
                      this._paramMap
                    );
                  },
                },
                {
                  key: "queryParamMap",
                  get: function () {
                    return (
                      this._queryParamMap ||
                        (this._queryParamMap = Qh(this.queryParams)),
                      this._queryParamMap
                    );
                  },
                },
                {
                  key: "toString",
                  value: function () {
                    return "Route(url:'"
                      .concat(
                        this.url
                          .map(function (e) {
                            return e.toString();
                          })
                          .join("/"),
                        "', path:'"
                      )
                      .concat(
                        this.routeConfig ? this.routeConfig.path : "",
                        "')"
                      );
                  },
                },
              ]),
              e
            );
          })(),
          jf = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n), ((i = t.call(this, r)).url = e), Ff(f(i), r), i
              );
            }
            return (
              g(n, [
                {
                  key: "toString",
                  value: function () {
                    return Lf(this._root);
                  },
                },
              ]),
              n
            );
          })(Tf);
        function Ff(e, t) {
          (t.value._routerState = e),
            t.children.forEach(function (t) {
              return Ff(e, t);
            });
        }
        function Lf(e) {
          var t =
            e.children.length > 0
              ? " { ".concat(e.children.map(Lf).join(", "), " } ")
              : "";
          return "".concat(e.value).concat(t);
        }
        function Wf(e) {
          if (e.snapshot) {
            var t = e.snapshot,
              n = e._futureSnapshot;
            (e.snapshot = n),
              Zh(t.queryParams, n.queryParams) ||
                e.queryParams.next(n.queryParams),
              t.fragment !== n.fragment && e.fragment.next(n.fragment),
              Zh(t.params, n.params) || e.params.next(n.params),
              (function (e, t) {
                if (e.length !== t.length) return !1;
                for (var n = 0; n < e.length; ++n)
                  if (!Zh(e[n], t[n])) return !1;
                return !0;
              })(t.url, n.url) || e.url.next(n.url),
              Zh(t.data, n.data) || e.data.next(n.data);
          } else
            (e.snapshot = e._futureSnapshot),
              e.data.next(e._futureSnapshot.data);
        }
        function zf(e, t) {
          var n, r;
          return (
            Zh(e.params, t.params) &&
            lf((n = e.url), (r = t.url)) &&
            n.every(function (e, t) {
              return Zh(e.parameters, r[t].parameters);
            }) &&
            !(!e.parent != !t.parent) &&
            (!e.parent || zf(e.parent, t.parent))
          );
        }
        function qf(e, n, r) {
          if (r && e.shouldReuseRoute(n.value, r.value.snapshot)) {
            var i = r.value;
            i._futureSnapshot = n.value;
            var o = (function (e, n, r) {
              return n.children.map(function (n) {
                var i,
                  o = t(r.children);
                try {
                  for (o.s(); !(i = o.n()).done; ) {
                    var a = i.value;
                    if (e.shouldReuseRoute(n.value, a.value.snapshot))
                      return qf(e, n, a);
                  }
                } catch (s) {
                  o.e(s);
                } finally {
                  o.f();
                }
                return qf(e, n);
              });
            })(e, n, r);
            return new Af(i, o);
          }
          if (e.shouldAttach(n.value)) {
            var a = e.retrieve(n.value);
            if (null !== a) {
              var s = a.route;
              return Hf(n, s), s;
            }
          }
          var u,
            l = new Df(
              new qc((u = n.value).url),
              new qc(u.params),
              new qc(u.queryParams),
              new qc(u.fragment),
              new qc(u.data),
              u.outlet,
              u.component,
              u
            ),
            c = n.children.map(function (t) {
              return qf(e, t);
            });
          return new Af(l, c);
        }
        function Hf(e, t) {
          if (e.value.routeConfig !== t.value.routeConfig)
            throw new Error(
              "Cannot reattach ActivatedRouteSnapshot created from a different route"
            );
          if (e.children.length !== t.children.length)
            throw new Error(
              "Cannot reattach ActivatedRouteSnapshot with a different number of children"
            );
          t.value._futureSnapshot = e.value;
          for (var n = 0; n < e.children.length; ++n)
            Hf(e.children[n], t.children[n]);
        }
        function Uf(e) {
          return (
            "object" == typeof e && null != e && !e.outlets && !e.segmentPath
          );
        }
        function Vf(e) {
          return "object" == typeof e && null != e && e.outlets;
        }
        function Bf(e, t, n, r, i) {
          var o = {};
          return (
            r &&
              Xh(r, function (e, t) {
                o[t] = Array.isArray(e)
                  ? e.map(function (e) {
                      return "".concat(e);
                    })
                  : "".concat(e);
              }),
            new af(n.root === e ? t : Qf(n.root, e, t), o, i)
          );
        }
        function Qf(e, t, n) {
          var r = {};
          return (
            Xh(e.children, function (e, i) {
              r[i] = e === t ? n : Qf(e, t, n);
            }),
            new sf(e.segments, r)
          );
        }
        var Gf = (function () {
            function e(t, n, r) {
              if (
                (p(this, e),
                (this.isAbsolute = t),
                (this.numberOfDoubleDots = n),
                (this.commands = r),
                t && r.length > 0 && Uf(r[0]))
              )
                throw new Error("Root segment cannot have matrix parameters");
              var i = r.find(Vf);
              if (i && i !== Yh(r))
                throw new Error("{outlets:{}} has to be the last command");
            }
            return (
              g(e, [
                {
                  key: "toRoot",
                  value: function () {
                    return (
                      this.isAbsolute &&
                      1 === this.commands.length &&
                      "/" == this.commands[0]
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Kf = function e(t, n, r) {
            p(this, e),
              (this.segmentGroup = t),
              (this.processChildren = n),
              (this.index = r);
          };
        function Zf(e, t, n) {
          if (
            (e || (e = new sf([], {})),
            0 === e.segments.length && e.hasChildren())
          )
            return Jf(e, t, n);
          var r = (function (e, t, n) {
              for (
                var r = 0,
                  i = t,
                  o = { match: !1, pathIndex: 0, commandIndex: 0 };
                i < e.segments.length;

              ) {
                if (r >= n.length) return o;
                var a = e.segments[i],
                  s = n[r];
                if (Vf(s)) break;
                var u = "".concat(s),
                  l = r < n.length - 1 ? n[r + 1] : null;
                if (i > 0 && void 0 === u) break;
                if (u && l && "object" == typeof l && void 0 === l.outlets) {
                  if (!ed(u, l, a)) return o;
                  r += 2;
                } else {
                  if (!ed(u, {}, a)) return o;
                  r++;
                }
                i++;
              }
              return { match: !0, pathIndex: i, commandIndex: r };
            })(e, t, n),
            i = n.slice(r.commandIndex);
          if (r.match && r.pathIndex < e.segments.length) {
            var o = new sf(e.segments.slice(0, r.pathIndex), {});
            return (
              (o.children.primary = new sf(
                e.segments.slice(r.pathIndex),
                e.children
              )),
              Jf(o, 0, i)
            );
          }
          return r.match && 0 === i.length
            ? new sf(e.segments, {})
            : r.match && !e.hasChildren()
            ? $f(e, t, n)
            : r.match
            ? Jf(e, 0, i)
            : $f(e, t, n);
        }
        function Jf(e, t, n) {
          if (0 === n.length) return new sf(e.segments, {});
          var r = (function (e) {
              return Vf(e[0]) ? e[0].outlets : { primary: e };
            })(n),
            i = {};
          return (
            Xh(r, function (n, r) {
              "string" == typeof n && (n = [n]),
                null !== n && (i[r] = Zf(e.children[r], t, n));
            }),
            Xh(e.children, function (e, t) {
              void 0 === r[t] && (i[t] = e);
            }),
            new sf(e.segments, i)
          );
        }
        function $f(e, t, n) {
          for (var r = e.segments.slice(0, t), i = 0; i < n.length; ) {
            var o = n[i];
            if (Vf(o)) {
              var a = Yf(o.outlets);
              return new sf(r, a);
            }
            if (0 === i && Uf(n[0]))
              r.push(new uf(e.segments[t].path, Xf(n[0]))), i++;
            else {
              var s = Vf(o) ? o.outlets.primary : "".concat(o),
                u = i < n.length - 1 ? n[i + 1] : null;
              s && u && Uf(u)
                ? (r.push(new uf(s, Xf(u))), (i += 2))
                : (r.push(new uf(s, {})), i++);
            }
          }
          return new sf(r, {});
        }
        function Yf(e) {
          var t = {};
          return (
            Xh(e, function (e, n) {
              "string" == typeof e && (e = [e]),
                null !== e && (t[n] = $f(new sf([], {}), 0, e));
            }),
            t
          );
        }
        function Xf(e) {
          var t = {};
          return (
            Xh(e, function (e, n) {
              return (t[n] = "".concat(e));
            }),
            t
          );
        }
        function ed(e, t, n) {
          return e == n.path && Zh(t, n.parameters);
        }
        var td = (function () {
          function e(t, n, r, i) {
            p(this, e),
              (this.routeReuseStrategy = t),
              (this.futureState = n),
              (this.currState = r),
              (this.forwardEvent = i);
          }
          return (
            g(e, [
              {
                key: "activate",
                value: function (e) {
                  var t = this.futureState._root,
                    n = this.currState ? this.currState._root : null;
                  this.deactivateChildRoutes(t, n, e),
                    Wf(this.futureState.root),
                    this.activateChildRoutes(t, n, e);
                },
              },
              {
                key: "deactivateChildRoutes",
                value: function (e, t, n) {
                  var r = this,
                    i = Of(t);
                  e.children.forEach(function (e) {
                    var t = e.value.outlet;
                    r.deactivateRoutes(e, i[t], n), delete i[t];
                  }),
                    Xh(i, function (e, t) {
                      r.deactivateRouteAndItsChildren(e, n);
                    });
                },
              },
              {
                key: "deactivateRoutes",
                value: function (e, t, n) {
                  var r = e.value,
                    i = t ? t.value : null;
                  if (r === i)
                    if (r.component) {
                      var o = n.getContext(r.outlet);
                      o && this.deactivateChildRoutes(e, t, o.children);
                    } else this.deactivateChildRoutes(e, t, n);
                  else i && this.deactivateRouteAndItsChildren(t, n);
                },
              },
              {
                key: "deactivateRouteAndItsChildren",
                value: function (e, t) {
                  this.routeReuseStrategy.shouldDetach(e.value.snapshot)
                    ? this.detachAndStoreRouteSubtree(e, t)
                    : this.deactivateRouteAndOutlet(e, t);
                },
              },
              {
                key: "detachAndStoreRouteSubtree",
                value: function (e, t) {
                  var n = t.getContext(e.value.outlet);
                  if (n && n.outlet) {
                    var r = n.outlet.detach(),
                      i = n.children.onOutletDeactivated();
                    this.routeReuseStrategy.store(e.value.snapshot, {
                      componentRef: r,
                      route: e,
                      contexts: i,
                    });
                  }
                },
              },
              {
                key: "deactivateRouteAndOutlet",
                value: function (e, t) {
                  for (
                    var n = t.getContext(e.value.outlet),
                      r = n && e.value.component ? n.children : t,
                      i = Of(e),
                      o = 0,
                      a = Object.keys(i);
                    o < a.length;
                    o++
                  ) {
                    var s = a[o];
                    this.deactivateRouteAndItsChildren(i[s], r);
                  }
                  n &&
                    n.outlet &&
                    (n.outlet.deactivate(),
                    n.children.onOutletDeactivated(),
                    (n.attachRef = null),
                    (n.resolver = null),
                    (n.route = null));
                },
              },
              {
                key: "activateChildRoutes",
                value: function (e, t, n) {
                  var r = this,
                    i = Of(t);
                  e.children.forEach(function (e) {
                    r.activateRoutes(e, i[e.value.outlet], n),
                      r.forwardEvent(new Uh(e.value.snapshot));
                  }),
                    e.children.length &&
                      this.forwardEvent(new qh(e.value.snapshot));
                },
              },
              {
                key: "activateRoutes",
                value: function (e, t, n) {
                  var r = e.value,
                    i = t ? t.value : null;
                  if ((Wf(r), r === i))
                    if (r.component) {
                      var o = n.getOrCreateContext(r.outlet);
                      this.activateChildRoutes(e, t, o.children);
                    } else this.activateChildRoutes(e, t, n);
                  else if (r.component) {
                    var a = n.getOrCreateContext(r.outlet);
                    if (this.routeReuseStrategy.shouldAttach(r.snapshot)) {
                      var s = this.routeReuseStrategy.retrieve(r.snapshot);
                      this.routeReuseStrategy.store(r.snapshot, null),
                        a.children.onOutletReAttached(s.contexts),
                        (a.attachRef = s.componentRef),
                        (a.route = s.route.value),
                        a.outlet &&
                          a.outlet.attach(s.componentRef, s.route.value),
                        nd(s.route);
                    } else {
                      var u = (function (e) {
                          for (var t = e.parent; t; t = t.parent) {
                            var n = t.routeConfig;
                            if (n && n._loadedConfig) return n._loadedConfig;
                            if (n && n.component) return null;
                          }
                          return null;
                        })(r.snapshot),
                        l = u ? u.module.componentFactoryResolver : null;
                      (a.attachRef = null),
                        (a.route = r),
                        (a.resolver = l),
                        a.outlet && a.outlet.activateWith(r, l),
                        this.activateChildRoutes(e, null, a.children);
                    }
                  } else this.activateChildRoutes(e, null, n);
                },
              },
            ]),
            e
          );
        })();
        function nd(e) {
          Wf(e.value), e.children.forEach(nd);
        }
        var rd = function e(t, n) {
          p(this, e), (this.routes = t), (this.module = n);
        };
        function id(e) {
          return "function" == typeof e;
        }
        function od(e) {
          return e instanceof af;
        }
        var ad = Symbol("INITIAL_VALUE");
        function sd() {
          return Pc(function (e) {
            return (function () {
              for (
                var e = arguments.length, t = new Array(e), n = 0;
                n < e;
                n++
              )
                t[n] = arguments[n];
              var r, i;
              return (
                q(t[t.length - 1]) && (i = t.pop()),
                "function" == typeof t[t.length - 1] && (r = t.pop()),
                1 === t.length && w(t[0]) && (t = t[0]),
                oe(t, i).lift(new Qc(r))
              );
            })(
              e.map(function (e) {
                return e.pipe(
                  Yc(1),
                  (function () {
                    for (
                      var e = arguments.length, t = new Array(e), n = 0;
                      n < e;
                      n++
                    )
                      t[n] = arguments[n];
                    var r = t[t.length - 1];
                    return q(r)
                      ? (t.pop(),
                        function (e) {
                          return Zc(t, e, r);
                        })
                      : function (e) {
                          return Zc(t, e);
                        };
                  })(ad)
                );
              })
            ).pipe(
              th(function (e, t) {
                var n = !1;
                return t.reduce(function (e, r, i) {
                  if (e !== ad) return e;
                  if ((r === ad && (n = !0), !n)) {
                    if (!1 === r) return r;
                    if (i === t.length - 1 || od(r)) return r;
                  }
                  return e;
                }, e);
              }, ad),
              ih(function (e) {
                return e !== ad;
              }),
              H(function (e) {
                return od(e) ? e : !0 === e;
              }),
              Yc(1)
            );
          });
        }
        var ud,
          ld =
            (((ud = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || ud)();
            }),
            (ud.ɵcmp = rt({
              type: ud,
              selectors: [["ng-component"]],
              decls: 1,
              vars: 0,
              template: function (e, t) {
                1 & e && Vo(0, "router-outlet");
              },
              directives: function () {
                return [hv];
              },
              encapsulation: 2,
            })),
            ud);
        function cd(e) {
          for (
            var t =
                arguments.length > 1 && void 0 !== arguments[1]
                  ? arguments[1]
                  : "",
              n = 0;
            n < e.length;
            n++
          ) {
            var r = e[n];
            hd(r, fd(t, r));
          }
        }
        function hd(e, t) {
          e.children && cd(e.children, t);
        }
        function fd(e, t) {
          return t
            ? e || t.path
              ? e && !t.path
                ? "".concat(e, "/")
                : !e && t.path
                ? t.path
                : "".concat(e, "/").concat(t.path)
              : ""
            : e;
        }
        function dd(e) {
          var t = e.children && e.children.map(dd),
            n = t
              ? Object.assign(Object.assign({}, e), { children: t })
              : Object.assign({}, e);
          return (
            !n.component &&
              (t || n.loadChildren) &&
              n.outlet &&
              "primary" !== n.outlet &&
              (n.component = ld),
            n
          );
        }
        function vd(e) {
          return e.outlet || "primary";
        }
        function pd(e, t) {
          var r = e.filter(function (e) {
            return vd(e) === t;
          });
          return (
            r.push.apply(
              r,
              n(
                e.filter(function (e) {
                  return vd(e) !== t;
                })
              )
            ),
            r
          );
        }
        var md = {
          matched: !1,
          consumedSegments: [],
          lastChild: 0,
          parameters: {},
          positionalParamSegments: {},
        };
        function gd(e, t, n) {
          var r;
          if ("" === t.path)
            return "full" === t.pathMatch && (e.hasChildren() || n.length > 0)
              ? Object.assign({}, md)
              : {
                  matched: !0,
                  consumedSegments: [],
                  lastChild: 0,
                  parameters: {},
                  positionalParamSegments: {},
                };
          var i = (t.matcher || Kh)(n, e, t);
          if (!i) return Object.assign({}, md);
          var o = {};
          Xh(i.posParams, function (e, t) {
            o[t] = e.path;
          });
          var a =
            i.consumed.length > 0
              ? Object.assign(
                  Object.assign({}, o),
                  i.consumed[i.consumed.length - 1].parameters
                )
              : o;
          return {
            matched: !0,
            consumedSegments: i.consumed,
            lastChild: i.consumed.length,
            parameters: a,
            positionalParamSegments:
              null !== (r = i.posParams) && void 0 !== r ? r : {},
          };
        }
        function yd(e, n, r, i) {
          var o =
            arguments.length > 4 && void 0 !== arguments[4]
              ? arguments[4]
              : "corrected";
          if (
            r.length > 0 &&
            (function (e, t, n) {
              return n.some(function (n) {
                return kd(e, t, n) && "primary" !== vd(n);
              });
            })(e, r, i)
          ) {
            var a = new sf(
              n,
              (function (e, n, r, i) {
                var o = {};
                (o.primary = i),
                  (i._sourceSegment = e),
                  (i._segmentIndexShift = n.length);
                var a,
                  s = t(r);
                try {
                  for (s.s(); !(a = s.n()).done; ) {
                    var u = a.value;
                    if ("" === u.path && "primary" !== vd(u)) {
                      var l = new sf([], {});
                      (l._sourceSegment = e),
                        (l._segmentIndexShift = n.length),
                        (o[vd(u)] = l);
                    }
                  }
                } catch (c) {
                  s.e(c);
                } finally {
                  s.f();
                }
                return o;
              })(e, n, i, new sf(r, e.children))
            );
            return (
              (a._sourceSegment = e),
              (a._segmentIndexShift = n.length),
              { segmentGroup: a, slicedSegments: [] }
            );
          }
          if (
            0 === r.length &&
            (function (e, t, n) {
              return n.some(function (n) {
                return kd(e, t, n);
              });
            })(e, r, i)
          ) {
            var s = new sf(
              e.segments,
              (function (e, n, r, i, o, a) {
                var s,
                  u = {},
                  l = t(i);
                try {
                  for (l.s(); !(s = l.n()).done; ) {
                    var c = s.value;
                    if (kd(e, r, c) && !o[vd(c)]) {
                      var h = new sf([], {});
                      (h._sourceSegment = e),
                        (h._segmentIndexShift =
                          "legacy" === a ? e.segments.length : n.length),
                        (u[vd(c)] = h);
                    }
                  }
                } catch (f) {
                  l.e(f);
                } finally {
                  l.f();
                }
                return Object.assign(Object.assign({}, o), u);
              })(e, n, r, i, e.children, o)
            );
            return (
              (s._sourceSegment = e),
              (s._segmentIndexShift = n.length),
              { segmentGroup: s, slicedSegments: r }
            );
          }
          var u = new sf(e.segments, e.children);
          return (
            (u._sourceSegment = e),
            (u._segmentIndexShift = n.length),
            { segmentGroup: u, slicedSegments: r }
          );
        }
        function kd(e, t, n) {
          return (
            (!(e.hasChildren() || t.length > 0) || "full" !== n.pathMatch) &&
            "" === n.path
          );
        }
        function bd(e, t, n, r) {
          return (
            !!(vd(e) === r || ("primary" !== r && kd(t, n, e))) &&
            ("**" === e.path || gd(t, e, n).matched)
          );
        }
        function wd(e, t, n) {
          return 0 === t.length && !e.children[n];
        }
        var _d = function e(t) {
            p(this, e), (this.segmentGroup = t || null);
          },
          Sd = function e(t) {
            p(this, e), (this.urlTree = t);
          };
        function Cd(e) {
          return new D(function (t) {
            return t.error(new _d(e));
          });
        }
        function Ed(e) {
          return new D(function (t) {
            return t.error(new Sd(e));
          });
        }
        function Td(e) {
          return new D(function (t) {
            return t.error(
              new Error(
                "Only absolute redirects can have named outlets. redirectTo: '".concat(
                  e,
                  "'"
                )
              )
            );
          });
        }
        var xd = (function () {
          function e(t, n, r, i, o) {
            p(this, e),
              (this.configLoader = n),
              (this.urlSerializer = r),
              (this.urlTree = i),
              (this.config = o),
              (this.allowRedirects = !0),
              (this.ngModule = t.get(os));
          }
          return (
            g(e, [
              {
                key: "apply",
                value: function () {
                  var e = this,
                    t = yd(this.urlTree.root, [], [], this.config).segmentGroup,
                    n = new sf(t.segments, t.children);
                  return this.expandSegmentGroup(
                    this.ngModule,
                    this.config,
                    n,
                    "primary"
                  )
                    .pipe(
                      H(function (t) {
                        return e.createUrlTree(
                          Id(t),
                          e.urlTree.queryParams,
                          e.urlTree.fragment
                        );
                      })
                    )
                    .pipe(
                      sh(function (t) {
                        if (t instanceof Sd)
                          return (e.allowRedirects = !1), e.match(t.urlTree);
                        if (t instanceof _d) throw e.noMatchError(t);
                        throw t;
                      })
                    );
                },
              },
              {
                key: "match",
                value: function (e) {
                  var t = this;
                  return this.expandSegmentGroup(
                    this.ngModule,
                    this.config,
                    e.root,
                    "primary"
                  )
                    .pipe(
                      H(function (n) {
                        return t.createUrlTree(
                          Id(n),
                          e.queryParams,
                          e.fragment
                        );
                      })
                    )
                    .pipe(
                      sh(function (e) {
                        if (e instanceof _d) throw t.noMatchError(e);
                        throw e;
                      })
                    );
                },
              },
              {
                key: "noMatchError",
                value: function (e) {
                  return new Error(
                    "Cannot match any routes. URL Segment: '".concat(
                      e.segmentGroup,
                      "'"
                    )
                  );
                },
              },
              {
                key: "createUrlTree",
                value: function (e, t, n) {
                  var r =
                    e.segments.length > 0 ? new sf([], { primary: e }) : e;
                  return new af(r, t, n);
                },
              },
              {
                key: "expandSegmentGroup",
                value: function (e, t, n, r) {
                  return 0 === n.segments.length && n.hasChildren()
                    ? this.expandChildren(e, t, n).pipe(
                        H(function (e) {
                          return new sf([], e);
                        })
                      )
                    : this.expandSegment(e, n, t, n.segments, r, !0);
                },
              },
              {
                key: "expandChildren",
                value: function (e, t, n) {
                  for (
                    var r = this, i = [], o = 0, a = Object.keys(n.children);
                    o < a.length;
                    o++
                  ) {
                    var s = a[o];
                    "primary" === s ? i.unshift(s) : i.push(s);
                  }
                  return $(i).pipe(
                    ch(function (i) {
                      var o = n.children[i],
                        a = pd(t, i);
                      return r.expandSegmentGroup(e, a, o, i).pipe(
                        H(function (e) {
                          return { segment: e, outlet: i };
                        })
                      );
                    }),
                    th(function (e, t) {
                      return (e[t.outlet] = t.segment), e;
                    }, {}),
                    (function (e, t) {
                      var n = arguments.length >= 2;
                      return function (r) {
                        return r.pipe(
                          e
                            ? ih(function (t, n) {
                                return e(t, n, r);
                              })
                            : R,
                          hh(1),
                          n
                            ? yh(t)
                            : vh(function () {
                                return new Kc();
                              })
                        );
                      };
                    })()
                  );
                },
              },
              {
                key: "expandSegment",
                value: function (e, t, n, r, i, o) {
                  var a = this;
                  return $(n).pipe(
                    ch(function (s) {
                      return a
                        .expandSegmentAgainstRoute(e, t, n, s, r, i, o)
                        .pipe(
                          sh(function (e) {
                            if (e instanceof _d) return Ec(null);
                            throw e;
                          })
                        );
                    }),
                    wh(function (e) {
                      return !!e;
                    }),
                    sh(function (e, n) {
                      if (e instanceof Kc || "EmptyError" === e.name) {
                        if (wd(t, r, i)) return Ec(new sf([], {}));
                        throw new _d(t);
                      }
                      throw e;
                    })
                  );
                },
              },
              {
                key: "expandSegmentAgainstRoute",
                value: function (e, t, n, r, i, o, a) {
                  return bd(r, t, i, o)
                    ? void 0 === r.redirectTo
                      ? this.matchSegmentAgainstRoute(e, t, r, i, o)
                      : a && this.allowRedirects
                      ? this.expandSegmentAgainstRouteUsingRedirect(
                          e,
                          t,
                          n,
                          r,
                          i,
                          o
                        )
                      : Cd(t)
                    : Cd(t);
                },
              },
              {
                key: "expandSegmentAgainstRouteUsingRedirect",
                value: function (e, t, n, r, i, o) {
                  return "**" === r.path
                    ? this.expandWildCardWithParamsAgainstRouteUsingRedirect(
                        e,
                        n,
                        r,
                        o
                      )
                    : this.expandRegularSegmentAgainstRouteUsingRedirect(
                        e,
                        t,
                        n,
                        r,
                        i,
                        o
                      );
                },
              },
              {
                key: "expandWildCardWithParamsAgainstRouteUsingRedirect",
                value: function (e, t, n, r) {
                  var i = this,
                    o = this.applyRedirectCommands([], n.redirectTo, {});
                  return n.redirectTo.startsWith("/")
                    ? Ed(o)
                    : this.lineralizeSegments(n, o).pipe(
                        te(function (n) {
                          var o = new sf(n, {});
                          return i.expandSegment(e, o, t, n, r, !1);
                        })
                      );
                },
              },
              {
                key: "expandRegularSegmentAgainstRouteUsingRedirect",
                value: function (e, t, n, r, i, o) {
                  var a = this,
                    s = gd(t, r, i),
                    u = s.matched,
                    l = s.consumedSegments,
                    c = s.lastChild,
                    h = s.positionalParamSegments;
                  if (!u) return Cd(t);
                  var f = this.applyRedirectCommands(l, r.redirectTo, h);
                  return r.redirectTo.startsWith("/")
                    ? Ed(f)
                    : this.lineralizeSegments(r, f).pipe(
                        te(function (r) {
                          return a.expandSegment(
                            e,
                            t,
                            n,
                            r.concat(i.slice(c)),
                            o,
                            !1
                          );
                        })
                      );
                },
              },
              {
                key: "matchSegmentAgainstRoute",
                value: function (e, t, n, r, i) {
                  var o = this;
                  if ("**" === n.path)
                    return n.loadChildren
                      ? (n._loadedConfig
                          ? Ec(n._loadedConfig)
                          : this.configLoader.load(e.injector, n)
                        ).pipe(
                          H(function (e) {
                            return (n._loadedConfig = e), new sf(r, {});
                          })
                        )
                      : Ec(new sf(r, {}));
                  var a = gd(t, n, r),
                    s = a.matched,
                    u = a.consumedSegments,
                    l = a.lastChild;
                  if (!s) return Cd(t);
                  var c = r.slice(l);
                  return this.getChildConfig(e, n, r).pipe(
                    te(function (e) {
                      var r = e.module,
                        a = e.routes,
                        s = yd(t, u, c, a),
                        l = s.segmentGroup,
                        h = s.slicedSegments,
                        f = new sf(l.segments, l.children);
                      if (0 === h.length && f.hasChildren())
                        return o.expandChildren(r, a, f).pipe(
                          H(function (e) {
                            return new sf(u, e);
                          })
                        );
                      if (0 === a.length && 0 === h.length)
                        return Ec(new sf(u, {}));
                      var d = vd(n) === i;
                      return o
                        .expandSegment(r, f, a, h, d ? "primary" : i, !0)
                        .pipe(
                          H(function (e) {
                            return new sf(u.concat(e.segments), e.children);
                          })
                        );
                    })
                  );
                },
              },
              {
                key: "getChildConfig",
                value: function (e, t, n) {
                  var r = this;
                  return t.children
                    ? Ec(new rd(t.children, e))
                    : t.loadChildren
                    ? void 0 !== t._loadedConfig
                      ? Ec(t._loadedConfig)
                      : this.runCanLoadGuards(e.injector, t, n).pipe(
                          te(function (n) {
                            return n
                              ? r.configLoader.load(e.injector, t).pipe(
                                  H(function (e) {
                                    return (t._loadedConfig = e), e;
                                  })
                                )
                              : (function (e) {
                                  return new D(function (t) {
                                    return t.error(
                                      Gh(
                                        "Cannot load children because the guard of the route \"path: '".concat(
                                          e.path,
                                          "'\" returned false"
                                        )
                                      )
                                    );
                                  });
                                })(t);
                          })
                        )
                    : Ec(new rd([], e));
                },
              },
              {
                key: "runCanLoadGuards",
                value: function (e, t, n) {
                  var r = this,
                    i = t.canLoad;
                  return i && 0 !== i.length
                    ? Ec(
                        i.map(function (r) {
                          var i,
                            o = e.get(r);
                          if (
                            (function (e) {
                              return e && id(e.canLoad);
                            })(o)
                          )
                            i = o.canLoad(t, n);
                          else {
                            if (!id(o))
                              throw new Error("Invalid CanLoad guard");
                            i = o(t, n);
                          }
                          return ef(i);
                        })
                      ).pipe(
                        sd(),
                        Sh(function (e) {
                          if (od(e)) {
                            var t = Gh(
                              'Redirecting to "'.concat(
                                r.urlSerializer.serialize(e),
                                '"'
                              )
                            );
                            throw ((t.url = e), t);
                          }
                        }),
                        H(function (e) {
                          return !0 === e;
                        })
                      )
                    : Ec(!0);
                },
              },
              {
                key: "lineralizeSegments",
                value: function (e, t) {
                  for (var n = [], r = t.root; ; ) {
                    if (((n = n.concat(r.segments)), 0 === r.numberOfChildren))
                      return Ec(n);
                    if (r.numberOfChildren > 1 || !r.children.primary)
                      return Td(e.redirectTo);
                    r = r.children.primary;
                  }
                },
              },
              {
                key: "applyRedirectCommands",
                value: function (e, t, n) {
                  return this.applyRedirectCreatreUrlTree(
                    t,
                    this.urlSerializer.parse(t),
                    e,
                    n
                  );
                },
              },
              {
                key: "applyRedirectCreatreUrlTree",
                value: function (e, t, n, r) {
                  var i = this.createSegmentGroup(e, t.root, n, r);
                  return new af(
                    i,
                    this.createQueryParams(
                      t.queryParams,
                      this.urlTree.queryParams
                    ),
                    t.fragment
                  );
                },
              },
              {
                key: "createQueryParams",
                value: function (e, t) {
                  var n = {};
                  return (
                    Xh(e, function (e, r) {
                      if ("string" == typeof e && e.startsWith(":")) {
                        var i = e.substring(1);
                        n[r] = t[i];
                      } else n[r] = e;
                    }),
                    n
                  );
                },
              },
              {
                key: "createSegmentGroup",
                value: function (e, t, n, r) {
                  var i = this,
                    o = this.createSegments(e, t.segments, n, r),
                    a = {};
                  return (
                    Xh(t.children, function (t, o) {
                      a[o] = i.createSegmentGroup(e, t, n, r);
                    }),
                    new sf(o, a)
                  );
                },
              },
              {
                key: "createSegments",
                value: function (e, t, n, r) {
                  var i = this;
                  return t.map(function (t) {
                    return t.path.startsWith(":")
                      ? i.findPosParam(e, t, r)
                      : i.findOrReturn(t, n);
                  });
                },
              },
              {
                key: "findPosParam",
                value: function (e, t, n) {
                  var r = n[t.path.substring(1)];
                  if (!r)
                    throw new Error(
                      "Cannot redirect to '"
                        .concat(e, "'. Cannot find '")
                        .concat(t.path, "'.")
                    );
                  return r;
                },
              },
              {
                key: "findOrReturn",
                value: function (e, n) {
                  var r,
                    i = 0,
                    o = t(n);
                  try {
                    for (o.s(); !(r = o.n()).done; ) {
                      var a = r.value;
                      if (a.path === e.path) return n.splice(i), a;
                      i++;
                    }
                  } catch (s) {
                    o.e(s);
                  } finally {
                    o.f();
                  }
                  return e;
                },
              },
            ]),
            e
          );
        })();
        function Id(e) {
          for (
            var t = {}, n = 0, r = Object.keys(e.children);
            n < r.length;
            n++
          ) {
            var i = r[n],
              o = Id(e.children[i]);
            (o.segments.length > 0 || o.hasChildren()) && (t[i] = o);
          }
          return (function (e) {
            if (1 === e.numberOfChildren && e.children.primary) {
              var t = e.children.primary;
              return new sf(e.segments.concat(t.segments), t.children);
            }
            return e;
          })(new sf(e.segments, t));
        }
        var Ad = function e(t) {
            p(this, e),
              (this.path = t),
              (this.route = this.path[this.path.length - 1]);
          },
          Od = function e(t, n) {
            p(this, e), (this.component = t), (this.route = n);
          };
        function Rd(e, t, n) {
          var r = e._root;
          return Dd(r, t ? t._root : null, n, [r.value]);
        }
        function Pd(e, t, n) {
          var r = (function (e) {
            if (!e) return null;
            for (var t = e.parent; t; t = t.parent) {
              var n = t.routeConfig;
              if (n && n._loadedConfig) return n._loadedConfig;
            }
            return null;
          })(t);
          return (r ? r.module.injector : n).get(e);
        }
        function Dd(e, t, n, r) {
          var i =
              arguments.length > 4 && void 0 !== arguments[4]
                ? arguments[4]
                : { canDeactivateChecks: [], canActivateChecks: [] },
            o = Of(t);
          return (
            e.children.forEach(function (e) {
              !(function (e, t, n, r) {
                var i =
                    arguments.length > 4 && void 0 !== arguments[4]
                      ? arguments[4]
                      : { canDeactivateChecks: [], canActivateChecks: [] },
                  o = e.value,
                  a = t ? t.value : null,
                  s = n ? n.getContext(e.value.outlet) : null;
                if (a && o.routeConfig === a.routeConfig) {
                  var u = (function (e, t, n) {
                    if ("function" == typeof n) return n(e, t);
                    switch (n) {
                      case "pathParamsChange":
                        return !lf(e.url, t.url);
                      case "pathParamsOrQueryParamsChange":
                        return (
                          !lf(e.url, t.url) || !Zh(e.queryParams, t.queryParams)
                        );
                      case "always":
                        return !0;
                      case "paramsOrQueryParamsChange":
                        return !zf(e, t) || !Zh(e.queryParams, t.queryParams);
                      case "paramsChange":
                      default:
                        return !zf(e, t);
                    }
                  })(a, o, o.routeConfig.runGuardsAndResolvers);
                  u
                    ? i.canActivateChecks.push(new Ad(r))
                    : ((o.data = a.data), (o._resolvedData = a._resolvedData)),
                    Dd(e, t, o.component ? (s ? s.children : null) : n, r, i),
                    u &&
                      s &&
                      s.outlet &&
                      s.outlet.isActivated &&
                      i.canDeactivateChecks.push(new Od(s.outlet.component, a));
                } else
                  a && Nd(t, s, i),
                    i.canActivateChecks.push(new Ad(r)),
                    Dd(
                      e,
                      null,
                      o.component ? (s ? s.children : null) : n,
                      r,
                      i
                    );
              })(e, o[e.value.outlet], n, r.concat([e.value]), i),
                delete o[e.value.outlet];
            }),
            Xh(o, function (e, t) {
              return Nd(e, n.getContext(t), i);
            }),
            i
          );
        }
        function Nd(e, t, n) {
          var r = Of(e),
            i = e.value;
          Xh(r, function (e, r) {
            Nd(e, i.component ? (t ? t.children.getContext(r) : null) : t, n);
          }),
            n.canDeactivateChecks.push(
              new Od(
                i.component && t && t.outlet && t.outlet.isActivated
                  ? t.outlet.component
                  : null,
                i
              )
            );
        }
        var Md = function e() {
          p(this, e);
        };
        function jd(e) {
          return new D(function (t) {
            return t.error(e);
          });
        }
        var Fd = (function () {
          function e(t, n, r, i, o, a) {
            p(this, e),
              (this.rootComponentType = t),
              (this.config = n),
              (this.urlTree = r),
              (this.url = i),
              (this.paramsInheritanceStrategy = o),
              (this.relativeLinkResolution = a);
          }
          return (
            g(e, [
              {
                key: "recognize",
                value: function () {
                  var e = yd(
                      this.urlTree.root,
                      [],
                      [],
                      this.config.filter(function (e) {
                        return void 0 === e.redirectTo;
                      }),
                      this.relativeLinkResolution
                    ).segmentGroup,
                    t = this.processSegmentGroup(this.config, e, "primary");
                  if (null === t) return null;
                  var n = new Mf(
                      [],
                      Object.freeze({}),
                      Object.freeze(
                        Object.assign({}, this.urlTree.queryParams)
                      ),
                      this.urlTree.fragment,
                      {},
                      "primary",
                      this.rootComponentType,
                      null,
                      this.urlTree.root,
                      -1,
                      {}
                    ),
                    r = new Af(n, t),
                    i = new jf(this.url, r);
                  return this.inheritParamsAndData(i._root), i;
                },
              },
              {
                key: "inheritParamsAndData",
                value: function (e) {
                  var t = this,
                    n = e.value,
                    r = Nf(n, this.paramsInheritanceStrategy);
                  (n.params = Object.freeze(r.params)),
                    (n.data = Object.freeze(r.data)),
                    e.children.forEach(function (e) {
                      return t.inheritParamsAndData(e);
                    });
                },
              },
              {
                key: "processSegmentGroup",
                value: function (e, t, n) {
                  return 0 === t.segments.length && t.hasChildren()
                    ? this.processChildren(e, t)
                    : this.processSegment(e, t, t.segments, n);
                },
              },
              {
                key: "processChildren",
                value: function (e, t) {
                  for (
                    var r = [], i = 0, o = Object.keys(t.children);
                    i < o.length;
                    i++
                  ) {
                    var a = o[i],
                      s = t.children[a],
                      u = pd(e, a),
                      l = this.processSegmentGroup(u, s, a);
                    if (null === l) return null;
                    r.push.apply(r, n(l));
                  }
                  var c = Ld(r);
                  return (
                    c.sort(function (e, t) {
                      return "primary" === e.value.outlet
                        ? -1
                        : "primary" === t.value.outlet
                        ? 1
                        : e.value.outlet.localeCompare(t.value.outlet);
                    }),
                    c
                  );
                },
              },
              {
                key: "processSegment",
                value: function (e, n, r, i) {
                  var o,
                    a = t(e);
                  try {
                    for (a.s(); !(o = a.n()).done; ) {
                      var s = o.value,
                        u = this.processSegmentAgainstRoute(s, n, r, i);
                      if (null !== u) return u;
                    }
                  } catch (l) {
                    a.e(l);
                  } finally {
                    a.f();
                  }
                  return wd(n, r, i) ? [] : null;
                },
              },
              {
                key: "processSegmentAgainstRoute",
                value: function (e, t, n, r) {
                  if (e.redirectTo || !bd(e, t, n, r)) return null;
                  var i,
                    o = [],
                    a = [];
                  if ("**" === e.path) {
                    var s = n.length > 0 ? Yh(n).parameters : {};
                    i = new Mf(
                      n,
                      s,
                      Object.freeze(
                        Object.assign({}, this.urlTree.queryParams)
                      ),
                      this.urlTree.fragment,
                      qd(e),
                      vd(e),
                      e.component,
                      e,
                      Wd(t),
                      zd(t) + n.length,
                      Hd(e)
                    );
                  } else {
                    var u = gd(t, e, n);
                    if (!u.matched) return null;
                    (o = u.consumedSegments),
                      (a = n.slice(u.lastChild)),
                      (i = new Mf(
                        o,
                        u.parameters,
                        Object.freeze(
                          Object.assign({}, this.urlTree.queryParams)
                        ),
                        this.urlTree.fragment,
                        qd(e),
                        vd(e),
                        e.component,
                        e,
                        Wd(t),
                        zd(t) + o.length,
                        Hd(e)
                      ));
                  }
                  var l = (function (e) {
                      return e.children
                        ? e.children
                        : e.loadChildren
                        ? e._loadedConfig.routes
                        : [];
                    })(e),
                    c = yd(
                      t,
                      o,
                      a,
                      l.filter(function (e) {
                        return void 0 === e.redirectTo;
                      }),
                      this.relativeLinkResolution
                    ),
                    h = c.segmentGroup,
                    f = c.slicedSegments;
                  if (0 === f.length && h.hasChildren()) {
                    var d = this.processChildren(l, h);
                    return null === d ? null : [new Af(i, d)];
                  }
                  if (0 === l.length && 0 === f.length) return [new Af(i, [])];
                  var v = vd(e) === r,
                    p = this.processSegment(l, h, f, v ? "primary" : r);
                  return null === p ? null : [new Af(i, p)];
                },
              },
            ]),
            e
          );
        })();
        function Ld(e) {
          var r,
            i = [],
            o = new Set(),
            a = t(e);
          try {
            var s = function () {
              var e,
                t = r.value;
              if (
                !(function (e) {
                  var t = e.value.routeConfig;
                  return t && "" === t.path && void 0 === t.redirectTo;
                })(t)
              )
                return i.push(t), "continue";
              var a = i.find(function (e) {
                return t.value.routeConfig === e.value.routeConfig;
              });
              void 0 !== a
                ? ((e = a.children).push.apply(e, n(t.children)), o.add(a))
                : i.push(t);
            };
            for (a.s(); !(r = a.n()).done; ) s();
          } catch (f) {
            a.e(f);
          } finally {
            a.f();
          }
          var u,
            l = t(o);
          try {
            for (l.s(); !(u = l.n()).done; ) {
              var c = u.value,
                h = Ld(c.children);
              i.push(new Af(c.value, h));
            }
          } catch (f) {
            l.e(f);
          } finally {
            l.f();
          }
          return i.filter(function (e) {
            return !o.has(e);
          });
        }
        function Wd(e) {
          for (var t = e; t._sourceSegment; ) t = t._sourceSegment;
          return t;
        }
        function zd(e) {
          for (
            var t = e, n = t._segmentIndexShift ? t._segmentIndexShift : 0;
            t._sourceSegment;

          )
            n += (t = t._sourceSegment)._segmentIndexShift
              ? t._segmentIndexShift
              : 0;
          return n - 1;
        }
        function qd(e) {
          return e.data || {};
        }
        function Hd(e) {
          return e.resolve || {};
        }
        function Ud(e) {
          return Pc(function (t) {
            var n = e(t);
            return n
              ? $(n).pipe(
                  H(function () {
                    return t;
                  })
                )
              : Ec(t);
          });
        }
        var Vd = (function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              return p(this, n), t.apply(this, arguments);
            }
            return n;
          })(
            (function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "shouldDetach",
                    value: function (e) {
                      return !1;
                    },
                  },
                  { key: "store", value: function (e, t) {} },
                  {
                    key: "shouldAttach",
                    value: function (e) {
                      return !1;
                    },
                  },
                  {
                    key: "retrieve",
                    value: function (e) {
                      return null;
                    },
                  },
                  {
                    key: "shouldReuseRoute",
                    value: function (e, t) {
                      return e.routeConfig === t.routeConfig;
                    },
                  },
                ]),
                e
              );
            })()
          ),
          Bd = new Jn("ROUTES"),
          Qd = (function () {
            function e(t, n, r, i) {
              p(this, e),
                (this.loader = t),
                (this.compiler = n),
                (this.onLoadStartListener = r),
                (this.onLoadEndListener = i);
            }
            return (
              g(e, [
                {
                  key: "load",
                  value: function (e, t) {
                    var n = this;
                    if (t._loader$) return t._loader$;
                    this.onLoadStartListener && this.onLoadStartListener(t);
                    var r = this.loadModuleFactory(t.loadChildren).pipe(
                      H(function (r) {
                        n.onLoadEndListener && n.onLoadEndListener(t);
                        var i = r.create(e);
                        return new rd(
                          $h(
                            i.injector.get(Bd, void 0, Me.Self | Me.Optional)
                          ).map(dd),
                          i
                        );
                      }),
                      sh(function (e) {
                        throw ((t._loader$ = void 0), e);
                      })
                    );
                    return (
                      (t._loader$ = new he(r, function () {
                        return new W();
                      }).pipe(se())),
                      t._loader$
                    );
                  },
                },
                {
                  key: "loadModuleFactory",
                  value: function (e) {
                    var t = this;
                    return "string" == typeof e
                      ? $(this.loader.load(e))
                      : ef(e()).pipe(
                          te(function (e) {
                            return e instanceof as
                              ? Ec(e)
                              : $(t.compiler.compileModuleAsync(e));
                          })
                        );
                  },
                },
              ]),
              e
            );
          })(),
          Gd = function e() {
            p(this, e),
              (this.outlet = null),
              (this.route = null),
              (this.resolver = null),
              (this.children = new Kd()),
              (this.attachRef = null);
          },
          Kd = (function () {
            function e() {
              p(this, e), (this.contexts = new Map());
            }
            return (
              g(e, [
                {
                  key: "onChildOutletCreated",
                  value: function (e, t) {
                    var n = this.getOrCreateContext(e);
                    (n.outlet = t), this.contexts.set(e, n);
                  },
                },
                {
                  key: "onChildOutletDestroyed",
                  value: function (e) {
                    var t = this.getContext(e);
                    t && (t.outlet = null);
                  },
                },
                {
                  key: "onOutletDeactivated",
                  value: function () {
                    var e = this.contexts;
                    return (this.contexts = new Map()), e;
                  },
                },
                {
                  key: "onOutletReAttached",
                  value: function (e) {
                    this.contexts = e;
                  },
                },
                {
                  key: "getOrCreateContext",
                  value: function (e) {
                    var t = this.getContext(e);
                    return t || ((t = new Gd()), this.contexts.set(e, t)), t;
                  },
                },
                {
                  key: "getContext",
                  value: function (e) {
                    return this.contexts.get(e) || null;
                  },
                },
              ]),
              e
            );
          })(),
          Zd = (function () {
            function e() {
              p(this, e);
            }
            return (
              g(e, [
                {
                  key: "shouldProcessUrl",
                  value: function (e) {
                    return !0;
                  },
                },
                {
                  key: "extract",
                  value: function (e) {
                    return e;
                  },
                },
                {
                  key: "merge",
                  value: function (e, t) {
                    return e;
                  },
                },
              ]),
              e
            );
          })();
        function Jd(e) {
          throw e;
        }
        function $d(e, t, n) {
          return t.parse("/");
        }
        function Yd(e, t) {
          return Ec(null);
        }
        var Xd,
          ev,
          tv,
          nv =
            (((tv = (function () {
              function e(t, n, r, i, o, a, s, u) {
                var l = this;
                p(this, e),
                  (this.rootComponentType = t),
                  (this.urlSerializer = n),
                  (this.rootContexts = r),
                  (this.location = i),
                  (this.config = u),
                  (this.lastSuccessfulNavigation = null),
                  (this.currentNavigation = null),
                  (this.disposed = !1),
                  (this.lastLocationChangeInfo = null),
                  (this.navigationId = 0),
                  (this.isNgZoneEnabled = !1),
                  (this.events = new W()),
                  (this.errorHandler = Jd),
                  (this.malformedUriErrorHandler = $d),
                  (this.navigated = !1),
                  (this.lastSuccessfulId = -1),
                  (this.hooks = {
                    beforePreactivation: Yd,
                    afterPreactivation: Yd,
                  }),
                  (this.urlHandlingStrategy = new Zd()),
                  (this.routeReuseStrategy = new Vd()),
                  (this.onSameUrlNavigation = "ignore"),
                  (this.paramsInheritanceStrategy = "emptyOnly"),
                  (this.urlUpdateStrategy = "deferred"),
                  (this.relativeLinkResolution = "corrected"),
                  (this.ngModule = o.get(os)),
                  (this.console = o.get(tu));
                var c = o.get(vu);
                (this.isNgZoneEnabled =
                  c instanceof vu && vu.isInAngularZone()),
                  this.resetConfig(u),
                  (this.currentUrlTree = new af(new sf([], {}), {}, null)),
                  (this.rawUrlTree = this.currentUrlTree),
                  (this.browserUrlTree = this.currentUrlTree),
                  (this.configLoader = new Qd(
                    a,
                    s,
                    function (e) {
                      return l.triggerEvent(new Lh(e));
                    },
                    function (e) {
                      return l.triggerEvent(new Wh(e));
                    }
                  )),
                  (this.routerState = Pf(
                    this.currentUrlTree,
                    this.rootComponentType
                  )),
                  (this.transitions = new qc({
                    id: 0,
                    currentUrlTree: this.currentUrlTree,
                    currentRawUrl: this.currentUrlTree,
                    extractedUrl: this.urlHandlingStrategy.extract(
                      this.currentUrlTree
                    ),
                    urlAfterRedirects: this.urlHandlingStrategy.extract(
                      this.currentUrlTree
                    ),
                    rawUrl: this.currentUrlTree,
                    extras: {},
                    resolve: null,
                    reject: null,
                    promise: Promise.resolve(!0),
                    source: "imperative",
                    restoredState: null,
                    currentSnapshot: this.routerState.snapshot,
                    targetSnapshot: null,
                    currentRouterState: this.routerState,
                    targetRouterState: null,
                    guards: { canActivateChecks: [], canDeactivateChecks: [] },
                    guardsResult: null,
                  })),
                  (this.navigations = this.setupNavigations(this.transitions)),
                  this.processNavigations();
              }
              return (
                g(e, [
                  {
                    key: "setupNavigations",
                    value: function (e) {
                      var t = this,
                        n = this.events;
                      return e.pipe(
                        ih(function (e) {
                          return 0 !== e.id;
                        }),
                        H(function (e) {
                          return Object.assign(Object.assign({}, e), {
                            extractedUrl: t.urlHandlingStrategy.extract(
                              e.rawUrl
                            ),
                          });
                        }),
                        Pc(function (e) {
                          var r,
                            i,
                            o,
                            a,
                            s = !1,
                            u = !1;
                          return Ec(e).pipe(
                            Sh(function (e) {
                              t.currentNavigation = {
                                id: e.id,
                                initialUrl: e.currentRawUrl,
                                extractedUrl: e.extractedUrl,
                                trigger: e.source,
                                extras: e.extras,
                                previousNavigation: t.lastSuccessfulNavigation
                                  ? Object.assign(
                                      Object.assign(
                                        {},
                                        t.lastSuccessfulNavigation
                                      ),
                                      { previousNavigation: null }
                                    )
                                  : null,
                              };
                            }),
                            Pc(function (e) {
                              var r,
                                i,
                                o,
                                a,
                                s =
                                  !t.navigated ||
                                  e.extractedUrl.toString() !==
                                    t.browserUrlTree.toString();
                              if (
                                ("reload" === t.onSameUrlNavigation || s) &&
                                t.urlHandlingStrategy.shouldProcessUrl(e.rawUrl)
                              )
                                return Ec(e).pipe(
                                  Pc(function (e) {
                                    var r = t.transitions.getValue();
                                    return (
                                      n.next(
                                        new Ah(
                                          e.id,
                                          t.serializeUrl(e.extractedUrl),
                                          e.source,
                                          e.restoredState
                                        )
                                      ),
                                      r !== t.transitions.getValue()
                                        ? Sc
                                        : Promise.resolve(e)
                                    );
                                  }),
                                  ((r = t.ngModule.injector),
                                  (i = t.configLoader),
                                  (o = t.urlSerializer),
                                  (a = t.config),
                                  Pc(function (e) {
                                    return (function (e, t, n, r, i) {
                                      return new xd(e, t, n, r, i).apply();
                                    })(r, i, o, e.extractedUrl, a).pipe(
                                      H(function (t) {
                                        return Object.assign(
                                          Object.assign({}, e),
                                          { urlAfterRedirects: t }
                                        );
                                      })
                                    );
                                  })),
                                  Sh(function (e) {
                                    t.currentNavigation = Object.assign(
                                      Object.assign({}, t.currentNavigation),
                                      { finalUrl: e.urlAfterRedirects }
                                    );
                                  }),
                                  (function (e, n, r, i, o) {
                                    return te(function (r) {
                                      return (function (e, t, n, r) {
                                        var i =
                                            arguments.length > 4 &&
                                            void 0 !== arguments[4]
                                              ? arguments[4]
                                              : "emptyOnly",
                                          o =
                                            arguments.length > 5 &&
                                            void 0 !== arguments[5]
                                              ? arguments[5]
                                              : "legacy";
                                        try {
                                          var a = new Fd(
                                            e,
                                            t,
                                            n,
                                            r,
                                            i,
                                            o
                                          ).recognize();
                                          return null === a
                                            ? jd(new Md())
                                            : Ec(a);
                                        } catch (s) {
                                          return jd(s);
                                        }
                                      })(
                                        e,
                                        n,
                                        r.urlAfterRedirects,
                                        ((a = r.urlAfterRedirects),
                                        t.serializeUrl(a)),
                                        i,
                                        o
                                      ).pipe(
                                        H(function (e) {
                                          return Object.assign(
                                            Object.assign({}, r),
                                            { targetSnapshot: e }
                                          );
                                        })
                                      );
                                      var a;
                                    });
                                  })(
                                    t.rootComponentType,
                                    t.config,
                                    0,
                                    t.paramsInheritanceStrategy,
                                    t.relativeLinkResolution
                                  ),
                                  Sh(function (e) {
                                    "eager" === t.urlUpdateStrategy &&
                                      (e.extras.skipLocationChange ||
                                        t.setBrowserUrl(
                                          e.urlAfterRedirects,
                                          !!e.extras.replaceUrl,
                                          e.id,
                                          e.extras.state
                                        ),
                                      (t.browserUrlTree = e.urlAfterRedirects));
                                    var r = new Dh(
                                      e.id,
                                      t.serializeUrl(e.extractedUrl),
                                      t.serializeUrl(e.urlAfterRedirects),
                                      e.targetSnapshot
                                    );
                                    n.next(r);
                                  })
                                );
                              if (
                                s &&
                                t.rawUrlTree &&
                                t.urlHandlingStrategy.shouldProcessUrl(
                                  t.rawUrlTree
                                )
                              ) {
                                var u = e.id,
                                  l = e.extractedUrl,
                                  c = e.source,
                                  h = e.restoredState,
                                  f = e.extras,
                                  d = new Ah(u, t.serializeUrl(l), c, h);
                                n.next(d);
                                var v = Pf(l, t.rootComponentType).snapshot;
                                return Ec(
                                  Object.assign(Object.assign({}, e), {
                                    targetSnapshot: v,
                                    urlAfterRedirects: l,
                                    extras: Object.assign(
                                      Object.assign({}, f),
                                      { skipLocationChange: !1, replaceUrl: !1 }
                                    ),
                                  })
                                );
                              }
                              return (
                                (t.rawUrlTree = e.rawUrl),
                                (t.browserUrlTree = e.urlAfterRedirects),
                                e.resolve(null),
                                Sc
                              );
                            }),
                            Ud(function (e) {
                              var n = e.targetSnapshot,
                                r = e.id,
                                i = e.extractedUrl,
                                o = e.rawUrl,
                                a = e.extras,
                                s = a.skipLocationChange,
                                u = a.replaceUrl;
                              return t.hooks.beforePreactivation(n, {
                                navigationId: r,
                                appliedUrlTree: i,
                                rawUrlTree: o,
                                skipLocationChange: !!s,
                                replaceUrl: !!u,
                              });
                            }),
                            Sh(function (e) {
                              var n = new Nh(
                                e.id,
                                t.serializeUrl(e.extractedUrl),
                                t.serializeUrl(e.urlAfterRedirects),
                                e.targetSnapshot
                              );
                              t.triggerEvent(n);
                            }),
                            H(function (e) {
                              return Object.assign(Object.assign({}, e), {
                                guards: Rd(
                                  e.targetSnapshot,
                                  e.currentSnapshot,
                                  t.rootContexts
                                ),
                              });
                            }),
                            (function (e, t) {
                              return te(function (n) {
                                var r = n.targetSnapshot,
                                  i = n.currentSnapshot,
                                  o = n.guards,
                                  a = o.canActivateChecks,
                                  s = o.canDeactivateChecks;
                                return 0 === s.length && 0 === a.length
                                  ? Ec(
                                      Object.assign(Object.assign({}, n), {
                                        guardsResult: !0,
                                      })
                                    )
                                  : (function (e, t, n, r) {
                                      return $(e).pipe(
                                        te(function (e) {
                                          return (function (e, t, n, r, i) {
                                            var o =
                                              t && t.routeConfig
                                                ? t.routeConfig.canDeactivate
                                                : null;
                                            return o && 0 !== o.length
                                              ? Ec(
                                                  o.map(function (o) {
                                                    var a,
                                                      s = Pd(o, t, i);
                                                    if (
                                                      (function (e) {
                                                        return (
                                                          e &&
                                                          id(e.canDeactivate)
                                                        );
                                                      })(s)
                                                    )
                                                      a = ef(
                                                        s.canDeactivate(
                                                          e,
                                                          t,
                                                          n,
                                                          r
                                                        )
                                                      );
                                                    else {
                                                      if (!id(s))
                                                        throw new Error(
                                                          "Invalid CanDeactivate guard"
                                                        );
                                                      a = ef(s(e, t, n, r));
                                                    }
                                                    return a.pipe(wh());
                                                  })
                                                ).pipe(sd())
                                              : Ec(!0);
                                          })(e.component, e.route, n, t, r);
                                        }),
                                        wh(function (e) {
                                          return !0 !== e;
                                        }, !0)
                                      );
                                    })(s, r, i, e).pipe(
                                      te(function (n) {
                                        return n && "boolean" == typeof n
                                          ? (function (e, t, n, r) {
                                              return $(t).pipe(
                                                ch(function (t) {
                                                  return Zc(
                                                    (function (e, t) {
                                                      return (
                                                        null !== e &&
                                                          t &&
                                                          t(new zh(e)),
                                                        Ec(!0)
                                                      );
                                                    })(t.route.parent, r),
                                                    (function (e, t) {
                                                      return (
                                                        null !== e &&
                                                          t &&
                                                          t(new Hh(e)),
                                                        Ec(!0)
                                                      );
                                                    })(t.route, r),
                                                    (function (e, t, n) {
                                                      var r = t[t.length - 1],
                                                        i = t
                                                          .slice(
                                                            0,
                                                            t.length - 1
                                                          )
                                                          .reverse()
                                                          .map(function (e) {
                                                            return (function (
                                                              e
                                                            ) {
                                                              var t =
                                                                e.routeConfig
                                                                  ? e
                                                                      .routeConfig
                                                                      .canActivateChild
                                                                  : null;
                                                              return t &&
                                                                0 !== t.length
                                                                ? {
                                                                    node: e,
                                                                    guards: t,
                                                                  }
                                                                : null;
                                                            })(e);
                                                          })
                                                          .filter(function (e) {
                                                            return null !== e;
                                                          })
                                                          .map(function (t) {
                                                            return Jc(
                                                              function () {
                                                                return Ec(
                                                                  t.guards.map(
                                                                    function (
                                                                      i
                                                                    ) {
                                                                      var o,
                                                                        a = Pd(
                                                                          i,
                                                                          t.node,
                                                                          n
                                                                        );
                                                                      if (
                                                                        (function (
                                                                          e
                                                                        ) {
                                                                          return (
                                                                            e &&
                                                                            id(
                                                                              e.canActivateChild
                                                                            )
                                                                          );
                                                                        })(a)
                                                                      )
                                                                        o = ef(
                                                                          a.canActivateChild(
                                                                            r,
                                                                            e
                                                                          )
                                                                        );
                                                                      else {
                                                                        if (
                                                                          !id(a)
                                                                        )
                                                                          throw new Error(
                                                                            "Invalid CanActivateChild guard"
                                                                          );
                                                                        o = ef(
                                                                          a(
                                                                            r,
                                                                            e
                                                                          )
                                                                        );
                                                                      }
                                                                      return o.pipe(
                                                                        wh()
                                                                      );
                                                                    }
                                                                  )
                                                                ).pipe(sd());
                                                              }
                                                            );
                                                          });
                                                      return Ec(i).pipe(sd());
                                                    })(e, t.path, n),
                                                    (function (e, t, n) {
                                                      var r = t.routeConfig
                                                        ? t.routeConfig
                                                            .canActivate
                                                        : null;
                                                      return r && 0 !== r.length
                                                        ? Ec(
                                                            r.map(function (r) {
                                                              return Jc(
                                                                function () {
                                                                  var i,
                                                                    o = Pd(
                                                                      r,
                                                                      t,
                                                                      n
                                                                    );
                                                                  if (
                                                                    (function (
                                                                      e
                                                                    ) {
                                                                      return (
                                                                        e &&
                                                                        id(
                                                                          e.canActivate
                                                                        )
                                                                      );
                                                                    })(o)
                                                                  )
                                                                    i = ef(
                                                                      o.canActivate(
                                                                        t,
                                                                        e
                                                                      )
                                                                    );
                                                                  else {
                                                                    if (!id(o))
                                                                      throw new Error(
                                                                        "Invalid CanActivate guard"
                                                                      );
                                                                    i = ef(
                                                                      o(t, e)
                                                                    );
                                                                  }
                                                                  return i.pipe(
                                                                    wh()
                                                                  );
                                                                }
                                                              );
                                                            })
                                                          ).pipe(sd())
                                                        : Ec(!0);
                                                    })(e, t.route, n)
                                                  );
                                                }),
                                                wh(function (e) {
                                                  return !0 !== e;
                                                }, !0)
                                              );
                                            })(r, a, e, t)
                                          : Ec(n);
                                      }),
                                      H(function (e) {
                                        return Object.assign(
                                          Object.assign({}, n),
                                          { guardsResult: e }
                                        );
                                      })
                                    );
                              });
                            })(t.ngModule.injector, function (e) {
                              return t.triggerEvent(e);
                            }),
                            Sh(function (e) {
                              if (od(e.guardsResult)) {
                                var n = Gh(
                                  'Redirecting to "'.concat(
                                    t.serializeUrl(e.guardsResult),
                                    '"'
                                  )
                                );
                                throw ((n.url = e.guardsResult), n);
                              }
                              var r = new Mh(
                                e.id,
                                t.serializeUrl(e.extractedUrl),
                                t.serializeUrl(e.urlAfterRedirects),
                                e.targetSnapshot,
                                !!e.guardsResult
                              );
                              t.triggerEvent(r);
                            }),
                            ih(function (e) {
                              if (!e.guardsResult) {
                                t.resetUrlToCurrentUrlTree();
                                var r = new Rh(
                                  e.id,
                                  t.serializeUrl(e.extractedUrl),
                                  ""
                                );
                                return n.next(r), e.resolve(!1), !1;
                              }
                              return !0;
                            }),
                            Ud(function (e) {
                              if (e.guards.canActivateChecks.length)
                                return Ec(e).pipe(
                                  Sh(function (e) {
                                    var n = new jh(
                                      e.id,
                                      t.serializeUrl(e.extractedUrl),
                                      t.serializeUrl(e.urlAfterRedirects),
                                      e.targetSnapshot
                                    );
                                    t.triggerEvent(n);
                                  }),
                                  Pc(function (e) {
                                    var r,
                                      i,
                                      o = !1;
                                    return Ec(e).pipe(
                                      ((r = t.paramsInheritanceStrategy),
                                      (i = t.ngModule.injector),
                                      te(function (e) {
                                        var t = e.targetSnapshot,
                                          n = e.guards.canActivateChecks;
                                        if (!n.length) return Ec(e);
                                        var o = 0;
                                        return $(n).pipe(
                                          ch(function (e) {
                                            return (function (e, t, n, r) {
                                              return (function (e, t, n, r) {
                                                var i = Object.keys(e);
                                                if (0 === i.length)
                                                  return Ec({});
                                                var o = {};
                                                return $(i).pipe(
                                                  te(function (i) {
                                                    return (function (
                                                      e,
                                                      t,
                                                      n,
                                                      r
                                                    ) {
                                                      var i = Pd(e, t, r);
                                                      return ef(
                                                        i.resolve
                                                          ? i.resolve(t, n)
                                                          : i(t, n)
                                                      );
                                                    })(e[i], t, n, r).pipe(
                                                      Sh(function (e) {
                                                        o[i] = e;
                                                      })
                                                    );
                                                  }),
                                                  hh(1),
                                                  te(function () {
                                                    return Object.keys(o)
                                                      .length === i.length
                                                      ? Ec(o)
                                                      : Sc;
                                                  })
                                                );
                                              })(e._resolve, e, t, r).pipe(
                                                H(function (t) {
                                                  return (
                                                    (e._resolvedData = t),
                                                    (e.data = Object.assign(
                                                      Object.assign({}, e.data),
                                                      Nf(e, n).resolve
                                                    )),
                                                    null
                                                  );
                                                })
                                              );
                                            })(e.route, t, r, i);
                                          }),
                                          Sh(function () {
                                            return o++;
                                          }),
                                          hh(1),
                                          te(function (t) {
                                            return o === n.length ? Ec(e) : Sc;
                                          })
                                        );
                                      })),
                                      Sh({
                                        next: function () {
                                          return (o = !0);
                                        },
                                        complete: function () {
                                          if (!o) {
                                            var r = new Rh(
                                              e.id,
                                              t.serializeUrl(e.extractedUrl),
                                              "At least one route resolver didn't emit any value."
                                            );
                                            n.next(r), e.resolve(!1);
                                          }
                                        },
                                      })
                                    );
                                  }),
                                  Sh(function (e) {
                                    var n = new Fh(
                                      e.id,
                                      t.serializeUrl(e.extractedUrl),
                                      t.serializeUrl(e.urlAfterRedirects),
                                      e.targetSnapshot
                                    );
                                    t.triggerEvent(n);
                                  })
                                );
                            }),
                            Ud(function (e) {
                              var n = e.targetSnapshot,
                                r = e.id,
                                i = e.extractedUrl,
                                o = e.rawUrl,
                                a = e.extras,
                                s = a.skipLocationChange,
                                u = a.replaceUrl;
                              return t.hooks.afterPreactivation(n, {
                                navigationId: r,
                                appliedUrlTree: i,
                                rawUrlTree: o,
                                skipLocationChange: !!s,
                                replaceUrl: !!u,
                              });
                            }),
                            H(function (e) {
                              var n = (function (e, t, n) {
                                var r = qf(e, t._root, n ? n._root : void 0);
                                return new Rf(r, t);
                              })(
                                t.routeReuseStrategy,
                                e.targetSnapshot,
                                e.currentRouterState
                              );
                              return Object.assign(Object.assign({}, e), {
                                targetRouterState: n,
                              });
                            }),
                            Sh(function (e) {
                              (t.currentUrlTree = e.urlAfterRedirects),
                                (t.rawUrlTree = t.urlHandlingStrategy.merge(
                                  t.currentUrlTree,
                                  e.rawUrl
                                )),
                                (t.routerState = e.targetRouterState),
                                "deferred" === t.urlUpdateStrategy &&
                                  (e.extras.skipLocationChange ||
                                    t.setBrowserUrl(
                                      t.rawUrlTree,
                                      !!e.extras.replaceUrl,
                                      e.id,
                                      e.extras.state
                                    ),
                                  (t.browserUrlTree = e.urlAfterRedirects));
                            }),
                            ((i = t.rootContexts),
                            (o = t.routeReuseStrategy),
                            (a = function (e) {
                              return t.triggerEvent(e);
                            }),
                            H(function (e) {
                              return (
                                new td(
                                  o,
                                  e.targetRouterState,
                                  e.currentRouterState,
                                  a
                                ).activate(i),
                                e
                              );
                            })),
                            Sh({
                              next: function () {
                                s = !0;
                              },
                              complete: function () {
                                s = !0;
                              },
                            }),
                            ((r = function () {
                              if (!s && !u) {
                                t.resetUrlToCurrentUrlTree();
                                var r = new Rh(
                                  e.id,
                                  t.serializeUrl(e.extractedUrl),
                                  "Navigation ID "
                                    .concat(
                                      e.id,
                                      " is not equal to the current navigation id "
                                    )
                                    .concat(t.navigationId)
                                );
                                n.next(r), e.resolve(!1);
                              }
                              t.currentNavigation = null;
                            }),
                            function (e) {
                              return e.lift(new Th(r));
                            }),
                            sh(function (r) {
                              if (
                                ((u = !0),
                                (s = r) && s.ngNavigationCancelingError)
                              ) {
                                var i = od(r.url);
                                i ||
                                  ((t.navigated = !0),
                                  t.resetStateAndUrl(
                                    e.currentRouterState,
                                    e.currentUrlTree,
                                    e.rawUrl
                                  ));
                                var o = new Rh(
                                  e.id,
                                  t.serializeUrl(e.extractedUrl),
                                  r.message
                                );
                                n.next(o),
                                  i
                                    ? setTimeout(function () {
                                        var n = t.urlHandlingStrategy.merge(
                                          r.url,
                                          t.rawUrlTree
                                        );
                                        t.scheduleNavigation(
                                          n,
                                          "imperative",
                                          null,
                                          {
                                            skipLocationChange:
                                              e.extras.skipLocationChange,
                                            replaceUrl:
                                              "eager" === t.urlUpdateStrategy,
                                          },
                                          {
                                            resolve: e.resolve,
                                            reject: e.reject,
                                            promise: e.promise,
                                          }
                                        );
                                      }, 0)
                                    : e.resolve(!1);
                              } else {
                                t.resetStateAndUrl(
                                  e.currentRouterState,
                                  e.currentUrlTree,
                                  e.rawUrl
                                );
                                var a = new Ph(
                                  e.id,
                                  t.serializeUrl(e.extractedUrl),
                                  r
                                );
                                n.next(a);
                                try {
                                  e.resolve(t.errorHandler(r));
                                } catch (l) {
                                  e.reject(l);
                                }
                              }
                              var s;
                              return Sc;
                            })
                          );
                        })
                      );
                    },
                  },
                  {
                    key: "resetRootComponentType",
                    value: function (e) {
                      (this.rootComponentType = e),
                        (this.routerState.root.component =
                          this.rootComponentType);
                    },
                  },
                  {
                    key: "getTransition",
                    value: function () {
                      var e = this.transitions.value;
                      return (e.urlAfterRedirects = this.browserUrlTree), e;
                    },
                  },
                  {
                    key: "setTransition",
                    value: function (e) {
                      this.transitions.next(
                        Object.assign(
                          Object.assign({}, this.getTransition()),
                          e
                        )
                      );
                    },
                  },
                  {
                    key: "initialNavigation",
                    value: function () {
                      this.setUpLocationChangeListener(),
                        0 === this.navigationId &&
                          this.navigateByUrl(this.location.path(!0), {
                            replaceUrl: !0,
                          });
                    },
                  },
                  {
                    key: "setUpLocationChangeListener",
                    value: function () {
                      var e = this;
                      this.locationSubscription ||
                        (this.locationSubscription = this.location.subscribe(
                          function (t) {
                            var n = e.extractLocationChangeInfoFromEvent(t);
                            e.shouldScheduleNavigation(
                              e.lastLocationChangeInfo,
                              n
                            ) &&
                              setTimeout(function () {
                                var t = n.source,
                                  r = n.state,
                                  i = n.urlTree,
                                  o = { replaceUrl: !0 };
                                if (r) {
                                  var a = Object.assign({}, r);
                                  delete a.navigationId,
                                    0 !== Object.keys(a).length &&
                                      (o.state = a);
                                }
                                e.scheduleNavigation(i, t, r, o);
                              }, 0),
                              (e.lastLocationChangeInfo = n);
                          }
                        ));
                    },
                  },
                  {
                    key: "extractLocationChangeInfoFromEvent",
                    value: function (e) {
                      var t;
                      return {
                        source:
                          "popstate" === e.type ? "popstate" : "hashchange",
                        urlTree: this.parseUrl(e.url),
                        state: (
                          null === (t = e.state) || void 0 === t
                            ? void 0
                            : t.navigationId
                        )
                          ? e.state
                          : null,
                        transitionId: this.getTransition().id,
                      };
                    },
                  },
                  {
                    key: "shouldScheduleNavigation",
                    value: function (e, t) {
                      if (!e) return !0;
                      var n = t.urlTree.toString() === e.urlTree.toString();
                      return !(
                        t.transitionId === e.transitionId &&
                        n &&
                        (("hashchange" === t.source &&
                          "popstate" === e.source) ||
                          ("popstate" === t.source &&
                            "hashchange" === e.source))
                      );
                    },
                  },
                  {
                    key: "url",
                    get: function () {
                      return this.serializeUrl(this.currentUrlTree);
                    },
                  },
                  {
                    key: "getCurrentNavigation",
                    value: function () {
                      return this.currentNavigation;
                    },
                  },
                  {
                    key: "triggerEvent",
                    value: function (e) {
                      this.events.next(e);
                    },
                  },
                  {
                    key: "resetConfig",
                    value: function (e) {
                      cd(e),
                        (this.config = e.map(dd)),
                        (this.navigated = !1),
                        (this.lastSuccessfulId = -1);
                    },
                  },
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      this.dispose();
                    },
                  },
                  {
                    key: "dispose",
                    value: function () {
                      this.transitions.complete(),
                        this.locationSubscription &&
                          (this.locationSubscription.unsubscribe(),
                          (this.locationSubscription = void 0)),
                        (this.disposed = !0);
                    },
                  },
                  {
                    key: "createUrlTree",
                    value: function (e) {
                      var t =
                          arguments.length > 1 && void 0 !== arguments[1]
                            ? arguments[1]
                            : {},
                        r = t.relativeTo,
                        i = t.queryParams,
                        o = t.fragment,
                        a = t.queryParamsHandling,
                        s = t.preserveFragment,
                        u = r || this.routerState.root,
                        l = s ? this.currentUrlTree.fragment : o,
                        c = null;
                      switch (a) {
                        case "merge":
                          c = Object.assign(
                            Object.assign({}, this.currentUrlTree.queryParams),
                            i
                          );
                          break;
                        case "preserve":
                          c = this.currentUrlTree.queryParams;
                          break;
                        default:
                          c = i || null;
                      }
                      return (
                        null !== c && (c = this.removeEmptyProps(c)),
                        (function (e, t, r, i, o) {
                          if (0 === r.length)
                            return Bf(t.root, t.root, t, i, o);
                          var a = (function (e) {
                            if (
                              "string" == typeof e[0] &&
                              1 === e.length &&
                              "/" === e[0]
                            )
                              return new Gf(!0, 0, e);
                            var t = 0,
                              r = !1,
                              i = e.reduce(function (e, i, o) {
                                if ("object" == typeof i && null != i) {
                                  if (i.outlets) {
                                    var a = {};
                                    return (
                                      Xh(i.outlets, function (e, t) {
                                        a[t] =
                                          "string" == typeof e
                                            ? e.split("/")
                                            : e;
                                      }),
                                      [].concat(n(e), [{ outlets: a }])
                                    );
                                  }
                                  if (i.segmentPath)
                                    return [].concat(n(e), [i.segmentPath]);
                                }
                                return "string" != typeof i
                                  ? [].concat(n(e), [i])
                                  : 0 === o
                                  ? (i.split("/").forEach(function (n, i) {
                                      (0 == i && "." === n) ||
                                        (0 == i && "" === n
                                          ? (r = !0)
                                          : ".." === n
                                          ? t++
                                          : "" != n && e.push(n));
                                    }),
                                    e)
                                  : [].concat(n(e), [i]);
                              }, []);
                            return new Gf(r, t, i);
                          })(r);
                          if (a.toRoot())
                            return Bf(t.root, new sf([], {}), t, i, o);
                          var s = (function (e, t, n) {
                              if (e.isAbsolute) return new Kf(t.root, !0, 0);
                              if (-1 === n.snapshot._lastPathIndex) {
                                var r = n.snapshot._urlSegment;
                                return new Kf(r, r === t.root, 0);
                              }
                              var i = Uf(e.commands[0]) ? 0 : 1;
                              return (function (e, t, n) {
                                for (var r = e, i = t, o = n; o > i; ) {
                                  if (((o -= i), !(r = r.parent)))
                                    throw new Error("Invalid number of '../'");
                                  i = r.segments.length;
                                }
                                return new Kf(r, !1, i - o);
                              })(
                                n.snapshot._urlSegment,
                                n.snapshot._lastPathIndex + i,
                                e.numberOfDoubleDots
                              );
                            })(a, t, e),
                            u = s.processChildren
                              ? Jf(s.segmentGroup, s.index, a.commands)
                              : Zf(s.segmentGroup, s.index, a.commands);
                          return Bf(s.segmentGroup, u, t, i, o);
                        })(u, this.currentUrlTree, e, c, l)
                      );
                    },
                  },
                  {
                    key: "navigateByUrl",
                    value: function (e) {
                      var t =
                          arguments.length > 1 && void 0 !== arguments[1]
                            ? arguments[1]
                            : { skipLocationChange: !1 },
                        n = od(e) ? e : this.parseUrl(e),
                        r = this.urlHandlingStrategy.merge(n, this.rawUrlTree);
                      return this.scheduleNavigation(r, "imperative", null, t);
                    },
                  },
                  {
                    key: "navigate",
                    value: function (e) {
                      var t =
                        arguments.length > 1 && void 0 !== arguments[1]
                          ? arguments[1]
                          : { skipLocationChange: !1 };
                      return (
                        (function (e) {
                          for (var t = 0; t < e.length; t++) {
                            var n = e[t];
                            if (null == n)
                              throw new Error(
                                "The requested path contains "
                                  .concat(n, " segment at index ")
                                  .concat(t)
                              );
                          }
                        })(e),
                        this.navigateByUrl(this.createUrlTree(e, t), t)
                      );
                    },
                  },
                  {
                    key: "serializeUrl",
                    value: function (e) {
                      return this.urlSerializer.serialize(e);
                    },
                  },
                  {
                    key: "parseUrl",
                    value: function (e) {
                      var t;
                      try {
                        t = this.urlSerializer.parse(e);
                      } catch (n) {
                        t = this.malformedUriErrorHandler(
                          n,
                          this.urlSerializer,
                          e
                        );
                      }
                      return t;
                    },
                  },
                  {
                    key: "isActive",
                    value: function (e, t) {
                      if (od(e)) return tf(this.currentUrlTree, e, t);
                      var n = this.parseUrl(e);
                      return tf(this.currentUrlTree, n, t);
                    },
                  },
                  {
                    key: "removeEmptyProps",
                    value: function (e) {
                      return Object.keys(e).reduce(function (t, n) {
                        var r = e[n];
                        return null != r && (t[n] = r), t;
                      }, {});
                    },
                  },
                  {
                    key: "processNavigations",
                    value: function () {
                      var e = this;
                      this.navigations.subscribe(
                        function (t) {
                          (e.navigated = !0),
                            (e.lastSuccessfulId = t.id),
                            e.events.next(
                              new Oh(
                                t.id,
                                e.serializeUrl(t.extractedUrl),
                                e.serializeUrl(e.currentUrlTree)
                              )
                            ),
                            (e.lastSuccessfulNavigation = e.currentNavigation),
                            t.resolve(!0);
                        },
                        function (t) {
                          e.console.warn("Unhandled Navigation Error: ");
                        }
                      );
                    },
                  },
                  {
                    key: "scheduleNavigation",
                    value: function (e, t, n, r, i) {
                      if (this.disposed) return Promise.resolve(!1);
                      var o,
                        a,
                        s,
                        u = this.getTransition(),
                        l =
                          "imperative" !== t &&
                          "imperative" === (null == u ? void 0 : u.source),
                        c =
                          (this.lastSuccessfulId === u.id ||
                          this.currentNavigation
                            ? u.rawUrl
                            : u.urlAfterRedirects
                          ).toString() === e.toString();
                      if (l && c) return Promise.resolve(!0);
                      i
                        ? ((o = i.resolve), (a = i.reject), (s = i.promise))
                        : (s = new Promise(function (e, t) {
                            (o = e), (a = t);
                          }));
                      var h = ++this.navigationId;
                      return (
                        this.setTransition({
                          id: h,
                          source: t,
                          restoredState: n,
                          currentUrlTree: this.currentUrlTree,
                          currentRawUrl: this.rawUrlTree,
                          rawUrl: e,
                          extras: r,
                          resolve: o,
                          reject: a,
                          promise: s,
                          currentSnapshot: this.routerState.snapshot,
                          currentRouterState: this.routerState,
                        }),
                        s.catch(function (e) {
                          return Promise.reject(e);
                        })
                      );
                    },
                  },
                  {
                    key: "setBrowserUrl",
                    value: function (e, t, n, r) {
                      var i = this.urlSerializer.serialize(e);
                      (r = r || {}),
                        this.location.isCurrentPathEqualTo(i) || t
                          ? this.location.replaceState(
                              i,
                              "",
                              Object.assign(Object.assign({}, r), {
                                navigationId: n,
                              })
                            )
                          : this.location.go(
                              i,
                              "",
                              Object.assign(Object.assign({}, r), {
                                navigationId: n,
                              })
                            );
                    },
                  },
                  {
                    key: "resetStateAndUrl",
                    value: function (e, t, n) {
                      (this.routerState = e),
                        (this.currentUrlTree = t),
                        (this.rawUrlTree = this.urlHandlingStrategy.merge(
                          this.currentUrlTree,
                          n
                        )),
                        this.resetUrlToCurrentUrlTree();
                    },
                  },
                  {
                    key: "resetUrlToCurrentUrlTree",
                    value: function () {
                      this.location.replaceState(
                        this.urlSerializer.serialize(this.rawUrlTree),
                        "",
                        { navigationId: this.lastSuccessfulId }
                      );
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || tv)(
                fr(Yn),
                fr(cf),
                fr(Kd),
                fr(dl),
                fr(Io),
                fr(ju),
                fr(hu),
                fr(void 0)
              );
            }),
            (tv.ɵprov = Ee({ token: tv, factory: tv.ɵfac })),
            tv),
          rv =
            (((ev = (function () {
              function e(t, n, r, i, o) {
                p(this, e),
                  (this.router = t),
                  (this.route = n),
                  (this.commands = []),
                  (this.onChanges = new W()),
                  null == r && i.setAttribute(o.nativeElement, "tabindex", "0");
              }
              return (
                g(e, [
                  {
                    key: "ngOnChanges",
                    value: function (e) {
                      this.onChanges.next(this);
                    },
                  },
                  {
                    key: "routerLink",
                    set: function (e) {
                      this.commands =
                        null != e ? (Array.isArray(e) ? e : [e]) : [];
                    },
                  },
                  {
                    key: "onClick",
                    value: function () {
                      var e = {
                        skipLocationChange: ov(this.skipLocationChange),
                        replaceUrl: ov(this.replaceUrl),
                        state: this.state,
                      };
                      return this.router.navigateByUrl(this.urlTree, e), !0;
                    },
                  },
                  {
                    key: "urlTree",
                    get: function () {
                      return this.router.createUrlTree(this.commands, {
                        relativeTo:
                          void 0 !== this.relativeTo
                            ? this.relativeTo
                            : this.route,
                        queryParams: this.queryParams,
                        fragment: this.fragment,
                        queryParamsHandling: this.queryParamsHandling,
                        preserveFragment: ov(this.preserveFragment),
                      });
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || ev)(
                Wo(nv),
                Wo(Df),
                Kn("tabindex"),
                Wo(Ia),
                Wo(Ea)
              );
            }),
            (ev.ɵdir = lt({
              type: ev,
              selectors: [["", "routerLink", "", 5, "a", 5, "area"]],
              hostBindings: function (e, t) {
                1 & e &&
                  Go("click", function () {
                    return t.onClick();
                  });
              },
              inputs: {
                routerLink: "routerLink",
                queryParams: "queryParams",
                fragment: "fragment",
                queryParamsHandling: "queryParamsHandling",
                preserveFragment: "preserveFragment",
                skipLocationChange: "skipLocationChange",
                replaceUrl: "replaceUrl",
                state: "state",
                relativeTo: "relativeTo",
              },
              features: [wt],
            })),
            ev),
          iv =
            (((Xd = (function () {
              function e(t, n, r) {
                var i = this;
                p(this, e),
                  (this.router = t),
                  (this.route = n),
                  (this.locationStrategy = r),
                  (this.commands = []),
                  (this.onChanges = new W()),
                  (this.subscription = t.events.subscribe(function (e) {
                    e instanceof Oh && i.updateTargetUrlAndHref();
                  }));
              }
              return (
                g(e, [
                  {
                    key: "routerLink",
                    set: function (e) {
                      this.commands =
                        null != e ? (Array.isArray(e) ? e : [e]) : [];
                    },
                  },
                  {
                    key: "ngOnChanges",
                    value: function (e) {
                      this.updateTargetUrlAndHref(), this.onChanges.next(this);
                    },
                  },
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      this.subscription.unsubscribe();
                    },
                  },
                  {
                    key: "onClick",
                    value: function (e, t, n, r, i) {
                      if (0 !== e || t || n || r || i) return !0;
                      if (
                        "string" == typeof this.target &&
                        "_self" != this.target
                      )
                        return !0;
                      var o = {
                        skipLocationChange: ov(this.skipLocationChange),
                        replaceUrl: ov(this.replaceUrl),
                        state: this.state,
                      };
                      return this.router.navigateByUrl(this.urlTree, o), !1;
                    },
                  },
                  {
                    key: "updateTargetUrlAndHref",
                    value: function () {
                      this.href = this.locationStrategy.prepareExternalUrl(
                        this.router.serializeUrl(this.urlTree)
                      );
                    },
                  },
                  {
                    key: "urlTree",
                    get: function () {
                      return this.router.createUrlTree(this.commands, {
                        relativeTo:
                          void 0 !== this.relativeTo
                            ? this.relativeTo
                            : this.route,
                        queryParams: this.queryParams,
                        fragment: this.fragment,
                        queryParamsHandling: this.queryParamsHandling,
                        preserveFragment: ov(this.preserveFragment),
                      });
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Xd)(Wo(nv), Wo(Df), Wo(ol));
            }),
            (Xd.ɵdir = lt({
              type: Xd,
              selectors: [
                ["a", "routerLink", ""],
                ["area", "routerLink", ""],
              ],
              hostVars: 2,
              hostBindings: function (e, t) {
                1 & e &&
                  Go("click", function (e) {
                    return t.onClick(
                      e.button,
                      e.ctrlKey,
                      e.shiftKey,
                      e.altKey,
                      e.metaKey
                    );
                  }),
                  2 & e && (fa("href", t.href, Sr), jo("target", t.target));
              },
              inputs: {
                routerLink: "routerLink",
                target: "target",
                queryParams: "queryParams",
                fragment: "fragment",
                queryParamsHandling: "queryParamsHandling",
                preserveFragment: "preserveFragment",
                skipLocationChange: "skipLocationChange",
                replaceUrl: "replaceUrl",
                state: "state",
                relativeTo: "relativeTo",
              },
              features: [wt],
            })),
            Xd);
        function ov(e) {
          return "" === e || !!e;
        }
        var av,
          sv,
          uv,
          lv,
          cv =
            (((sv = (function () {
              function e(t, n, r, i, o, a) {
                var s = this;
                p(this, e),
                  (this.router = t),
                  (this.element = n),
                  (this.renderer = r),
                  (this.cdr = i),
                  (this.link = o),
                  (this.linkWithHref = a),
                  (this.classes = []),
                  (this.isActive = !1),
                  (this.routerLinkActiveOptions = { exact: !1 }),
                  (this.routerEventsSubscription = t.events.subscribe(function (
                    e
                  ) {
                    e instanceof Oh && s.update();
                  }));
              }
              return (
                g(e, [
                  {
                    key: "ngAfterContentInit",
                    value: function () {
                      var e = this;
                      Ec(
                        this.links.changes,
                        this.linksWithHrefs.changes,
                        Ec(null)
                      )
                        .pipe(ie())
                        .subscribe(function (t) {
                          e.update(), e.subscribeToEachLinkOnChanges();
                        });
                    },
                  },
                  {
                    key: "subscribeToEachLinkOnChanges",
                    value: function () {
                      var e,
                        t = this;
                      null === (e = this.linkInputChangesSubscription) ||
                        void 0 === e ||
                        e.unsubscribe();
                      var r = []
                        .concat(
                          n(this.links.toArray()),
                          n(this.linksWithHrefs.toArray()),
                          [this.link, this.linkWithHref]
                        )
                        .filter(function (e) {
                          return !!e;
                        })
                        .map(function (e) {
                          return e.onChanges;
                        });
                      this.linkInputChangesSubscription = $(r)
                        .pipe(ie())
                        .subscribe(function (e) {
                          t.isActive !== t.isLinkActive(t.router)(e) &&
                            t.update();
                        });
                    },
                  },
                  {
                    key: "routerLinkActive",
                    set: function (e) {
                      var t = Array.isArray(e) ? e : e.split(" ");
                      this.classes = t.filter(function (e) {
                        return !!e;
                      });
                    },
                  },
                  {
                    key: "ngOnChanges",
                    value: function (e) {
                      this.update();
                    },
                  },
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      var e;
                      this.routerEventsSubscription.unsubscribe(),
                        null === (e = this.linkInputChangesSubscription) ||
                          void 0 === e ||
                          e.unsubscribe();
                    },
                  },
                  {
                    key: "update",
                    value: function () {
                      var e = this;
                      this.links &&
                        this.linksWithHrefs &&
                        this.router.navigated &&
                        Promise.resolve().then(function () {
                          var t = e.hasActiveLinks();
                          e.isActive !== t &&
                            ((e.isActive = t),
                            e.cdr.markForCheck(),
                            e.classes.forEach(function (n) {
                              t
                                ? e.renderer.addClass(
                                    e.element.nativeElement,
                                    n
                                  )
                                : e.renderer.removeClass(
                                    e.element.nativeElement,
                                    n
                                  );
                            }));
                        });
                    },
                  },
                  {
                    key: "isLinkActive",
                    value: function (e) {
                      var t = this;
                      return function (n) {
                        return e.isActive(
                          n.urlTree,
                          t.routerLinkActiveOptions.exact
                        );
                      };
                    },
                  },
                  {
                    key: "hasActiveLinks",
                    value: function () {
                      var e = this.isLinkActive(this.router);
                      return (
                        (this.link && e(this.link)) ||
                        (this.linkWithHref && e(this.linkWithHref)) ||
                        this.links.some(e) ||
                        this.linksWithHrefs.some(e)
                      );
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || sv)(
                Wo(nv),
                Wo(Ea),
                Wo(Ia),
                Wo($a),
                Wo(rv, 8),
                Wo(iv, 8)
              );
            }),
            (sv.ɵdir = lt({
              type: sv,
              selectors: [["", "routerLinkActive", ""]],
              contentQueries: function (e, t, n) {
                var r;
                1 & e && (qs(n, rv, 1), qs(n, iv, 1)),
                  2 & e &&
                    (Ws((r = Hs())) && (t.links = r),
                    Ws((r = Hs())) && (t.linksWithHrefs = r));
              },
              inputs: {
                routerLinkActiveOptions: "routerLinkActiveOptions",
                routerLinkActive: "routerLinkActive",
              },
              exportAs: ["routerLinkActive"],
              features: [wt],
            })),
            sv),
          hv =
            (((av = (function () {
              function e(t, n, r, i, o) {
                p(this, e),
                  (this.parentContexts = t),
                  (this.location = n),
                  (this.resolver = r),
                  (this.changeDetector = o),
                  (this.activated = null),
                  (this._activatedRoute = null),
                  (this.activateEvents = new xs()),
                  (this.deactivateEvents = new xs()),
                  (this.name = i || "primary"),
                  t.onChildOutletCreated(this.name, this);
              }
              return (
                g(e, [
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      this.parentContexts.onChildOutletDestroyed(this.name);
                    },
                  },
                  {
                    key: "ngOnInit",
                    value: function () {
                      if (!this.activated) {
                        var e = this.parentContexts.getContext(this.name);
                        e &&
                          e.route &&
                          (e.attachRef
                            ? this.attach(e.attachRef, e.route)
                            : this.activateWith(e.route, e.resolver || null));
                      }
                    },
                  },
                  {
                    key: "isActivated",
                    get: function () {
                      return !!this.activated;
                    },
                  },
                  {
                    key: "component",
                    get: function () {
                      if (!this.activated)
                        throw new Error("Outlet is not activated");
                      return this.activated.instance;
                    },
                  },
                  {
                    key: "activatedRoute",
                    get: function () {
                      if (!this.activated)
                        throw new Error("Outlet is not activated");
                      return this._activatedRoute;
                    },
                  },
                  {
                    key: "activatedRouteData",
                    get: function () {
                      return this._activatedRoute
                        ? this._activatedRoute.snapshot.data
                        : {};
                    },
                  },
                  {
                    key: "detach",
                    value: function () {
                      if (!this.activated)
                        throw new Error("Outlet is not activated");
                      this.location.detach();
                      var e = this.activated;
                      return (
                        (this.activated = null),
                        (this._activatedRoute = null),
                        e
                      );
                    },
                  },
                  {
                    key: "attach",
                    value: function (e, t) {
                      (this.activated = e),
                        (this._activatedRoute = t),
                        this.location.insert(e.hostView);
                    },
                  },
                  {
                    key: "deactivate",
                    value: function () {
                      if (this.activated) {
                        var e = this.component;
                        this.activated.destroy(),
                          (this.activated = null),
                          (this._activatedRoute = null),
                          this.deactivateEvents.emit(e);
                      }
                    },
                  },
                  {
                    key: "activateWith",
                    value: function (e, t) {
                      if (this.isActivated)
                        throw new Error(
                          "Cannot activate an already activated outlet"
                        );
                      this._activatedRoute = e;
                      var n = (t = t || this.resolver).resolveComponentFactory(
                          e._futureSnapshot.routeConfig.component
                        ),
                        r = this.parentContexts.getOrCreateContext(
                          this.name
                        ).children,
                        i = new fv(e, r, this.location.injector);
                      (this.activated = this.location.createComponent(
                        n,
                        this.location.length,
                        i
                      )),
                        this.changeDetector.markForCheck(),
                        this.activateEvents.emit(this.activated.instance);
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || av)(Wo(Kd), Wo(us), Wo(wa), Kn("name"), Wo($a));
            }),
            (av.ɵdir = lt({
              type: av,
              selectors: [["router-outlet"]],
              outputs: {
                activateEvents: "activate",
                deactivateEvents: "deactivate",
              },
              exportAs: ["outlet"],
            })),
            av),
          fv = (function () {
            function e(t, n, r) {
              p(this, e),
                (this.route = t),
                (this.childContexts = n),
                (this.parent = r);
            }
            return (
              g(e, [
                {
                  key: "get",
                  value: function (e, t) {
                    return e === Df
                      ? this.route
                      : e === Kd
                      ? this.childContexts
                      : this.parent.get(e, t);
                  },
                },
              ]),
              e
            );
          })(),
          dv = function e() {
            p(this, e);
          },
          vv = (function () {
            function e() {
              p(this, e);
            }
            return (
              g(e, [
                {
                  key: "preload",
                  value: function (e, t) {
                    return Ec(null);
                  },
                },
              ]),
              e
            );
          })(),
          pv =
            (((lv = (function () {
              function e(t, n, r, i, o) {
                p(this, e),
                  (this.router = t),
                  (this.injector = i),
                  (this.preloadingStrategy = o),
                  (this.loader = new Qd(
                    n,
                    r,
                    function (e) {
                      return t.triggerEvent(new Lh(e));
                    },
                    function (e) {
                      return t.triggerEvent(new Wh(e));
                    }
                  ));
              }
              return (
                g(e, [
                  {
                    key: "setUpPreloading",
                    value: function () {
                      var e = this;
                      this.subscription = this.router.events
                        .pipe(
                          ih(function (e) {
                            return e instanceof Oh;
                          }),
                          ch(function () {
                            return e.preload();
                          })
                        )
                        .subscribe(function () {});
                    },
                  },
                  {
                    key: "preload",
                    value: function () {
                      var e = this.injector.get(os);
                      return this.processRoutes(e, this.router.config);
                    },
                  },
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      this.subscription && this.subscription.unsubscribe();
                    },
                  },
                  {
                    key: "processRoutes",
                    value: function (e, n) {
                      var r,
                        i = [],
                        o = t(n);
                      try {
                        for (o.s(); !(r = o.n()).done; ) {
                          var a = r.value;
                          if (a.loadChildren && !a.canLoad && a._loadedConfig) {
                            var s = a._loadedConfig;
                            i.push(this.processRoutes(s.module, s.routes));
                          } else
                            a.loadChildren && !a.canLoad
                              ? i.push(this.preloadConfig(e, a))
                              : a.children &&
                                i.push(this.processRoutes(e, a.children));
                        }
                      } catch (u) {
                        o.e(u);
                      } finally {
                        o.f();
                      }
                      return $(i).pipe(
                        ie(),
                        H(function (e) {})
                      );
                    },
                  },
                  {
                    key: "preloadConfig",
                    value: function (e, t) {
                      var n = this;
                      return this.preloadingStrategy.preload(t, function () {
                        return (
                          t._loadedConfig
                            ? Ec(t._loadedConfig)
                            : n.loader.load(e.injector, t)
                        ).pipe(
                          te(function (e) {
                            return (
                              (t._loadedConfig = e),
                              n.processRoutes(e.module, e.routes)
                            );
                          })
                        );
                      });
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || lv)(fr(nv), fr(ju), fr(hu), fr(Io), fr(dv));
            }),
            (lv.ɵprov = Ee({ token: lv, factory: lv.ɵfac })),
            lv),
          mv =
            (((uv = (function () {
              function e(t, n) {
                var r =
                  arguments.length > 2 && void 0 !== arguments[2]
                    ? arguments[2]
                    : {};
                p(this, e),
                  (this.router = t),
                  (this.viewportScroller = n),
                  (this.options = r),
                  (this.lastId = 0),
                  (this.lastSource = "imperative"),
                  (this.restoredId = 0),
                  (this.store = {}),
                  (r.scrollPositionRestoration =
                    r.scrollPositionRestoration || "disabled"),
                  (r.anchorScrolling = r.anchorScrolling || "disabled");
              }
              return (
                g(e, [
                  {
                    key: "init",
                    value: function () {
                      "disabled" !== this.options.scrollPositionRestoration &&
                        this.viewportScroller.setHistoryScrollRestoration(
                          "manual"
                        ),
                        (this.routerEventsSubscription =
                          this.createScrollEvents()),
                        (this.scrollEventsSubscription =
                          this.consumeScrollEvents());
                    },
                  },
                  {
                    key: "createScrollEvents",
                    value: function () {
                      var e = this;
                      return this.router.events.subscribe(function (t) {
                        t instanceof Ah
                          ? ((e.store[e.lastId] =
                              e.viewportScroller.getScrollPosition()),
                            (e.lastSource = t.navigationTrigger),
                            (e.restoredId = t.restoredState
                              ? t.restoredState.navigationId
                              : 0))
                          : t instanceof Oh &&
                            ((e.lastId = t.id),
                            e.scheduleScrollEvent(
                              t,
                              e.router.parseUrl(t.urlAfterRedirects).fragment
                            ));
                      });
                    },
                  },
                  {
                    key: "consumeScrollEvents",
                    value: function () {
                      var e = this;
                      return this.router.events.subscribe(function (t) {
                        t instanceof Vh &&
                          (t.position
                            ? "top" === e.options.scrollPositionRestoration
                              ? e.viewportScroller.scrollToPosition([0, 0])
                              : "enabled" ===
                                  e.options.scrollPositionRestoration &&
                                e.viewportScroller.scrollToPosition(t.position)
                            : t.anchor &&
                              "enabled" === e.options.anchorScrolling
                            ? e.viewportScroller.scrollToAnchor(t.anchor)
                            : "disabled" !==
                                e.options.scrollPositionRestoration &&
                              e.viewportScroller.scrollToPosition([0, 0]));
                      });
                    },
                  },
                  {
                    key: "scheduleScrollEvent",
                    value: function (e, t) {
                      this.router.triggerEvent(
                        new Vh(
                          e,
                          "popstate" === this.lastSource
                            ? this.store[this.restoredId]
                            : null,
                          t
                        )
                      );
                    },
                  },
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      this.routerEventsSubscription &&
                        this.routerEventsSubscription.unsubscribe(),
                        this.scrollEventsSubscription &&
                          this.scrollEventsSubscription.unsubscribe();
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || uv)(fr(nv), fr(Ml), fr(void 0));
            }),
            (uv.ɵprov = Ee({ token: uv, factory: uv.ɵfac })),
            uv),
          gv = new Jn("ROUTER_CONFIGURATION"),
          yv = new Jn("ROUTER_FORROOT_GUARD"),
          kv = [
            dl,
            { provide: cf, useClass: hf },
            {
              provide: nv,
              useFactory: function (e, t, n, r, i, o, a) {
                var s =
                    arguments.length > 7 && void 0 !== arguments[7]
                      ? arguments[7]
                      : {},
                  u = arguments.length > 8 ? arguments[8] : void 0,
                  l = arguments.length > 9 ? arguments[9] : void 0,
                  c = new nv(null, e, t, n, r, i, o, $h(a));
                if (
                  (u && (c.urlHandlingStrategy = u),
                  l && (c.routeReuseStrategy = l),
                  (function (e, t) {
                    e.errorHandler && (t.errorHandler = e.errorHandler),
                      e.malformedUriErrorHandler &&
                        (t.malformedUriErrorHandler =
                          e.malformedUriErrorHandler),
                      e.onSameUrlNavigation &&
                        (t.onSameUrlNavigation = e.onSameUrlNavigation),
                      e.paramsInheritanceStrategy &&
                        (t.paramsInheritanceStrategy =
                          e.paramsInheritanceStrategy),
                      e.relativeLinkResolution &&
                        (t.relativeLinkResolution = e.relativeLinkResolution),
                      e.urlUpdateStrategy &&
                        (t.urlUpdateStrategy = e.urlUpdateStrategy);
                  })(s, c),
                  s.enableTracing)
                ) {
                  var h = Bu();
                  c.events.subscribe(function (e) {
                    h.logGroup("Router Event: ".concat(e.constructor.name)),
                      h.log(e.toString()),
                      h.log(e),
                      h.logGroupEnd();
                  });
                }
                return c;
              },
              deps: [
                cf,
                Kd,
                dl,
                Io,
                ju,
                hu,
                Bd,
                gv,
                [
                  (function () {
                    return function e() {
                      p(this, e);
                    };
                  })(),
                  new mr(),
                ],
                [
                  (function () {
                    return function e() {
                      p(this, e);
                    };
                  })(),
                  new mr(),
                ],
              ],
            },
            Kd,
            {
              provide: Df,
              useFactory: function (e) {
                return e.routerState.root;
              },
              deps: [nv],
            },
            { provide: ju, useClass: Wu },
            pv,
            vv,
            (function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "preload",
                    value: function (e, t) {
                      return t().pipe(
                        sh(function () {
                          return Ec(null);
                        })
                      );
                    },
                  },
                ]),
                e
              );
            })(),
            { provide: gv, useValue: { enableTracing: !1 } },
          ];
        function bv() {
          return new Iu("Router", nv);
        }
        var wv,
          _v =
            (((wv = (function () {
              function e(t, n) {
                p(this, e);
              }
              return (
                g(e, null, [
                  {
                    key: "forRoot",
                    value: function (t, n) {
                      return {
                        ngModule: e,
                        providers: [
                          kv,
                          Tv(t),
                          {
                            provide: yv,
                            useFactory: Ev,
                            deps: [[nv, new mr(), new gr()]],
                          },
                          { provide: gv, useValue: n || {} },
                          {
                            provide: ol,
                            useFactory: Cv,
                            deps: [Ku, [new pr(cl), new mr()], gv],
                          },
                          { provide: mv, useFactory: Sv, deps: [nv, Ml, gv] },
                          {
                            provide: dv,
                            useExisting:
                              n && n.preloadingStrategy
                                ? n.preloadingStrategy
                                : vv,
                          },
                          { provide: Iu, multi: !0, useFactory: bv },
                          [
                            Iv,
                            {
                              provide: Gs,
                              multi: !0,
                              useFactory: Av,
                              deps: [Iv],
                            },
                            { provide: Rv, useFactory: Ov, deps: [Iv] },
                            { provide: eu, multi: !0, useExisting: Rv },
                          ],
                        ],
                      };
                    },
                  },
                  {
                    key: "forChild",
                    value: function (t) {
                      return { ngModule: e, providers: [Tv(t)] };
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || wv)(fr(yv, 8), fr(nv, 8));
            }),
            (wv.ɵmod = st({ type: wv })),
            (wv.ɵinj = Te({})),
            wv);
        function Sv(e, t, n) {
          return n.scrollOffset && t.setOffset(n.scrollOffset), new mv(e, t, n);
        }
        function Cv(e, t) {
          var n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
          return n.useHash ? new fl(e, t) : new hl(e, t);
        }
        function Ev(e) {
          return "guarded";
        }
        function Tv(e) {
          return [
            { provide: $n, multi: !0, useValue: e },
            { provide: Bd, multi: !0, useValue: e },
          ];
        }
        var xv,
          Iv =
            (((xv = (function () {
              function e(t) {
                p(this, e),
                  (this.injector = t),
                  (this.initNavigation = !1),
                  (this.resultOfPreactivationDone = new W());
              }
              return (
                g(e, [
                  {
                    key: "appInitializer",
                    value: function () {
                      var e = this;
                      return this.injector
                        .get($u, Promise.resolve(null))
                        .then(function () {
                          var t = null,
                            n = new Promise(function (e) {
                              return (t = e);
                            }),
                            r = e.injector.get(nv),
                            i = e.injector.get(gv);
                          return (
                            "disabled" === i.initialNavigation
                              ? (r.setUpLocationChangeListener(), t(!0))
                              : "enabled" === i.initialNavigation ||
                                "enabledBlocking" === i.initialNavigation
                              ? ((r.hooks.afterPreactivation = function () {
                                  return e.initNavigation
                                    ? Ec(null)
                                    : ((e.initNavigation = !0),
                                      t(!0),
                                      e.resultOfPreactivationDone);
                                }),
                                r.initialNavigation())
                              : t(!0),
                            n
                          );
                        });
                    },
                  },
                  {
                    key: "bootstrapListener",
                    value: function (e) {
                      var t = this.injector.get(gv),
                        n = this.injector.get(pv),
                        r = this.injector.get(mv),
                        i = this.injector.get(nv),
                        o = this.injector.get(Nu);
                      e === o.components[0] &&
                        (("enabledNonBlocking" !== t.initialNavigation &&
                          void 0 !== t.initialNavigation) ||
                          i.initialNavigation(),
                        n.setUpPreloading(),
                        r.init(),
                        i.resetRootComponentType(o.componentTypes[0]),
                        this.resultOfPreactivationDone.next(null),
                        this.resultOfPreactivationDone.complete());
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || xv)(fr(Io));
            }),
            (xv.ɵprov = Ee({ token: xv, factory: xv.ɵfac })),
            xv);
        function Av(e) {
          return e.appInitializer.bind(e);
        }
        function Ov(e) {
          return e.bootstrapListener.bind(e);
        }
        var Rv = new Jn("Router Initializer"),
          Pv = function e() {
            p(this, e);
          };
        function Dv(e, t) {
          return { type: 7, name: e, definitions: t, options: {} };
        }
        function Nv(e) {
          var t =
            arguments.length > 1 && void 0 !== arguments[1]
              ? arguments[1]
              : null;
          return { type: 4, styles: t, timings: e };
        }
        function Mv(e) {
          var t =
            arguments.length > 1 && void 0 !== arguments[1]
              ? arguments[1]
              : null;
          return { type: 2, steps: e, options: t };
        }
        function jv(e) {
          return { type: 6, styles: e, offset: null };
        }
        function Fv(e, t, n) {
          return { type: 0, name: e, styles: t, options: n };
        }
        function Lv(e) {
          return { type: 5, steps: e };
        }
        function Wv(e, t) {
          var n =
            arguments.length > 2 && void 0 !== arguments[2]
              ? arguments[2]
              : null;
          return { type: 1, expr: e, animation: t, options: n };
        }
        function zv(e) {
          Promise.resolve(null).then(e);
        }
        var qv = (function () {
            function e() {
              var t =
                  arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : 0,
                n =
                  arguments.length > 1 && void 0 !== arguments[1]
                    ? arguments[1]
                    : 0;
              p(this, e),
                (this._onDoneFns = []),
                (this._onStartFns = []),
                (this._onDestroyFns = []),
                (this._started = !1),
                (this._destroyed = !1),
                (this._finished = !1),
                (this._position = 0),
                (this.parentPlayer = null),
                (this.totalTime = t + n);
            }
            return (
              g(e, [
                {
                  key: "_onFinish",
                  value: function () {
                    this._finished ||
                      ((this._finished = !0),
                      this._onDoneFns.forEach(function (e) {
                        return e();
                      }),
                      (this._onDoneFns = []));
                  },
                },
                {
                  key: "onStart",
                  value: function (e) {
                    this._onStartFns.push(e);
                  },
                },
                {
                  key: "onDone",
                  value: function (e) {
                    this._onDoneFns.push(e);
                  },
                },
                {
                  key: "onDestroy",
                  value: function (e) {
                    this._onDestroyFns.push(e);
                  },
                },
                {
                  key: "hasStarted",
                  value: function () {
                    return this._started;
                  },
                },
                { key: "init", value: function () {} },
                {
                  key: "play",
                  value: function () {
                    this.hasStarted() ||
                      (this._onStart(), this.triggerMicrotask()),
                      (this._started = !0);
                  },
                },
                {
                  key: "triggerMicrotask",
                  value: function () {
                    var e = this;
                    zv(function () {
                      return e._onFinish();
                    });
                  },
                },
                {
                  key: "_onStart",
                  value: function () {
                    this._onStartFns.forEach(function (e) {
                      return e();
                    }),
                      (this._onStartFns = []);
                  },
                },
                { key: "pause", value: function () {} },
                { key: "restart", value: function () {} },
                {
                  key: "finish",
                  value: function () {
                    this._onFinish();
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    this._destroyed ||
                      ((this._destroyed = !0),
                      this.hasStarted() || this._onStart(),
                      this.finish(),
                      this._onDestroyFns.forEach(function (e) {
                        return e();
                      }),
                      (this._onDestroyFns = []));
                  },
                },
                { key: "reset", value: function () {} },
                {
                  key: "setPosition",
                  value: function (e) {
                    this._position = this.totalTime ? e * this.totalTime : 1;
                  },
                },
                {
                  key: "getPosition",
                  value: function () {
                    return this.totalTime ? this._position / this.totalTime : 1;
                  },
                },
                {
                  key: "triggerCallback",
                  value: function (e) {
                    var t = "start" == e ? this._onStartFns : this._onDoneFns;
                    t.forEach(function (e) {
                      return e();
                    }),
                      (t.length = 0);
                  },
                },
              ]),
              e
            );
          })(),
          Hv = (function () {
            function e(t) {
              var n = this;
              p(this, e),
                (this._onDoneFns = []),
                (this._onStartFns = []),
                (this._finished = !1),
                (this._started = !1),
                (this._destroyed = !1),
                (this._onDestroyFns = []),
                (this.parentPlayer = null),
                (this.totalTime = 0),
                (this.players = t);
              var r = 0,
                i = 0,
                o = 0,
                a = this.players.length;
              0 == a
                ? zv(function () {
                    return n._onFinish();
                  })
                : this.players.forEach(function (e) {
                    e.onDone(function () {
                      ++r == a && n._onFinish();
                    }),
                      e.onDestroy(function () {
                        ++i == a && n._onDestroy();
                      }),
                      e.onStart(function () {
                        ++o == a && n._onStart();
                      });
                  }),
                (this.totalTime = this.players.reduce(function (e, t) {
                  return Math.max(e, t.totalTime);
                }, 0));
            }
            return (
              g(e, [
                {
                  key: "_onFinish",
                  value: function () {
                    this._finished ||
                      ((this._finished = !0),
                      this._onDoneFns.forEach(function (e) {
                        return e();
                      }),
                      (this._onDoneFns = []));
                  },
                },
                {
                  key: "init",
                  value: function () {
                    this.players.forEach(function (e) {
                      return e.init();
                    });
                  },
                },
                {
                  key: "onStart",
                  value: function (e) {
                    this._onStartFns.push(e);
                  },
                },
                {
                  key: "_onStart",
                  value: function () {
                    this.hasStarted() ||
                      ((this._started = !0),
                      this._onStartFns.forEach(function (e) {
                        return e();
                      }),
                      (this._onStartFns = []));
                  },
                },
                {
                  key: "onDone",
                  value: function (e) {
                    this._onDoneFns.push(e);
                  },
                },
                {
                  key: "onDestroy",
                  value: function (e) {
                    this._onDestroyFns.push(e);
                  },
                },
                {
                  key: "hasStarted",
                  value: function () {
                    return this._started;
                  },
                },
                {
                  key: "play",
                  value: function () {
                    this.parentPlayer || this.init(),
                      this._onStart(),
                      this.players.forEach(function (e) {
                        return e.play();
                      });
                  },
                },
                {
                  key: "pause",
                  value: function () {
                    this.players.forEach(function (e) {
                      return e.pause();
                    });
                  },
                },
                {
                  key: "restart",
                  value: function () {
                    this.players.forEach(function (e) {
                      return e.restart();
                    });
                  },
                },
                {
                  key: "finish",
                  value: function () {
                    this._onFinish(),
                      this.players.forEach(function (e) {
                        return e.finish();
                      });
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    this._onDestroy();
                  },
                },
                {
                  key: "_onDestroy",
                  value: function () {
                    this._destroyed ||
                      ((this._destroyed = !0),
                      this._onFinish(),
                      this.players.forEach(function (e) {
                        return e.destroy();
                      }),
                      this._onDestroyFns.forEach(function (e) {
                        return e();
                      }),
                      (this._onDestroyFns = []));
                  },
                },
                {
                  key: "reset",
                  value: function () {
                    this.players.forEach(function (e) {
                      return e.reset();
                    }),
                      (this._destroyed = !1),
                      (this._finished = !1),
                      (this._started = !1);
                  },
                },
                {
                  key: "setPosition",
                  value: function (e) {
                    var t = e * this.totalTime;
                    this.players.forEach(function (e) {
                      var n = e.totalTime ? Math.min(1, t / e.totalTime) : 1;
                      e.setPosition(n);
                    });
                  },
                },
                {
                  key: "getPosition",
                  value: function () {
                    var e = this.players.reduce(function (e, t) {
                      return null === e || t.totalTime > e.totalTime ? t : e;
                    }, null);
                    return null != e ? e.getPosition() : 0;
                  },
                },
                {
                  key: "beforeDestroy",
                  value: function () {
                    this.players.forEach(function (e) {
                      e.beforeDestroy && e.beforeDestroy();
                    });
                  },
                },
                {
                  key: "triggerCallback",
                  value: function (e) {
                    var t = "start" == e ? this._onStartFns : this._onDoneFns;
                    t.forEach(function (e) {
                      return e();
                    }),
                      (t.length = 0);
                  },
                },
              ]),
              e
            );
          })(),
          Uv = (function () {
            function e(t, n) {
              p(this, e), (this.defaultTranscoder = t), (this.subject = n);
            }
            return (
              g(e, [
                {
                  key: "has",
                  value: function (e) {
                    return this.subject.has(e);
                  },
                },
                {
                  key: "get",
                  value: function (e, t) {
                    return this.subject.get(e, t || this.defaultTranscoder);
                  },
                },
                {
                  key: "set",
                  value: function (e, t, n) {
                    this.subject.set(e, t, n || this.defaultTranscoder);
                  },
                },
                {
                  key: "remove",
                  value: function (e) {
                    this.subject.remove(e);
                  },
                },
                {
                  key: "clear",
                  value: function () {
                    this.subject.clear();
                  },
                },
                {
                  key: "withDefaultTranscoder",
                  value: function (t) {
                    return new e(t, this.subject);
                  },
                },
              ]),
              e
            );
          })(),
          Vv = (function () {
            function e(t) {
              p(this, e), (this.defaultTranscoder = t);
            }
            return (
              g(e, [
                {
                  key: "get",
                  value: function (e, t) {
                    var n = this.getItem(e);
                    return void 0 !== n
                      ? (t || this.defaultTranscoder).decode(n)
                      : void 0;
                  },
                },
                {
                  key: "set",
                  value: function (e, t, n) {
                    this.setItem(e, (n || this.defaultTranscoder).encode(t));
                  },
                },
                {
                  key: "withDefaultTranscoder",
                  value: function (e) {
                    return new Uv(e, this);
                  },
                },
              ]),
              e
            );
          })(),
          Bv = {
            JSON: new ((function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "encode",
                    value: function (e) {
                      return JSON.stringify(e);
                    },
                  },
                  {
                    key: "decode",
                    value: function (e) {
                      try {
                        return JSON.parse(e);
                      } catch (t) {
                        return;
                      }
                    },
                  },
                ]),
                e
              );
            })())(),
            STRING: new ((function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "encode",
                    value: function (e) {
                      return e;
                    },
                  },
                  {
                    key: "decode",
                    value: function (e) {
                      return e;
                    },
                  },
                ]),
                e
              );
            })())(),
            BOOLEAN: new ((function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "encode",
                    value: function (e) {
                      return e.toString();
                    },
                  },
                  {
                    key: "decode",
                    value: function (e) {
                      return "true" === e || ("false" !== e && void 0);
                    },
                  },
                ]),
                e
              );
            })())(),
            NUMBER: new ((function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "encode",
                    value: function (e) {
                      return e.toString();
                    },
                  },
                  {
                    key: "decode",
                    value: function (e) {
                      var t = Number(e);
                      return Number.isFinite(t) ? t : void 0;
                    },
                  },
                ]),
                e
              );
            })())(),
            DATE_ISO_STRING: new ((function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "encode",
                    value: function (e) {
                      return e.toISOString();
                    },
                  },
                  {
                    key: "decode",
                    value: function (e) {
                      var t = Date.parse(e);
                      return isNaN(t) ? void 0 : new Date(t);
                    },
                  },
                ]),
                e
              );
            })())(),
            DATE_EPOCH_TIME: new ((function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "encode",
                    value: function (e) {
                      return e.valueOf().toString();
                    },
                  },
                  {
                    key: "decode",
                    value: function (e) {
                      var t = parseInt(e, 10);
                      return isNaN(t) ? void 0 : new Date(t);
                    },
                  },
                ]),
                e
              );
            })())(),
          },
          Qv = (function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              var e;
              return (
                p(this, n), ((e = t.call(this, Bv.JSON)).storage = new Map()), e
              );
            }
            return (
              g(n, [
                {
                  key: "has",
                  value: function (e) {
                    return this.storage.has(e);
                  },
                },
                {
                  key: "remove",
                  value: function (e) {
                    this.storage.delete(e);
                  },
                },
                {
                  key: "clear",
                  value: function () {
                    this.storage.clear();
                  },
                },
                {
                  key: "getItem",
                  value: function (e) {
                    if (this.storage.has(e)) return this.storage.get(e);
                  },
                },
                {
                  key: "setItem",
                  value: function (e, t) {
                    this.storage.set(e, t);
                  },
                },
              ]),
              n
            );
          })(Vv),
          Gv = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e) {
              var r;
              return p(this, n), ((r = t.call(this, Bv.JSON)).storage = e), r;
            }
            return (
              g(n, [
                {
                  key: "has",
                  value: function (e) {
                    return null !== this.storage.getItem(e);
                  },
                },
                {
                  key: "remove",
                  value: function (e) {
                    this.storage.removeItem(e);
                  },
                },
                {
                  key: "clear",
                  value: function () {
                    this.storage.clear();
                  },
                },
                {
                  key: "getItem",
                  value: function (e) {
                    var t = this.storage.getItem(e);
                    return null !== t ? t : void 0;
                  },
                },
                {
                  key: "setItem",
                  value: function (e, t) {
                    return this.storage.setItem(e, t);
                  },
                },
              ]),
              n
            );
          })(Vv);
        function Kv(e) {
          if (!e) return !1;
          try {
            var t = Date.now(),
              n = "storage-test-entry-".concat(t),
              r = "storage-test-value-".concat(t);
            e.setItem(n, r);
            var i = e.getItem(n);
            return e.removeItem(n), i === r;
          } catch (o) {
            return !1;
          }
        }
        new Jn("SESSION_STORAGE", {
          providedIn: "root",
          factory: function () {
            try {
              if ("undefined" != typeof sessionStorage && Kv(sessionStorage))
                return new Gv(sessionStorage);
            } catch (e) {}
            return new Qv();
          },
        });
        var Zv,
          Jv,
          $v,
          Yv,
          Xv = new Jn("LOCAL_STORAGE", {
            providedIn: "root",
            factory: function () {
              try {
                if ("undefined" != typeof localStorage && Kv(localStorage))
                  return new Gv(localStorage);
              } catch (e) {}
              return new Qv();
            },
          }),
          ep =
            (((Zv = (function () {
              function e(t) {
                p(this, e),
                  (this.storage = t),
                  (this.userEntries = new qc([])),
                  (this.repeatQuestions = new qc(!1)),
                  (this.selectedLanguage = new qc(np.GERMAN));
              }
              return (
                g(e, [
                  {
                    key: "getUserEntries",
                    value: function () {
                      var e = this.storage.get(tp.USER_ENTRIES);
                      return e && this.userEntries.next(e), this.userEntries;
                    },
                  },
                  {
                    key: "setUserEntries",
                    value: function (e) {
                      this.storage.set(tp.USER_ENTRIES, e),
                        this.userEntries.next(e);
                    },
                  },
                  {
                    key: "getRepeatQuestions",
                    value: function () {
                      var e = this.storage.get(tp.REPEAT_QUESTIONS);
                      return (
                        e && this.repeatQuestions.next(e), this.repeatQuestions
                      );
                    },
                  },
                  {
                    key: "setRepeatQuestions",
                    value: function (e) {
                      this.storage.set(tp.REPEAT_QUESTIONS, e),
                        this.repeatQuestions.next(e);
                    },
                  },
                  {
                    key: "getSelectedLanguage",
                    value: function () {
                      var e = this.storage.get(tp.SELECTED_LANGUAGE);
                      return (
                        e && this.selectedLanguage.next(e),
                        this.selectedLanguage
                      );
                    },
                  },
                  {
                    key: "setSelectedLanguage",
                    value: function (e) {
                      this.storage.set(tp.SELECTED_LANGUAGE, e),
                        this.selectedLanguage.next(e);
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Zv)(fr(Xv));
            }),
            (Zv.ɵprov = Ee({
              token: Zv,
              factory: Zv.ɵfac,
              providedIn: "root",
            })),
            Zv),
          tp = (function (e) {
            return (
              (e.SELECTED_LANGUAGE = "SELECTED_LANGUAGE"),
              (e.REPEAT_QUESTIONS = "REPEAT_QUESTIONS"),
              (e.USER_ENTRIES = "USER_ENTRIES"),
              e
            );
          })({}),
          np = (function (e) {
            return (
              (e.GERMAN = "GERMAN"),
              (e.ENGLISH = "ENGLISH"),
              (e.SPANISH = "SPANISH"),
              e
            );
          })({}),
          rp = {
            titleRandomGenerator: "Zufallsgenerator",
            subtitleRandomGenerator: "F\xfcr eigene Fragen etc.",
            buttonTextRandomPick: "Neue Auswahl",
            buttonTextNewQuestion: "Neue Frage",
            toggleSwitchTextRepeatQuestions: "Fragen wiederholen",
            toggleSwitchTextRepeatUserEntries: "Eintr\xe4ge wiederholen",
            checkin: {
              name: "Checkin",
              questions: [
                "Was ist mir besonders Sch\xf6nes in dieser Woche passiert?",
                "Was macht mich an meinem Beruf glu\u0308cklich?",
                "Warum k\xf6nnen sich meine Kolleg:innen/Schu\u0308ler:innen/TN glu\u0308cklich sch\xe4tzen, mit mir zu arbeiten?",
                "Wann habe ich das letzte Mal richtig laut gelacht und woru\u0308ber?",
                "Was haben meine Lehrer:innen wohl damals u\u0308ber mich gedacht und was wu\u0308rden sie heute sagen?",
                "Wen hat mein letzter Schneeball getroffen?",
                "Wobei bin ich abergl\xe4ubisch?",
                "Wen mochte ich lieber? Ernie oder Bert?",
                "Wie hei\xdft es richtig: Die Nutella oder das Nutella?",
                "Dar\xfcber kann ich fast immer l\xe4cheln.",
                "Welches Tier mag ich besonders? Und worin \xe4hneln wir uns?",
                "Worin w\xfcrde ich mich gerne verbessern?",
                "Was steht auf meiner ewigen To-Do-Liste?",
                "Was habe ich mir f\xfcr das kommende Wochenende vorgenommen?",
                "Strand oder Berge?",
                "Welches ist meine Lieblingsstadt?",
                "Habe ich schon mal was geklaut?",
                "Mit 1.000 \u20ac, die ich nur heute ausgeben darf, w\xfcrde ich \u2026",
                "Das habe ich im letzten Jahr \xfcber mich gelernt:",
                "Mit diesen drei Lebensmitteln k\xf6nnte ich mich ewig ern\xe4hren:",
                "Zu was sage ich heute ja?",
                "F\xfcr was war ich heute schon dankbar?",
                "Was ich an meiner T\xe4tigkeit liebe:",
                "Heute werde ich ...",
                "Was m\xf6chte ich in meinem Leben gerne mal ausprobieren?",
                "Was m\xfcsste heute passieren, damit der Tag perfekt wird?",
                "Ein sehr sch\xf6nes Geschenk, welches ich mal bekommen habe \u2026",
                "Was habe ich dieses Jahr hinter mir gelassen?",
                "Ein Lebensmotto von mir \u2026",
                "Wer oder was bekommt heute meine Aufmerksamkeit?",
                "Wenn meine aktuelle Stimmung ein Lied oder ein Film w\xe4re ...",
                "Welche Eigenschaften mag ich am liebsten an meinen Freund:innen?",
                "Worauf freue ich mich momentan besonders?",
                "Was fasziniert mich?",
                "Was war heute mein erster Gedanke?",
                "Auf was bin ich stolz?",
                "Was war das bislang Beste an diesem Tag?",
                "Mein Lieblingshobby \u2026",
                "Wen habe ich mal zuf\xe4llig im Ausland getroffen?",
                "Wie k\xf6nnen wir heute den gemeinsamen Tag perfekt gestalten?",
                "Warum bin ich heute hier?",
                "Welche Superheldenkraft h\xe4tte ich gern?",
                "Welches war die erste CD, die ich mir gekauft habe?",
                "Hund oder Katze?",
                "Was ist mein derzeitiges Lieblingsessen?",
                "Was ist mein derzeitiges Lieblingskleidungsst\xfcck?",
                "Welches Gericht habe ich zuletzt gekocht?",
                "Wenn ich ein Tier w\xe4re, dann am liebsten ein(e) ...",
                "Mein Anti-Hobby ist \u2026",
                "Mach jemandem hier aus der Runde spontan ein Kompliment.",
                "Welches Buch habe ich zuletzt gelesen?",
                "Ein Film- oder Serientipp ...",
                "Was w\xfcrde ich gerade tun, wenn ich nicht hier w\xe4re?",
                "Wo w\xe4re ich gerade gerne im Urlaub?",
                "Welche gro\xdfe Reise steht noch aus?",
                "Habe ich ein Vorbild oder Idol?",
                "Was wei\xdf ganz sicher noch niemand in dieser Runde \xfcber mich?",
                "Eine Information, die nur auf mich in dieser Runde zutreffen d\xfcrfte \u2026",
                "Ich habe noch niemals ...",
                "Meine Lieblingsjahreszeit \u2026",
                "Was war der schlimmste Job, den ich jemals hatte?",
                "Was m\xf6chte ich heute lernen?",
                "Welche meiner heutigen Charaktereigenschaften war auch schon in meiner Kindheit stark ausgepr\xe4gt?",
                "Damit m\xf6chte ich endlich aufh\xf6ren:",
                "Damit m\xf6chte ich endlich anfangen:",
                "Welches Wort sollten wir \xf6fter und welches seltener verwenden?",
                "Was bringt mich zur Wei\xdfglut?",
                "Eine meiner Schw\xe4chen \u2026",
                "Eine meiner St\xe4rken \u2026",
                "Was war ein guter Tipp, den ich mal bekommen habe?",
                "Was war ein guter Tipp, den ich mal gegeben habe?",
                "Welchen Gegenstand w\xfcrde ich aus meinem brennenden Haus retten?",
                "Welche drei Gegenst\xe4nde w\xfcrde ich auf eine einsame Insel mitnehmen?",
                "Meine Lieblingsfarbe \u2026",
                "Welches ist der sch\xf6nste Ort nahe meines Wohnorts?",
                "Das war meine gro\xdfe Leidenschaft als Kind \u2026",
                "Welche Farbe bin ich heute und warum?",
                "Mein bisheriges Highlight in dieser Woche:",
                "Was ist meine geometrische Lieblingsform und warum?",
                "Wenn ich nicht gerade hier w\xe4re, wo w\xe4re ich dann?",
                "Was gibt mir Energie?",
                "Wenn ich eine Fremdsprache \xfcber Nacht lernen k\xf6nnte, welche w\xfcrde ich mir aussuchen?",
                "Wer sollte in der Verfilmung meines Lebens die Hauptrolle spielen?",
                "Wie k\xf6nnte der Titel meiner Autobiografie hei\xdfen?",
                "Wann habe ich zum letzten Mal etwas zum allerersten Mal getan?",
                "Was kann ich \xfcberdurchschnittlich gut?",
                "Mit welcher ber\xfchmten Pers\xf6nlichkeit w\xfcrde ich gerne mal einen Tag lang tauschen?",
                "Welchen Beruf w\xfcrde ich gerne mal eine Woche lang ausprobieren?",
                "Welche drei Werte sind f\xfcr mich besonders wichtig?",
                "Unter diesen Bedingungen kann ich am besten lernen:",
                "Ein Held meiner Kindheit \u2026",
                "Wenn ich ein Gesetz erlassen d\xfcrfte, an das sich alle Menschen weltweit halten m\xfcssten \u2026",
                "Wie bin ich heute hier - und inwiefern ist das typisch oder ungew\xf6hnlich?",
                "Was waren drei wichtige Meilensteine in meiner Biografie?",
                "Welches ist mein Lieblingsort in meiner Wohnung?",
                "Welche Sportart schaue ich lieber, als dass ich sie selbst betreibe?",
                "Welches Musikgenre mag ich besonders und welches gar nicht?",
                "Das bereitet mir manchmal Kopfzerbrechen:",
                "Dabei kann ich besonders gut entspannen und mich erholen:",
                "Optimist oder Pessimist: Wie w\xfcrde ich mich selbst beschreiben?",
              ],
            },
            kennenlernen: {
              name: "Kennenlernen",
              questions: [
                "In welchem Ort bist du aufgewachsen?",
                "W\xfcrdest du alleine auf ein Konzert gehen? Was w\xfcrdest du niemals alleine tun und warum nicht?",
                "Hast du ein Lieblingsbuch? Falls ja: Warum gef\xe4llt es dir so gut?",
                "Hast du Geschwister?",
                "Urlaub: In die Berge oder lieber ans Meer?\xa0",
                "Welche Hobbys hattest du fr\xfcher, die du heute nicht mehr aus\xfcbst?",
                "Woran hast du mal geglaubt, was sich sp\xe4ter als falsch herausgestellt hat?",
                "Wenn du eine Million Euro gewinnen w\xfcrdest, was w\xfcrdest du tun?",
                "Kennst du die Geschichte hinter deinem Namen?",
                "Wenn du ein Tier w\xe4rest, was w\xe4rest du dann? Und warum?",
                "Welche drei Dinge w\xfcrdest du auf eine einsame Insel mitnehmen?\xa0",
                "Was ist dir in letzter Zeit passiert, wor\xfcber du dich total gefreut hast?",
                "Wenn du nochmal w\xe4hlen k\xf6nntest, welchen Beruf w\xfcrdest du erlernen?",
                "Spielst du ein Instrument oder w\xfcrdest du gerne eines spielen?",
                "Was war als Kind dein Traumberuf?\xa0",
                "Auf welches Essen w\xfcrdest du niemals verzichten wollen?",
                "Welche Eissorte isst du am liebsten?",
                "Kochst du gerne?",
                "Welches ist dein Lieblingsplatz bei dir zuhause?",
                "Was ist dein Lieblingshobby?",
                "Welche ist deine Lieblingsfarbe? Und hast du schon mal etwas nur wegen dieser Farbe gekauft?",
                "Hast du ein Idol oder Vorbild?",
                "Welche Musikrichtung h\xf6rst du?",
                "Wenn dich ein guter Freund oder eine gute Freundin beschreiben soll, was w\xfcrde er/sie als erstes sagen?",
              ],
            },
            entwederOder: {
              name: "Entweder oder",
              questions: [
                "Sekt oder Saft",
                "Bier oder Wein",
                "Rotwein oder Wei\xdfwein",
                "Berge oder Meer",
                "Mac oder Microsoft",
                "Katze oder Hund",
                "Stadt oder Land",
                "Auto oder Zug",
                "Ferienhaus oder Hotel",
                "Handy oder Smartphone",
                "Gem\xfcse oder Obst",
                "Disco oder Club",
                "Kochen oder bestellen",
                "Pizza oder Pasta\xa0",
                "Ski oder Snowboard",
                "Theater oder Kino",
                "Thriller oder Kom\xf6die",
                "Metal oder Klassik",
                "Camping\xa0oder Hotel",
                "Nadelwald oder Laubwald",
                "Schnee oder Sonne",
                "Wandern oder spazieren",
                "Erdbeere oder Kirsche",
                "Wurst oder K\xe4se",
                "S\xfc\xdfes oder herzhaftes Fr\xfchst\xfcck",
                "Brot oder M\xfcsli",
                "Musik oder Stille",
                "Paris oder London",
                "Kaffee oder Tee",
                "Holmes oder Watson",
                "Westernfilm oder Mittelalter-Epos",
                "Beatles oder Stones",
                "Rammstein oder Kelly Family",
                "Rosen oder Lilien",
                "Lagerfeuer oder Kamin",
                "Schaukel oder Wippe",
                "Zug oder Bus",
                "Herr der Ringe oder Harry Potter",
                "M\xfccke oder Schnake",
                "Moin oder Servus",
                "Alt oder K\xf6lsch",
                "Wei\xdfbrot oder Schwarzbrot",
                "Semmel oder Br\xf6tchen",
                "Fasching oder Karneval",
                "BigBlueButton oder Zoom",
                "Ketchup oder Mayo",
                "Fahrrad oder E-Bike",
                "Popcorn oder Nachos",
                "Schokolade oder Chips",
                "Singen oder Tanzen",
                "Butter oder Margarine",
                "Fr\xfchaufsteher oder Nachteule",
                "E-Mail oder Brief",
                "Eis im Becher oder in der Waffel",
                "Handy oder Festnetz",
                "Joggen oder Fitnessstudio",
                "Helau oder Alaaf",
              ],
            },
            zielsetzung: {
              name: "Zielsetzung",
              questions: [
                "Was ist der n\xe4chste Schritt?",
                "Was ist das Reizvolle an diesem Ziel?",
                "Wie l\xe4sst sich das Ziel am wahrscheinlichsten erreichen?",
                "Was muss geschehen, damit das Ziel klarer wird?",
                "Wie l\xe4sst sich das Ziel in einem Satz beschreiben?",
                "Was kann ich tun, damit ich das Ziel nicht erreiche?\xa0",
                "Auf einer Skala von 0 (gar nicht) bis 10 (sehr stark): Wie sehr m\xf6chte ich dieses Ziel erreichen?",
                "Auf einer Skala von 0 (gar nicht) bis 10 (sehr stark): Wie sehr glaube ich, kann ich dieses Ziel mit meinen aktuellen Mitteln erreichen?",
                "Wer w\xfcrde sich dar\xfcber freuen, wenn ich das Ziel erreiche?",
                "Wer w\xfcrde sich dar\xfcber freuen, wenn ich das Ziel nicht erreiche?",
                "Gibt es Konkurrenzziele, die ich zeitgleich ebenfalls gerne verfolgen w\xfcrde?",
                "Wie lange glaube ich, wird es dauern, bis ich das Ziel erreiche?",
                "Gibt es Abstufungen f\xfcr mein Ziel (z. B. im Sinne von Schulnoten: Wie s\xe4he ein Ergebnis aus, das ich mit der Note 3 bewerten w\xfcrde, wie eines mit Note 2 usw.)?",
                "Wer w\xfcrde merken, wenn das Ziel erreicht wurde?",
                "Gibt es einen Zeitplan f\xfcr die Zielerreichung?",
                "Wie realistisch ist das Ziel?",
                "Was brauche ich von au\xdfen, um das Ziel zu erreichen?",
                "Wie l\xe4sst sich \xfcberpr\xfcfen, ob das Ziel erreicht wurde?",
                "Ist das Ziel eher ein Fernziel oder ein Teilziel?",
                "Ist das Ziel flexibel oder unver\xe4nderlich?",
                "Seit wann verfolge ich das Ziel? Wie kam es dazu?",
                "Habe ich schon einmal ein \xe4hnliches Ziel verfolgt? Mit welchem Ergebnis?",
                "Ist das Ziel eher ein berufliches, ein privates oder von beidem etwas?",
                "Wer oder was hat mich dazu angeregt, dieses Ziel zu verfolgen?",
                "Gibt es jemanden, der/die das Ziel noch viel st\xe4rker verfolgt als ich?",
                "Kommt das Ziel eher von innen oder von au\xdfen?",
                "Kenne ich jemanden, der/die ein solches Ziel schon mal erreicht hat? Wie hat er/sie das geschafft?",
                "Welche Kompetenzen oder Eigenschaften bringe ich mit, die der Zielerreichung dienlich sein k\xf6nnen?",
                "Wenn ich jemandem das Ziel in einem Bild oder einer Metapher beschreiben sollte: Was w\xfcrde mir dazu einfallen?",
                "Was ist mir dabei wichtiger: Das Ziel oder der Weg dorthin?",
                "Von wem ben\xf6tige ich Unterst\xfctzung, um das Ziel zu erreichen?",
                "Welche Ressourcen braucht es, um das Ziel zu erreichen?",
              ],
            },
            teamarbeit: {
              name: "Teamarbeit",
              questions: [
                "An unseren Arbeitspl\xe4tzen brauchen wir auf jeden Fall ...\xa0",
                "Im letzten Jahr hat sich Folgendes ge\xe4ndert:",
                "In Zukunft treffen wir Entscheidungen, indem wir ...\xa0",
                "Wir sollten als Team in diesem Jahr auf jeden Fall ...\xa0",
                "Ich komme montags mit einem L\xe4cheln zur Arbeit, wenn ...",
                "Ich brauche noch ..., um mich im Team wohler zu f\xfchlen.",
                "Ich w\xfcrde das Team verlassen, wenn ...",
                "In einem Jahr werde ich stolz auf unsere Teamarbeit zur\xfcckschauen, weil ...",
                "Ich m\xf6chte zuk\xfcnftig im Team die Rolle als ... wahrnehmen.",
                "Was wir definitiv lassen sollten, ist ...\xa0",
                "Ich werde zu einer guten Arbeitsatmosph\xe4re beitragen, indem ich ...",
                "Folgende Perspektive fehlt in unserem Team aktuell:\xa0",
                "Ich brauche ..., um meine Arbeit gut erledigen zu k\xf6nnen.",
                "Wir sollten aufh\xf6ren mit ...",
                "Wir brauchen mehr von ...",
                "An meinem Team sch\xe4tze ich besonders ...\xa0",
                "Erfolgreiche Teamarbeit wird im Wesentlichen beeinflusst von ...",
              ],
            },
            kontroversen: {
              name: "Kontroversen",
              questions: [
                "Soll die t\xe4gliche Nutzungsdauer von Netflix und Co. gesetzlich beschr\xe4nkt werden?",
                "Sollen E-Scooter in allen St\xe4dten verboten werden?",
                "Sollen s\xe4mtliche Univorlesungen per Video aufgezeichnet werden?",
                "Brauchen wir den 6-Stunden-Arbeitstag?",
                "Brauchen wir ein bedingungsloses Grundeinkommen?",
                "Soll das Rauchen in der \xd6ffentlichkeit verboten werden?",
                "Brauchen wir ein verpflichtendes soziales Jahr?",
                "Brauchen wir ein Recht auf Feierabend?",
                "Brauchen wir ein Tempolimit von 130 km/h auf der Autobahn?",
                "Brauchen wir eine Helmpflicht f\xfcr Fahrradfahrer?",
                "Soll das Betteln in Innenst\xe4dten verboten werden?",
                "Sollen Hausaufgaben abgeschafft werden?",
                "Sollte sich das Gehalt von Politiker:innen am Durchschnittseinkommen der B\xfcrger:innen ihres Landes orientieren?",
                "Soll das anonymisierte Bewerbungsverfahren gesetzlich verpflichtend sein?",
                "Brauchen wir die Schuluniform?",
                "Brauchen wir die Null-Promille-Grenze im Stra\xdfenverkehr?",
                "Sollen Sch\xf6nheitswettbewerbe (wie z. B. Miss-Wahlen) verboten werden?",
              ],
            },
            gruppenreflexion: {
              name: "Gruppenreflexion",
              questions: [
                "Wie habe ich unsere Gruppenarbeit erlebt?",
                "Hat sich jeder einbringen k\xf6nnen?",
                "Wie zufrieden bin ich mit unserem Ergebnis?",
                "Sind wir arbeitsteilig vorgegangen? Wie hat das funktioniert?",
                "Was hat gut geklappt?",
                "Was k\xf6nnten wir beim n\xe4chsten Mal besser machen?",
                "Was sollten wir als Gruppe beibehalten?",
                "Gab es etwas, das mich irritiert hat?",
                "Was hat mich \xfcberrascht?",
                "Was fiel uns leicht?",
                "Womit hatten wir Schwierigkeiten?",
                "Wo k\xf6nnten wir noch einen Tipp gebrauchen?",
                "Was h\xe4tte wohl ein Au\xdfenstehender gedacht, wenn er uns beobachtet h\xe4tte?",
                "Wie haben wir uns koordiniert und abgestimmt?",
                "Wie habe ich die Atmosph\xe4re w\xe4hrend der Gruppenarbeit erlebt?",
              ],
            },
            checkout: {
              name: "Checkout",
              questions: [
                "Was nehme ich mir konkret als n\xe4chstes vor?",
                "Was ist heute h\xe4ngen geblieben?",
                "Das hat mich heute gefreut:",
                "Das nehme ich f\xfcr mich mit:",
                "Das hat mich heute erstaunt:",
                "Das hat mich heute inspiriert:",
                "Das m\xf6chte ich gerne noch einmal machen:",
                "Mit welchen Gedanken beende ich den heutigen Tag?",
                "Das hat mich heute positiv \xfcberrascht:",
                "Was hat mir heute imponiert?",
                "Was sind meine n\xe4chsten drei Schritte?",
                "Was konnte ich heute an mir selbst beobachten?",
                "Welchen Satz m\xf6chte ich noch loswerden?",
                "Ein Lob f\xfcr eine Person f\xfcr einen klugen Satz oder eine gute Aktion.",
                "Wie ging es mir heute zum Einstieg und wie es geht es mir jetzt?",
                "Nach dem heutigen Tag denke ich ...",
                "Worauf lag heute mein Hauptaugenmerk?",
                "Wenn meine jetzige Verfassung ein Tier w\xe4re, welches w\xe4re es?",
                "Was war heute besonders gewinnbringend?",
                "Ein Highlight war heute f\xfcr mich \u2026",
                "Was lasse ich heute hier?",
                "Was m\xf6chte ich als n\xe4chstes ausprobieren oder umsetzen?",
                "Wovon br\xe4uchte ich mehr?",
                "Wovon br\xe4uchte ich weniger?",
                "Was werde ich das n\xe4chste Mal anders machen?",
                "Heute war ich ...",
                "Welches Problem konnte ich heute l\xf6sen oder angehen?",
                "Wie zufrieden bin ich heute mit mir?",
                "Womit werde ich mich f\xfcr meine heutige Teilnahme belohnen?",
                "Was war heute der wertvollste Fehler?",
                "Welches Wort sollten wir das n\xe4chste Mal seltener verwenden?",
                "Womit m\xf6chte ich heute noch die meiste Zeit verbringen?",
                "Ich habe mich heute sehr wohl gef\xfchlt, als ...",
                "Was ist immer noch frustrierend f\xfcr mich?",
                "Was habe ich heute vermisst?",
                "Wo sehe ich noch Entwicklungspotenzial?",
                "Wor\xfcber musste ich heute l\xe4cheln?",
                "Welcher Beitrag eines Teammitglieds war heute besonders wertvoll?",
                "Wann habe ich heute meine Komfortzone verlassen?",
                "Was m\xf6chte ich der Runde noch sagen?",
                "Was fiel mir heute leicht?",
                "F\xfcr ein n\xe4chstes Mal w\xfcrde ich mir w\xfcnschen ...",
                "Was h\xe4tten wir heute mit einer Extra-Stunde anfangen sollen?",
                "Was war heute meine gr\xf6\xdfte Herausforderung?",
                "Wor\xfcber werde ich weiter nachdenken?",
                "Nach dem heutigen Tag f\xfchle ich mich vorbereitet f\xfcr ...",
                "Was ist mir heute deutlicher geworden?",
                "Was werde ich ab sofort \xe4ndern?",
                "Was werde ich weiterhin beibehalten?",
                "Welchen Vorsatz nehme ich mir heute mit?",
                "Wem werde ich von heute berichten?",
                "Welche drei Adjektive fallen mir zu heute ein?",
                "Was sehe ich jetzt klarer?",
                "Womit darf/sollte/muss ich mich noch weitergehend befassen?",
                "Was ist mein zentrales Learning von heute?",
                "Woran werde ich mich nach dem heutigen Tag erinnern?",
              ],
            },
          },
          ip = {
            titleRandomGenerator: "Random generator",
            subtitleRandomGenerator: "For custom questions etc.",
            buttonTextRandomPick: "New selection",
            buttonTextNewQuestion: "New questions",
            toggleSwitchTextRepeatQuestions: "Repeat questions",
            toggleSwitchTextRepeatUserEntries: "Repeat entries",
            checkin: {
              name: "Checkin",
              questions: [
                "What beautiful happened to me this week?",
                "What makes me happy about my job?",
                "Why can my colleagues/classmates/pupils/participants be happy to work with me?",
                "When did I laugh out loud the last time and why?",
                "What did my teachers think of me and what would they think of me today?",
                "Who was hit by my last snowball?",
                "In which situations am I superstitious?",
                "Do/Did I prefer Ernie or Bert?",
                "What's the proper article: 'Die' Nutella or 'das' Nutella?",
                "Something that makes me smile nearly every time.",
                "Which animal do I really like? In how far are we similar to each other?",
                "What would I like to get better at?",
                "What is on my eternal to-do-list?",
                "What are my plans for the upcoming weekend?",
                "Beach or mountains?",
                "Which is my favourite city?",
                "Have I ever stolen something?",
                "If I had 1000,- Euros I could only spend today I would\u2026",
                "That did I learn about myself in the past year:",
                "I could nourish myself with these three different food for a lifetime:",
                "What will I say yes to today?",
                "What was I thankful for today?",
                "What I love about my occupation is:",
                "Today I will\u2026",
                "What would I like to try in my life?",
                "What has to happen to make this day perfect?",
                "A very nice present I once received\u2026",
                "What did I leave behind this year?",
                "A philosophy of life of me\u2026",
                "Who or what will get my attention today?",
                "If my current mood was a song or a movie\u2026",
                "Which character trait do I appreciate most about my friends?",
                "What do I really look forward to?",
                "What fascinates me?",
                "What was my first thought today?",
                "What am I proud of?",
                "What was the best about my day so far?",
                "My favourite hobby\u2026",
                "Whom did I accidentally meet abroad?",
                "How could we create our shared day perfectly?",
                "Why am I here today?",
                "Which superpower would I like to have?",
                "What was the first CD I ever bought?",
                "Dog or cat?",
                "What is my current favourite food?",
                "What is my current favourite piece of clothes?",
                "Which dish did I cook last?",
                "If I were an animal I'd prefer to be a\u2026",
                "My anti-hobby is...",
                "Compliment somebody in this round.",
                "Which book did I read last?",
                "A movie or a series recommendation\u2026",
                "What would I do if I wasn't here \u2026",
                "Where would I like to be on holiday at the moment?",
                "Which big journey is still ahead?",
                "Do I have a role model or an idol?",
                "What does nobody in this round know about me?",
                "A piece of information that would only apply to me in this round\u2026",
                "I never have\u2026",
                "My favourite season\u2026",
                "What is the worst job I ever had?",
                "What would I like to learn today?",
                "Which of my current character traits was already distinctive in my childhood?",
                "Something I would finally like to end:",
                "Something I would finally like to start:",
                "Which word should we use more and which one less?",
                "What drives me mad?",
                "One of my weaknesses \u2026",
                "One of my strengths \u2026",
                "What was a good advice I once received?",
                "What was a good advice I once gave?",
                "Which thing would I rescue from my burning house?",
                "Which three things would I take with me to a lonely island?",
                "My favourite colour \u2026",
                "Which is the most beautiful place near my place of residence?",
                "That used to be my great passion as a child \u2026",
                "Which colour am I today and why?",
                "My highlight this week up to now:",
                "What is my favourite geometrical shape and why?",
                "If I was not here right now, I would be in/at\u2026",
                "What offers me power?",
                "If I could learn a foreign language over night, which language would I choose?",
                "Who should star in the film adaption of my life? ",
                "What could be the title of my autobiography?",
                "When was the last time I did something for the first time?",
                "What can I do above-average?",
                "With which famous person would I like to switch for a day?",
                "Which job would I like to try for a week?",
                "Which three values matter a lot to me?",
                "These conditions support my ability to learn:",
                "One of my childhood heroes\u2026",
                "If I was to pass a law which all people had to abide worldwide \u2026",
                "How am I here today - in how far is typical of me or special?",
                "What had been the three most important milestones in my autobiography?",
                "Which is my favourite place in my home?",
                "Which sport would I rather watch than do?",
                "Which musical genre do I really like and which not?",
                "What is sometimes a real worry to me?",
                "Something that helps me relax and recover:",
                "Optimist or pessimist: How would I describe myself?",
              ],
            },
            kennenlernen: {
              name: "Get to know",
              questions: [
                "Where did you grow up?",
                "Would you go to a concert on your own? Is there anything you would never do on your own? Say why!",
                "Do you have a favourite book? If so, why do you like it that much?",
                "Do you have siblings?",
                "Holidays: Mountains or sea?",
                "Which hobbies did you use to have and do not have anymore today?",
                "What did you once believe in that turned out to be wrong?",
                "What would you do if you won a million euros?",
                "Do you know the story behind your name?",
                "If you were an animal, which would it be and why?",
                "Which three things would you take with you to a lonely island?",
                "What happened to you recently that made you really happy?",
                "If you had the chance to choose again: Which job would you like to learn?",
                "Do you play an instrument or would you like to play one?",
                "What was your dream job when you were a child?",
                "Which food couldn't you live without?",
                "Which is your favourite ice cream flavour?",
                "Do you enjoy cooking?",
                "Which is your favourite corner in your home?",
                "What is your favourite hobby?",
                "What is your favourite colour? Have you ever bought anything just because of the colour?",
                "Do you have an idol or a role model?",
                "Which kind of music do you prefer listening to?",
                "What is the first thing a good friend would say to describe you?",
              ],
            },
            entwederOder: {
              name: "Either or",
              questions: [
                "Sparkling wine or juice",
                "Beer or wine",
                "Red or white wine",
                "Mountains or the sea",
                "Apple or Windows",
                "Cat or dog",
                "City or countryside",
                "Car or train",
                "Holiday house or hotel",
                "Mobile phone or smartphone",
                "Vegetables or fruit",
                "Disco or club",
                "Cooking or takeaway",
                "Pizza or pasta",
                "Skiing or snowboarding",
                "Theatre play or cinema ",
                "Thriller or comedy",
                "Metal or classic",
                "Camping or hotel",
                "fir tree or oak",
                "Snow or sunshine",
                "Hiking or going for a walk",
                "Strawberry or cherry",
                "Sausages or cheese",
                "Sweet or hearty dishes",
                "Bread or muesli",
                "Music or silence",
                "Paris or London",
                "Coffee or tea",
                "Holmes or Watson",
                "Western movie or medieval movie",
                "Beatles or Stones",
                "Justin Bieber or Lady Gaga",
                "Roses or lillies",
                "Bonfire or chimney",
                "Swing or seesaw",
                "Train or bus",
                "Lord of the rings or Harry Potter",
                "Mosquito or crane fly",
                "Moin or servus",
                "Alt or K\xf6lsch",
                "White or brown bread",
                "Semmel or br\xf6tchen",
                "Fasching or Karneval",
                "Bigbluebutton or Zoom",
                "Ketchup or mayo",
                "Bike or e-bike",
                "Popcorn or nachos",
                "Chocolate or crisps",
                "Singing or dancing",
                "Butter or margarine",
                "Early riser or night owl",
                "Email or letter",
                "Sundae or cornet ice cream",
                "Mobile phone or landline",
                "Running or gym",
                "Helau or Alaaf",
              ],
            },
            zielsetzung: {
              name: "Objectives",
              questions: [
                "What is the next step?",
                "Why is our/my goal attractive?",
                "How is our/my goal most likely achievable?",
                "What has to be done to make our/my goal clear?",
                "How can our/my goal be described in one single sentence?",
                "What can I do NOT to reach our goal?",
                "On scale of 0 (not at all) to 10 (strongly): How much do I want to reach our/my goal?",
                "On scale of 0 (not at all) to 10 (strongly): How much do I believe to reach our/my goal on the basis of my current means?",
                "Who would be happy if I reached our/my goal?",
                "Who would be happy if I did not reach our/my goal?",
                "Are there competing goals I would like to reach as well?",
                "How long do I expect does it take me to reach our/my goal? ",
                "Are there graduations for my goal (as in school grades): What would an A-graded aim look like, what would a C-graded aim look like and so forth\u2026?",
                "Who would notice if I reached my goal?",
                "Is there a time schedule for my goal?",
                "How realistic is our/my goal?",
                "What kind of help (from others/elsewhere) do I need? ",
                "How can be checked if our/my aim has been achieved?",
                "Is my goal a part of a goal or a goal on its own?",
                "Is my goal flexible or static?",
                "Since when do I pursue my goal? What made me pursue this goal?",
                "Have I ever pursued a similar goal (as I do now)? What was its result?",
                "Is it a private or a professional goal or both?",
                "Who or what made me pursue this goal? ",
                "Is there anybody pursuing this goal more than me?",
                "Is this an intrinsic or extrinsic goal?",
                "Do I know anybody who has already reached this goal? How did he/she reach it?",
                "Which skills and character traits do I have that make me able to reach my goal?",
                "If I had to describe this goal as a picture or a metaphor: What would cross my mind?",
                "What is more important to me: My goal or the way to reach it?",
                "Who do I need to support me to reach my goal?",
                "Which resources does it take to reach my goal?",
              ],
            },
            teamarbeit: {
              name: "Teamwork",
              questions: [
                "At our workplaces we definitely need ... ",
                "In the last year the following has changed:",
                "In the future we will make decisions by ... ",
                "As a team this year we should definitely ... ",
                "I come to work on Mondays with a smile when ...",
                "I still need ... to feel more comfortable in my team.",
                "I would leave the team if ...",
                "A year from now I will look back proudly on our teamwork because ...",
                "In the future I would like to play the role of ... in my team.",
                "What we should definitely avoid is ... ",
                "I will contribute to a good working atmosphere by ...",
                "The following perspective is currently missing in our team: ",
                "I need ... to be able to do my job well.",
                "We should quit ...",
                "We need more of ...",
                "What I particularly appreciate about my team is ... ",
                "Successful teamwork is essentially influenced by ...",
              ],
            },
            kontroversen: {
              name: "Controversies",
              questions: [
                "Should the daily use of Netflix etc. be restricted by law?",
                "Should e-scooters be banned from all cities?",
                "Should all university lectures get filmed?",
                "Do we need a 6-hour workday?",
                "Do we need an unconditinional basic income?",
                "Should smoking in public be forbidden?",
                "Do we need an obligatory social year?",
                "Do we need a right to have a 'closing time'?",
                "Do we need a speed limit of 130 km/h on the highway?",
                "Should helmets be mandatory for cyclists?",
                "Should begging be forbidden in city centers?",
                "Should homework be abolished?",
                "Should the salary of politicians be based on the average income of the citizens of their country?",
                "Should job applications be anonymous by law?",
                "Should school uniforms be mandatory?",
                "Do we need a zero-alcohol-level on the roads?",
                "Should beauty contests (such as pageants) be forbidden?",
              ],
            },
            gruppenreflexion: {
              name: "Group reflection",
              questions: [
                "How did I experience our group work?",
                "Was everyone able to contribute?",
                "How satisfied am I with our results?",
                "Did we work in a division of labor? How did it work?",
                "What worked well?",
                "What could we do better next time?",
                "What should we keep doing/being as a group?",
                "Was there anything that irritated me?",
                "What surprised me?",
                "What was easy for us?",
                "What did we have difficulty with?",
                "Where could we use some advice?",
                "What would an outsider have thought if he/she had observed us?",
                "How did we coordinate and align ourselves?",
                "How did I experience the atmosphere during the group work?",
              ],
            },
            checkout: {
              name: "Checkout",
              questions: [
                "What is my precise next step?",
                "What did I get caught on today?",
                "That made me happy today:",
                "That is what I want to keep:",
                "That was astonishing for me happy today:",
                "That inspired me today:",
                "That is something I would like to do again:",
                "What will be my last thought today?",
                "That was a positive surprise today:",
                "That was impressive today:",
                "Which three steps will I take next?",
                "What was I able to observe about myself today?",
                "Which sentence would I like to get off my chest?",
                "A compliment to a person for a clever sentence or a good action.",
                "How did I feel at the beginning of today and how do I feel now?",
                "After today I think\u2026",
                "What was my primary focus today?",
                "If my current mood was be an animal, it would be a\u2026",
                "What was advantageous for me today?",
                "One of today's highlights today was\u2026",
                "What do I leave behind here today?",
                "What do I want to try or do next?",
                "What do I need more of?",
                "What do I need less of?",
                "What will I do differently next time?",
                "Today I was\u2026.",
                "Which problem could I solve today?",
                "How satisfied am I about myself today?",
                "How will I reward myself for participating today?",
                "What was today's most precious mistake?",
                "Which word should we use less next time?",
                "What do I want to spend most of today's time with?",
                "I felt quite well today when...",
                "What do I still regard as frustrating?",
                "What did I miss?",
                "Where do I see potential of development?",
                "What could I smile about today?",
                "Which contribution of another participant was extraordinary worthwhile for me today?",
                "When did I leave my comfort zone today?",
                "What do I want to tell this group?",
                "What was easy for me today?",
                "Next time I hope for\u2026",
                "What could we have done with an extra hour today?",
                "What was my greatest challenge today?",
                "What will I keep thinking about?",
                "After today I feel prepared for\u2026",
                "What became clearer for me today?",
                "What will I change from now on?",
                "What will I keep doing?",
                "Which resolution will I take on today?",
                "Whom will I tell about today?",
                "Which three adjectives do I connect with today?",
                "What do I see clearer now?",
                "What will I have to / want to / need to keep dealing with?",
                "What is my main learning of today? ",
                "What will I remember after today?",
              ],
            },
          },
          op = {
            titleRandomGenerator: "Generador aleatorio",
            subtitleRandomGenerator: "Para preguntas personalizadas, etc.",
            buttonTextRandomPick: "Nueva selecci\xf3n",
            buttonTextNewQuestion: "Nueva pregunta",
            toggleSwitchTextRepeatQuestions: "Repetir preguntas",
            toggleSwitchTextRepeatUserEntries: "Repetir entradas",
            checkin: {
              name: "Checkin",
              questions: [
                "\xbfQu\xe9 me ha pasado esta semana realmente especial?",
                "\xbfQu\xe9 me satisface de mi trabajo?",
                "\xbfPor qu\xe9 mis colegas/ alumnado/ TN se pueden sentir bien/comodos trabajando conmigo?",
                "\xbfCu\xe1ndo y sobre qu\xe9 me re\xed con ganas por \xfaltima vez?",
                "\xbfQu\xe9 pensaban sobre m\xed mis profesores y que dir\xedan hoy?",
                "\xbfQui\xe9n fue topado/golpeado por mi \xfaltima bola de nieve?",
                "\xbfEn que ocasiones me muestro supersticioso?",
                "\xbfA qui\xe9n prefiero: A Epi o a Blas?",
                "\xbfC\xf3mo se dice correctamente: Die Nutella o Das Nutella?",
                "Algo que siempre me hace re\xedr.",
                "\xbfQu\xe9 animal me gusta m\xe1s? \xbfEn qu\xe9 nos parecemos?",
                "\xbfEn qu\xe9 me gustar\xeda mejorar?",
                "\xbfQu\xe9 aparece en mi eterna lista de cosas que hacer?",
                "\xbfCu\xe1les son mis planes para el pr\xf3ximo fin de semana?",
                "\xbfPlaya o monta\xf1a?",
                "\xbfCu\xe1l es mi ciudad favorita?",
                "\xbfAlguna vez he robado algo?",
                "Si tuviera 1000 Euros para gastar solo hoy, har\xeda ...",
                "Eso lo aprend\xed sobre m\xed mismo el pasado a\xf1o:",
                "Con esos tres alimentos me podr\xeda alimentar siempre.",
                "\xbfA qu\xe9 dir\xeda hoy s\xed?",
                "\xbfPor qu\xe9 me siento hoy agradecido?",
                "Lo que me encanta de mi profesi\xf3n es:",
                "Hoy har\xe9 ...",
                "\xbfQu\xe9 me gustar\xeda probar en la vida?",
                "\xbfQu\xe9 deber\xeda pasar hoy, para qu\xe9 el d\xeda fuera perfecto?",
                "Un regalo muy bonito, que alguna vez he recibido....",
                "\xbfQu\xe9 he dejado atr\xe1s este a\xf1o?",
                "Mi filosof\xeda de vida:",
                "\xbfQui\xe9n o qu\xe9 capta hoy mi atenci\xf3n?",
                "Si mi humor de hoy fuera una canci\xf3n o una pel\xedcula, ser\xeda ...",
                "\xbfQu\xe9 cualidades prefiero en mis amistades?",
                "\xbfSobre qu\xe9 me alegro especialmente en estos momentos?",
                "\xbfQu\xe9 me fascina?",
                "\xbfCu\xe1l fue hoy mi primer pensamiento?",
                "\xbfDe qu\xe9 me siento orgulloso?",
                "\xbfQu\xe9 ha sido, hasta el momento, lo mejor del d\xeda?",
                "Mi afici\xf3n preferida...",
                "\xbfA qui\xe9n encontr\xe9 por casualidad en el extranjero?",
                "\xbfC\xf3mo podr\xedamos hoy planear perfectamente nuestro d\xeda juntos?",
                "\xbfPor qu\xe9 estoy aqu\xed hoy?",
                "\xbfQu\xe9 superpoder me gustar\xeda tener?",
                "\xbfCu\xe1l fue el primer CD que me compr\xe9?",
                "\xbfPerro o gato?",
                "\xbfCu\xe1l es, ahora mismo, mi comida favorita?",
                "\xbfCu\xe1l es, ahora mismo, mi prenda de ropa favorita?",
                "\xbfQu\xe9 plato cocin\xe9 la \xfaltima vez?",
                "Si fuera un animal, preferir\xeda ser...",
                "Mi anti-hobby es...",
                "Haz un cumplido a alguien de la reuni\xf3n.",
                "\xbfCu\xe1l es el \xfaltimo libro que he le\xeddo?",
                "Una recomendaci\xf3n de una pel\xedcula o de una serie.",
                "\xbfQu\xe9 estar\xeda haciendo ahora, si no estuviera aqu\xed?",
                "\xbfD\xf3nde estar\xeda ahora de vacaciones?",
                "Un gran viaje a\xfan por planificar ...",
                "\xbfTengo un modelo o \xeddolo?",
                "\xbfQu\xe9 es lo que nadie de esta reuni\xf3n conoce de m\xed?",
                "Una informaci\xf3n que, en esta reuni\xf3n, solo es palicable a m\xed \u2026",
                "Nunca he ...",
                "Mi estaci\xf3n del a\xf1o favorita:",
                "\xbfCu\xe1l fue el peor trabajo que he tenido?",
                "\xbfQu\xe9 me gustar\xeda aprender hoy?",
                "\xbfCu\xe1l de mis rasgos de personalidad ya estaba presente en mi infancia?",
                "Algo con lo que me gustar\xeda, por f\xedn, terminar.",
                "Algo que me gustar\xeda, por fin, empezar.",
                "\xbfQu\xe9 palabra deber\xedamos usar con m\xe1s o menos frecuencia?",
                "\xbfQu\xe9 me saca de quicio?",
                "Una de mis debilidades ...",
                "Una de mis fortalezas ...",
                "\xbfQu\xe9 buen consejo he recibido alguna vez?",
                "\xbfQu\xe9 buen consejo he dado alguna vez?",
                "\xbfQu\xe9 objeto rescatar\xeda de mi casa en llamas?",
                "\xbfQu\xe9 tres objetos llevar\xeda conmigo a una isla desierta?",
                "Mi color favorito ...",
                "\xbfCu\xe1l es el lugar m\xe1s bonito en los alrededores de donde vivo?",
                "Esa era mi pasi\xf3n de ni\xf1o ...",
                "\xbfDe qu\xe9 color me siento hoy y por qu\xe9?",
                "Mi acontecimiento m\xe1s importante de la semana ...",
                "\xbfCu\xe1l es mi figura geom\xe9trica favorita y por qu\xe9?",
                "Si no estuviera ahora mismo aqu\xed, \xbfd\xf3nde estar\xeda entonces?",
                "\xbfQu\xe9 me proporciona energ\xeda?",
                "Si pudiera aprender un idioma durante la noche, \xbfcu\xe1l escoger\xeda?",
                "\xbfQui\xe9n har\xeda el papel protagonista en la pel\xedcula sobre mi vida?",
                "\xbfC\xf3mo se podr\xeda titular mi autobiograf\xeda?",
                "\xbfCu\xe1ndo fue la \xfaltima vez que hice algo por primera vez en mi vida?",
                "\xbfQu\xe9 s\xe9 hacer por encima de la media?",
                "\xbfCon qu\xe9 conocidas personalidades me cambiar\xeda por un d\xeda?",
                "\xbfQu\xe9 trabajo me gustar\xeda probar durante una semana?",
                "\xbfQu\xe9 tres valores son importantes para m\xed?",
                "\xbfBajo qu\xe9 condiciones aprendo mejor?",
                "Un h\xe9roe de mi infancia ...",
                "Si pudiera eximir de cumplir una ley que toda la humanidad debe cumplir ...",
                "\xbfC\xf3mo estoy hoy aqu\xed? \xbfHasta que punto es algo t\xedpico o at\xedpico en m\xed?",
                "\xbfCu\xe1les han sido los tres pilares m\xe1s importantes de mi vida?",
                "\xbfCu\xe1l es el rinc\xf3n preferido de mi vivienda?",
                "\xbfQu\xe9 deporte prefiero ver que practicar?",
                "\xbfQu\xe9 g\xe9nero musical me gusta especialmente y cu\xe1l no?",
                "Eso me genera quebraderos de cabeza:",
                "Algo que me ayuda a relajarme y a recuperarme.",
                "Optimista o pesimista: \xbfC\xf3mo me definir\xeda a m\xed mismo?",
              ],
            },
            kennenlernen: {
              name: "Conocerse",
              questions: [
                "\xbfEn qu\xe9 lugar creciste?",
                "\xbfIr\xedas solo a un concierto? \xbfQu\xe9 es lo que nunca har\xedas solo? y \xbfpor qu\xe9?",
                "\xbfTienes un libro favorito? En caso de que s\xed: \xbfPor qu\xe9 te gusta tanto?",
                "\xbfTienes hermanos?",
                "Vacaciones: \xbfEn el mar o en la monta\xf1a?",
                "\xbfQu\xe9 aficiones ten\xedas antes que ya no realizas ahora?",
                "\xbfQu\xe9 creencias tuviste, que luego las tuvieras por falsas?",
                "Si ganaras un mill\xf3n de euros, \xbfqu\xe9 har\xedas?",
                "\xbfConoces la historia que hay detr\xe1s de tu nombre?",
                "Si fueras un animal, \xbfcu\xe1l ser\xedas y por qu\xe9?",
                "\xbfQu\xe9 tres cosas llevar\xedas a una isla desierta y por qu\xe9?",
                "\xbfQu\xe9 te ha pasado recientemente, que te haya hecho inmensamente feliz?",
                "Si pudieras elegir otra vez, \xbfqu\xe9 profesi\xf3n te gustar\xeda aprender?",
                "\xbfTocas un instrumento o te gustar\xeda tocar alguno?",
                "\xbfCu\xe1l era en tu ni\xf1ez la profesi\xf3n de tus sue\xf1os?",
                "\xbfSin qu\xe9 comida no podr\xedas vivir?",
                "\xbfCu\xe1l es tu sabor de helado favorito?",
                "\xbfTe gusta cocinar?",
                "\xbfCu\xe1l es tu rinc\xf3n favorito de la casa?",
                "\xbfCu\xe1l es tu afici\xf3n favorita?",
                "\xbfCu\xe1l es tu color favorito? \xbfHas comprado algo alguna vez solo porque era de ese color?",
                "\xbfTienes alg\xfan modelo o \xeddolo?",
                "\xbfQu\xe9 tipo de m\xfasica prefieres escuchar?",
                "\xbfCu\xe1l es la primera caracter\xedstica que tus amigos usar\xedan para describirte?",
              ],
            },
            entwederOder: {
              name: "Una de dos",
              questions: [
                "Champ\xe1n o zumo",
                "Cerveza o vino",
                "Vino blanco o tinto",
                "Mar o monta\xf1a",
                "Apple o Windows/ Mac o Microsoft",
                "Gato o perro",
                "Ciudad o pueblo",
                "Coche o tren",
                "Hotel o residencia de vacaciones",
                "M\xf3vil o tel\xe9fono inteligente",
                "Verdura o fruta",
                "Discoteca o bar",
                "Cocinar o pedido a domicilio",
                "Pizza o pasta",
                "Esqu\xed o snowboard",
                "Cine o teatro",
                "Suspense o comedia",
                "Heavy o cl\xe1sica",
                "Camping o Hotel",
                "Bosque de con\xedferas o caducifolio",
                "Sol o nieve",
                "Senderismo o caminar",
                "Fresas o cerezas",
                "Salchichas o queso",
                "Desayuno dulce o salado",
                "Pan o cereales",
                "M\xfasica o silencio",
                "Par\xeds o Londres",
                "T\xe9 o caf\xe9",
                "Sherlock Holmes o Dr. Watson",
                "Pel\xedcula del oeste o de la Edad Media",
                "Beatles o Stones",
                "Rammstein o la Kelly Family",
                "Rosas o lilas",
                "Hoguera o chimenea",
                "Columpio o balanc\xedn",
                "Tren o bus",
                "El se\xf1or de los anillos o Harry Potter",
                "Mosquito o zancudo",
                "Hola o Adi\xf3s",
                "Alt o Kolsch",
                "Pan blanco o negro",
                "Semmel o Br\xf6tchen",
                "Fasching o Karneval",
                "BigBlueButton o Zoom",
                "Mayonesa o K\xe9tchup",
                "Bicicleta el\xe9ctrica o bicicleta",
                "Palomitas o nachos",
                "Chocolate o patatas",
                "Bailar o cantar",
                "Mantequilla o margarina",
                "B\xfaho o Alondra",
                "Carta o e-mail",
                "Helado en tarrina o en cucurucho",
                "Tel\xe9fono m\xf3vil o fijo",
                "Gimnasio o correr",
                "Helau o Alaaf",
              ],
            },
            zielsetzung: {
              name: "Objetivos",
              questions: [
                "\xbfCu\xe1l es el siguiente paso?",
                "\xbfQu\xe9 te atrae de la meta?",
                "\xbfC\xf3mo se hace la meta m\xe1s llevadera?",
                "\xbfQu\xe9 tiene que suceder para despejar el camino a la meta?",
                "\xbfC\xf3mo se podr\xeda describir esa meta en una sola frase?",
                "\xbfQu\xe9 puedo hacer para no alcanzar la meta?",
                "En una escala del 0 (para nada) al 10 (muy fuerte): \xbfcu\xe1nto valoro alcanzar esa meta?",
                "En una escala del 0 (para nada) al 10 (muy fuerte): \xbfc\xf3mo valoro que puedo alcanzar la meta con los medios de los que dispongo actualmente?",
                "\xbfQui\xe9n se alegrar\xeda si alcanzara la meta?",
                "\xbfQui\xe9n se alegrar\xeda si no alcanzara la meta?",
                "\xbfHay metas competitivas que me gustar\xedan alcanzar tambi\xe9n?",
                "\xbfCu\xe1nto tiempo creo que tardar\xe9 en lograr mi objetivo?",
                "\xbfHay escalas en la meta, como por ejemplo en las notas de clase? \xbfC\xf3mo ser\xeda obtener un sobresaliente o un muy deficiente?",
                "\xbfQui\xe9n se dar\xeda cuenta de que la meta ha sido alcanzada?",
                "\xbfHay una planificaci\xf3n temporal para alcanzar el objetivo?",
                "\xbfC\xf3mo de realista es el objetivo?",
                "\xbfQu\xe9 ayuda externa necesito para llegar al objetivo?",
                "\xbfC\xf3mo puedo examinar que el prop\xf3sito ha sido alcanzado?",
                "\xbfEs un objetivo a corto, medio o largo plazo?",
                "\xbfEs una meta inamovible o flexible?",
                "\xbfDesde cu\xe1ndo persigo ese objetivo? \xbfC\xf3mo llegu\xe9 a \xe9l?",
                "\xbfAlguna vez hab\xeda perseguido un prop\xf3sito parecido? \xbfCon qu\xe9 resultados?",
                "\xbfEs un objetivo privado, profesional o algo de los dos?",
                "\xbfQui\xe9n o qu\xe9 me ha animado a perseguir mi objetivo?",
                "\xbfHay alguien que persiga este objetivo con a\xfan mayor empe\xf1o que yo?",
                "\xbfSe trata de un objetivo intr\xednseco o extr\xednseco?",
                "\xbfConozco a alguien que haya alcanzado una meta parecida antes? \xbfC\xf3mo lo logr\xf3?",
                "\xbfQu\xe9 competencias o cualidades poseo que me permitir\xe1n conseguir mi prop\xf3sito?",
                "Si tuviera que describir mi objetivo a trav\xe9s de una met\xe1fora o un cuadro, \xbfqu\xe9 imagen me viene a la cabeza?",
                "\xbfQu\xe9 me resulta m\xe1s importante: la meta o el camino para conseguirla?",
                "\xbfDe qui\xe9n necesito apoyo para alcanzar la meta?",
                "\xbfQu\xe9 recursos se necesitan para alcanzar el objetivo?",
              ],
            },
            teamarbeit: {
              name: "Trabajo en equipo",
              questions: [
                "En nuestros puestos de trabajo necesitamos indispensablemente ...",
                "El \xfaltimo a\xf1o ha cambiado lo siguiente:",
                "En el futuro tomaremos decisiones, en las cuales ...",
                "Este a\xf1o como equipo definitivamente deber\xedamos ...",
                "Llego los lunes a trabajar con una sonrisa, si ...",
                "Para encontrarme perfectamente c\xf3modo en el equipo, a\xfan necesito ...",
                "Dejar\xeda el equipo, si ...",
                "En un a\xf1o, cuando vuelva la vista atr\xe1s, me sentir\xe9 orgulloso de nuestro trabajo en equipo, porque ...",
                "En el futuro me gustar\xeda adoptar en el equipo el papel de ...",
                "Lo que deber\xedamos dejar definitivamente es ...",
                "Colaborar\xe9 en la creaci\xf3n de una buena atm\xf3sfera laboral mediante ...",
                "Las siguientes perspectivas a\xfan faltan en nuestro equipo actual:",
                "Para poder resolver bien mi trabajo, necesito ...",
                "Deberiamos terminar con ...",
                "Necesitamos m\xe1s ...",
                "Lo que valoro especialmente de mi equipo es ...",
                "Un trabajo en equipo exitoso est\xe1 esencialmente influido por ...",
              ],
            },
            kontroversen: {
              name: "Pol\xe9micas",
              questions: [
                "\xbfDeber\xeda estar restringido legalmente el uso rutinario de Netflix y otras plataformas similares?",
                "\xbfDeber\xedan estar prohibidas las motos el\xe9ctricas en las ciudades?",
                "\xbfDeber\xedan ser grabadas todas las clases universitarias?",
                "\xbfEs necesaria la jornada laboral de 6 horas?",
                "\xbfEs necesario un salario base sin condiciones?",
                "\xbfDeber\xeda estar proh\xedbido fumar en espacios p\xfablicos?",
                "\xbfEs necesario un a\xf1o obligatorio destinado a servicios sociales?",
                "\xbfEs necesario que el tiempo libre sea un derecho?",
                "\xbfEs necesario que la velocidad m\xe1xima permitida en la autopista sea de 130 Km/h?",
                "\xbfEs necesario que sea obligatorio el uso de casco en la bici?",
                "\xbfDeber\xeda estar proh\xedbida en el centro de las ciudades la mendicidad?",
                "\xbfDeber\xedan ser suprimidos los deberes para casa?",
                "\xbfDeber\xeda el sueldo en la politica estar calculado seg\xfan los ingresos medios de la ciudadan\xeda?",
                "\xbfDeber\xedan ser an\xf3nimas por ley las solicitudes de trabajo?",
                "\xbfEs necesario el uniforme de escuela?",
                "\xbfEs necesario una tasa de alcoholemia del 0% en nuestras carreteras?",
                "\xbfDeber\xedan estar proh\xedbidos los concursos de belleza, as\xed como la elecci\xf3n de misses?",
              ],
            },
            gruppenreflexion: {
              name: "Reflexi\xf3n grupal",
              questions: [
                "\xbfC\xf3mo he vivido nuestra experiencia de trabajo en grupo?",
                "\xbfPudo contribuir cada miembro del grupo?",
                "\xbfC\xf3mo de satisfecho estoy con los resultados?",
                "\xbfHemos hecho un reparto de tareas? \xbfC\xf3mo ha funcionado?",
                "\xbfQu\xe9 ha salido bien?",
                "\xbfQu\xe9 podr\xedamos mejorar la pr\xf3xima vez?",
                "\xbfQu\xe9 deber\xedamos mantener como grupo?",
                "\xbfHubo algo que me molestara?",
                "\xbfQu\xe9 me sorprendi\xf3?",
                "\xbfQu\xe9 nos result\xf3 f\xe1cil?",
                "\xbfCon qu\xe9 tuvimos dificultades?",
                "\xbfEn qu\xe9 parte podr\xedamos a\xfan necesitar consejo?",
                "\xbfQu\xe9 hubiera pensado un observador externo de nuestro trabajo?",
                "\xbfC\xf3mo nos hemos coordinado y puesto de acuerdo?",
                "\xbfQu\xe9 clima de trabajo me he encontrado durante el trabajo en equipo?",
              ],
            },
            checkout: {
              name: "Checkout",
              questions: [
                "\xbfQu\xe9 es lo siguiente que me propongo?",
                "\xbfQu\xe9 conclusiones saco de todo esto hoy?",
                "Algo que hoy me ha hecho feliz:",
                "Algo que me llevo conmigo:",
                "Algo que me ha sorprendido:",
                "Algo que me ha inspirado:",
                "Algo que me gustar\xeda repetir:",
                "\xbfCon qu\xe9 pensamientos termino hoy el d\xeda?",
                "Algo que me ha sorprendido para bien:",
                "Algo que me ha impresionado/infundido respeto:",
                "\xbfCu\xe1les ser\xe1n mis pr\xf3ximos tres pasos?",
                "\xbfQu\xe9 pude hoy observar de m\xed mismo?",
                "\xbfQu\xe9 frase quiero a\xfan quitar de mi cabeza?",
                "Un cumplido para una persona, una frase inteligente o un acto memorable:",
                "\xbfC\xf3mo me encontraba hoy al inicio y c\xf3mo me siento ahora?",
                "Tras el d\xeda de hoy pienso que ...",
                "\xbfEn qu\xe9 he puesto hoy mi foco principalmente?",
                "Si mi estado de \xe1nimo ahora mismo fuera un animal, ser\xeda ...",
                "\xbfQu\xe9 fue especialmente ventajoso para m\xed hoy?",
                "Uno de los momentos destacables del d\xeda ha sido ...",
                "\xbfQu\xe9 dejo aqu\xed hoy?",
                "\xbfQu\xe9 me gustar\xeda probar o poner en pr\xe1ctica despu\xe9s?",
                "\xbfDe qu\xe9 necesito m\xe1s?",
                "\xbfDe qu\xe9 necesito menos?",
                "\xbfQu\xe9 har\xeda distinto la pr\xf3xima vez?",
                "Hoy he estado/sido ...",
                "\xbfQu\xe9 problema he podido solucionar hoy?",
                "\xbfC\xf3mo de satisfecho estoy hoy conmigo mismo?",
                "\xbfC\xf3mo me recompensar\xe9 por mi participaci\xf3n de hoy?",
                "\xbfCu\xe1l ha sido hoy el error m\xe1s valioso?",
                "\xbfQu\xe9 palabra tenemos que usar menos la pr\xf3xima vez?",
                "\xbfA qu\xe9 quisiera dedicar a\xfan la mayor parte de mi tiempo hoy?",
                "Hoy me he sentido especialmente bien, cuando ...",
                "\xbfQu\xe9 es lo que a\xfan me frustra?",
                "\xbfQu\xe9 he olvidado hoy?",
                "\xbfD\xf3nde veo todav\xeda un posible potencial de desarrollo?",
                "\xbfSobre qu\xe9 me podr\xeda haber re\xeddo hoy?",
                "\xbfQu\xe9 aportaci\xf3n de otro participante del grupo ha sido especialmente valiosa para m\xed?",
                "\xbfCu\xe1ndo he salido hoy de mi zona de confort?",
                "\xbfQu\xe9 me gustar\xeda decirle al grupo a\xfan?",
                "\xbfQu\xe9 me result\xf3 f\xe1cil hoy?",
                "Para la pr\xf3xima vez desear\xeda ...",
                "\xbfQu\xe9 podr\xedamos haber hecho hoy con una hora extra?",
                "\xbfCu\xe1l ha sido hoy mi reto m\xe1s importante?",
                "\xbfSobre qu\xe9 seguir\xe9 hoy reflexionando?",
                "Tras el d\xeda de hoy me siento preparado para ...",
                "\xbfQu\xe9 se ha aclarado para m\xed?",
                "\xbfQu\xe9 cambiar\xe9 inmediatamente?",
                "\xbfQu\xe9 voy a seguir manteniendo?",
                "\xbfQu\xe9 prop\xf3sito me llevo hoy conmigo?",
                "\xbfA qui\xe9n le contar\xe9 sobre el d\xeda de hoy?",
                "\xbfQu\xe9 tres adjetivos me vienen a la cabeza sobre hoy?",
                "\xbfQu\xe9 veo ahora con mayor claridad?",
                "\xbfDe qu\xe9 deber\xeda/ querr\xeda/ necesitar\xeda ocuparme a\xfan?",
                "\xbfCu\xe1l es mi principal aprendizaje de hoy?",
                "\xbfDe qu\xe9 me voy a acordar tras el d\xeda de hoy?",
              ],
            },
          },
          ap =
            (((Yv = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || Yv)();
            }),
            (Yv.ɵmod = st({ type: Yv })),
            (Yv.ɵinj = Te({})),
            Yv),
          sp =
            ((($v = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || $v)();
            }),
            ($v.ɵmod = st({ type: $v })),
            ($v.ɵinj = Te({ imports: [[ap]] })),
            $v),
          up =
            (((Jv = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || Jv)();
            }),
            (Jv.ɵmod = st({ type: Jv })),
            (Jv.ɵinj = Te({ imports: [sp] })),
            Jv),
          lp = ["will-change", ""],
          cp = [
            [
              ["", "pIcon", ""],
              ["", "p-icon", ""],
              ["", "pSvg", ""],
              ["", "p-svg", ""],
              ["", "pImage", ""],
              ["", "p-image", ""],
            ],
            "*",
          ],
          hp = ["[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]", "*"],
          fp = ["inputElem"],
          dp = ["stateElem"];
        function vp(e, t) {
          1 & e &&
            (Ho(0, "div", 3, 4),
            Xo(2, 1),
            Ho(3, "label"),
            Xo(4, 2),
            Uo(),
            Uo());
        }
        var pp,
          mp,
          gp,
          yp = [
            [
              ["ngx-p-indeterminate"],
              ["p-indeterminate"],
              ["ngx-p-hover"],
              ["p-hover"],
              ["ngx-p-toggle"],
              ["p-toggle"],
            ],
            [
              ["", "pIcon", ""],
              ["", "p-icon", ""],
              ["", "pSvg", ""],
              ["", "p-svg", ""],
              ["", "pImage", ""],
              ["", "p-image", ""],
            ],
            "*",
          ],
          kp = [
            "ngx-p-indeterminate, p-indeterminate, ngx-p-hover, p-hover, ngx-p-toggle, p-toggle",
            "[pIcon], [p-icon], [pSvg], [p-svg], [pImage], [p-image]",
            "*",
          ],
          bp =
            (((gp = function e(t) {
              p(this, e),
                (this.elemRef = t),
                this.elemRef.nativeElement.classList.add("icon");
            }).ɵfac = function (e) {
              return new (e || gp)(Wo(Ea));
            }),
            (gp.ɵdir = lt({
              type: gp,
              selectors: [
                ["", "pIcon", ""],
                ["", "p-icon", ""],
              ],
            })),
            gp),
          wp =
            (((mp = function e(t) {
              p(this, e),
                (this.elemRef = t),
                this.elemRef.nativeElement.classList.add("image");
            }).ɵfac = function (e) {
              return new (e || mp)(Wo(Ea));
            }),
            (mp.ɵdir = lt({
              type: mp,
              selectors: [
                ["", "pImage", ""],
                ["", "p-image", ""],
              ],
            })),
            mp),
          _p =
            (((pp = (function () {
              function e(t) {
                p(this, e), (this.elemRef = t);
              }
              return (
                g(e, [
                  {
                    key: "ngAfterViewInit",
                    value: function () {
                      this.elemRef.nativeElement.classList.add("svg");
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || pp)(Wo(Ea));
            }),
            (pp.ɵdir = lt({
              type: pp,
              selectors: [
                ["", "pSvg", ""],
                ["", "p-svg", ""],
              ],
            })),
            pp),
          Sp = (function (e) {
            return (e.On = "on"), (e.Off = "off"), e;
          })({});
        function Cp(e, t) {
          return e ? "p-" + e + (t ? "-o" : "") : null;
        }
        function Ep(e) {
          return "boolean" == typeof e
            ? e
            : /^\s*(true|1|on|enable)\s*$/i.test(e);
        }
        var Tp,
          xp,
          Ip,
          Ap,
          Op,
          Rp,
          Pp,
          Dp,
          Np =
            (((Dp = (function () {
              function e(t, n) {
                p(this, e),
                  (this.el = t),
                  (this.renderer = n),
                  (this.outline = !1);
              }
              return (
                g(e, [
                  {
                    key: "ngOnChanges",
                    value: function (e) {
                      if (e.color || e.outline) {
                        var t = Cp(
                            e.color ? e.color.previousValue : this.color,
                            e.outline ? e.outline.previousValue : null
                          ),
                          n = Cp(
                            e.color ? e.color.currentValue : this.color,
                            e.outline ? e.outline.currentValue : null
                          );
                        this.renderer.removeClass(this.el.nativeElement, t),
                          this.renderer.addClass(this.el.nativeElement, n);
                      }
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Dp)(Wo(Ea), Wo(Ia));
            }),
            (Dp.ɵcmp = rt({
              type: Dp,
              selectors: [
                ["ngx-p-hover", "will-change", ""],
                ["p-hover", "will-change", ""],
              ],
              hostAttrs: [1, "state", "p-is-hover"],
              inputs: { outline: "outline", color: "color" },
              features: [wt],
              attrs: lp,
              ngContentSelectors: hp,
              decls: 3,
              vars: 0,
              template: function (e, t) {
                1 & e && (Yo(cp), Xo(0), Ho(1, "label"), Xo(2, 1), Uo());
              },
              encapsulation: 2,
              changeDetection: 0,
            })),
            Dp),
          Mp =
            (((Pp = (function () {
              function e(t, n) {
                var r =
                    arguments.length > 2 &&
                    void 0 !== arguments[2] &&
                    arguments[2],
                  i = arguments.length > 3 ? arguments[3] : void 0;
                p(this, e),
                  (this.el = t),
                  (this.renderer = n),
                  (this._outline = r),
                  (this._color = i);
              }
              return (
                g(e, [
                  {
                    key: "ngAfterViewInit",
                    value: function () {
                      this._color &&
                        this.renderer.addClass(
                          this.el.nativeElement,
                          "p-"
                            .concat(this._color)
                            .concat(this._outline ? "-o" : "")
                        );
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Pp)(Wo(Ea), Wo(Ia), Kn("outline"), Kn("color"));
            }),
            (Pp.ɵcmp = rt({
              type: Pp,
              selectors: [
                ["ngx-p-hover", 3, "will-change", ""],
                ["p-hover", 3, "will-change", ""],
              ],
              hostAttrs: [1, "state", "p-is-hover"],
              ngContentSelectors: hp,
              decls: 3,
              vars: 0,
              template: function (e, t) {
                1 & e && (Yo(cp), Xo(0), Ho(1, "label"), Xo(2, 1), Uo());
              },
              encapsulation: 2,
              changeDetection: 0,
            })),
            Pp),
          jp =
            (((Rp = (function () {
              function e(t, n) {
                p(this, e),
                  (this.el = t),
                  (this.renderer = n),
                  (this.outline = !1);
              }
              return (
                g(e, [
                  {
                    key: "ngOnChanges",
                    value: function (e) {
                      if (e.color || e.outline) {
                        var t = Cp(
                            e.color ? e.color.previousValue : this.color,
                            e.outline ? e.outline.previousValue : null
                          ),
                          n = Cp(
                            e.color ? e.color.currentValue : this.color,
                            e.outline ? e.outline.currentValue : null
                          );
                        this.renderer.removeClass(this.el.nativeElement, t),
                          this.renderer.addClass(this.el.nativeElement, n);
                      }
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Rp)(Wo(Ea), Wo(Ia));
            }),
            (Rp.ɵcmp = rt({
              type: Rp,
              selectors: [
                ["ngx-p-indeterminate", "will-change", ""],
                ["p-indeterminate", "will-change", ""],
              ],
              hostAttrs: [1, "state", "p-is-indeterminate"],
              inputs: { outline: "outline", color: "color" },
              features: [wt],
              attrs: lp,
              ngContentSelectors: hp,
              decls: 3,
              vars: 0,
              template: function (e, t) {
                1 & e && (Yo(cp), Xo(0), Ho(1, "label"), Xo(2, 1), Uo());
              },
              encapsulation: 2,
              changeDetection: 0,
            })),
            Rp),
          Fp =
            (((Op = (function () {
              function e(t, n) {
                var r =
                    arguments.length > 2 &&
                    void 0 !== arguments[2] &&
                    arguments[2],
                  i = arguments.length > 3 ? arguments[3] : void 0;
                p(this, e),
                  (this.el = t),
                  (this.renderer = n),
                  (this._outline = r),
                  (this._color = i);
              }
              return (
                g(e, [
                  {
                    key: "ngAfterViewInit",
                    value: function () {
                      this._color &&
                        this.renderer.addClass(
                          this.el.nativeElement,
                          "p-"
                            .concat(this._color)
                            .concat(this._outline ? "-o" : "")
                        );
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Op)(Wo(Ea), Wo(Ia), Kn("outline"), Kn("color"));
            }),
            (Op.ɵcmp = rt({
              type: Op,
              selectors: [
                ["ngx-p-indeterminate", 3, "will-change", ""],
                ["p-indeterminate", 3, "will-change", ""],
              ],
              hostAttrs: [1, "state", "p-is-indeterminate"],
              ngContentSelectors: hp,
              decls: 3,
              vars: 0,
              template: function (e, t) {
                1 & e && (Yo(cp), Xo(0), Ho(1, "label"), Xo(2, 1), Uo());
              },
              encapsulation: 2,
              changeDetection: 0,
            })),
            Op),
          Lp =
            (((Ap = (function () {
              function e(t, n) {
                p(this, e),
                  (this.el = t),
                  (this.renderer = n),
                  (this.outline = !1);
              }
              return (
                g(e, [
                  {
                    key: "isToggleOn",
                    get: function () {
                      return this.type === Sp.On;
                    },
                  },
                  {
                    key: "isToggleOff",
                    get: function () {
                      return this.type === Sp.Off;
                    },
                  },
                  {
                    key: "ngOnChanges",
                    value: function (e) {
                      if (e.color || e.outline) {
                        var t = Cp(
                            e.color ? e.color.previousValue : this.color,
                            e.outline ? e.outline.previousValue : null
                          ),
                          n = Cp(
                            e.color ? e.color.currentValue : this.color,
                            e.outline ? e.outline.currentValue : null
                          );
                        this.renderer.removeClass(this.el.nativeElement, t),
                          this.renderer.addClass(this.el.nativeElement, n);
                      }
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Ap)(Wo(Ea), Wo(Ia));
            }),
            (Ap.ɵcmp = rt({
              type: Ap,
              selectors: [
                ["ngx-p-toggle", "will-change", ""],
                ["p-toggle", "will-change", ""],
              ],
              hostAttrs: [1, "state"],
              hostVars: 4,
              hostBindings: function (e, t) {
                2 & e && ra("p-on", t.isToggleOn)("p-off", t.isToggleOff);
              },
              inputs: { outline: "outline", type: "type", color: "color" },
              features: [wt],
              attrs: lp,
              ngContentSelectors: hp,
              decls: 3,
              vars: 0,
              template: function (e, t) {
                1 & e && (Yo(cp), Xo(0), Ho(1, "label"), Xo(2, 1), Uo());
              },
              encapsulation: 2,
              changeDetection: 0,
            })),
            Ap),
          Wp =
            (((Ip = (function () {
              function e(t, n) {
                var r =
                    arguments.length > 2 &&
                    void 0 !== arguments[2] &&
                    arguments[2],
                  i = arguments.length > 3 ? arguments[3] : void 0,
                  o = arguments.length > 4 ? arguments[4] : void 0;
                p(this, e),
                  (this.el = t),
                  (this.renderer = n),
                  (this._outline = r),
                  (this._color = i),
                  (this._type = o);
              }
              return (
                g(e, [
                  {
                    key: "ngAfterViewInit",
                    value: function () {
                      this._color &&
                        this.renderer.addClass(
                          this.el.nativeElement,
                          "p-"
                            .concat(this._color)
                            .concat(this._outline ? "-o" : "")
                        ),
                        this.renderer.addClass(
                          this.el.nativeElement,
                          "p-".concat(this._type)
                        );
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Ip)(
                Wo(Ea),
                Wo(Ia),
                Kn("outline"),
                Kn("color"),
                Kn("type")
              );
            }),
            (Ip.ɵcmp = rt({
              type: Ip,
              selectors: [
                ["ngx-p-toggle", 3, "will-change", ""],
                ["p-toggle", 3, "will-change", ""],
              ],
              hostAttrs: [1, "state"],
              ngContentSelectors: hp,
              decls: 3,
              vars: 0,
              template: function (e, t) {
                1 & e && (Yo(cp), Xo(0), Ho(1, "label"), Xo(2, 1), Uo());
              },
              encapsulation: 2,
              changeDetection: 0,
            })),
            Ip),
          zp =
            (((xp = (function () {
              function e(t, n, r, i, o, a) {
                var s =
                    arguments.length > 6 &&
                    void 0 !== arguments[6] &&
                    arguments[6],
                  u =
                    arguments.length > 7 &&
                    void 0 !== arguments[7] &&
                    arguments[7],
                  l =
                    arguments.length > 8 &&
                    void 0 !== arguments[8] &&
                    arguments[8],
                  c =
                    arguments.length > 9 &&
                    void 0 !== arguments[9] &&
                    arguments[9],
                  h =
                    arguments.length > 10 &&
                    void 0 !== arguments[10] &&
                    arguments[10],
                  f = arguments.length > 11 ? arguments[11] : void 0;
                p(this, e),
                  (this.renderer = t),
                  (this.el = n),
                  (this.cd = r),
                  (this._stroke = i),
                  (this._shape = o),
                  (this._animation = a),
                  (this._isSwitch = s),
                  (this._bigger = u),
                  (this._enableFocus = l),
                  (this._plain = c),
                  (this._outline = h),
                  (this._color = f),
                  (this.checked = !1),
                  (this.disabled = !1),
                  (this.lock = !1),
                  (this.change = new xs()),
                  (this._isSwitch = Ep(this._isSwitch)),
                  (this._bigger = Ep(this._bigger)),
                  (this._enableFocus = Ep(this._enableFocus)),
                  (this._plain = Ep(this._plain)),
                  (this._outline = Ep(this._outline));
              }
              return (
                g(e, [
                  {
                    key: "ngAfterViewInit",
                    value: function () {
                      var e = this.el.nativeElement;
                      this._isSwitch && this.renderer.addClass(e, "p-switch"),
                        this._bigger && this.renderer.addClass(e, "p-bigger"),
                        this._enableFocus &&
                          this.renderer.addClass(e, "p-has-focus"),
                        this._plain && this.renderer.addClass(e, "p-plain"),
                        this._stroke &&
                          this.renderer.addClass(e, "p-".concat(this._stroke)),
                        this._shape &&
                          this.renderer.addClass(e, "p-".concat(this._shape)),
                        this._animation &&
                          this.renderer.addClass(
                            e,
                            "p-".concat(this._animation)
                          ),
                        this._iconDir && this.renderer.addClass(e, "p-icon"),
                        this._svgDir && this.renderer.addClass(e, "p-svg"),
                        this._imgDir && this.renderer.addClass(e, "p-image"),
                        (this._hoverDir || this._hoverWillChangeDir) &&
                          this.renderer.addClass(e, "p-has-hover"),
                        (this._indeterminateDir ||
                          this._indeterminateWillChangeDir) &&
                          this.renderer.addClass(e, "p-has-indeterminate"),
                        this._isToggle && this.renderer.addClass(e, "p-toggle"),
                        this._isSwitch ||
                          this._iconDir ||
                          this._svgDir ||
                          this._imgDir ||
                          this.renderer.addClass(e, "p-default"),
                        this._color &&
                          !this._isToggle &&
                          this.renderer.addClass(
                            this._stateElem.nativeElement,
                            "p-"
                              .concat(this._color)
                              .concat(this._outline ? "-o" : "")
                          );
                    },
                  },
                  {
                    key: "_onChange",
                    value: function (e, t, n) {
                      e.stopPropagation(),
                        (this.checked = t),
                        this.change.emit({ value: n, checked: t, event: e });
                    },
                  },
                  {
                    key: "_isToggle",
                    get: function () {
                      return (
                        2 === this._toggleComps.length ||
                        2 === this._toggleWillChangeComps.length
                      );
                    },
                  },
                  {
                    key: "setIndeterminate",
                    value: function () {
                      var e =
                        !(arguments.length > 0 && void 0 !== arguments[0]) ||
                        arguments[0];
                      this._inputElem.nativeElement.indeterminate = e;
                    },
                  },
                  {
                    key: "forceCheck",
                    value: function (e) {
                      (this.checked = e), this.cd.markForCheck();
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || xp)(
                Wo(Ia),
                Wo(Ea),
                Wo($a),
                Kn("stroke"),
                Kn("shape"),
                Kn("animation"),
                Kn("isSwitch"),
                Kn("bigger"),
                Kn("enableFocus"),
                Kn("plain"),
                Kn("outline"),
                Kn("color")
              );
            }),
            (xp.ɵcmp = rt({
              type: xp,
              selectors: [
                ["ngx-pretty-checkbox", 3, "will-change", ""],
                ["p-checkbox", 3, "will-change", ""],
              ],
              contentQueries: function (e, t, n) {
                var r;
                1 & e &&
                  (qs(n, bp, 1),
                  qs(n, _p, 1),
                  qs(n, wp, 1),
                  qs(n, Mp, 1),
                  qs(n, Np, 1),
                  qs(n, Fp, 1),
                  qs(n, jp, 1),
                  qs(n, Wp, 0),
                  qs(n, Lp, 0)),
                  2 & e &&
                    (Ws((r = Hs())) && (t._iconDir = r.first),
                    Ws((r = Hs())) && (t._svgDir = r.first),
                    Ws((r = Hs())) && (t._imgDir = r.first),
                    Ws((r = Hs())) && (t._hoverDir = r.first),
                    Ws((r = Hs())) && (t._hoverWillChangeDir = r.first),
                    Ws((r = Hs())) && (t._indeterminateDir = r.first),
                    Ws((r = Hs())) && (t._indeterminateWillChangeDir = r.first),
                    Ws((r = Hs())) && (t._toggleComps = r),
                    Ws((r = Hs())) && (t._toggleWillChangeComps = r));
              },
              viewQuery: function (e, t) {
                var n;
                1 & e && (zs(fp, 3), zs(dp, 1)),
                  2 & e &&
                    (Ws((n = Hs())) && (t._inputElem = n.first),
                    Ws((n = Hs())) && (t._stateElem = n.first));
              },
              hostAttrs: [1, "pretty"],
              hostVars: 2,
              hostBindings: function (e, t) {
                2 & e && ra("p-locked", t.lock);
              },
              inputs: {
                checked: "checked",
                disabled: "disabled",
                lock: "lock",
                value: "value",
              },
              outputs: { change: "change" },
              exportAs: ["ngxPrettyCheckbox"],
              ngContentSelectors: kp,
              decls: 4,
              vars: 4,
              consts: [
                [
                  "type",
                  "checkbox",
                  3,
                  "value",
                  "checked",
                  "disabled",
                  "change",
                ],
                ["inputElem", ""],
                ["class", "state", 4, "ngIf"],
                [1, "state"],
                ["stateElem", ""],
              ],
              template: function (e, t) {
                if (1 & e) {
                  var n = Bo();
                  Yo(yp),
                    Ho(0, "input", 0, 1),
                    Go("change", function (e) {
                      Ut(n);
                      var r = Lo(1);
                      return t._onChange(e, r.checked, r.value);
                    }),
                    Uo(),
                    Fo(2, vp, 5, 0, "div", 2),
                    Xo(3);
                }
                2 & e &&
                  (zo("value", t.value)("checked", t.checked)(
                    "disabled",
                    t.disabled
                  ),
                  yi(2),
                  zo("ngIf", !t._isToggle));
              },
              directives: [Il],
              encapsulation: 2,
            })),
            xp),
          qp =
            (((Tp = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || Tp)();
            }),
            (Tp.ɵmod = st({ type: Tp })),
            (Tp.ɵinj = Te({ imports: [[Pl, up]] })),
            Tp);
        function Hp(e, t) {
          if (
            (1 & e && (Ho(0, "div", 6), Ho(1, "h3"), la(2), Uo(), Uo()), 2 & e)
          ) {
            var n = Jo();
            zo("@showHide", n.show ? "show" : "hide")(
              "@languageChange",
              n.selectedLanguage
            ),
              yi(2),
              ha("> ", n.selectedQuestionSet.name, "");
          }
        }
        function Up(e, t) {
          if (
            (1 & e &&
              (Ho(0, "div", 7), Ho(1, "h1", null, 8), la(3), Uo(), Uo()),
            2 & e)
          ) {
            var n = Jo();
            zo("@showHide", n.show ? "show" : "hide")(
              "@languageChange",
              n.selectedLanguage
            ),
              yi(3),
              ca(n.selectedQuestionSet.questions[n.selectedIndex]);
          }
        }
        function Vp(e, t) {
          if (1 & e) {
            var n = Bo();
            Ho(0, "div"),
              Ho(1, "button", 9),
              Go("click", function () {
                return Ut(n), Jo().nextQuestion();
              }),
              la(2),
              Uo(),
              Uo();
          }
          if (2 & e) {
            var r = Jo();
            yi(2), ha(" ", r.questionnaire.buttonTextNewQuestion, " ");
          }
        }
        var Bp,
          Qp,
          Gp,
          Kp =
            (((Gp = (function () {
              function e(t, n, r) {
                p(this, e),
                  (this.activatedRoute = t),
                  (this.router = n),
                  (this.persistenceService = r),
                  (this.heading0 = ""),
                  (this.heading0Shown = ""),
                  (this.show = !0),
                  (this.startUpFadeIn = !1),
                  (this.repeatQuestions = !1),
                  (this.usedIndices = new Set()),
                  (this.selectedLanguage = np.GERMAN);
              }
              return (
                g(e, [
                  {
                    key: "ngOnInit",
                    value: function () {
                      var e = this;
                      (this.activatedRouteSubscription =
                        this.activatedRoute.paramMap.subscribe(function (t) {
                          var n = t.get("type");
                          e.usedIndices.clear(),
                            n
                              ? ((e.questionType = n.toLowerCase()),
                                e.nextQuestion())
                              : e.navigateToCheckin();
                        })),
                        (this.repeatQuestionsSubscription =
                          this.persistenceService
                            .getRepeatQuestions()
                            .subscribe(function (t) {
                              return (e.repeatQuestions = t);
                            })),
                        (this.languageSwitchSubscription =
                          this.persistenceService
                            .getSelectedLanguage()
                            .subscribe(function (t) {
                              switch (
                                ((e.selectedLanguage = t), e.selectedLanguage)
                              ) {
                                case np.GERMAN:
                                  e.questionnaire = rp;
                                  break;
                                case np.ENGLISH:
                                  e.questionnaire = ip;
                                  break;
                                case np.SPANISH:
                                  e.questionnaire = op;
                                  break;
                                default:
                                  e.questionnaire = rp;
                              }
                              e.updateSelectedQuestionSetTranslation();
                            }));
                    },
                  },
                  {
                    key: "ngAfterViewInit",
                    value: function () {
                      var e = this;
                      window.scrollTo(0, 0),
                        setTimeout(function () {
                          e.startUpFadeIn = !0;
                        }, 700);
                    },
                  },
                  {
                    key: "updateRepeatQuestionIsAllowed",
                    value: function (e) {
                      (this.repeatQuestions = e),
                        this.persistenceService.setRepeatQuestions(e);
                    },
                  },
                  {
                    key: "nextQuestion",
                    value: function () {
                      var e = this;
                      (this.show = !1),
                        setTimeout(function () {
                          e.updateSelectedQuestionSetTranslation();
                          var t = e.getRandomIndexForQuestions(
                            e.selectedQuestionSet
                          );
                          if (!e.repeatQuestions)
                            for (
                              e.usedIndices.size ===
                                e.selectedQuestionSet.questions.length &&
                              e.usedIndices.clear();
                              e.usedIndices.has(t);

                            )
                              (t = e.getRandomIndexForQuestions(
                                e.selectedQuestionSet
                              )),
                                (e.show = !1);
                          e.usedIndices.add(t),
                            (e.selectedIndex = t),
                            console.log(
                              "Questions left: ",
                              e.selectedQuestionSet.questions.length -
                                e.usedIndices.size
                            ),
                            (e.heading0Shown = e.heading0);
                        }, 1500),
                        setTimeout(function () {
                          e.show = !0;
                        }, 1800);
                    },
                  },
                  {
                    key: "updateSelectedQuestionSetTranslation",
                    value: function () {
                      switch (this.questionType) {
                        case "checkin":
                          this.selectedQuestionSet = this.questionnaire.checkin;
                          break;
                        case "kennenlernen":
                          this.selectedQuestionSet =
                            this.questionnaire.kennenlernen;
                          break;
                        case "entweder_oder":
                          this.selectedQuestionSet =
                            this.questionnaire.entwederOder;
                          break;
                        case "zielsetzung":
                          this.selectedQuestionSet =
                            this.questionnaire.zielsetzung;
                          break;
                        case "teamarbeit":
                          this.selectedQuestionSet =
                            this.questionnaire.teamarbeit;
                          break;
                        case "kontroversen":
                          this.selectedQuestionSet =
                            this.questionnaire.kontroversen;
                          break;
                        case "gruppenreflexion":
                          this.selectedQuestionSet =
                            this.questionnaire.gruppenreflexion;
                          break;
                        case "checkout":
                          this.selectedQuestionSet =
                            this.questionnaire.checkout;
                          break;
                        default:
                          this.selectedQuestionSet = this.questionnaire.checkin;
                      }
                    },
                  },
                  {
                    key: "getRandomIndexForQuestions",
                    value: function (e) {
                      return Math.round(
                        Math.random() * (e.questions.length - 1)
                      );
                    },
                  },
                  {
                    key: "navigateToCheckin",
                    value: function () {
                      this.router.navigate(["checkin"]);
                    },
                  },
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      this.repeatQuestionsSubscription.unsubscribe(),
                        this.activatedRouteSubscription.unsubscribe(),
                        this.languageSwitchSubscription.unsubscribe();
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Gp)(Wo(Df), Wo(nv), Wo(ep));
            }),
            (Gp.ɵcmp = rt({
              type: Gp,
              selectors: [["app-home"]],
              decls: 7,
              vars: 6,
              consts: [
                ["id", "home-wrapper"],
                ["id", "questionTypePrettyFormatted", 4, "ngIf"],
                [
                  "style",
                  "flex-grow: 1;",
                  "class",
                  "animated-text-big",
                  4,
                  "ngIf",
                ],
                [
                  2,
                  "display",
                  "flex",
                  "justify-content",
                  "center",
                  "align-items",
                  "center",
                  "margin-top",
                  "-15px",
                  "margin-bottom",
                  "15px",
                ],
                [4, "ngIf"],
                [
                  "color",
                  "primary",
                  "isSwitch",
                  "true",
                  "stroke",
                  "fill",
                  2,
                  "display",
                  "flex",
                  "justify-content",
                  "center",
                  "margin-left",
                  "1rem",
                  "margin-top",
                  "0.5rem",
                  3,
                  "checked",
                  "change",
                ],
                ["id", "questionTypePrettyFormatted"],
                [1, "animated-text-big", 2, "flex-grow", "1"],
                ["heading0div", ""],
                [1, "button", "d-flex", 3, "click"],
              ],
              template: function (e, t) {
                1 & e &&
                  (Ho(0, "div", 0),
                  Fo(1, Hp, 3, 3, "div", 1),
                  Fo(2, Up, 4, 3, "div", 2),
                  Ho(3, "div", 3),
                  Fo(4, Vp, 3, 1, "div", 4),
                  Uo(),
                  Ho(5, "p-checkbox", 5),
                  Go("change", function () {
                    return t.updateRepeatQuestionIsAllowed(!t.repeatQuestions);
                  }),
                  la(6),
                  Uo(),
                  Uo()),
                  2 & e &&
                    (zo("@startUpFadeIn", t.startUpFadeIn ? "true" : "false"),
                    yi(1),
                    zo("ngIf", t.selectedQuestionSet),
                    yi(1),
                    zo("ngIf", t.selectedQuestionSet),
                    yi(2),
                    zo("ngIf", t.selectedQuestionSet),
                    yi(1),
                    zo("checked", t.repeatQuestions),
                    yi(1),
                    ha(
                      " ",
                      t.questionnaire.toggleSwitchTextRepeatQuestions,
                      " "
                    ));
              },
              directives: [Il, zp],
              styles: [
                "#home-wrapper[_ngcontent-%COMP%]{height:95%;display:flex;justify-content:center;flex-direction:column}.button[_ngcontent-%COMP%]{display:inline-block;transition:.35s;background-color:transparent;border:1px solid #fff;padding:7px 25px;color:#fff;cursor:pointer;outline:none}.button[_ngcontent-%COMP%], .button[_ngcontent-%COMP%]:hover{text-decoration:none;border-radius:0}.button[_ngcontent-%COMP%]:hover{background-color:#ffffff7c;color:#000;border-style:solid;border-width:1px}.button[_ngcontent-%COMP%]:active{border-style:outset;border-color:#fff}.span-primary[_ngcontent-%COMP%]{border:#fff;border-style:solid;border-width:1px;color:#f26a38;padding:3px 5px;font-size:small;border-radius:0}p[_ngcontent-%COMP%], span[_ngcontent-%COMP%]{color:#a7a7a7}h4[_ngcontent-%COMP%]{text-transform:uppercase;text-decoration:underline;-webkit-text-decoration-color:#a7a7a7;text-decoration-color:#a7a7a7}h2[_ngcontent-%COMP%], h4[_ngcontent-%COMP%], h5[_ngcontent-%COMP%], li[_ngcontent-%COMP%]{letter-spacing:2px}li[_ngcontent-%COMP%]{color:#a7a7a7;font-size:small}.animated-text-big[_ngcontent-%COMP%]{display:flex;justify-content:center;margin-bottom:20px;height:100%;align-items:center}.animated-text-big[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{overflow:hidden;margin:0 auto;letter-spacing:2px;text-align:center}#questionTypePrettyFormatted[_ngcontent-%COMP%]{display:flex;justify-content:center;margin-bottom:-15px}",
              ],
              data: {
                animation: [
                  Dv("showHide", [
                    Fv("show", jv({ opacity: 100 })),
                    Fv("hide", jv({ opacity: 0 })),
                    Wv("show <=> hide", [Nv("0.5s")]),
                  ]),
                  Dv("startUpFadeIn", [
                    Fv("true", jv({ opacity: 100 })),
                    Fv("false", jv({ opacity: 0 })),
                    Wv("true <=> false", [Nv("0.5s")]),
                  ]),
                  Dv("languageChange", [
                    Wv("void => *", []),
                    Wv("* => void", []),
                    Wv("* => *", [
                      Nv(
                        2e3,
                        Lv([
                          jv({ offset: 0, opacity: 0 }),
                          jv({ offset: 1, opacity: 100 }),
                        ])
                      ),
                    ]),
                  ]),
                ],
              },
            })),
            Gp),
          Zp =
            (((Qp = (function () {
              function e() {
                p(this, e), (this.heading0Shown = "");
              }
              return (
                g(e, [
                  {
                    key: "ngOnInit",
                    value: function () {
                      this.heading0TypingCallback(this);
                    },
                  },
                  {
                    key: "heading0TypingCallback",
                    value: function (e) {
                      var t = e.heading0Shown.length;
                      t < e.heading0.length &&
                        (e.heading0Shown += e.heading0[t]),
                        setTimeout(e.heading0TypingCallback, 80, e);
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Qp)();
            }),
            (Qp.ɵcmp = rt({
              type: Qp,
              selectors: [["app-heading"]],
              inputs: { heading0: "heading0" },
              decls: 3,
              vars: 1,
              consts: [[1, "heading", "animated-text"]],
              template: function (e, t) {
                1 & e && (Ho(0, "div", 0), Ho(1, "h1"), la(2), Uo(), Uo()),
                  2 & e && (yi(2), ca(t.heading0Shown));
              },
              styles: [
                ".animated-text[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{overflow:hidden;border-right:.15em solid #fff;white-space:nowrap;margin:0 auto;letter-spacing:2px;-webkit-animation:blink-caret .75s step-end infinite;animation:blink-caret .75s step-end infinite}@-webkit-keyframes blink-caret{0%,to{border-color:transparent}50%{border-color:#fff}}@keyframes blink-caret{0%,to{border-color:transparent}50%{border-color:#fff}}",
              ],
            })),
            Qp),
          Jp =
            (((Bp = (function () {
              function e() {
                p(this, e), (this.show = !1);
              }
              return (
                g(e, [
                  {
                    key: "ngOnInit",
                    value: function () {
                      var e = this;
                      setTimeout(function () {
                        e.show = !0;
                      }, 700);
                    },
                  },
                  {
                    key: "toggle",
                    value: function () {
                      this.show = !this.show;
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Bp)();
            }),
            (Bp.ɵcmp = rt({
              type: Bp,
              selectors: [["app-impressum"]],
              decls: 58,
              vars: 2,
              consts: [
                [1, "page-container"],
                [3, "heading0"],
                [2, "width", "100%"],
                [1, "parent"],
                [1, "div1"],
                [1, "div2"],
                ["href", "https://ish-gruppe.de/", "target", "_blank"],
                [2, "text-align", "justify"],
                [1, "div3"],
                [1, "contributor-name"],
                [1, "contributor-contact"],
                [1, "div4"],
              ],
              template: function (e, t) {
                1 & e &&
                  (Ho(0, "div", 0),
                  Vo(1, "app-heading", 1),
                  Ho(2, "div", 2),
                  Ho(3, "div", 3),
                  Ho(4, "div", 4),
                  Ho(5, "h2"),
                  la(6, " Anbieter "),
                  Uo(),
                  Ho(7, "p"),
                  la(
                    8,
                    " Institut f\xfcr Schulentwicklung und Hochschuldidaktik GmbH "
                  ),
                  Uo(),
                  Ho(9, "p"),
                  la(10, " Dennis Sawatzki "),
                  Uo(),
                  Ho(11, "p"),
                  la(12, " Schadowstra\xdfe 34 "),
                  Uo(),
                  Ho(13, "p"),
                  la(14, " 44799 Bochum "),
                  Uo(),
                  Uo(),
                  Ho(15, "div", 5),
                  Ho(16, "h2"),
                  la(17, " Kontakt "),
                  Uo(),
                  Ho(18, "p"),
                  la(19, "Website: "),
                  Ho(20, "a", 6),
                  la(21, " https://ish-gruppe.de/"),
                  Uo(),
                  Uo(),
                  Ho(22, "p"),
                  la(23, " E-Mail: office@ish-gruppe.de "),
                  Uo(),
                  Ho(24, "p", 7),
                  la(25, " Telefon: 02 34 / 54 57 41 11 "),
                  Uo(),
                  Uo(),
                  Ho(26, "div", 8),
                  Ho(27, "h2"),
                  la(28, " Technische Umsetzung "),
                  Uo(),
                  Ho(29, "p", 9),
                  la(30, " S\xf6ren Stra\xdfmann "),
                  Uo(),
                  Ho(31, "p", 10),
                  la(32, " E-Mail: soeren.strassmann@coachingspace.net "),
                  Uo(),
                  Ho(33, "p", 9),
                  la(34, " Sergej Grilborzer "),
                  Uo(),
                  Ho(35, "p", 10),
                  la(36, " E-Mail: sergej@grilborzer.de "),
                  Uo(),
                  Uo(),
                  Ho(37, "div", 11),
                  Ho(38, "h2"),
                  la(39, " Fachliche Umsetzung "),
                  Uo(),
                  Ho(40, "p", 9),
                  la(41, " Dennis Sawatzki "),
                  Uo(),
                  Ho(42, "p", 10),
                  la(43, " E-Mail: sawatzki@ish-gruppe.de "),
                  Uo(),
                  Ho(44, "p", 9),
                  la(45, " Marcus Kuhn "),
                  Uo(),
                  Ho(46, "p", 10),
                  la(47, " E-Mail: kuhn@ish-gruppe.de "),
                  Uo(),
                  Ho(48, "h2"),
                  la(49, " Englische \xdcbersetzung "),
                  Uo(),
                  Ho(50, "p", 9),
                  la(51, " Annika Franzke "),
                  Uo(),
                  Ho(52, "p", 10),
                  la(53, " Gustav-Heinemann-Gesamtschule\xa0Alsdorf "),
                  Uo(),
                  Ho(54, "h2"),
                  la(55, " Spanische \xdcbersetzung "),
                  Uo(),
                  Ho(56, "p", 9),
                  la(57, " Carmen Gonzalez Guti\xe9rez "),
                  Uo(),
                  Uo(),
                  Uo(),
                  Uo(),
                  Uo()),
                  2 & e &&
                    (yi(1),
                    zo("heading0", "Impressum"),
                    yi(1),
                    zo("@showHide", t.show ? "show" : "hide"));
              },
              directives: [Zp],
              styles: [
                '@media screen and (max-width:576px){.about-section[_ngcontent-%COMP%]{flex-wrap:wrap}}.color-primary[_ngcontent-%COMP%]{color:#f26a38}.button[_ngcontent-%COMP%]{text-decoration:none;display:inline-block;transition:.35s;border:1px solid #f26a38;padding:7px 25px;color:#fff}.button[_ngcontent-%COMP%]:hover{text-decoration:none;background-color:#ee9575;color:#000;border-style:solid}.span-primary[_ngcontent-%COMP%]{border:#f26a38;border-style:solid;border-width:1px;color:#f26a38;padding:3px 5px;font-size:small}h3[_ngcontent-%COMP%]{font-weight:200;margin-bottom:10px}h2[_ngcontent-%COMP%], h5[_ngcontent-%COMP%], li[_ngcontent-%COMP%]{letter-spacing:2px}li[_ngcontent-%COMP%]{color:#a7a7a7;font-size:small}p[_ngcontent-%COMP%]{line-height:20px;margin-top:8px;margin-bottom:0}.contributor-name[_ngcontent-%COMP%]{font-weight:700;margin-bottom:0}.contributor-contact[_ngcontent-%COMP%]{margin-bottom:10px}.parent[_ngcontent-%COMP%]{display:grid;grid-template-areas:"Anbieter" "Kontakt" "TechnischeUmsetzung" "FachlicheUmsetzung";grid-template-columns:repeat(auto-fill,minmax(200px,1fr));grid-template-rows:auto;grid-column-gap:10rem;grid-row-gap:1.5rem}.div1[_ngcontent-%COMP%]{grid-area:Anbieter}.div2[_ngcontent-%COMP%]{grid-area:Kontakt}.div3[_ngcontent-%COMP%]{grid-area:TechnischeUmsetzung}.div4[_ngcontent-%COMP%]{grid-area:FachlicheUmsetzung}@media (min-width:1024px){.parent[_ngcontent-%COMP%]{grid-template-areas:"Anbieter TechnischeUmsetzung" "Kontakt FachlicheUmsetzung"}}',
              ],
              data: {
                animation: [
                  Dv("showHide", [
                    Fv("show", jv({ opacity: 100 })),
                    Fv("hide", jv({ opacity: 0 })),
                    Wv("show <=> hide", [Nv("0.5s")]),
                  ]),
                ],
              },
            })),
            Bp),
          $p = ["userInput"],
          Yp = function (e) {
            return { active: e };
          };
        function Xp(e, t) {
          if (1 & e) {
            var n = Bo();
            Ho(0, "div", 11),
              Ho(1, "div", 12),
              Go("click", function () {
                return Ut(n), Jo().deselectActiveElement();
              }),
              Ho(2, "p", 13),
              la(3),
              Uo(),
              Ho(4, "i", 14),
              Go("click", function () {
                Ut(n);
                var e = t.index;
                return Jo().removeUserEntry(e);
              }),
              Uo(),
              Uo(),
              Uo();
          }
          if (2 & e) {
            var r = t.$implicit,
              i = t.index,
              o = Jo();
            zo("@startUpFadeIn", o.startUpFadeIn ? "true" : "false")(
              "@showHide",
              o.show ? "show" : "hide"
            ),
              yi(1),
              zo("ngClass", Ss(5, Yp, 0 === i && o.highlightSelectedElement)),
              yi(1),
              zo("ngClass", Ss(7, Yp, 0 === i && o.highlightSelectedElement)),
              yi(1),
              ca(r);
          }
        }
        var em,
          tm,
          nm,
          rm,
          im = [
            { path: "", component: Kp },
            {
              path: "random-generator",
              component:
                ((tm = (function () {
                  function e(t) {
                    p(this, e),
                      (this.persistenceService = t),
                      (this.show = !0),
                      (this.startUpFadeIn = !1),
                      (this.repeatQuestions = !1),
                      (this.numberOfPickedEntries = 0),
                      (this.userEntries = []),
                      (this.highlightSelectedElement = !1),
                      (this.selectedLanguage = np.GERMAN);
                  }
                  return (
                    g(e, [
                      {
                        key: "ngOnInit",
                        value: function () {
                          var e = this;
                          (this.userEntrySubscription = this.persistenceService
                            .getUserEntries()
                            .subscribe(function (t) {
                              return (e.userEntries = t);
                            })),
                            (this.repeatQuestionsSubscription =
                              this.persistenceService
                                .getRepeatQuestions()
                                .subscribe(function (t) {
                                  return (e.repeatQuestions = t);
                                })),
                            (this.languageSwitchSubscription =
                              this.persistenceService
                                .getSelectedLanguage()
                                .subscribe(function (t) {
                                  switch (
                                    ((e.selectedLanguage = t),
                                    e.selectedLanguage)
                                  ) {
                                    case np.GERMAN:
                                      e.questionnaire = rp;
                                      break;
                                    case np.ENGLISH:
                                      e.questionnaire = ip;
                                      break;
                                    case np.SPANISH:
                                      e.questionnaire = op;
                                      break;
                                    default:
                                      e.questionnaire = rp;
                                  }
                                }));
                        },
                      },
                      {
                        key: "ngAfterViewInit",
                        value: function () {
                          var e = this;
                          window.scrollTo(0, 0),
                            setTimeout(function () {
                              e.startUpFadeIn = !0;
                            }, 700);
                        },
                      },
                      {
                        key: "updateRepeatQuestionIsAllowed",
                        value: function (e) {
                          (this.repeatQuestions = e),
                            this.persistenceService.setRepeatQuestions(e);
                        },
                      },
                      {
                        key: "addUserEntry",
                        value: function (e) {
                          e &&
                            (this.userEntries.push(e),
                            this.persistenceService.setUserEntries(
                              this.userEntries
                            ),
                            (this.userInputRef.nativeElement.value = ""));
                        },
                      },
                      {
                        key: "removeUserEntry",
                        value: function (e) {
                          console.log(
                            "removeUserEntry "
                              .concat(e, " from ")
                              .concat(this.userEntries.length)
                          ),
                            this.userEntries.splice(e, 1),
                            this.persistenceService.setUserEntries(
                              this.userEntries
                            );
                        },
                      },
                      {
                        key: "deselectActiveElement",
                        value: function () {
                          this.highlightSelectedElement = !1;
                        },
                      },
                      {
                        key: "makeNewSelection",
                        value: function () {
                          this.repeatQuestions
                            ? this.pickRandomEntry()
                            : this.pickRandomEntryWithoutRepeating();
                        },
                      },
                      {
                        key: "pickRandomEntry",
                        value: function () {
                          var e = this;
                          (this.show = !1),
                            setTimeout(function () {
                              var t = e.getRandomIndexForQuestions(
                                e.userEntries
                              );
                              e.selectedIndex = t;
                              var n = e.userEntries[t];
                              e.userEntries.splice(t, 1),
                                e.userEntries.unshift(n),
                                (e.highlightSelectedElement = !0),
                                e.persistenceService.setUserEntries(
                                  e.userEntries
                                );
                            }, 1500),
                            setTimeout(function () {
                              e.show = !0;
                            }, 1800);
                        },
                      },
                      {
                        key: "pickRandomEntryWithoutRepeating",
                        value: function () {
                          var e = this;
                          (this.show = !1),
                            setTimeout(function () {
                              e.numberOfPickedEntries ===
                                e.userEntries.length &&
                                (e.numberOfPickedEntries = 0);
                              var t = e.userEntries.slice(
                                e.numberOfPickedEntries,
                                e.userEntries.length
                              );
                              e.selectedIndex =
                                e.getRandomIndexForQuestions(t) +
                                e.numberOfPickedEntries;
                              var n = e.userEntries[e.selectedIndex];
                              e.userEntries.splice(e.selectedIndex, 1),
                                e.userEntries.unshift(n),
                                (e.numberOfPickedEntries =
                                  e.numberOfPickedEntries + 1),
                                (e.highlightSelectedElement = !0),
                                e.persistenceService.setUserEntries(
                                  e.userEntries
                                );
                            }, 1500),
                            setTimeout(function () {
                              e.show = !0;
                            }, 1800);
                        },
                      },
                      {
                        key: "getRandomIndexForQuestions",
                        value: function (e) {
                          return Math.round(Math.random() * (e.length - 1));
                        },
                      },
                      {
                        key: "ngOnDestroy",
                        value: function () {
                          this.userEntrySubscription.unsubscribe(),
                            this.repeatQuestionsSubscription.unsubscribe(),
                            this.languageSwitchSubscription.unsubscribe();
                        },
                      },
                    ]),
                    e
                  );
                })()),
                (tm.ɵfac = function (e) {
                  return new (e || tm)(Wo(ep));
                }),
                (tm.ɵcmp = rt({
                  type: tm,
                  selectors: [["app-random-generator"]],
                  viewQuery: function (e, t) {
                    var n;
                    1 & e && zs($p, 1),
                      2 & e && Ws((n = Hs())) && (t.userInputRef = n.first);
                  },
                  decls: 15,
                  vars: 8,
                  consts: [
                    [
                      "id",
                      "randomGeneratorWrapper",
                      1,
                      "d-flex",
                      "flex-column",
                      2,
                      "justify-content",
                      "center",
                      "align-items",
                      "center",
                      "margin-top",
                      "0.5rem",
                      "margin-bottom",
                      "15px",
                    ],
                    [1, "random-generator-title", 2, "margin-bottom", "-1rem"],
                    [1, "random-generator-title"],
                    [1, "d-flex", "flex-row", 2, "width", "100%"],
                    [
                      "placeholder",
                      "",
                      2,
                      "border-radius",
                      "4px",
                      3,
                      "keydown.enter",
                    ],
                    ["userInput", ""],
                    [
                      1,
                      "button",
                      "d-flex",
                      2,
                      "margin-left",
                      "0.5rem",
                      3,
                      "click",
                    ],
                    [1, "fas", "fa-plus"],
                    ["style", "width: 100%;", 4, "ngFor", "ngForOf"],
                    [
                      1,
                      "button",
                      "d-flex",
                      2,
                      "width",
                      "100%",
                      "margin-top",
                      "1rem",
                      3,
                      "click",
                    ],
                    [
                      "color",
                      "primary",
                      "isSwitch",
                      "true",
                      "stroke",
                      "fill",
                      2,
                      "display",
                      "flex",
                      "justify-content",
                      "center",
                      "margin-left",
                      "1rem",
                      "margin-top",
                      "1rem",
                      3,
                      "checked",
                      "change",
                    ],
                    [2, "width", "100%"],
                    [
                      1,
                      "d-flex",
                      "flex-row",
                      "userEntry",
                      2,
                      "align-items",
                      "center",
                      "justify-content",
                      "space-between",
                      3,
                      "ngClass",
                      "click",
                    ],
                    [2, "width", "100%", 3, "ngClass"],
                    [
                      1,
                      "fas",
                      "fa-times",
                      2,
                      "cursor",
                      "pointer",
                      "margin-left",
                      "1rem",
                      3,
                      "click",
                    ],
                  ],
                  template: function (e, t) {
                    if (1 & e) {
                      var n = Bo();
                      Ho(0, "div", 0),
                        Ho(1, "h2", 1),
                        la(2),
                        Uo(),
                        Ho(3, "h4", 2),
                        la(4),
                        Uo(),
                        Ho(5, "div", 3),
                        Ho(6, "input", 4, 5),
                        Go("keydown.enter", function () {
                          Ut(n);
                          var e = Lo(7);
                          return t.addUserEntry(e.value);
                        }),
                        Uo(),
                        Ho(8, "button", 6),
                        Go("click", function () {
                          Ut(n);
                          var e = Lo(7);
                          return t.addUserEntry(e.value);
                        }),
                        Vo(9, "i", 7),
                        Uo(),
                        Uo(),
                        Fo(10, Xp, 5, 9, "div", 8),
                        Ho(11, "button", 9),
                        Go("click", function () {
                          return t.makeNewSelection();
                        }),
                        la(12),
                        Uo(),
                        Ho(13, "p-checkbox", 10),
                        Go("change", function () {
                          return t.updateRepeatQuestionIsAllowed(
                            !t.repeatQuestions
                          );
                        }),
                        la(14),
                        Uo(),
                        Uo();
                    }
                    2 & e &&
                      (zo("@startUpFadeIn", t.startUpFadeIn ? "true" : "false")(
                        "@languageChange",
                        t.selectedLanguage
                      ),
                      yi(2),
                      ha(" ", t.questionnaire.titleRandomGenerator, ""),
                      yi(2),
                      ha(" ", t.questionnaire.subtitleRandomGenerator, ""),
                      yi(6),
                      zo("ngForOf", t.userEntries),
                      yi(2),
                      ha(" ", t.questionnaire.buttonTextRandomPick, " "),
                      yi(1),
                      zo("checked", t.repeatQuestions),
                      yi(1),
                      ha(
                        " ",
                        t.questionnaire.toggleSwitchTextRepeatUserEntries,
                        " "
                      ));
                  },
                  directives: [Tl, zp, Cl],
                  styles: [
                    ".userEntry[_ngcontent-%COMP%]{transition:.35s;background-color:transparent;border:1px solid #fff;padding:5px 15px;color:#fff;border-radius:4px;margin-top:.5rem;max-height:1.5rem}.userEntry[_ngcontent-%COMP%]:hover{background-color:#ffffff7c}.userEntry.active[_ngcontent-%COMP%], .userEntry[_ngcontent-%COMP%]:hover{text-decoration:none;color:#000;border-style:solid;border-width:1px;border-radius:0}.userEntry.active[_ngcontent-%COMP%]{background-color:#4edf99}p.active[_ngcontent-%COMP%], p[_ngcontent-%COMP%]:hover{color:#000}.userEntryText[_ngcontent-%COMP%]:hover{text-decoration:none;background-color:#ffffff7c;color:#000;border-style:solid;border-width:1px;border-radius:0}.userEntry[_ngcontent-%COMP%]:active{border-style:outset;border-color:#fff}.button[_ngcontent-%COMP%]{text-decoration:none;display:inline-block;transition:.35s;background-color:transparent;border:1px solid #fff;padding:5px 15px;color:#fff;cursor:pointer;border-radius:4px;outline:none}.button[_ngcontent-%COMP%]:hover{text-decoration:none;background-color:#ffffff7c;color:#000;border-style:solid;border-width:1px;border-radius:0}.button[_ngcontent-%COMP%]:active{border-style:outset;border-color:#fff}h2[_ngcontent-%COMP%], h5[_ngcontent-%COMP%]{letter-spacing:2px}input[_ngcontent-%COMP%]{width:100%;height:2.5rem}@media screen and (min-width:1200px){#randomGeneratorWrapper[_ngcontent-%COMP%]{margin-right:1rem}}",
                  ],
                  data: {
                    animation: [
                      Dv("showHide", [
                        Fv("show", jv({ opacity: 100 })),
                        Fv("hide", jv({ opacity: 0 })),
                        Wv("show <=> hide", [Nv("0.5s")]),
                      ]),
                      Dv("startUpFadeIn", [
                        Fv("true", jv({ opacity: 100 })),
                        Fv("false", jv({ opacity: 0 })),
                        Wv("true <=> false", [Nv("0.5s")]),
                      ]),
                      Dv("languageChange", [
                        Wv("void => *", []),
                        Wv("* => void", []),
                        Wv("* => *", [
                          Nv(
                            2e3,
                            Lv([
                              jv({ offset: 0, opacity: 0 }),
                              jv({ offset: 1, opacity: 100 }),
                            ])
                          ),
                        ]),
                      ]),
                    ],
                  },
                })),
                tm),
            },
            { path: "impressum", component: Jp },
            {
              path: "security",
              component:
                ((em = (function () {
                  function e() {
                    p(this, e), (this.show = !1);
                  }
                  return (
                    g(e, [
                      {
                        key: "ngOnInit",
                        value: function () {
                          var e = this;
                          window.scrollTo(0, 0),
                            setTimeout(function () {
                              e.show = !0;
                            }, 700);
                        },
                      },
                      {
                        key: "toggle",
                        value: function () {
                          this.show = !this.show;
                        },
                      },
                    ]),
                    e
                  );
                })()),
                (em.ɵfac = function (e) {
                  return new (e || em)();
                }),
                (em.ɵcmp = rt({
                  type: em,
                  selectors: [["app-security"]],
                  decls: 54,
                  vars: 2,
                  consts: [
                    [1, "page-container", 2, "padding-right", "15px"],
                    [3, "heading0"],
                    [1, "content-section"],
                    [2, "margin-top", "10px"],
                    [2, "margin-left", "30px"],
                    [2, "text-align", "justify"],
                    [2, "margin-top", "30px"],
                    [
                      "href",
                      "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html",
                    ],
                  ],
                  template: function (e, t) {
                    1 & e &&
                      (Ho(0, "div", 0),
                      Vo(1, "app-heading", 1),
                      Ho(2, "div", 2),
                      Ho(3, "h2", 3),
                      la(4, " Benennung der verantwortlichen Stelle "),
                      Uo(),
                      Ho(5, "p"),
                      la(
                        6,
                        " Die verantwortliche Stelle f\xfcr die Datenverarbeitung auf dieser Website ist: "
                      ),
                      Uo(),
                      Ho(7, "div", 4),
                      Ho(8, "p"),
                      la(9, " Dennis Sawatzki "),
                      Uo(),
                      Ho(10, "p"),
                      la(11, " Schadowstra\xdfe 34 "),
                      Uo(),
                      Ho(12, "p"),
                      la(13, " 44799 Bochum "),
                      Uo(),
                      Uo(),
                      Ho(14, "p", 5),
                      la(
                        15,
                        " Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen \xfcber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. \xc4.). "
                      ),
                      Uo(),
                      Ho(16, "h2", 6),
                      la(
                        17,
                        " Recht auf Beschwerde bei der zust\xe4ndigen Aufsichtsbeh\xf6rde "
                      ),
                      Uo(),
                      Ho(18, "p", 5),
                      la(
                        19,
                        " Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Versto\xdfes ein Beschwerderecht bei der zust\xe4ndigen Aufsichtsbeh\xf6rde zu. Zust\xe4ndige Aufsichtsbeh\xf6rde bez\xfcglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: "
                      ),
                      Uo(),
                      Ho(20, "div", 4),
                      Ho(21, "a", 7),
                      la(22, " BFDI "),
                      Uo(),
                      Uo(),
                      Ho(23, "h2", 6),
                      la(
                        24,
                        " Widerruf Ihrer Einwilligung zur Datenverarbeitung "
                      ),
                      Uo(),
                      Ho(25, "p", 5),
                      la(
                        26,
                        " Nur mit Ihrer ausdr\xfccklichen Einwilligung sind einige Vorg\xe4nge der Datenverarbeitung m\xf6glich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m\xf6glich. F\xfcr den Widerruf gen\xfcgt eine formlose Mitteilung per E-Mail. Die Rechtm\xe4\xdfigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber\xfchrt. "
                      ),
                      Uo(),
                      Ho(27, "h2", 6),
                      la(28, " Recht auf Daten\xfcbertragbarkeit "),
                      Uo(),
                      Ho(29, "p", 5),
                      la(
                        30,
                        " Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf\xfcllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aush\xe4ndigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte \xdcbertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist. "
                      ),
                      Uo(),
                      Ho(31, "h2", 6),
                      la(
                        32,
                        " Recht auf Auskunft, Berichtigung, Sperrung, L\xf6schung "
                      ),
                      Uo(),
                      Ho(33, "p", 5),
                      la(
                        34,
                        " Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft \xfcber Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empf\xe4nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder L\xf6schung dieser Daten. Diesbez\xfcglich und auch zu weiteren Fragen zum Thema personenbezogene Daten k\xf6nnen Sie sich jederzeit \xfcber die im Impressum aufgef\xfchrten Kontaktm\xf6glichkeiten an uns wenden. "
                      ),
                      Uo(),
                      Ho(35, "h2", 6),
                      la(36, " SSL- bzw. TLS-Verschl\xfcsselung "),
                      Uo(),
                      Ho(37, "p", 5),
                      la(
                        38,
                        " Aus Sicherheitsgr\xfcnden und zum Schutz der \xdcbertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschl\xfcsselung. Damit sind Daten, die Sie \xfcber diese Website \xfcbermitteln, f\xfcr Dritte nicht mitlesbar. Sie erkennen eine verschl\xfcsselte Verbindung an der \u201ehttps://\u201c Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile. "
                      ),
                      Uo(),
                      Ho(39, "h2", 6),
                      la(40, " Datenspeicherung im lokalen Browserspeicher "),
                      Uo(),
                      Ho(41, "p", 5),
                      la(
                        42,
                        " Um Ihre Erfahrung mit unserer Seite bestm\xf6glich zu gestalten, haben Sie die M\xf6glichkeit "
                      ),
                      Uo(),
                      Ho(43, "ul"),
                      Ho(44, "li"),
                      la(45, " die gew\xfcnsche Sprache einzustellen "),
                      Uo(),
                      Ho(46, "li"),
                      la(
                        47,
                        " das Wiederholen/ nicht Wiederholen von Fragen einzustellen "
                      ),
                      Uo(),
                      Ho(48, "li"),
                      la(
                        49,
                        " und eigene Begriffe im Zufallsgenerator einzutragen "
                      ),
                      Uo(),
                      Uo(),
                      Ho(50, "p", 5),
                      la(
                        51,
                        ' Damit Ihre gew\xfcnschten Pr\xe4ferenzen und eingegeben Begriffe beim Verlassen oder Aktualisieren der Seite nicht verloren gehen, werden sie unverschl\xfcsselt in Ihrem lokalen Browserspeicher, dem "Local Storage", hinterlegt. Es werden keine Daten an den Anbieter oder an Dritte \xfcbertragen. Die Daten verbleiben auf Ihrem System bis sie von Ihnen gel\xf6scht werden. Sie k\xf6nnen die Daten l\xf6schen indem Sie mittels der Browsereinstellungen den Browserchache leeren. '
                      ),
                      Uo(),
                      Ho(52, "p", 5),
                      la(
                        53,
                        " Sie k\xf6nnen das Speichern von lokalen Daten in Ihren Browsereinstellungen allgemein verbieten. In diesem Fall weisen wir auf m\xf6gliche Funktionseinschr\xe4nkungen dieser Seite hin. "
                      ),
                      Uo(),
                      Uo(),
                      Uo()),
                      2 & e &&
                        (yi(1),
                        zo("heading0", "Datenschutz"),
                        yi(1),
                        zo("@showHide", t.show ? "show" : "hide"));
                  },
                  directives: [Zp],
                  styles: [
                    ".contect-section[_ngcontent-%COMP%]{width:250px}.color-primary[_ngcontent-%COMP%]{color:#f26a38}.button[_ngcontent-%COMP%]{text-decoration:none;display:inline-block;transition:.35s;border:1px solid #f26a38;padding:7px 25px;color:#fff}.button[_ngcontent-%COMP%]:hover{text-decoration:none;background-color:#ee9575;color:#000;border-style:solid}.span-primary[_ngcontent-%COMP%]{border:#f26a38;border-style:solid;border-width:1px;color:#f26a38;padding:3px 5px;font-size:small}h3[_ngcontent-%COMP%]{font-weight:200;margin-bottom:10px}h1[_ngcontent-%COMP%], h2[_ngcontent-%COMP%], h5[_ngcontent-%COMP%]{letter-spacing:2px}h1[_ngcontent-%COMP%]{text-transform:uppercase;font-size:xx-large;font-weight:600}p[_ngcontent-%COMP%]{line-height:20px;margin-top:8px;margin-bottom:8px}",
                  ],
                  data: {
                    animation: [
                      Dv("showHide", [
                        Fv("show", jv({ opacity: 100 })),
                        Fv("hide", jv({ opacity: 0 })),
                        Wv("show <=> hide", [Nv("0.5s")]),
                      ]),
                    ],
                  },
                })),
                em),
            },
            { path: ":type", component: Kp },
          ],
          om =
            (((nm = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || nm)();
            }),
            (nm.ɵmod = st({ type: nm })),
            (nm.ɵinj = Te({
              imports: [
                [_v.forRoot(im, { relativeLinkResolution: "legacy" })],
                _v,
              ],
            })),
            nm),
          am = function (e) {
            return { active: e };
          },
          sm =
            (((rm = (function () {
              function e(t) {
                p(this, e),
                  (this.persistenceService = t),
                  (this.LANG_GERMAN = np.GERMAN),
                  (this.LANG_ENGLISH = np.ENGLISH),
                  (this.LANG_SPANISH = np.SPANISH),
                  (this.selectedLanguage = np.GERMAN);
              }
              return (
                g(e, [
                  {
                    key: "ngOnInit",
                    value: function () {
                      var e = this;
                      this.persistenceService
                        .getSelectedLanguage()
                        .subscribe(function (t) {
                          return (e.selectedLanguage = t);
                        });
                    },
                  },
                  {
                    key: "selectLanguage",
                    value: function (e) {
                      (this.selectedLanguage = e),
                        this.persistenceService.setSelectedLanguage(e);
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || rm)(Wo(ep));
            }),
            (rm.ɵcmp = rt({
              type: rm,
              selectors: [["language-switcher"]],
              decls: 3,
              vars: 9,
              consts: [
                [
                  "src",
                  "assets/Germany-32x32.png",
                  1,
                  "language-flag",
                  3,
                  "ngClass",
                  "click",
                ],
                [
                  "src",
                  "assets/United-Kingdom-32x32.png",
                  1,
                  "language-flag",
                  3,
                  "ngClass",
                  "click",
                ],
                [
                  "src",
                  "assets/Spain-32x32.png",
                  1,
                  "language-flag",
                  3,
                  "ngClass",
                  "click",
                ],
              ],
              template: function (e, t) {
                1 & e &&
                  (Ho(0, "img", 0),
                  Go("click", function () {
                    return t.selectLanguage(t.LANG_GERMAN);
                  }),
                  Uo(),
                  Ho(1, "img", 1),
                  Go("click", function () {
                    return t.selectLanguage(t.LANG_ENGLISH);
                  }),
                  Uo(),
                  Ho(2, "img", 2),
                  Go("click", function () {
                    return t.selectLanguage(t.LANG_SPANISH);
                  }),
                  Uo()),
                  2 & e &&
                    (zo("ngClass", Ss(3, am, "GERMAN" === t.selectedLanguage)),
                    yi(1),
                    zo("ngClass", Ss(5, am, "ENGLISH" === t.selectedLanguage)),
                    yi(1),
                    zo("ngClass", Ss(7, am, "SPANISH" === t.selectedLanguage)));
              },
              directives: [Cl],
              styles: [
                ".language-flag[_ngcontent-%COMP%]{opacity:.5;cursor:pointer}.language-flag.active[_ngcontent-%COMP%]{opacity:1}",
              ],
            })),
            rm);
        function um(e, t) {
          1 & e && Vo(0, "language-switcher");
        }
        var lm = function () {
            return ["/checkin"];
          },
          cm = function () {
            return { exact: !0 };
          },
          hm = function () {
            return ["/kennenlernen"];
          },
          fm = function () {
            return ["/entweder_oder"];
          },
          dm = function () {
            return ["/zielsetzung"];
          },
          vm = function () {
            return ["/teamarbeit"];
          },
          pm = function () {
            return ["/kontroversen"];
          },
          mm = function () {
            return ["/gruppenreflexion"];
          },
          gm = function () {
            return ["/checkout"];
          };
        function ym(e, t) {
          1 & e &&
            (Ho(0, "div", 6),
            Ho(1, "a", 7),
            Vo(2, "i", 8),
            Uo(),
            Ho(3, "a", 7),
            Vo(4, "i", 9),
            Uo(),
            Ho(5, "a", 7),
            Vo(6, "i", 10),
            Uo(),
            Ho(7, "a", 7),
            Vo(8, "i", 11),
            Uo(),
            Ho(9, "a", 7),
            Vo(10, "i", 12),
            Uo(),
            Ho(11, "a", 7),
            Vo(12, "i", 13),
            Uo(),
            Ho(13, "a", 7),
            Vo(14, "i", 14),
            Uo(),
            Ho(15, "a", 7),
            Vo(16, "i", 15),
            Uo(),
            Uo()),
            2 & e &&
              (zo("@languageChange", Jo().selectedLanguage),
              yi(1),
              zo("routerLink", _s(17, lm))(
                "routerLinkActiveOptions",
                _s(18, cm)
              ),
              yi(2),
              zo("routerLink", _s(19, hm))(
                "routerLinkActiveOptions",
                _s(20, cm)
              ),
              yi(2),
              zo("routerLink", _s(21, fm))(
                "routerLinkActiveOptions",
                _s(22, cm)
              ),
              yi(2),
              zo("routerLink", _s(23, dm))(
                "routerLinkActiveOptions",
                _s(24, cm)
              ),
              yi(2),
              zo("routerLink", _s(25, vm))(
                "routerLinkActiveOptions",
                _s(26, cm)
              ),
              yi(2),
              zo("routerLink", _s(27, pm))(
                "routerLinkActiveOptions",
                _s(28, cm)
              ),
              yi(2),
              zo("routerLink", _s(29, mm))(
                "routerLinkActiveOptions",
                _s(30, cm)
              ),
              yi(2),
              zo("routerLink", _s(31, gm))(
                "routerLinkActiveOptions",
                _s(32, cm)
              ));
        }
        var km,
          bm,
          wm =
            (((bm = (function () {
              function e(t, n) {
                p(this, e),
                  (this.router = t),
                  (this.persistenceService = n),
                  (this.selectedLanguage = np.GERMAN),
                  (this.questionnaire = rp);
              }
              return (
                g(e, [
                  {
                    key: "ngOnInit",
                    value: function () {
                      var e = this;
                      this.updateCategoryTranslation(),
                        (this.languageSwitchSubscription =
                          this.persistenceService
                            .getSelectedLanguage()
                            .subscribe(function (t) {
                              (e.selectedLanguage = t),
                                e.updateCategoryTranslation();
                            }));
                    },
                  },
                  {
                    key: "navigateToRoot",
                    value: function () {
                      this.router.navigate(["/checkin"]);
                    },
                  },
                  {
                    key: "notViewingImpressumOrDatenschutz",
                    value: function () {
                      return (
                        "/security" !== this.router.url &&
                        "/impressum" !== this.router.url
                      );
                    },
                  },
                  {
                    key: "updateCategoryTranslation",
                    value: function () {
                      switch (this.selectedLanguage) {
                        case np.GERMAN:
                          this.questionnaire = rp;
                          break;
                        case np.ENGLISH:
                          this.questionnaire = ip;
                          break;
                        case np.SPANISH:
                          this.questionnaire = op;
                          break;
                        default:
                          this.questionnaire = rp;
                      }
                    },
                  },
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      this.languageSwitchSubscription.unsubscribe();
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || bm)(Wo(nv), Wo(ep));
            }),
            (bm.ɵcmp = rt({
              type: bm,
              selectors: [["app-navbar"]],
              decls: 7,
              vars: 2,
              consts: [
                ["id", "logoContainer"],
                [2, "margin-right", "10px", "cursor", "pointer", 3, "click"],
                [
                  "src",
                  "../../assets/logo-stuhlkreis.png",
                  2,
                  "height",
                  "35px",
                  "vertical-align",
                  "middle",
                ],
                [1, "nav-title"],
                [4, "ngIf"],
                ["class", "nav-item-container", 4, "ngIf"],
                [1, "nav-item-container"],
                [
                  "routerLinkActive",
                  "nav-item-active",
                  1,
                  "nav-item",
                  2,
                  "margin-right",
                  "15px",
                  3,
                  "routerLink",
                  "routerLinkActiveOptions",
                ],
                [1, "fa", "fa-sun"],
                [1, "fas", "fa-handshake"],
                [1, "fas", "fa-exchange-alt"],
                [1, "fas", "fa-bullseye"],
                [1, "fas", "fa-users"],
                [1, "fas", "fa-balance-scale-left"],
                [1, "fas", "fa-comments"],
                [1, "fa", "fa-moon"],
              ],
              template: function (e, t) {
                1 & e &&
                  (Ho(0, "div", 0),
                  Ho(1, "div", 1),
                  Go("click", function () {
                    return t.navigateToRoot();
                  }),
                  Vo(2, "img", 2),
                  Ho(3, "span", 3),
                  la(4, " digitaler-stuhlkreis.de "),
                  Uo(),
                  Uo(),
                  Fo(5, um, 1, 0, "language-switcher", 4),
                  Uo(),
                  Fo(6, ym, 17, 33, "div", 5)),
                  2 & e &&
                    (yi(5),
                    zo("ngIf", t.notViewingImpressumOrDatenschutz()),
                    yi(1),
                    zo("ngIf", t.notViewingImpressumOrDatenschutz()));
              },
              directives: [Il, sm, iv, cv],
              styles: [
                ".color-primary[_ngcontent-%COMP%], a[_ngcontent-%COMP%], h4[_ngcontent-%COMP%]{color:#fff}a[_ngcontent-%COMP%], h4[_ngcontent-%COMP%]{font-size:large}.nav-title[_ngcontent-%COMP%], a[_ngcontent-%COMP%]{text-decoration:none}.nav-title[_ngcontent-%COMP%]{display:inline-block;transition:.35s;background-color:transparent;border:0;padding:0 25px 0 7px;color:#f26a38;color:#fff}.nav-bar[_ngcontent-%COMP%], .nav-item-container[_ngcontent-%COMP%]{justify-content:space-between;align-items:center}.nav-item-container[_ngcontent-%COMP%]{display:flex;opacity:1;transition:all 1s}.nav-item[_ngcontent-%COMP%]{text-decoration:none;display:inline-block;background-color:transparent;transition:all 2s;color:#fff}.nav-item[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{display:inline}.social-link[_ngcontent-%COMP%]{display:inline-block;opacity:1;transition:all 2s}@media screen and (max-width:768px){.social-link[_ngcontent-%COMP%]{display:none;opacity:0;transition:all 2s}}@media screen and (max-width:936px){.sk-nav-item-compressed[_ngcontent-%COMP%]{display:none;opacity:0;transition:all 2s}}#logoContainer[_ngcontent-%COMP%]{display:flex;justify-content:space-between;margin-bottom:1.5rem}@media screen and (max-height:400px){#logoContainer[_ngcontent-%COMP%]{font-size:smaller;margin-bottom:1rem}}.nav-item[_ngcontent-%COMP%]:hover{border:1px solid #f26a38}.nav-item-active[_ngcontent-%COMP%]{border:0;border-bottom:1px;border-style:solid}@media screen and (min-width:769px){.nav-item-hamburger[_ngcontent-%COMP%]{display:none;opacity:1;transition:all 2s}}@media (max-width:768px){a[_ngcontent-%COMP%]{font-size:medium}}@media (min-width:936px){a[_ngcontent-%COMP%]{font-size:smaller}}@media (min-width:1025px){a[_ngcontent-%COMP%]{font-size:small}}@media (min-width:1252px){a[_ngcontent-%COMP%]{font-size:large}}",
              ],
              data: {
                animation: [
                  Dv("languageChange", [
                    Wv("void => *", []),
                    Wv("* => void", []),
                    Wv("* => *", [
                      Nv(
                        2e3,
                        Lv([
                          jv({ offset: 0, opacity: 0 }),
                          jv({ offset: 1, opacity: 100 }),
                        ])
                      ),
                    ]),
                  ]),
                ],
              },
            })),
            bm),
          _m =
            (((km = (function () {
              function e() {
                p(this, e), (this.title = "digitaler-stuhlkreis.de");
              }
              return (
                g(e, [
                  {
                    key: "ngOnInit",
                    value: function () {
                      this.innerHeight = window.innerHeight;
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || km)();
            }),
            (km.ɵcmp = rt({
              type: km,
              selectors: [["app-root"]],
              decls: 7,
              vars: 0,
              consts: [
                [
                  "id",
                  "outer-grey-div",
                  1,
                  "digitaler-stuhlkreis",
                  2,
                  "overflow",
                  "hidden",
                ],
                ["id", "inner-grey-div"],
                ["id", "app-outer-wrapper"],
                ["id", "app-inner-content-wrapper"],
                ["id", "app-inner-content-centered"],
              ],
              template: function (e, t) {
                1 & e &&
                  (Ho(0, "div", 0),
                  Ho(1, "div", 1),
                  Ho(2, "div", 2),
                  Vo(3, "app-navbar"),
                  Ho(4, "div", 3),
                  Ho(5, "div", 4),
                  Vo(6, "router-outlet"),
                  Uo(),
                  Uo(),
                  Uo(),
                  Uo(),
                  Uo());
              },
              directives: [wm, hv],
              styles: [
                "#app-outer-wrapper[_ngcontent-%COMP%]{margin:20px;display:flex;flex-direction:column;height:88%}#app-inner-content-wrapper[_ngcontent-%COMP%]{display:flex;justify-content:center;overflow-x:hidden;margin-top:20px;margin-bottom:20px;flex-grow:1}#app-inner-content-centered[_ngcontent-%COMP%]{max-width:1024px}#app-inner-content-wrapper[_ngcontent-%COMP%]::-webkit-scrollbar-track{box-shadow:inset 0 0 6px rgba(0,0,0,.3);-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);border-radius:10px;background-color:#a7a7a7}#app-inner-content-wrapper[_ngcontent-%COMP%]::-webkit-scrollbar{width:6px;background-color:#28282f}#app-inner-content-wrapper[_ngcontent-%COMP%]::-webkit-scrollbar-thumb{border-radius:10px;box-shadow:inset 0 0 6px rgba(0,0,0,.3);-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);background-color:#fff}",
              ],
            })),
            km);
        function Sm() {
          return (
            "undefined" != typeof process &&
            "[object process]" === {}.toString.call(process)
          );
        }
        function Cm(e) {
          switch (e.length) {
            case 0:
              return new qv();
            case 1:
              return e[0];
            default:
              return new Hv(e);
          }
        }
        function Em(e, t, n, r) {
          var i =
              arguments.length > 4 && void 0 !== arguments[4]
                ? arguments[4]
                : {},
            o =
              arguments.length > 5 && void 0 !== arguments[5]
                ? arguments[5]
                : {},
            a = [],
            s = [],
            u = -1,
            l = null;
          if (
            (r.forEach(function (e) {
              var n = e.offset,
                r = n == u,
                c = (r && l) || {};
              Object.keys(e).forEach(function (n) {
                var r = n,
                  s = e[n];
                if ("offset" !== n)
                  switch (((r = t.normalizePropertyName(r, a)), s)) {
                    case "!":
                      s = i[n];
                      break;
                    case "*":
                      s = o[n];
                      break;
                    default:
                      s = t.normalizeStyleValue(n, r, s, a);
                  }
                c[r] = s;
              }),
                r || s.push(c),
                (l = c),
                (u = n);
            }),
            a.length)
          ) {
            var c = "\n - ";
            throw new Error(
              "Unable to animate due to the following errors:"
                .concat(c)
                .concat(a.join(c))
            );
          }
          return s;
        }
        function Tm(e, t, n, r) {
          switch (t) {
            case "start":
              e.onStart(function () {
                return r(n && xm(n, "start", e));
              });
              break;
            case "done":
              e.onDone(function () {
                return r(n && xm(n, "done", e));
              });
              break;
            case "destroy":
              e.onDestroy(function () {
                return r(n && xm(n, "destroy", e));
              });
          }
        }
        function xm(e, t, n) {
          var r = n.totalTime,
            i = Im(
              e.element,
              e.triggerName,
              e.fromState,
              e.toState,
              t || e.phaseName,
              null == r ? e.totalTime : r,
              !!n.disabled
            ),
            o = e._data;
          return null != o && (i._data = o), i;
        }
        function Im(e, t, n, r) {
          var i =
              arguments.length > 4 && void 0 !== arguments[4]
                ? arguments[4]
                : "",
            o =
              arguments.length > 5 && void 0 !== arguments[5]
                ? arguments[5]
                : 0,
            a = arguments.length > 6 ? arguments[6] : void 0;
          return {
            element: e,
            triggerName: t,
            fromState: n,
            toState: r,
            phaseName: i,
            totalTime: o,
            disabled: !!a,
          };
        }
        function Am(e, t, n) {
          var r;
          return (
            e instanceof Map
              ? (r = e.get(t)) || e.set(t, (r = n))
              : (r = e[t]) || (r = e[t] = n),
            r
          );
        }
        function Om(e) {
          var t = e.indexOf(":");
          return [e.substring(1, t), e.substr(t + 1)];
        }
        var Rm = function (e, t) {
            return !1;
          },
          Pm = function (e, t) {
            return !1;
          },
          Dm = function (e, t, n) {
            return [];
          },
          Nm = Sm();
        (Nm || "undefined" != typeof Element) &&
          ((Rm = function (e, t) {
            return e.contains(t);
          }),
          (Pm = (function () {
            if (Nm || Element.prototype.matches)
              return function (e, t) {
                return e.matches(t);
              };
            var e = Element.prototype,
              t =
                e.matchesSelector ||
                e.mozMatchesSelector ||
                e.msMatchesSelector ||
                e.oMatchesSelector ||
                e.webkitMatchesSelector;
            return t
              ? function (e, n) {
                  return t.apply(e, [n]);
                }
              : Pm;
          })()),
          (Dm = function (e, t, n) {
            var r = [];
            if (n)
              for (var i = e.querySelectorAll(t), o = 0; o < i.length; o++)
                r.push(i[o]);
            else {
              var a = e.querySelector(t);
              a && r.push(a);
            }
            return r;
          }));
        var Mm = null,
          jm = !1;
        function Fm(e) {
          Mm ||
            ((Mm =
              ("undefined" != typeof document ? document.body : null) || {}),
            (jm = !!Mm.style && "WebkitAppearance" in Mm.style));
          var t = !0;
          return (
            Mm.style &&
              !(function (e) {
                return "ebkit" == e.substring(1, 6);
              })(e) &&
              !(t = e in Mm.style) &&
              jm &&
              (t =
                "Webkit" + e.charAt(0).toUpperCase() + e.substr(1) in Mm.style),
            t
          );
        }
        var Lm = Pm,
          Wm = Rm,
          zm = Dm;
        function qm(e) {
          var t = {};
          return (
            Object.keys(e).forEach(function (n) {
              var r = n.replace(/([a-z])([A-Z])/g, "$1-$2");
              t[r] = e[n];
            }),
            t
          );
        }
        var Hm,
          Um =
            (((Hm = (function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "validateStyleProperty",
                    value: function (e) {
                      return Fm(e);
                    },
                  },
                  {
                    key: "matchesElement",
                    value: function (e, t) {
                      return Lm(e, t);
                    },
                  },
                  {
                    key: "containsElement",
                    value: function (e, t) {
                      return Wm(e, t);
                    },
                  },
                  {
                    key: "query",
                    value: function (e, t, n) {
                      return zm(e, t, n);
                    },
                  },
                  {
                    key: "computeStyle",
                    value: function (e, t, n) {
                      return n || "";
                    },
                  },
                  {
                    key: "animate",
                    value: function (e, t, n, r, i) {
                      return new qv(n, r);
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Hm)();
            }),
            (Hm.ɵprov = Ee({ token: Hm, factory: Hm.ɵfac })),
            Hm),
          Vm = (function () {
            var e = function e() {
              p(this, e);
            };
            return (e.NOOP = new Um()), e;
          })();
        function Bm(e) {
          if ("number" == typeof e) return e;
          var t = e.match(/^(-?[\.\d]+)(m?s)/);
          return !t || t.length < 2 ? 0 : Qm(parseFloat(t[1]), t[2]);
        }
        function Qm(e, t) {
          switch (t) {
            case "s":
              return 1e3 * e;
            default:
              return e;
          }
        }
        function Gm(e, t, n) {
          return e.hasOwnProperty("duration")
            ? e
            : (function (e, t, n) {
                var r,
                  i = 0,
                  o = "";
                if ("string" == typeof e) {
                  var a = e.match(
                    /^(-?[\.\d]+)(m?s)(?:\s+(-?[\.\d]+)(m?s))?(?:\s+([-a-z]+(?:\(.+?\))?))?$/i
                  );
                  if (null === a)
                    return (
                      t.push(
                        'The provided timing value "'.concat(e, '" is invalid.')
                      ),
                      { duration: 0, delay: 0, easing: "" }
                    );
                  r = Qm(parseFloat(a[1]), a[2]);
                  var s = a[3];
                  null != s && (i = Qm(parseFloat(s), a[4]));
                  var u = a[5];
                  u && (o = u);
                } else r = e;
                if (!n) {
                  var l = !1,
                    c = t.length;
                  r < 0 &&
                    (t.push(
                      "Duration values below 0 are not allowed for this animation step."
                    ),
                    (l = !0)),
                    i < 0 &&
                      (t.push(
                        "Delay values below 0 are not allowed for this animation step."
                      ),
                      (l = !0)),
                    l &&
                      t.splice(
                        c,
                        0,
                        'The provided timing value "'.concat(e, '" is invalid.')
                      );
                }
                return { duration: r, delay: i, easing: o };
              })(e, t, n);
        }
        function Km(e) {
          var t =
            arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
          return (
            Object.keys(e).forEach(function (n) {
              t[n] = e[n];
            }),
            t
          );
        }
        function Zm(e, t) {
          var n =
            arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
          if (t) for (var r in e) n[r] = e[r];
          else Km(e, n);
          return n;
        }
        function Jm(e, t, n) {
          return n ? t + ":" + n + ";" : "";
        }
        function $m(e) {
          for (var t = "", n = 0; n < e.style.length; n++) {
            var r = e.style.item(n);
            t += Jm(0, r, e.style.getPropertyValue(r));
          }
          for (var i in e.style)
            e.style.hasOwnProperty(i) &&
              !i.startsWith("_") &&
              (t += Jm(
                0,
                i.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase(),
                e.style[i]
              ));
          e.setAttribute("style", t);
        }
        function Ym(e, t, n) {
          e.style &&
            (Object.keys(t).forEach(function (r) {
              var i = ag(r);
              n && !n.hasOwnProperty(r) && (n[r] = e.style[i]),
                (e.style[i] = t[r]);
            }),
            Sm() && $m(e));
        }
        function Xm(e, t) {
          e.style &&
            (Object.keys(t).forEach(function (t) {
              var n = ag(t);
              e.style[n] = "";
            }),
            Sm() && $m(e));
        }
        function eg(e) {
          return Array.isArray(e) ? (1 == e.length ? e[0] : Mv(e)) : e;
        }
        var tg = new RegExp("{{\\s*(.+?)\\s*}}", "g");
        function ng(e) {
          var t = [];
          if ("string" == typeof e) {
            for (var n; (n = tg.exec(e)); ) t.push(n[1]);
            tg.lastIndex = 0;
          }
          return t;
        }
        function rg(e, t, n) {
          var r = e.toString(),
            i = r.replace(tg, function (e, r) {
              var i = t[r];
              return (
                t.hasOwnProperty(r) ||
                  (n.push(
                    "Please provide a value for the animation param ".concat(r)
                  ),
                  (i = "")),
                i.toString()
              );
            });
          return i == r ? e : i;
        }
        function ig(e) {
          for (var t = [], n = e.next(); !n.done; )
            t.push(n.value), (n = e.next());
          return t;
        }
        var og = /-+([a-z0-9])/g;
        function ag(e) {
          return e.replace(og, function () {
            for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
              t[n] = arguments[n];
            return t[1].toUpperCase();
          });
        }
        function sg(e, t) {
          return 0 === e || 0 === t;
        }
        function ug(e, t, n) {
          var r = Object.keys(n);
          if (r.length && t.length) {
            var i = t[0],
              o = [];
            if (
              (r.forEach(function (e) {
                i.hasOwnProperty(e) || o.push(e), (i[e] = n[e]);
              }),
              o.length)
            )
              for (
                var a = function () {
                    var n = t[s];
                    o.forEach(function (t) {
                      n[t] = cg(e, t);
                    });
                  },
                  s = 1;
                s < t.length;
                s++
              )
                a();
          }
          return t;
        }
        function lg(e, t, n) {
          switch (t.type) {
            case 7:
              return e.visitTrigger(t, n);
            case 0:
              return e.visitState(t, n);
            case 1:
              return e.visitTransition(t, n);
            case 2:
              return e.visitSequence(t, n);
            case 3:
              return e.visitGroup(t, n);
            case 4:
              return e.visitAnimate(t, n);
            case 5:
              return e.visitKeyframes(t, n);
            case 6:
              return e.visitStyle(t, n);
            case 8:
              return e.visitReference(t, n);
            case 9:
              return e.visitAnimateChild(t, n);
            case 10:
              return e.visitAnimateRef(t, n);
            case 11:
              return e.visitQuery(t, n);
            case 12:
              return e.visitStagger(t, n);
            default:
              throw new Error(
                "Unable to resolve animation metadata node #".concat(t.type)
              );
          }
        }
        function cg(e, t) {
          return window.getComputedStyle(e)[t];
        }
        var hg = new Set(["true", "1"]),
          fg = new Set(["false", "0"]);
        function dg(e, t) {
          var n = hg.has(e) || fg.has(e),
            r = hg.has(t) || fg.has(t);
          return function (i, o) {
            var a = "*" == e || e == i,
              s = "*" == t || t == o;
            return (
              !a &&
                n &&
                "boolean" == typeof i &&
                (a = i ? hg.has(e) : fg.has(e)),
              !s &&
                r &&
                "boolean" == typeof o &&
                (s = o ? hg.has(t) : fg.has(t)),
              a && s
            );
          };
        }
        var vg = new RegExp("s*:selfs*,?", "g");
        function pg(e, t, n) {
          return new mg(e).build(t, n);
        }
        var mg = (function () {
            function t(e) {
              p(this, t), (this._driver = e);
            }
            return (
              g(t, [
                {
                  key: "build",
                  value: function (e, t) {
                    var n = new gg(t);
                    return (
                      this._resetContextStyleTimingState(n), lg(this, eg(e), n)
                    );
                  },
                },
                {
                  key: "_resetContextStyleTimingState",
                  value: function (e) {
                    (e.currentQuerySelector = ""),
                      (e.collectedStyles = {}),
                      (e.collectedStyles[""] = {}),
                      (e.currentTime = 0);
                  },
                },
                {
                  key: "visitTrigger",
                  value: function (e, t) {
                    var n = this,
                      r = (t.queryCount = 0),
                      i = (t.depCount = 0),
                      o = [],
                      a = [];
                    return (
                      "@" == e.name.charAt(0) &&
                        t.errors.push(
                          "animation triggers cannot be prefixed with an `@` sign (e.g. trigger('@foo', [...]))"
                        ),
                      e.definitions.forEach(function (e) {
                        if ((n._resetContextStyleTimingState(t), 0 == e.type)) {
                          var s = e,
                            u = s.name;
                          u
                            .toString()
                            .split(/\s*,\s*/)
                            .forEach(function (e) {
                              (s.name = e), o.push(n.visitState(s, t));
                            }),
                            (s.name = u);
                        } else if (1 == e.type) {
                          var l = n.visitTransition(e, t);
                          (r += l.queryCount), (i += l.depCount), a.push(l);
                        } else
                          t.errors.push(
                            "only state() and transition() definitions can sit inside of a trigger()"
                          );
                      }),
                      {
                        type: 7,
                        name: e.name,
                        states: o,
                        transitions: a,
                        queryCount: r,
                        depCount: i,
                        options: null,
                      }
                    );
                  },
                },
                {
                  key: "visitState",
                  value: function (e, t) {
                    var n = this.visitStyle(e.styles, t),
                      r = (e.options && e.options.params) || null;
                    if (n.containsDynamicStyles) {
                      var i = new Set(),
                        o = r || {};
                      if (
                        (n.styles.forEach(function (e) {
                          if (yg(e)) {
                            var t = e;
                            Object.keys(t).forEach(function (e) {
                              ng(t[e]).forEach(function (e) {
                                o.hasOwnProperty(e) || i.add(e);
                              });
                            });
                          }
                        }),
                        i.size)
                      ) {
                        var a = ig(i.values());
                        t.errors.push(
                          'state("'
                            .concat(
                              e.name,
                              '", ...) must define default values for all the following style substitutions: '
                            )
                            .concat(a.join(", "))
                        );
                      }
                    }
                    return {
                      type: 0,
                      name: e.name,
                      style: n,
                      options: r ? { params: r } : null,
                    };
                  },
                },
                {
                  key: "visitTransition",
                  value: function (e, t) {
                    (t.queryCount = 0), (t.depCount = 0);
                    var n,
                      r,
                      i,
                      o = lg(this, eg(e.animation), t);
                    return {
                      type: 1,
                      matchers:
                        ((n = e.expr),
                        (r = t.errors),
                        (i = []),
                        "string" == typeof n
                          ? n.split(/\s*,\s*/).forEach(function (e) {
                              return (function (e, t, n) {
                                if (":" == e[0]) {
                                  var r = (function (e, t) {
                                    switch (e) {
                                      case ":enter":
                                        return "void => *";
                                      case ":leave":
                                        return "* => void";
                                      case ":increment":
                                        return function (e, t) {
                                          return parseFloat(t) > parseFloat(e);
                                        };
                                      case ":decrement":
                                        return function (e, t) {
                                          return parseFloat(t) < parseFloat(e);
                                        };
                                      default:
                                        return (
                                          t.push(
                                            'The transition alias value "'.concat(
                                              e,
                                              '" is not supported'
                                            )
                                          ),
                                          "* => *"
                                        );
                                    }
                                  })(e, n);
                                  if ("function" == typeof r)
                                    return void t.push(r);
                                  e = r;
                                }
                                var i = e.match(
                                  /^(\*|[-\w]+)\s*(<?[=-]>)\s*(\*|[-\w]+)$/
                                );
                                if (null == i || i.length < 4)
                                  return (
                                    n.push(
                                      'The provided transition expression "'.concat(
                                        e,
                                        '" is not supported'
                                      )
                                    ),
                                    t
                                  );
                                var o = i[1],
                                  a = i[2],
                                  s = i[3];
                                t.push(dg(o, s)),
                                  "<" != a[0] ||
                                    ("*" == o && "*" == s) ||
                                    t.push(dg(s, o));
                              })(e, i, r);
                            })
                          : i.push(n),
                        i),
                      animation: o,
                      queryCount: t.queryCount,
                      depCount: t.depCount,
                      options: kg(e.options),
                    };
                  },
                },
                {
                  key: "visitSequence",
                  value: function (e, t) {
                    var n = this;
                    return {
                      type: 2,
                      steps: e.steps.map(function (e) {
                        return lg(n, e, t);
                      }),
                      options: kg(e.options),
                    };
                  },
                },
                {
                  key: "visitGroup",
                  value: function (e, t) {
                    var n = this,
                      r = t.currentTime,
                      i = 0,
                      o = e.steps.map(function (e) {
                        t.currentTime = r;
                        var o = lg(n, e, t);
                        return (i = Math.max(i, t.currentTime)), o;
                      });
                    return (
                      (t.currentTime = i),
                      { type: 3, steps: o, options: kg(e.options) }
                    );
                  },
                },
                {
                  key: "visitAnimate",
                  value: function (e, t) {
                    var n,
                      r = (function (e, t) {
                        var n = null;
                        if (e.hasOwnProperty("duration")) n = e;
                        else if ("number" == typeof e)
                          return bg(Gm(e, t).duration, 0, "");
                        var r = e;
                        if (
                          r.split(/\s+/).some(function (e) {
                            return "{" == e.charAt(0) && "{" == e.charAt(1);
                          })
                        ) {
                          var i = bg(0, 0, "");
                          return (i.dynamic = !0), (i.strValue = r), i;
                        }
                        return bg(
                          (n = n || Gm(r, t)).duration,
                          n.delay,
                          n.easing
                        );
                      })(e.timings, t.errors);
                    t.currentAnimateTimings = r;
                    var i = e.styles ? e.styles : jv({});
                    if (5 == i.type) n = this.visitKeyframes(i, t);
                    else {
                      var o = e.styles,
                        a = !1;
                      if (!o) {
                        a = !0;
                        var s = {};
                        r.easing && (s.easing = r.easing), (o = jv(s));
                      }
                      t.currentTime += r.duration + r.delay;
                      var u = this.visitStyle(o, t);
                      (u.isEmptyStep = a), (n = u);
                    }
                    return (
                      (t.currentAnimateTimings = null),
                      { type: 4, timings: r, style: n, options: null }
                    );
                  },
                },
                {
                  key: "visitStyle",
                  value: function (e, t) {
                    var n = this._makeStyleAst(e, t);
                    return this._validateStyleAst(n, t), n;
                  },
                },
                {
                  key: "_makeStyleAst",
                  value: function (e, t) {
                    var n = [];
                    Array.isArray(e.styles)
                      ? e.styles.forEach(function (e) {
                          "string" == typeof e
                            ? "*" == e
                              ? n.push(e)
                              : t.errors.push(
                                  "The provided style string value ".concat(
                                    e,
                                    " is not allowed."
                                  )
                                )
                            : n.push(e);
                        })
                      : n.push(e.styles);
                    var r = !1,
                      i = null;
                    return (
                      n.forEach(function (e) {
                        if (yg(e)) {
                          var t = e,
                            n = t.easing;
                          if ((n && ((i = n), delete t.easing), !r))
                            for (var o in t)
                              if (t[o].toString().indexOf("{{") >= 0) {
                                r = !0;
                                break;
                              }
                        }
                      }),
                      {
                        type: 6,
                        styles: n,
                        easing: i,
                        offset: e.offset,
                        containsDynamicStyles: r,
                        options: null,
                      }
                    );
                  },
                },
                {
                  key: "_validateStyleAst",
                  value: function (e, t) {
                    var n = this,
                      r = t.currentAnimateTimings,
                      i = t.currentTime,
                      o = t.currentTime;
                    r && o > 0 && (o -= r.duration + r.delay),
                      e.styles.forEach(function (e) {
                        "string" != typeof e &&
                          Object.keys(e).forEach(function (r) {
                            if (n._driver.validateStyleProperty(r)) {
                              var a,
                                s,
                                u,
                                l,
                                c,
                                h = t.collectedStyles[t.currentQuerySelector],
                                f = h[r],
                                d = !0;
                              f &&
                                (o != i &&
                                  o >= f.startTime &&
                                  i <= f.endTime &&
                                  (t.errors.push(
                                    'The CSS property "'
                                      .concat(
                                        r,
                                        '" that exists between the times of "'
                                      )
                                      .concat(f.startTime, 'ms" and "')
                                      .concat(
                                        f.endTime,
                                        'ms" is also being animated in a parallel animation between the times of "'
                                      )
                                      .concat(o, 'ms" and "')
                                      .concat(i, 'ms"')
                                  ),
                                  (d = !1)),
                                (o = f.startTime)),
                                d && (h[r] = { startTime: o, endTime: i }),
                                t.options &&
                                  ((a = e[r]),
                                  (s = t.options),
                                  (u = t.errors),
                                  (l = s.params || {}),
                                  (c = ng(a)).length &&
                                    c.forEach(function (e) {
                                      l.hasOwnProperty(e) ||
                                        u.push(
                                          "Unable to resolve the local animation param ".concat(
                                            e,
                                            " in the given list of values"
                                          )
                                        );
                                    }));
                            } else
                              t.errors.push(
                                'The provided animation property "'.concat(
                                  r,
                                  '" is not a supported CSS property for animations'
                                )
                              );
                          });
                      });
                  },
                },
                {
                  key: "visitKeyframes",
                  value: function (e, t) {
                    var n = this,
                      r = { type: 5, styles: [], options: null };
                    if (!t.currentAnimateTimings)
                      return (
                        t.errors.push(
                          "keyframes() must be placed inside of a call to animate()"
                        ),
                        r
                      );
                    var i = 0,
                      o = [],
                      a = !1,
                      s = !1,
                      u = 0,
                      l = e.steps.map(function (e) {
                        var r = n._makeStyleAst(e, t),
                          l =
                            null != r.offset
                              ? r.offset
                              : (function (e) {
                                  if ("string" == typeof e) return null;
                                  var t = null;
                                  if (Array.isArray(e))
                                    e.forEach(function (e) {
                                      if (yg(e) && e.hasOwnProperty("offset")) {
                                        var n = e;
                                        (t = parseFloat(n.offset)),
                                          delete n.offset;
                                      }
                                    });
                                  else if (
                                    yg(e) &&
                                    e.hasOwnProperty("offset")
                                  ) {
                                    var n = e;
                                    (t = parseFloat(n.offset)), delete n.offset;
                                  }
                                  return t;
                                })(r.styles),
                          c = 0;
                        return (
                          null != l && (i++, (c = r.offset = l)),
                          (s = s || c < 0 || c > 1),
                          (a = a || c < u),
                          (u = c),
                          o.push(c),
                          r
                        );
                      });
                    s &&
                      t.errors.push(
                        "Please ensure that all keyframe offsets are between 0 and 1"
                      ),
                      a &&
                        t.errors.push(
                          "Please ensure that all keyframe offsets are in order"
                        );
                    var c = e.steps.length,
                      h = 0;
                    i > 0 && i < c
                      ? t.errors.push(
                          "Not all style() steps within the declared keyframes() contain offsets"
                        )
                      : 0 == i && (h = 1 / (c - 1));
                    var f = c - 1,
                      d = t.currentTime,
                      v = t.currentAnimateTimings,
                      p = v.duration;
                    return (
                      l.forEach(function (e, i) {
                        var a = h > 0 ? (i == f ? 1 : h * i) : o[i],
                          s = a * p;
                        (t.currentTime = d + v.delay + s),
                          (v.duration = s),
                          n._validateStyleAst(e, t),
                          (e.offset = a),
                          r.styles.push(e);
                      }),
                      r
                    );
                  },
                },
                {
                  key: "visitReference",
                  value: function (e, t) {
                    return {
                      type: 8,
                      animation: lg(this, eg(e.animation), t),
                      options: kg(e.options),
                    };
                  },
                },
                {
                  key: "visitAnimateChild",
                  value: function (e, t) {
                    return t.depCount++, { type: 9, options: kg(e.options) };
                  },
                },
                {
                  key: "visitAnimateRef",
                  value: function (e, t) {
                    return {
                      type: 10,
                      animation: this.visitReference(e.animation, t),
                      options: kg(e.options),
                    };
                  },
                },
                {
                  key: "visitQuery",
                  value: function (t, n) {
                    var r = n.currentQuerySelector,
                      i = t.options || {};
                    n.queryCount++, (n.currentQuery = t);
                    var o = e(
                        (function (e) {
                          var t = !!e.split(/\s*,\s*/).find(function (e) {
                            return ":self" == e;
                          });
                          return (
                            t && (e = e.replace(vg, "")),
                            [
                              (e = e
                                .replace(/@\*/g, ".ng-trigger")
                                .replace(/@\w+/g, function (e) {
                                  return ".ng-trigger-" + e.substr(1);
                                })
                                .replace(/:animating/g, ".ng-animating")),
                              t,
                            ]
                          );
                        })(t.selector),
                        2
                      ),
                      a = o[0],
                      s = o[1];
                    (n.currentQuerySelector = r.length ? r + " " + a : a),
                      Am(n.collectedStyles, n.currentQuerySelector, {});
                    var u = lg(this, eg(t.animation), n);
                    return (
                      (n.currentQuery = null),
                      (n.currentQuerySelector = r),
                      {
                        type: 11,
                        selector: a,
                        limit: i.limit || 0,
                        optional: !!i.optional,
                        includeSelf: s,
                        animation: u,
                        originalSelector: t.selector,
                        options: kg(t.options),
                      }
                    );
                  },
                },
                {
                  key: "visitStagger",
                  value: function (e, t) {
                    t.currentQuery ||
                      t.errors.push(
                        "stagger() can only be used inside of query()"
                      );
                    var n =
                      "full" === e.timings
                        ? { duration: 0, delay: 0, easing: "full" }
                        : Gm(e.timings, t.errors, !0);
                    return {
                      type: 12,
                      animation: lg(this, eg(e.animation), t),
                      timings: n,
                      options: null,
                    };
                  },
                },
              ]),
              t
            );
          })(),
          gg = function e(t) {
            p(this, e),
              (this.errors = t),
              (this.queryCount = 0),
              (this.depCount = 0),
              (this.currentTransition = null),
              (this.currentQuery = null),
              (this.currentQuerySelector = null),
              (this.currentAnimateTimings = null),
              (this.currentTime = 0),
              (this.collectedStyles = {}),
              (this.options = null);
          };
        function yg(e) {
          return !Array.isArray(e) && "object" == typeof e;
        }
        function kg(e) {
          var t;
          return (
            e
              ? (e = Km(e)).params && (e.params = (t = e.params) ? Km(t) : null)
              : (e = {}),
            e
          );
        }
        function bg(e, t, n) {
          return { duration: e, delay: t, easing: n };
        }
        function wg(e, t, n, r, i, o) {
          var a =
              arguments.length > 6 && void 0 !== arguments[6]
                ? arguments[6]
                : null,
            s = arguments.length > 7 && void 0 !== arguments[7] && arguments[7];
          return {
            type: 1,
            element: e,
            keyframes: t,
            preStyleProps: n,
            postStyleProps: r,
            duration: i,
            delay: o,
            totalTime: i + o,
            easing: a,
            subTimeline: s,
          };
        }
        var _g = (function () {
            function e() {
              p(this, e), (this._map = new Map());
            }
            return (
              g(e, [
                {
                  key: "consume",
                  value: function (e) {
                    var t = this._map.get(e);
                    return t ? this._map.delete(e) : (t = []), t;
                  },
                },
                {
                  key: "append",
                  value: function (e, t) {
                    var r,
                      i = this._map.get(e);
                    i || this._map.set(e, (i = [])),
                      (r = i).push.apply(r, n(t));
                  },
                },
                {
                  key: "has",
                  value: function (e) {
                    return this._map.has(e);
                  },
                },
                {
                  key: "clear",
                  value: function () {
                    this._map.clear();
                  },
                },
              ]),
              e
            );
          })(),
          Sg = new RegExp(":enter", "g"),
          Cg = new RegExp(":leave", "g");
        function Eg(e, t, n, r, i) {
          var o =
              arguments.length > 5 && void 0 !== arguments[5]
                ? arguments[5]
                : {},
            a =
              arguments.length > 6 && void 0 !== arguments[6]
                ? arguments[6]
                : {},
            s = arguments.length > 7 ? arguments[7] : void 0,
            u = arguments.length > 8 ? arguments[8] : void 0,
            l =
              arguments.length > 9 && void 0 !== arguments[9]
                ? arguments[9]
                : [];
          return new Tg().buildKeyframes(e, t, n, r, i, o, a, s, u, l);
        }
        var Tg = (function () {
            function e() {
              p(this, e);
            }
            return (
              g(e, [
                {
                  key: "buildKeyframes",
                  value: function (e, t, n, r, i, o, a, s, u) {
                    var l =
                      arguments.length > 9 && void 0 !== arguments[9]
                        ? arguments[9]
                        : [];
                    u = u || new _g();
                    var c = new Ig(e, t, u, r, i, l, []);
                    (c.options = s),
                      c.currentTimeline.setStyles([o], null, c.errors, s),
                      lg(this, n, c);
                    var h = c.timelines.filter(function (e) {
                      return e.containsAnimation();
                    });
                    if (h.length && Object.keys(a).length) {
                      var f = h[h.length - 1];
                      f.allowOnlyTimelineStyles() ||
                        f.setStyles([a], null, c.errors, s);
                    }
                    return h.length
                      ? h.map(function (e) {
                          return e.buildKeyframes();
                        })
                      : [wg(t, [], [], [], 0, 0, "", !1)];
                  },
                },
                { key: "visitTrigger", value: function (e, t) {} },
                { key: "visitState", value: function (e, t) {} },
                { key: "visitTransition", value: function (e, t) {} },
                {
                  key: "visitAnimateChild",
                  value: function (e, t) {
                    var n = t.subInstructions.consume(t.element);
                    if (n) {
                      var r = t.createSubContext(e.options),
                        i = t.currentTimeline.currentTime,
                        o = this._visitSubInstructions(n, r, r.options);
                      i != o && t.transformIntoNewTimeline(o);
                    }
                    t.previousNode = e;
                  },
                },
                {
                  key: "visitAnimateRef",
                  value: function (e, t) {
                    var n = t.createSubContext(e.options);
                    n.transformIntoNewTimeline(),
                      this.visitReference(e.animation, n),
                      t.transformIntoNewTimeline(n.currentTimeline.currentTime),
                      (t.previousNode = e);
                  },
                },
                {
                  key: "_visitSubInstructions",
                  value: function (e, t, n) {
                    var r = t.currentTimeline.currentTime,
                      i = null != n.duration ? Bm(n.duration) : null,
                      o = null != n.delay ? Bm(n.delay) : null;
                    return (
                      0 !== i &&
                        e.forEach(function (e) {
                          var n = t.appendInstructionToTimeline(e, i, o);
                          r = Math.max(r, n.duration + n.delay);
                        }),
                      r
                    );
                  },
                },
                {
                  key: "visitReference",
                  value: function (e, t) {
                    t.updateOptions(e.options, !0),
                      lg(this, e.animation, t),
                      (t.previousNode = e);
                  },
                },
                {
                  key: "visitSequence",
                  value: function (e, t) {
                    var n = this,
                      r = t.subContextCount,
                      i = t,
                      o = e.options;
                    if (
                      o &&
                      (o.params || o.delay) &&
                      ((i = t.createSubContext(o)).transformIntoNewTimeline(),
                      null != o.delay)
                    ) {
                      6 == i.previousNode.type &&
                        (i.currentTimeline.snapshotCurrentStyles(),
                        (i.previousNode = xg));
                      var a = Bm(o.delay);
                      i.delayNextStep(a);
                    }
                    e.steps.length &&
                      (e.steps.forEach(function (e) {
                        return lg(n, e, i);
                      }),
                      i.currentTimeline.applyStylesToKeyframe(),
                      i.subContextCount > r && i.transformIntoNewTimeline()),
                      (t.previousNode = e);
                  },
                },
                {
                  key: "visitGroup",
                  value: function (e, t) {
                    var n = this,
                      r = [],
                      i = t.currentTimeline.currentTime,
                      o =
                        e.options && e.options.delay ? Bm(e.options.delay) : 0;
                    e.steps.forEach(function (a) {
                      var s = t.createSubContext(e.options);
                      o && s.delayNextStep(o),
                        lg(n, a, s),
                        (i = Math.max(i, s.currentTimeline.currentTime)),
                        r.push(s.currentTimeline);
                    }),
                      r.forEach(function (e) {
                        return t.currentTimeline.mergeTimelineCollectedStyles(
                          e
                        );
                      }),
                      t.transformIntoNewTimeline(i),
                      (t.previousNode = e);
                  },
                },
                {
                  key: "_visitTiming",
                  value: function (e, t) {
                    if (e.dynamic) {
                      var n = e.strValue;
                      return Gm(
                        t.params ? rg(n, t.params, t.errors) : n,
                        t.errors
                      );
                    }
                    return {
                      duration: e.duration,
                      delay: e.delay,
                      easing: e.easing,
                    };
                  },
                },
                {
                  key: "visitAnimate",
                  value: function (e, t) {
                    var n = (t.currentAnimateTimings = this._visitTiming(
                        e.timings,
                        t
                      )),
                      r = t.currentTimeline;
                    n.delay &&
                      (t.incrementTime(n.delay), r.snapshotCurrentStyles());
                    var i = e.style;
                    5 == i.type
                      ? this.visitKeyframes(i, t)
                      : (t.incrementTime(n.duration),
                        this.visitStyle(i, t),
                        r.applyStylesToKeyframe()),
                      (t.currentAnimateTimings = null),
                      (t.previousNode = e);
                  },
                },
                {
                  key: "visitStyle",
                  value: function (e, t) {
                    var n = t.currentTimeline,
                      r = t.currentAnimateTimings;
                    !r &&
                      n.getCurrentStyleProperties().length &&
                      n.forwardFrame();
                    var i = (r && r.easing) || e.easing;
                    e.isEmptyStep
                      ? n.applyEmptyStep(i)
                      : n.setStyles(e.styles, i, t.errors, t.options),
                      (t.previousNode = e);
                  },
                },
                {
                  key: "visitKeyframes",
                  value: function (e, t) {
                    var n = t.currentAnimateTimings,
                      r = t.currentTimeline.duration,
                      i = n.duration,
                      o = t.createSubContext().currentTimeline;
                    (o.easing = n.easing),
                      e.styles.forEach(function (e) {
                        o.forwardTime((e.offset || 0) * i),
                          o.setStyles(e.styles, e.easing, t.errors, t.options),
                          o.applyStylesToKeyframe();
                      }),
                      t.currentTimeline.mergeTimelineCollectedStyles(o),
                      t.transformIntoNewTimeline(r + i),
                      (t.previousNode = e);
                  },
                },
                {
                  key: "visitQuery",
                  value: function (e, t) {
                    var n = this,
                      r = t.currentTimeline.currentTime,
                      i = e.options || {},
                      o = i.delay ? Bm(i.delay) : 0;
                    o &&
                      (6 === t.previousNode.type ||
                        (0 == r &&
                          t.currentTimeline.getCurrentStyleProperties()
                            .length)) &&
                      (t.currentTimeline.snapshotCurrentStyles(),
                      (t.previousNode = xg));
                    var a = r,
                      s = t.invokeQuery(
                        e.selector,
                        e.originalSelector,
                        e.limit,
                        e.includeSelf,
                        !!i.optional,
                        t.errors
                      );
                    t.currentQueryTotal = s.length;
                    var u = null;
                    s.forEach(function (r, i) {
                      t.currentQueryIndex = i;
                      var s = t.createSubContext(e.options, r);
                      o && s.delayNextStep(o),
                        r === t.element && (u = s.currentTimeline),
                        lg(n, e.animation, s),
                        s.currentTimeline.applyStylesToKeyframe(),
                        (a = Math.max(a, s.currentTimeline.currentTime));
                    }),
                      (t.currentQueryIndex = 0),
                      (t.currentQueryTotal = 0),
                      t.transformIntoNewTimeline(a),
                      u &&
                        (t.currentTimeline.mergeTimelineCollectedStyles(u),
                        t.currentTimeline.snapshotCurrentStyles()),
                      (t.previousNode = e);
                  },
                },
                {
                  key: "visitStagger",
                  value: function (e, t) {
                    var n = t.parentContext,
                      r = t.currentTimeline,
                      i = e.timings,
                      o = Math.abs(i.duration),
                      a = o * (t.currentQueryTotal - 1),
                      s = o * t.currentQueryIndex;
                    switch (i.duration < 0 ? "reverse" : i.easing) {
                      case "reverse":
                        s = a - s;
                        break;
                      case "full":
                        s = n.currentStaggerTime;
                    }
                    var u = t.currentTimeline;
                    s && u.delayNextStep(s);
                    var l = u.currentTime;
                    lg(this, e.animation, t),
                      (t.previousNode = e),
                      (n.currentStaggerTime =
                        r.currentTime -
                        l +
                        (r.startTime - n.currentTimeline.startTime));
                  },
                },
              ]),
              e
            );
          })(),
          xg = {},
          Ig = (function () {
            function e(t, n, r, i, o, a, s, u) {
              p(this, e),
                (this._driver = t),
                (this.element = n),
                (this.subInstructions = r),
                (this._enterClassName = i),
                (this._leaveClassName = o),
                (this.errors = a),
                (this.timelines = s),
                (this.parentContext = null),
                (this.currentAnimateTimings = null),
                (this.previousNode = xg),
                (this.subContextCount = 0),
                (this.options = {}),
                (this.currentQueryIndex = 0),
                (this.currentQueryTotal = 0),
                (this.currentStaggerTime = 0),
                (this.currentTimeline = u || new Ag(this._driver, n, 0)),
                s.push(this.currentTimeline);
            }
            return (
              g(e, [
                {
                  key: "params",
                  get: function () {
                    return this.options.params;
                  },
                },
                {
                  key: "updateOptions",
                  value: function (e, t) {
                    var n = this;
                    if (e) {
                      var r = e,
                        i = this.options;
                      null != r.duration && (i.duration = Bm(r.duration)),
                        null != r.delay && (i.delay = Bm(r.delay));
                      var o = r.params;
                      if (o) {
                        var a = i.params;
                        a || (a = this.options.params = {}),
                          Object.keys(o).forEach(function (e) {
                            (t && a.hasOwnProperty(e)) ||
                              (a[e] = rg(o[e], a, n.errors));
                          });
                      }
                    }
                  },
                },
                {
                  key: "_copyOptions",
                  value: function () {
                    var e = {};
                    if (this.options) {
                      var t = this.options.params;
                      if (t) {
                        var n = (e.params = {});
                        Object.keys(t).forEach(function (e) {
                          n[e] = t[e];
                        });
                      }
                    }
                    return e;
                  },
                },
                {
                  key: "createSubContext",
                  value: function () {
                    var t =
                        arguments.length > 0 && void 0 !== arguments[0]
                          ? arguments[0]
                          : null,
                      n = arguments.length > 1 ? arguments[1] : void 0,
                      r = arguments.length > 2 ? arguments[2] : void 0,
                      i = n || this.element,
                      o = new e(
                        this._driver,
                        i,
                        this.subInstructions,
                        this._enterClassName,
                        this._leaveClassName,
                        this.errors,
                        this.timelines,
                        this.currentTimeline.fork(i, r || 0)
                      );
                    return (
                      (o.previousNode = this.previousNode),
                      (o.currentAnimateTimings = this.currentAnimateTimings),
                      (o.options = this._copyOptions()),
                      o.updateOptions(t),
                      (o.currentQueryIndex = this.currentQueryIndex),
                      (o.currentQueryTotal = this.currentQueryTotal),
                      (o.parentContext = this),
                      this.subContextCount++,
                      o
                    );
                  },
                },
                {
                  key: "transformIntoNewTimeline",
                  value: function (e) {
                    return (
                      (this.previousNode = xg),
                      (this.currentTimeline = this.currentTimeline.fork(
                        this.element,
                        e
                      )),
                      this.timelines.push(this.currentTimeline),
                      this.currentTimeline
                    );
                  },
                },
                {
                  key: "appendInstructionToTimeline",
                  value: function (e, t, n) {
                    var r = {
                        duration: null != t ? t : e.duration,
                        delay:
                          this.currentTimeline.currentTime +
                          (null != n ? n : 0) +
                          e.delay,
                        easing: "",
                      },
                      i = new Og(
                        this._driver,
                        e.element,
                        e.keyframes,
                        e.preStyleProps,
                        e.postStyleProps,
                        r,
                        e.stretchStartingKeyframe
                      );
                    return this.timelines.push(i), r;
                  },
                },
                {
                  key: "incrementTime",
                  value: function (e) {
                    this.currentTimeline.forwardTime(
                      this.currentTimeline.duration + e
                    );
                  },
                },
                {
                  key: "delayNextStep",
                  value: function (e) {
                    e > 0 && this.currentTimeline.delayNextStep(e);
                  },
                },
                {
                  key: "invokeQuery",
                  value: function (e, t, r, i, o, a) {
                    var s = [];
                    if ((i && s.push(this.element), e.length > 0)) {
                      e = (e = e.replace(
                        Sg,
                        "." + this._enterClassName
                      )).replace(Cg, "." + this._leaveClassName);
                      var u = this._driver.query(this.element, e, 1 != r);
                      0 !== r &&
                        (u =
                          r < 0
                            ? u.slice(u.length + r, u.length)
                            : u.slice(0, r)),
                        s.push.apply(s, n(u));
                    }
                    return (
                      o ||
                        0 != s.length ||
                        a.push(
                          '`query("'
                            .concat(
                              t,
                              '")` returned zero elements. (Use `query("'
                            )
                            .concat(
                              t,
                              '", { optional: true })` if you wish to allow this.)'
                            )
                        ),
                      s
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Ag = (function () {
            function e(t, n, r, i) {
              p(this, e),
                (this._driver = t),
                (this.element = n),
                (this.startTime = r),
                (this._elementTimelineStylesLookup = i),
                (this.duration = 0),
                (this._previousKeyframe = {}),
                (this._currentKeyframe = {}),
                (this._keyframes = new Map()),
                (this._styleSummary = {}),
                (this._pendingStyles = {}),
                (this._backFill = {}),
                (this._currentEmptyStepKeyframe = null),
                this._elementTimelineStylesLookup ||
                  (this._elementTimelineStylesLookup = new Map()),
                (this._localTimelineStyles = Object.create(this._backFill, {})),
                (this._globalTimelineStyles =
                  this._elementTimelineStylesLookup.get(n)),
                this._globalTimelineStyles ||
                  ((this._globalTimelineStyles = this._localTimelineStyles),
                  this._elementTimelineStylesLookup.set(
                    n,
                    this._localTimelineStyles
                  )),
                this._loadKeyframe();
            }
            return (
              g(e, [
                {
                  key: "containsAnimation",
                  value: function () {
                    switch (this._keyframes.size) {
                      case 0:
                        return !1;
                      case 1:
                        return this.getCurrentStyleProperties().length > 0;
                      default:
                        return !0;
                    }
                  },
                },
                {
                  key: "getCurrentStyleProperties",
                  value: function () {
                    return Object.keys(this._currentKeyframe);
                  },
                },
                {
                  key: "currentTime",
                  get: function () {
                    return this.startTime + this.duration;
                  },
                },
                {
                  key: "delayNextStep",
                  value: function (e) {
                    var t =
                      1 == this._keyframes.size &&
                      Object.keys(this._pendingStyles).length;
                    this.duration || t
                      ? (this.forwardTime(this.currentTime + e),
                        t && this.snapshotCurrentStyles())
                      : (this.startTime += e);
                  },
                },
                {
                  key: "fork",
                  value: function (t, n) {
                    return (
                      this.applyStylesToKeyframe(),
                      new e(
                        this._driver,
                        t,
                        n || this.currentTime,
                        this._elementTimelineStylesLookup
                      )
                    );
                  },
                },
                {
                  key: "_loadKeyframe",
                  value: function () {
                    this._currentKeyframe &&
                      (this._previousKeyframe = this._currentKeyframe),
                      (this._currentKeyframe = this._keyframes.get(
                        this.duration
                      )),
                      this._currentKeyframe ||
                        ((this._currentKeyframe = Object.create(
                          this._backFill,
                          {}
                        )),
                        this._keyframes.set(
                          this.duration,
                          this._currentKeyframe
                        ));
                  },
                },
                {
                  key: "forwardFrame",
                  value: function () {
                    (this.duration += 1), this._loadKeyframe();
                  },
                },
                {
                  key: "forwardTime",
                  value: function (e) {
                    this.applyStylesToKeyframe(),
                      (this.duration = e),
                      this._loadKeyframe();
                  },
                },
                {
                  key: "_updateStyle",
                  value: function (e, t) {
                    (this._localTimelineStyles[e] = t),
                      (this._globalTimelineStyles[e] = t),
                      (this._styleSummary[e] = {
                        time: this.currentTime,
                        value: t,
                      });
                  },
                },
                {
                  key: "allowOnlyTimelineStyles",
                  value: function () {
                    return (
                      this._currentEmptyStepKeyframe !== this._currentKeyframe
                    );
                  },
                },
                {
                  key: "applyEmptyStep",
                  value: function (e) {
                    var t = this;
                    e && (this._previousKeyframe.easing = e),
                      Object.keys(this._globalTimelineStyles).forEach(function (
                        e
                      ) {
                        (t._backFill[e] = t._globalTimelineStyles[e] || "*"),
                          (t._currentKeyframe[e] = "*");
                      }),
                      (this._currentEmptyStepKeyframe = this._currentKeyframe);
                  },
                },
                {
                  key: "setStyles",
                  value: function (e, t, n, r) {
                    var i = this;
                    t && (this._previousKeyframe.easing = t);
                    var o = (r && r.params) || {},
                      a = (function (e, t) {
                        var n,
                          r = {};
                        return (
                          e.forEach(function (e) {
                            "*" === e
                              ? (n = n || Object.keys(t)).forEach(function (e) {
                                  r[e] = "*";
                                })
                              : Zm(e, !1, r);
                          }),
                          r
                        );
                      })(e, this._globalTimelineStyles);
                    Object.keys(a).forEach(function (e) {
                      var t = rg(a[e], o, n);
                      (i._pendingStyles[e] = t),
                        i._localTimelineStyles.hasOwnProperty(e) ||
                          (i._backFill[e] =
                            i._globalTimelineStyles.hasOwnProperty(e)
                              ? i._globalTimelineStyles[e]
                              : "*"),
                        i._updateStyle(e, t);
                    });
                  },
                },
                {
                  key: "applyStylesToKeyframe",
                  value: function () {
                    var e = this,
                      t = this._pendingStyles,
                      n = Object.keys(t);
                    0 != n.length &&
                      ((this._pendingStyles = {}),
                      n.forEach(function (n) {
                        e._currentKeyframe[n] = t[n];
                      }),
                      Object.keys(this._localTimelineStyles).forEach(function (
                        t
                      ) {
                        e._currentKeyframe.hasOwnProperty(t) ||
                          (e._currentKeyframe[t] = e._localTimelineStyles[t]);
                      }));
                  },
                },
                {
                  key: "snapshotCurrentStyles",
                  value: function () {
                    var e = this;
                    Object.keys(this._localTimelineStyles).forEach(function (
                      t
                    ) {
                      var n = e._localTimelineStyles[t];
                      (e._pendingStyles[t] = n), e._updateStyle(t, n);
                    });
                  },
                },
                {
                  key: "getFinalKeyframe",
                  value: function () {
                    return this._keyframes.get(this.duration);
                  },
                },
                {
                  key: "properties",
                  get: function () {
                    var e = [];
                    for (var t in this._currentKeyframe) e.push(t);
                    return e;
                  },
                },
                {
                  key: "mergeTimelineCollectedStyles",
                  value: function (e) {
                    var t = this;
                    Object.keys(e._styleSummary).forEach(function (n) {
                      var r = t._styleSummary[n],
                        i = e._styleSummary[n];
                      (!r || i.time > r.time) && t._updateStyle(n, i.value);
                    });
                  },
                },
                {
                  key: "buildKeyframes",
                  value: function () {
                    var e = this;
                    this.applyStylesToKeyframe();
                    var t = new Set(),
                      n = new Set(),
                      r = 1 === this._keyframes.size && 0 === this.duration,
                      i = [];
                    this._keyframes.forEach(function (o, a) {
                      var s = Zm(o, !0);
                      Object.keys(s).forEach(function (e) {
                        var r = s[e];
                        "!" == r ? t.add(e) : "*" == r && n.add(e);
                      }),
                        r || (s.offset = a / e.duration),
                        i.push(s);
                    });
                    var o = t.size ? ig(t.values()) : [],
                      a = n.size ? ig(n.values()) : [];
                    if (r) {
                      var s = i[0],
                        u = Km(s);
                      (s.offset = 0), (u.offset = 1), (i = [s, u]);
                    }
                    return wg(
                      this.element,
                      i,
                      o,
                      a,
                      this.duration,
                      this.startTime,
                      this.easing,
                      !1
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Og = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r, i, o, a, s) {
              var u,
                l =
                  arguments.length > 6 &&
                  void 0 !== arguments[6] &&
                  arguments[6];
              return (
                p(this, n),
                ((u = t.call(this, e, r, s.delay)).element = r),
                (u.keyframes = i),
                (u.preStyleProps = o),
                (u.postStyleProps = a),
                (u._stretchStartingKeyframe = l),
                (u.timings = {
                  duration: s.duration,
                  delay: s.delay,
                  easing: s.easing,
                }),
                u
              );
            }
            return (
              g(n, [
                {
                  key: "containsAnimation",
                  value: function () {
                    return this.keyframes.length > 1;
                  },
                },
                {
                  key: "buildKeyframes",
                  value: function () {
                    var e = this.keyframes,
                      t = this.timings,
                      n = t.delay,
                      r = t.duration,
                      i = t.easing;
                    if (this._stretchStartingKeyframe && n) {
                      var o = [],
                        a = r + n,
                        s = n / a,
                        u = Zm(e[0], !1);
                      (u.offset = 0), o.push(u);
                      var l = Zm(e[0], !1);
                      (l.offset = Rg(s)), o.push(l);
                      for (var c = e.length - 1, h = 1; h <= c; h++) {
                        var f = Zm(e[h], !1);
                        (f.offset = Rg((n + f.offset * r) / a)), o.push(f);
                      }
                      (r = a), (n = 0), (i = ""), (e = o);
                    }
                    return wg(
                      this.element,
                      e,
                      this.preStyleProps,
                      this.postStyleProps,
                      r,
                      n,
                      i,
                      !0
                    );
                  },
                },
              ]),
              n
            );
          })(Ag);
        function Rg(e) {
          var t =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : 3,
            n = Math.pow(10, t - 1);
          return Math.round(e * n) / n;
        }
        var Pg = function e() {
            p(this, e);
          },
          Dg = (function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              return p(this, n), t.apply(this, arguments);
            }
            return (
              g(n, [
                {
                  key: "normalizePropertyName",
                  value: function (e, t) {
                    return ag(e);
                  },
                },
                {
                  key: "normalizeStyleValue",
                  value: function (e, t, n, r) {
                    var i = "",
                      o = n.toString().trim();
                    if (Ng[t] && 0 !== n && "0" !== n)
                      if ("number" == typeof n) i = "px";
                      else {
                        var a = n.match(/^[+-]?[\d\.]+([a-z]*)$/);
                        a &&
                          0 == a[1].length &&
                          r.push(
                            "Please provide a CSS unit value for "
                              .concat(e, ":")
                              .concat(n)
                          );
                      }
                    return o + i;
                  },
                },
              ]),
              n
            );
          })(Pg),
          Ng = (function (e) {
            var t = {};
            return (
              e.forEach(function (e) {
                return (t[e] = !0);
              }),
              t
            );
          })(
            "width,height,minWidth,minHeight,maxWidth,maxHeight,left,top,bottom,right,fontSize,outlineWidth,outlineOffset,paddingTop,paddingLeft,paddingBottom,paddingRight,marginTop,marginLeft,marginBottom,marginRight,borderRadius,borderWidth,borderTopWidth,borderLeftWidth,borderRightWidth,borderBottomWidth,textIndent,perspective".split(
              ","
            )
          );
        function Mg(e, t, n, r, i, o, a, s, u, l, c, h, f) {
          return {
            type: 0,
            element: e,
            triggerName: t,
            isRemovalTransition: i,
            fromState: n,
            fromStyles: o,
            toState: r,
            toStyles: a,
            timelines: s,
            queriedElements: u,
            preStyleProps: l,
            postStyleProps: c,
            totalTime: h,
            errors: f,
          };
        }
        var jg = {},
          Fg = (function () {
            function e(t, n, r) {
              p(this, e),
                (this._triggerName = t),
                (this.ast = n),
                (this._stateStyles = r);
            }
            return (
              g(e, [
                {
                  key: "match",
                  value: function (e, t, n, r) {
                    return (function (e, t, n, r, i) {
                      return e.some(function (e) {
                        return e(t, n, r, i);
                      });
                    })(this.ast.matchers, e, t, n, r);
                  },
                },
                {
                  key: "buildStyles",
                  value: function (e, t, n) {
                    var r = this._stateStyles["*"],
                      i = this._stateStyles[e],
                      o = r ? r.buildStyles(t, n) : {};
                    return i ? i.buildStyles(t, n) : o;
                  },
                },
                {
                  key: "build",
                  value: function (e, t, n, r, i, o, a, s, u, l) {
                    var c = [],
                      h = (this.ast.options && this.ast.options.params) || jg,
                      f = this.buildStyles(n, (a && a.params) || jg, c),
                      d = (s && s.params) || jg,
                      v = this.buildStyles(r, d, c),
                      p = new Set(),
                      m = new Map(),
                      g = new Map(),
                      y = "void" === r,
                      k = { params: Object.assign(Object.assign({}, h), d) },
                      b = l
                        ? []
                        : Eg(e, t, this.ast.animation, i, o, f, v, k, u, c),
                      w = 0;
                    if (
                      (b.forEach(function (e) {
                        w = Math.max(e.duration + e.delay, w);
                      }),
                      c.length)
                    )
                      return Mg(
                        t,
                        this._triggerName,
                        n,
                        r,
                        y,
                        f,
                        v,
                        [],
                        [],
                        m,
                        g,
                        w,
                        c
                      );
                    b.forEach(function (e) {
                      var n = e.element,
                        r = Am(m, n, {});
                      e.preStyleProps.forEach(function (e) {
                        return (r[e] = !0);
                      });
                      var i = Am(g, n, {});
                      e.postStyleProps.forEach(function (e) {
                        return (i[e] = !0);
                      }),
                        n !== t && p.add(n);
                    });
                    var _ = ig(p.values());
                    return Mg(
                      t,
                      this._triggerName,
                      n,
                      r,
                      y,
                      f,
                      v,
                      b,
                      _,
                      m,
                      g,
                      w
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Lg = (function () {
            function e(t, n) {
              p(this, e), (this.styles = t), (this.defaultParams = n);
            }
            return (
              g(e, [
                {
                  key: "buildStyles",
                  value: function (e, t) {
                    var n = {},
                      r = Km(this.defaultParams);
                    return (
                      Object.keys(e).forEach(function (t) {
                        var n = e[t];
                        null != n && (r[t] = n);
                      }),
                      this.styles.styles.forEach(function (e) {
                        if ("string" != typeof e) {
                          var i = e;
                          Object.keys(i).forEach(function (e) {
                            var o = i[e];
                            o.length > 1 && (o = rg(o, r, t)), (n[e] = o);
                          });
                        }
                      }),
                      n
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Wg = (function () {
            function e(t, n) {
              var r = this;
              p(this, e),
                (this.name = t),
                (this.ast = n),
                (this.transitionFactories = []),
                (this.states = {}),
                n.states.forEach(function (e) {
                  r.states[e.name] = new Lg(
                    e.style,
                    (e.options && e.options.params) || {}
                  );
                }),
                zg(this.states, "true", "1"),
                zg(this.states, "false", "0"),
                n.transitions.forEach(function (e) {
                  r.transitionFactories.push(new Fg(t, e, r.states));
                }),
                (this.fallbackTransition = new Fg(
                  t,
                  {
                    type: 1,
                    animation: { type: 2, steps: [], options: null },
                    matchers: [
                      function (e, t) {
                        return !0;
                      },
                    ],
                    options: null,
                    queryCount: 0,
                    depCount: 0,
                  },
                  this.states
                ));
            }
            return (
              g(e, [
                {
                  key: "containsQueries",
                  get: function () {
                    return this.ast.queryCount > 0;
                  },
                },
                {
                  key: "matchTransition",
                  value: function (e, t, n, r) {
                    return (
                      this.transitionFactories.find(function (i) {
                        return i.match(e, t, n, r);
                      }) || null
                    );
                  },
                },
                {
                  key: "matchStyles",
                  value: function (e, t, n) {
                    return this.fallbackTransition.buildStyles(e, t, n);
                  },
                },
              ]),
              e
            );
          })();
        function zg(e, t, n) {
          e.hasOwnProperty(t)
            ? e.hasOwnProperty(n) || (e[n] = e[t])
            : e.hasOwnProperty(n) && (e[t] = e[n]);
        }
        var qg = new _g(),
          Hg = (function () {
            function e(t, n, r) {
              p(this, e),
                (this.bodyNode = t),
                (this._driver = n),
                (this._normalizer = r),
                (this._animations = {}),
                (this._playersById = {}),
                (this.players = []);
            }
            return (
              g(e, [
                {
                  key: "register",
                  value: function (e, t) {
                    var n = [],
                      r = pg(this._driver, t, n);
                    if (n.length)
                      throw new Error(
                        "Unable to build the animation due to the following errors: ".concat(
                          n.join("\n")
                        )
                      );
                    this._animations[e] = r;
                  },
                },
                {
                  key: "_buildPlayer",
                  value: function (e, t, n) {
                    var r = e.element,
                      i = Em(0, this._normalizer, 0, e.keyframes, t, n);
                    return this._driver.animate(
                      r,
                      i,
                      e.duration,
                      e.delay,
                      e.easing,
                      [],
                      !0
                    );
                  },
                },
                {
                  key: "create",
                  value: function (e, t) {
                    var n,
                      r = this,
                      i =
                        arguments.length > 2 && void 0 !== arguments[2]
                          ? arguments[2]
                          : {},
                      o = [],
                      a = this._animations[e],
                      s = new Map();
                    if (
                      (a
                        ? (n = Eg(
                            this._driver,
                            t,
                            a,
                            "ng-enter",
                            "ng-leave",
                            {},
                            {},
                            i,
                            qg,
                            o
                          )).forEach(function (e) {
                            var t = Am(s, e.element, {});
                            e.postStyleProps.forEach(function (e) {
                              return (t[e] = null);
                            });
                          })
                        : (o.push(
                            "The requested animation doesn't exist or has already been destroyed"
                          ),
                          (n = [])),
                      o.length)
                    )
                      throw new Error(
                        "Unable to create the animation due to the following errors: ".concat(
                          o.join("\n")
                        )
                      );
                    s.forEach(function (e, t) {
                      Object.keys(e).forEach(function (n) {
                        e[n] = r._driver.computeStyle(t, n, "*");
                      });
                    });
                    var u = Cm(
                      n.map(function (e) {
                        var t = s.get(e.element);
                        return r._buildPlayer(e, {}, t);
                      })
                    );
                    return (
                      (this._playersById[e] = u),
                      u.onDestroy(function () {
                        return r.destroy(e);
                      }),
                      this.players.push(u),
                      u
                    );
                  },
                },
                {
                  key: "destroy",
                  value: function (e) {
                    var t = this._getPlayer(e);
                    t.destroy(), delete this._playersById[e];
                    var n = this.players.indexOf(t);
                    n >= 0 && this.players.splice(n, 1);
                  },
                },
                {
                  key: "_getPlayer",
                  value: function (e) {
                    var t = this._playersById[e];
                    if (!t)
                      throw new Error(
                        "Unable to find the timeline player referenced by ".concat(
                          e
                        )
                      );
                    return t;
                  },
                },
                {
                  key: "listen",
                  value: function (e, t, n, r) {
                    var i = Im(t, "", "", "");
                    return Tm(this._getPlayer(e), n, i, r), function () {};
                  },
                },
                {
                  key: "command",
                  value: function (e, t, n, r) {
                    if ("register" != n)
                      if ("create" != n) {
                        var i = this._getPlayer(e);
                        switch (n) {
                          case "play":
                            i.play();
                            break;
                          case "pause":
                            i.pause();
                            break;
                          case "reset":
                            i.reset();
                            break;
                          case "restart":
                            i.restart();
                            break;
                          case "finish":
                            i.finish();
                            break;
                          case "init":
                            i.init();
                            break;
                          case "setPosition":
                            i.setPosition(parseFloat(r[0]));
                            break;
                          case "destroy":
                            this.destroy(e);
                        }
                      } else this.create(e, t, r[0] || {});
                    else this.register(e, r[0]);
                  },
                },
              ]),
              e
            );
          })(),
          Ug = [],
          Vg = {
            namespaceId: "",
            setForRemoval: !1,
            setForMove: !1,
            hasAnimation: !1,
            removedBeforeQueried: !1,
          },
          Bg = {
            namespaceId: "",
            setForMove: !1,
            setForRemoval: !1,
            hasAnimation: !1,
            removedBeforeQueried: !0,
          },
          Qg = (function () {
            function e(t) {
              var n =
                arguments.length > 1 && void 0 !== arguments[1]
                  ? arguments[1]
                  : "";
              p(this, e), (this.namespaceId = n);
              var r,
                i = t && t.hasOwnProperty("value");
              if (
                ((this.value = null != (r = i ? t.value : t) ? r : null), i)
              ) {
                var o = Km(t);
                delete o.value, (this.options = o);
              } else this.options = {};
              this.options.params || (this.options.params = {});
            }
            return (
              g(e, [
                {
                  key: "params",
                  get: function () {
                    return this.options.params;
                  },
                },
                {
                  key: "absorbOptions",
                  value: function (e) {
                    var t = e.params;
                    if (t) {
                      var n = this.options.params;
                      Object.keys(t).forEach(function (e) {
                        null == n[e] && (n[e] = t[e]);
                      });
                    }
                  },
                },
              ]),
              e
            );
          })(),
          Gg = new Qg("void"),
          Kg = (function () {
            function e(t, n, r) {
              p(this, e),
                (this.id = t),
                (this.hostElement = n),
                (this._engine = r),
                (this.players = []),
                (this._triggers = {}),
                (this._queue = []),
                (this._elementListeners = new Map()),
                (this._hostClassName = "ng-tns-" + t),
                ty(n, this._hostClassName);
            }
            return (
              g(e, [
                {
                  key: "listen",
                  value: function (e, t, n, r) {
                    var i,
                      o = this;
                    if (!this._triggers.hasOwnProperty(t))
                      throw new Error(
                        'Unable to listen on the animation trigger event "'
                          .concat(n, '" because the animation trigger "')
                          .concat(t, "\" doesn't exist!")
                      );
                    if (null == n || 0 == n.length)
                      throw new Error(
                        'Unable to listen on the animation trigger "'.concat(
                          t,
                          '" because the provided event is undefined!'
                        )
                      );
                    if ("start" != (i = n) && "done" != i)
                      throw new Error(
                        'The provided animation trigger event "'
                          .concat(n, '" for the animation trigger "')
                          .concat(t, '" is not supported!')
                      );
                    var a = Am(this._elementListeners, e, []),
                      s = { name: t, phase: n, callback: r };
                    a.push(s);
                    var u = Am(this._engine.statesByElement, e, {});
                    return (
                      u.hasOwnProperty(t) ||
                        (ty(e, "ng-trigger"),
                        ty(e, "ng-trigger-" + t),
                        (u[t] = Gg)),
                      function () {
                        o._engine.afterFlush(function () {
                          var e = a.indexOf(s);
                          e >= 0 && a.splice(e, 1),
                            o._triggers[t] || delete u[t];
                        });
                      }
                    );
                  },
                },
                {
                  key: "register",
                  value: function (e, t) {
                    return !this._triggers[e] && ((this._triggers[e] = t), !0);
                  },
                },
                {
                  key: "_getTrigger",
                  value: function (e) {
                    var t = this._triggers[e];
                    if (!t)
                      throw new Error(
                        'The provided animation trigger "'.concat(
                          e,
                          '" has not been registered!'
                        )
                      );
                    return t;
                  },
                },
                {
                  key: "trigger",
                  value: function (e, t, n) {
                    var r = this,
                      i =
                        !(arguments.length > 3 && void 0 !== arguments[3]) ||
                        arguments[3],
                      o = this._getTrigger(t),
                      a = new Jg(this.id, t, e),
                      s = this._engine.statesByElement.get(e);
                    s ||
                      (ty(e, "ng-trigger"),
                      ty(e, "ng-trigger-" + t),
                      this._engine.statesByElement.set(e, (s = {})));
                    var u = s[t],
                      l = new Qg(n, this.id);
                    if (
                      (!(n && n.hasOwnProperty("value")) &&
                        u &&
                        l.absorbOptions(u.options),
                      (s[t] = l),
                      u || (u = Gg),
                      "void" === l.value || u.value !== l.value)
                    ) {
                      var c = Am(this._engine.playersByElement, e, []);
                      c.forEach(function (e) {
                        e.namespaceId == r.id &&
                          e.triggerName == t &&
                          e.queued &&
                          e.destroy();
                      });
                      var h = o.matchTransition(u.value, l.value, e, l.params),
                        f = !1;
                      if (!h) {
                        if (!i) return;
                        (h = o.fallbackTransition), (f = !0);
                      }
                      return (
                        this._engine.totalQueuedPlayers++,
                        this._queue.push({
                          element: e,
                          triggerName: t,
                          transition: h,
                          fromState: u,
                          toState: l,
                          player: a,
                          isFallbackTransition: f,
                        }),
                        f ||
                          (ty(e, "ng-animate-queued"),
                          a.onStart(function () {
                            ny(e, "ng-animate-queued");
                          })),
                        a.onDone(function () {
                          var t = r.players.indexOf(a);
                          t >= 0 && r.players.splice(t, 1);
                          var n = r._engine.playersByElement.get(e);
                          if (n) {
                            var i = n.indexOf(a);
                            i >= 0 && n.splice(i, 1);
                          }
                        }),
                        this.players.push(a),
                        c.push(a),
                        a
                      );
                    }
                    if (
                      !(function (e, t) {
                        var n = Object.keys(e),
                          r = Object.keys(t);
                        if (n.length != r.length) return !1;
                        for (var i = 0; i < n.length; i++) {
                          var o = n[i];
                          if (!t.hasOwnProperty(o) || e[o] !== t[o]) return !1;
                        }
                        return !0;
                      })(u.params, l.params)
                    ) {
                      var d = [],
                        v = o.matchStyles(u.value, u.params, d),
                        p = o.matchStyles(l.value, l.params, d);
                      d.length
                        ? this._engine.reportError(d)
                        : this._engine.afterFlush(function () {
                            Xm(e, v), Ym(e, p);
                          });
                    }
                  },
                },
                {
                  key: "deregister",
                  value: function (e) {
                    var t = this;
                    delete this._triggers[e],
                      this._engine.statesByElement.forEach(function (t, n) {
                        delete t[e];
                      }),
                      this._elementListeners.forEach(function (n, r) {
                        t._elementListeners.set(
                          r,
                          n.filter(function (t) {
                            return t.name != e;
                          })
                        );
                      });
                  },
                },
                {
                  key: "clearElementCache",
                  value: function (e) {
                    this._engine.statesByElement.delete(e),
                      this._elementListeners.delete(e);
                    var t = this._engine.playersByElement.get(e);
                    t &&
                      (t.forEach(function (e) {
                        return e.destroy();
                      }),
                      this._engine.playersByElement.delete(e));
                  },
                },
                {
                  key: "_signalRemovalForInnerTriggers",
                  value: function (e, t) {
                    var n = this,
                      r = this._engine.driver.query(e, ".ng-trigger", !0);
                    r.forEach(function (e) {
                      if (!e.__ng_removed) {
                        var r = n._engine.fetchNamespacesByElement(e);
                        r.size
                          ? r.forEach(function (n) {
                              return n.triggerLeaveAnimation(e, t, !1, !0);
                            })
                          : n.clearElementCache(e);
                      }
                    }),
                      this._engine.afterFlushAnimationsDone(function () {
                        return r.forEach(function (e) {
                          return n.clearElementCache(e);
                        });
                      });
                  },
                },
                {
                  key: "triggerLeaveAnimation",
                  value: function (e, t, n, r) {
                    var i = this,
                      o = this._engine.statesByElement.get(e);
                    if (o) {
                      var a = [];
                      if (
                        (Object.keys(o).forEach(function (t) {
                          if (i._triggers[t]) {
                            var n = i.trigger(e, t, "void", r);
                            n && a.push(n);
                          }
                        }),
                        a.length)
                      )
                        return (
                          this._engine.markElementAsRemoved(this.id, e, !0, t),
                          n &&
                            Cm(a).onDone(function () {
                              return i._engine.processLeaveNode(e);
                            }),
                          !0
                        );
                    }
                    return !1;
                  },
                },
                {
                  key: "prepareLeaveAnimationListeners",
                  value: function (e) {
                    var t = this,
                      n = this._elementListeners.get(e),
                      r = this._engine.statesByElement.get(e);
                    if (n && r) {
                      var i = new Set();
                      n.forEach(function (n) {
                        var o = n.name;
                        if (!i.has(o)) {
                          i.add(o);
                          var a = t._triggers[o].fallbackTransition,
                            s = r[o] || Gg,
                            u = new Qg("void"),
                            l = new Jg(t.id, o, e);
                          t._engine.totalQueuedPlayers++,
                            t._queue.push({
                              element: e,
                              triggerName: o,
                              transition: a,
                              fromState: s,
                              toState: u,
                              player: l,
                              isFallbackTransition: !0,
                            });
                        }
                      });
                    }
                  },
                },
                {
                  key: "removeNode",
                  value: function (e, t) {
                    var n = this,
                      r = this._engine;
                    if (
                      (e.childElementCount &&
                        this._signalRemovalForInnerTriggers(e, t),
                      !this.triggerLeaveAnimation(e, t, !0))
                    ) {
                      var i = !1;
                      if (r.totalAnimations) {
                        var o = r.players.length
                          ? r.playersByQueriedElement.get(e)
                          : [];
                        if (o && o.length) i = !0;
                        else
                          for (var a = e; (a = a.parentNode); )
                            if (r.statesByElement.get(a)) {
                              i = !0;
                              break;
                            }
                      }
                      if ((this.prepareLeaveAnimationListeners(e), i))
                        r.markElementAsRemoved(this.id, e, !1, t);
                      else {
                        var s = e.__ng_removed;
                        (s && s !== Vg) ||
                          (r.afterFlush(function () {
                            return n.clearElementCache(e);
                          }),
                          r.destroyInnerAnimations(e),
                          r._onRemovalComplete(e, t));
                      }
                    }
                  },
                },
                {
                  key: "insertNode",
                  value: function (e, t) {
                    ty(e, this._hostClassName);
                  },
                },
                {
                  key: "drainQueuedTransitions",
                  value: function (e) {
                    var t = this,
                      n = [];
                    return (
                      this._queue.forEach(function (r) {
                        var i = r.player;
                        if (!i.destroyed) {
                          var o = r.element,
                            a = t._elementListeners.get(o);
                          a &&
                            a.forEach(function (t) {
                              if (t.name == r.triggerName) {
                                var n = Im(
                                  o,
                                  r.triggerName,
                                  r.fromState.value,
                                  r.toState.value
                                );
                                (n._data = e),
                                  Tm(r.player, t.phase, n, t.callback);
                              }
                            }),
                            i.markedForDestroy
                              ? t._engine.afterFlush(function () {
                                  i.destroy();
                                })
                              : n.push(r);
                        }
                      }),
                      (this._queue = []),
                      n.sort(function (e, n) {
                        var r = e.transition.ast.depCount,
                          i = n.transition.ast.depCount;
                        return 0 == r || 0 == i
                          ? r - i
                          : t._engine.driver.containsElement(
                              e.element,
                              n.element
                            )
                          ? 1
                          : -1;
                      })
                    );
                  },
                },
                {
                  key: "destroy",
                  value: function (e) {
                    this.players.forEach(function (e) {
                      return e.destroy();
                    }),
                      this._signalRemovalForInnerTriggers(this.hostElement, e);
                  },
                },
                {
                  key: "elementContainsData",
                  value: function (e) {
                    var t = !1;
                    return (
                      this._elementListeners.has(e) && (t = !0),
                      (t =
                        !!this._queue.find(function (t) {
                          return t.element === e;
                        }) || t)
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Zg = (function () {
            function e(t, n, r) {
              p(this, e),
                (this.bodyNode = t),
                (this.driver = n),
                (this._normalizer = r),
                (this.players = []),
                (this.newHostElements = new Map()),
                (this.playersByElement = new Map()),
                (this.playersByQueriedElement = new Map()),
                (this.statesByElement = new Map()),
                (this.disabledNodes = new Set()),
                (this.totalAnimations = 0),
                (this.totalQueuedPlayers = 0),
                (this._namespaceLookup = {}),
                (this._namespaceList = []),
                (this._flushFns = []),
                (this._whenQuietFns = []),
                (this.namespacesByHostElement = new Map()),
                (this.collectedEnterElements = []),
                (this.collectedLeaveElements = []),
                (this.onRemovalComplete = function (e, t) {});
            }
            return (
              g(e, [
                {
                  key: "_onRemovalComplete",
                  value: function (e, t) {
                    this.onRemovalComplete(e, t);
                  },
                },
                {
                  key: "queuedPlayers",
                  get: function () {
                    var e = [];
                    return (
                      this._namespaceList.forEach(function (t) {
                        t.players.forEach(function (t) {
                          t.queued && e.push(t);
                        });
                      }),
                      e
                    );
                  },
                },
                {
                  key: "createNamespace",
                  value: function (e, t) {
                    var n = new Kg(e, t, this);
                    return (
                      this.bodyNode &&
                      this.driver.containsElement(this.bodyNode, t)
                        ? this._balanceNamespaceList(n, t)
                        : (this.newHostElements.set(t, n),
                          this.collectEnterElement(t)),
                      (this._namespaceLookup[e] = n)
                    );
                  },
                },
                {
                  key: "_balanceNamespaceList",
                  value: function (e, t) {
                    var n = this._namespaceList.length - 1;
                    if (n >= 0) {
                      for (var r = !1, i = n; i >= 0; i--)
                        if (
                          this.driver.containsElement(
                            this._namespaceList[i].hostElement,
                            t
                          )
                        ) {
                          this._namespaceList.splice(i + 1, 0, e), (r = !0);
                          break;
                        }
                      r || this._namespaceList.splice(0, 0, e);
                    } else this._namespaceList.push(e);
                    return this.namespacesByHostElement.set(t, e), e;
                  },
                },
                {
                  key: "register",
                  value: function (e, t) {
                    var n = this._namespaceLookup[e];
                    return n || (n = this.createNamespace(e, t)), n;
                  },
                },
                {
                  key: "registerTrigger",
                  value: function (e, t, n) {
                    var r = this._namespaceLookup[e];
                    r && r.register(t, n) && this.totalAnimations++;
                  },
                },
                {
                  key: "destroy",
                  value: function (e, t) {
                    var n = this;
                    if (e) {
                      var r = this._fetchNamespace(e);
                      this.afterFlush(function () {
                        n.namespacesByHostElement.delete(r.hostElement),
                          delete n._namespaceLookup[e];
                        var t = n._namespaceList.indexOf(r);
                        t >= 0 && n._namespaceList.splice(t, 1);
                      }),
                        this.afterFlushAnimationsDone(function () {
                          return r.destroy(t);
                        });
                    }
                  },
                },
                {
                  key: "_fetchNamespace",
                  value: function (e) {
                    return this._namespaceLookup[e];
                  },
                },
                {
                  key: "fetchNamespacesByElement",
                  value: function (e) {
                    var t = new Set(),
                      n = this.statesByElement.get(e);
                    if (n)
                      for (var r = Object.keys(n), i = 0; i < r.length; i++) {
                        var o = n[r[i]].namespaceId;
                        if (o) {
                          var a = this._fetchNamespace(o);
                          a && t.add(a);
                        }
                      }
                    return t;
                  },
                },
                {
                  key: "trigger",
                  value: function (e, t, n, r) {
                    if ($g(t)) {
                      var i = this._fetchNamespace(e);
                      if (i) return i.trigger(t, n, r), !0;
                    }
                    return !1;
                  },
                },
                {
                  key: "insertNode",
                  value: function (e, t, n, r) {
                    if ($g(t)) {
                      var i = t.__ng_removed;
                      if (i && i.setForRemoval) {
                        (i.setForRemoval = !1), (i.setForMove = !0);
                        var o = this.collectedLeaveElements.indexOf(t);
                        o >= 0 && this.collectedLeaveElements.splice(o, 1);
                      }
                      if (e) {
                        var a = this._fetchNamespace(e);
                        a && a.insertNode(t, n);
                      }
                      r && this.collectEnterElement(t);
                    }
                  },
                },
                {
                  key: "collectEnterElement",
                  value: function (e) {
                    this.collectedEnterElements.push(e);
                  },
                },
                {
                  key: "markElementAsDisabled",
                  value: function (e, t) {
                    t
                      ? this.disabledNodes.has(e) ||
                        (this.disabledNodes.add(e),
                        ty(e, "ng-animate-disabled"))
                      : this.disabledNodes.has(e) &&
                        (this.disabledNodes.delete(e),
                        ny(e, "ng-animate-disabled"));
                  },
                },
                {
                  key: "removeNode",
                  value: function (e, t, n, r) {
                    if ($g(t)) {
                      var i = e ? this._fetchNamespace(e) : null;
                      if (
                        (i
                          ? i.removeNode(t, r)
                          : this.markElementAsRemoved(e, t, !1, r),
                        n)
                      ) {
                        var o = this.namespacesByHostElement.get(t);
                        o && o.id !== e && o.removeNode(t, r);
                      }
                    } else this._onRemovalComplete(t, r);
                  },
                },
                {
                  key: "markElementAsRemoved",
                  value: function (e, t, n, r) {
                    this.collectedLeaveElements.push(t),
                      (t.__ng_removed = {
                        namespaceId: e,
                        setForRemoval: r,
                        hasAnimation: n,
                        removedBeforeQueried: !1,
                      });
                  },
                },
                {
                  key: "listen",
                  value: function (e, t, n, r, i) {
                    return $g(t)
                      ? this._fetchNamespace(e).listen(t, n, r, i)
                      : function () {};
                  },
                },
                {
                  key: "_buildInstruction",
                  value: function (e, t, n, r, i) {
                    return e.transition.build(
                      this.driver,
                      e.element,
                      e.fromState.value,
                      e.toState.value,
                      n,
                      r,
                      e.fromState.options,
                      e.toState.options,
                      t,
                      i
                    );
                  },
                },
                {
                  key: "destroyInnerAnimations",
                  value: function (e) {
                    var t = this,
                      n = this.driver.query(e, ".ng-trigger", !0);
                    n.forEach(function (e) {
                      return t.destroyActiveAnimationsForElement(e);
                    }),
                      0 != this.playersByQueriedElement.size &&
                        (n = this.driver.query(e, ".ng-animating", !0)).forEach(
                          function (e) {
                            return t.finishActiveQueriedAnimationOnElement(e);
                          }
                        );
                  },
                },
                {
                  key: "destroyActiveAnimationsForElement",
                  value: function (e) {
                    var t = this.playersByElement.get(e);
                    t &&
                      t.forEach(function (e) {
                        e.queued ? (e.markedForDestroy = !0) : e.destroy();
                      });
                  },
                },
                {
                  key: "finishActiveQueriedAnimationOnElement",
                  value: function (e) {
                    var t = this.playersByQueriedElement.get(e);
                    t &&
                      t.forEach(function (e) {
                        return e.finish();
                      });
                  },
                },
                {
                  key: "whenRenderingDone",
                  value: function () {
                    var e = this;
                    return new Promise(function (t) {
                      if (e.players.length)
                        return Cm(e.players).onDone(function () {
                          return t();
                        });
                      t();
                    });
                  },
                },
                {
                  key: "processLeaveNode",
                  value: function (e) {
                    var t = this,
                      n = e.__ng_removed;
                    if (n && n.setForRemoval) {
                      if (((e.__ng_removed = Vg), n.namespaceId)) {
                        this.destroyInnerAnimations(e);
                        var r = this._fetchNamespace(n.namespaceId);
                        r && r.clearElementCache(e);
                      }
                      this._onRemovalComplete(e, n.setForRemoval);
                    }
                    this.driver.matchesElement(e, ".ng-animate-disabled") &&
                      this.markElementAsDisabled(e, !1),
                      this.driver
                        .query(e, ".ng-animate-disabled", !0)
                        .forEach(function (e) {
                          t.markElementAsDisabled(e, !1);
                        });
                  },
                },
                {
                  key: "flush",
                  value: function () {
                    var e = this,
                      t =
                        arguments.length > 0 && void 0 !== arguments[0]
                          ? arguments[0]
                          : -1,
                      n = [];
                    if (
                      (this.newHostElements.size &&
                        (this.newHostElements.forEach(function (t, n) {
                          return e._balanceNamespaceList(t, n);
                        }),
                        this.newHostElements.clear()),
                      this.totalAnimations &&
                        this.collectedEnterElements.length)
                    )
                      for (
                        var r = 0;
                        r < this.collectedEnterElements.length;
                        r++
                      )
                        ty(this.collectedEnterElements[r], "ng-star-inserted");
                    if (
                      this._namespaceList.length &&
                      (this.totalQueuedPlayers ||
                        this.collectedLeaveElements.length)
                    ) {
                      var i = [];
                      try {
                        n = this._flushAnimations(i, t);
                      } finally {
                        for (var o = 0; o < i.length; o++) i[o]();
                      }
                    } else
                      for (
                        var a = 0;
                        a < this.collectedLeaveElements.length;
                        a++
                      )
                        this.processLeaveNode(this.collectedLeaveElements[a]);
                    if (
                      ((this.totalQueuedPlayers = 0),
                      (this.collectedEnterElements.length = 0),
                      (this.collectedLeaveElements.length = 0),
                      this._flushFns.forEach(function (e) {
                        return e();
                      }),
                      (this._flushFns = []),
                      this._whenQuietFns.length)
                    ) {
                      var s = this._whenQuietFns;
                      (this._whenQuietFns = []),
                        n.length
                          ? Cm(n).onDone(function () {
                              s.forEach(function (e) {
                                return e();
                              });
                            })
                          : s.forEach(function (e) {
                              return e();
                            });
                    }
                  },
                },
                {
                  key: "reportError",
                  value: function (e) {
                    throw new Error(
                      "Unable to process animations due to the following failed trigger transitions\n ".concat(
                        e.join("\n")
                      )
                    );
                  },
                },
                {
                  key: "_flushAnimations",
                  value: function (e, t) {
                    var r = this,
                      i = new _g(),
                      o = [],
                      a = new Map(),
                      s = [],
                      u = new Map(),
                      l = new Map(),
                      c = new Map(),
                      h = new Set();
                    this.disabledNodes.forEach(function (e) {
                      h.add(e);
                      for (
                        var t = r.driver.query(e, ".ng-animate-queued", !0),
                          n = 0;
                        n < t.length;
                        n++
                      )
                        h.add(t[n]);
                    });
                    var f = this.bodyNode,
                      d = Array.from(this.statesByElement.keys()),
                      v = ey(d, this.collectedEnterElements),
                      p = new Map(),
                      m = 0;
                    v.forEach(function (e, t) {
                      var n = "ng-enter" + m++;
                      p.set(t, n),
                        e.forEach(function (e) {
                          return ty(e, n);
                        });
                    });
                    for (
                      var g = [], y = new Set(), k = new Set(), b = 0;
                      b < this.collectedLeaveElements.length;
                      b++
                    ) {
                      var w = this.collectedLeaveElements[b],
                        _ = w.__ng_removed;
                      _ &&
                        _.setForRemoval &&
                        (g.push(w),
                        y.add(w),
                        _.hasAnimation
                          ? this.driver
                              .query(w, ".ng-star-inserted", !0)
                              .forEach(function (e) {
                                return y.add(e);
                              })
                          : k.add(w));
                    }
                    var S = new Map(),
                      C = ey(d, Array.from(y));
                    C.forEach(function (e, t) {
                      var n = "ng-leave" + m++;
                      S.set(t, n),
                        e.forEach(function (e) {
                          return ty(e, n);
                        });
                    }),
                      e.push(function () {
                        v.forEach(function (e, t) {
                          var n = p.get(t);
                          e.forEach(function (e) {
                            return ny(e, n);
                          });
                        }),
                          C.forEach(function (e, t) {
                            var n = S.get(t);
                            e.forEach(function (e) {
                              return ny(e, n);
                            });
                          }),
                          g.forEach(function (e) {
                            r.processLeaveNode(e);
                          });
                      });
                    for (
                      var E = [], T = [], x = this._namespaceList.length - 1;
                      x >= 0;
                      x--
                    )
                      this._namespaceList[x]
                        .drainQueuedTransitions(t)
                        .forEach(function (e) {
                          var t = e.player,
                            n = e.element;
                          if ((E.push(t), r.collectedEnterElements.length)) {
                            var a = n.__ng_removed;
                            if (a && a.setForMove) return void t.destroy();
                          }
                          var h = !f || !r.driver.containsElement(f, n),
                            d = S.get(n),
                            v = p.get(n),
                            m = r._buildInstruction(e, i, v, d, h);
                          if (m.errors && m.errors.length) T.push(m);
                          else {
                            if (h)
                              return (
                                t.onStart(function () {
                                  return Xm(n, m.fromStyles);
                                }),
                                t.onDestroy(function () {
                                  return Ym(n, m.toStyles);
                                }),
                                void o.push(t)
                              );
                            if (e.isFallbackTransition)
                              return (
                                t.onStart(function () {
                                  return Xm(n, m.fromStyles);
                                }),
                                t.onDestroy(function () {
                                  return Ym(n, m.toStyles);
                                }),
                                void o.push(t)
                              );
                            m.timelines.forEach(function (e) {
                              return (e.stretchStartingKeyframe = !0);
                            }),
                              i.append(n, m.timelines),
                              s.push({ instruction: m, player: t, element: n }),
                              m.queriedElements.forEach(function (e) {
                                return Am(u, e, []).push(t);
                              }),
                              m.preStyleProps.forEach(function (e, t) {
                                var n = Object.keys(e);
                                if (n.length) {
                                  var r = l.get(t);
                                  r || l.set(t, (r = new Set())),
                                    n.forEach(function (e) {
                                      return r.add(e);
                                    });
                                }
                              }),
                              m.postStyleProps.forEach(function (e, t) {
                                var n = Object.keys(e),
                                  r = c.get(t);
                                r || c.set(t, (r = new Set())),
                                  n.forEach(function (e) {
                                    return r.add(e);
                                  });
                              });
                          }
                        });
                    if (T.length) {
                      var I = [];
                      T.forEach(function (e) {
                        I.push(
                          "@".concat(e.triggerName, " has failed due to:\n")
                        ),
                          e.errors.forEach(function (e) {
                            return I.push("- ".concat(e, "\n"));
                          });
                      }),
                        E.forEach(function (e) {
                          return e.destroy();
                        }),
                        this.reportError(I);
                    }
                    var A = new Map(),
                      O = new Map();
                    s.forEach(function (e) {
                      var t = e.element;
                      i.has(t) &&
                        (O.set(t, t),
                        r._beforeAnimationBuild(
                          e.player.namespaceId,
                          e.instruction,
                          A
                        ));
                    }),
                      o.forEach(function (e) {
                        var t = e.element;
                        r._getPreviousPlayers(
                          t,
                          !1,
                          e.namespaceId,
                          e.triggerName,
                          null
                        ).forEach(function (e) {
                          Am(A, t, []).push(e), e.destroy();
                        });
                      });
                    var R = g.filter(function (e) {
                        return oy(e, l, c);
                      }),
                      P = new Map();
                    Xg(P, this.driver, k, c, "*").forEach(function (e) {
                      oy(e, l, c) && R.push(e);
                    });
                    var D = new Map();
                    v.forEach(function (e, t) {
                      Xg(D, r.driver, new Set(e), l, "!");
                    }),
                      R.forEach(function (e) {
                        var t = P.get(e),
                          n = D.get(e);
                        P.set(e, Object.assign(Object.assign({}, t), n));
                      });
                    var N = [],
                      M = [],
                      j = {};
                    s.forEach(function (e) {
                      var t = e.element,
                        n = e.player,
                        s = e.instruction;
                      if (i.has(t)) {
                        if (h.has(t))
                          return (
                            n.onDestroy(function () {
                              return Ym(t, s.toStyles);
                            }),
                            (n.disabled = !0),
                            n.overrideTotalTime(s.totalTime),
                            void o.push(n)
                          );
                        var u = j;
                        if (O.size > 1) {
                          for (var l = t, c = []; (l = l.parentNode); ) {
                            var f = O.get(l);
                            if (f) {
                              u = f;
                              break;
                            }
                            c.push(l);
                          }
                          c.forEach(function (e) {
                            return O.set(e, u);
                          });
                        }
                        var d = r._buildAnimation(n.namespaceId, s, A, a, D, P);
                        if ((n.setRealPlayer(d), u === j)) N.push(n);
                        else {
                          var v = r.playersByElement.get(u);
                          v && v.length && (n.parentPlayer = Cm(v)), o.push(n);
                        }
                      } else
                        Xm(t, s.fromStyles),
                          n.onDestroy(function () {
                            return Ym(t, s.toStyles);
                          }),
                          M.push(n),
                          h.has(t) && o.push(n);
                    }),
                      M.forEach(function (e) {
                        var t = a.get(e.element);
                        if (t && t.length) {
                          var n = Cm(t);
                          e.setRealPlayer(n);
                        }
                      }),
                      o.forEach(function (e) {
                        e.parentPlayer
                          ? e.syncPlayerEvents(e.parentPlayer)
                          : e.destroy();
                      });
                    for (var F = 0; F < g.length; F++) {
                      var L = g[F],
                        W = L.__ng_removed;
                      if ((ny(L, "ng-leave"), !W || !W.hasAnimation)) {
                        var z = [];
                        if (u.size) {
                          var q = u.get(L);
                          q && q.length && z.push.apply(z, n(q));
                          for (
                            var H = this.driver.query(L, ".ng-animating", !0),
                              U = 0;
                            U < H.length;
                            U++
                          ) {
                            var V = u.get(H[U]);
                            V && V.length && z.push.apply(z, n(V));
                          }
                        }
                        var B = z.filter(function (e) {
                          return !e.destroyed;
                        });
                        B.length ? ry(this, L, B) : this.processLeaveNode(L);
                      }
                    }
                    return (
                      (g.length = 0),
                      N.forEach(function (e) {
                        r.players.push(e),
                          e.onDone(function () {
                            e.destroy();
                            var t = r.players.indexOf(e);
                            r.players.splice(t, 1);
                          }),
                          e.play();
                      }),
                      N
                    );
                  },
                },
                {
                  key: "elementContainsData",
                  value: function (e, t) {
                    var n = !1,
                      r = t.__ng_removed;
                    return (
                      r && r.setForRemoval && (n = !0),
                      this.playersByElement.has(t) && (n = !0),
                      this.playersByQueriedElement.has(t) && (n = !0),
                      this.statesByElement.has(t) && (n = !0),
                      this._fetchNamespace(e).elementContainsData(t) || n
                    );
                  },
                },
                {
                  key: "afterFlush",
                  value: function (e) {
                    this._flushFns.push(e);
                  },
                },
                {
                  key: "afterFlushAnimationsDone",
                  value: function (e) {
                    this._whenQuietFns.push(e);
                  },
                },
                {
                  key: "_getPreviousPlayers",
                  value: function (e, t, n, r, i) {
                    var o = [];
                    if (t) {
                      var a = this.playersByQueriedElement.get(e);
                      a && (o = a);
                    } else {
                      var s = this.playersByElement.get(e);
                      if (s) {
                        var u = !i || "void" == i;
                        s.forEach(function (e) {
                          e.queued || ((u || e.triggerName == r) && o.push(e));
                        });
                      }
                    }
                    return (
                      (n || r) &&
                        (o = o.filter(function (e) {
                          return !(
                            (n && n != e.namespaceId) ||
                            (r && r != e.triggerName)
                          );
                        })),
                      o
                    );
                  },
                },
                {
                  key: "_beforeAnimationBuild",
                  value: function (e, n, r) {
                    var i,
                      o = this,
                      a = n.element,
                      s = n.isRemovalTransition ? void 0 : e,
                      u = n.isRemovalTransition ? void 0 : n.triggerName,
                      l = t(n.timelines);
                    try {
                      var c = function () {
                        var e = i.value.element,
                          t = e !== a,
                          l = Am(r, e, []);
                        o._getPreviousPlayers(e, t, s, u, n.toState).forEach(
                          function (e) {
                            var t = e.getRealPlayer();
                            t.beforeDestroy && t.beforeDestroy(),
                              e.destroy(),
                              l.push(e);
                          }
                        );
                      };
                      for (l.s(); !(i = l.n()).done; ) c();
                    } catch (h) {
                      l.e(h);
                    } finally {
                      l.f();
                    }
                    Xm(a, n.fromStyles);
                  },
                },
                {
                  key: "_buildAnimation",
                  value: function (e, t, n, r, i, o) {
                    var a = this,
                      s = t.triggerName,
                      u = t.element,
                      l = [],
                      c = new Set(),
                      h = new Set(),
                      f = t.timelines.map(function (t) {
                        var f = t.element;
                        c.add(f);
                        var d = f.__ng_removed;
                        if (d && d.removedBeforeQueried)
                          return new qv(t.duration, t.delay);
                        var v,
                          p,
                          m = f !== u,
                          g = ((v = (n.get(f) || Ug).map(function (e) {
                            return e.getRealPlayer();
                          })),
                          (p = []),
                          iy(v, p),
                          p).filter(function (e) {
                            return !!e.element && e.element === f;
                          }),
                          y = i.get(f),
                          k = o.get(f),
                          b = Em(0, a._normalizer, 0, t.keyframes, y, k),
                          w = a._buildPlayer(t, b, g);
                        if ((t.subTimeline && r && h.add(f), m)) {
                          var _ = new Jg(e, s, f);
                          _.setRealPlayer(w), l.push(_);
                        }
                        return w;
                      });
                    l.forEach(function (e) {
                      Am(a.playersByQueriedElement, e.element, []).push(e),
                        e.onDone(function () {
                          return (function (e, t, n) {
                            var r;
                            if (e instanceof Map) {
                              if ((r = e.get(t))) {
                                if (r.length) {
                                  var i = r.indexOf(n);
                                  r.splice(i, 1);
                                }
                                0 == r.length && e.delete(t);
                              }
                            } else if ((r = e[t])) {
                              if (r.length) {
                                var o = r.indexOf(n);
                                r.splice(o, 1);
                              }
                              0 == r.length && delete e[t];
                            }
                            return r;
                          })(a.playersByQueriedElement, e.element, e);
                        });
                    }),
                      c.forEach(function (e) {
                        return ty(e, "ng-animating");
                      });
                    var d = Cm(f);
                    return (
                      d.onDestroy(function () {
                        c.forEach(function (e) {
                          return ny(e, "ng-animating");
                        }),
                          Ym(u, t.toStyles);
                      }),
                      h.forEach(function (e) {
                        Am(r, e, []).push(d);
                      }),
                      d
                    );
                  },
                },
                {
                  key: "_buildPlayer",
                  value: function (e, t, n) {
                    return t.length > 0
                      ? this.driver.animate(
                          e.element,
                          t,
                          e.duration,
                          e.delay,
                          e.easing,
                          n
                        )
                      : new qv(e.duration, e.delay);
                  },
                },
              ]),
              e
            );
          })(),
          Jg = (function () {
            function e(t, n, r) {
              p(this, e),
                (this.namespaceId = t),
                (this.triggerName = n),
                (this.element = r),
                (this._player = new qv()),
                (this._containsRealPlayer = !1),
                (this._queuedCallbacks = {}),
                (this.destroyed = !1),
                (this.markedForDestroy = !1),
                (this.disabled = !1),
                (this.queued = !0),
                (this.totalTime = 0);
            }
            return (
              g(e, [
                {
                  key: "setRealPlayer",
                  value: function (e) {
                    var t = this;
                    this._containsRealPlayer ||
                      ((this._player = e),
                      Object.keys(this._queuedCallbacks).forEach(function (n) {
                        t._queuedCallbacks[n].forEach(function (t) {
                          return Tm(e, n, void 0, t);
                        });
                      }),
                      (this._queuedCallbacks = {}),
                      (this._containsRealPlayer = !0),
                      this.overrideTotalTime(e.totalTime),
                      (this.queued = !1));
                  },
                },
                {
                  key: "getRealPlayer",
                  value: function () {
                    return this._player;
                  },
                },
                {
                  key: "overrideTotalTime",
                  value: function (e) {
                    this.totalTime = e;
                  },
                },
                {
                  key: "syncPlayerEvents",
                  value: function (e) {
                    var t = this,
                      n = this._player;
                    n.triggerCallback &&
                      e.onStart(function () {
                        return n.triggerCallback("start");
                      }),
                      e.onDone(function () {
                        return t.finish();
                      }),
                      e.onDestroy(function () {
                        return t.destroy();
                      });
                  },
                },
                {
                  key: "_queueEvent",
                  value: function (e, t) {
                    Am(this._queuedCallbacks, e, []).push(t);
                  },
                },
                {
                  key: "onDone",
                  value: function (e) {
                    this.queued && this._queueEvent("done", e),
                      this._player.onDone(e);
                  },
                },
                {
                  key: "onStart",
                  value: function (e) {
                    this.queued && this._queueEvent("start", e),
                      this._player.onStart(e);
                  },
                },
                {
                  key: "onDestroy",
                  value: function (e) {
                    this.queued && this._queueEvent("destroy", e),
                      this._player.onDestroy(e);
                  },
                },
                {
                  key: "init",
                  value: function () {
                    this._player.init();
                  },
                },
                {
                  key: "hasStarted",
                  value: function () {
                    return !this.queued && this._player.hasStarted();
                  },
                },
                {
                  key: "play",
                  value: function () {
                    !this.queued && this._player.play();
                  },
                },
                {
                  key: "pause",
                  value: function () {
                    !this.queued && this._player.pause();
                  },
                },
                {
                  key: "restart",
                  value: function () {
                    !this.queued && this._player.restart();
                  },
                },
                {
                  key: "finish",
                  value: function () {
                    this._player.finish();
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    (this.destroyed = !0), this._player.destroy();
                  },
                },
                {
                  key: "reset",
                  value: function () {
                    !this.queued && this._player.reset();
                  },
                },
                {
                  key: "setPosition",
                  value: function (e) {
                    this.queued || this._player.setPosition(e);
                  },
                },
                {
                  key: "getPosition",
                  value: function () {
                    return this.queued ? 0 : this._player.getPosition();
                  },
                },
                {
                  key: "triggerCallback",
                  value: function (e) {
                    var t = this._player;
                    t.triggerCallback && t.triggerCallback(e);
                  },
                },
              ]),
              e
            );
          })();
        function $g(e) {
          return e && 1 === e.nodeType;
        }
        function Yg(e, t) {
          var n = e.style.display;
          return (e.style.display = null != t ? t : "none"), n;
        }
        function Xg(e, t, n, r, i) {
          var o = [];
          n.forEach(function (e) {
            return o.push(Yg(e));
          });
          var a = [];
          r.forEach(function (n, r) {
            var o = {};
            n.forEach(function (e) {
              var n = (o[e] = t.computeStyle(r, e, i));
              (n && 0 != n.length) || ((r.__ng_removed = Bg), a.push(r));
            }),
              e.set(r, o);
          });
          var s = 0;
          return (
            n.forEach(function (e) {
              return Yg(e, o[s++]);
            }),
            a
          );
        }
        function ey(e, t) {
          var n = new Map();
          if (
            (e.forEach(function (e) {
              return n.set(e, []);
            }),
            0 == t.length)
          )
            return n;
          var r = new Set(t),
            i = new Map();
          function o(e) {
            if (!e) return 1;
            var t = i.get(e);
            if (t) return t;
            var a = e.parentNode;
            return (t = n.has(a) ? a : r.has(a) ? 1 : o(a)), i.set(e, t), t;
          }
          return (
            t.forEach(function (e) {
              var t = o(e);
              1 !== t && n.get(t).push(e);
            }),
            n
          );
        }
        function ty(e, t) {
          if (e.classList) e.classList.add(t);
          else {
            var n = e.$$classes;
            n || (n = e.$$classes = {}), (n[t] = !0);
          }
        }
        function ny(e, t) {
          if (e.classList) e.classList.remove(t);
          else {
            var n = e.$$classes;
            n && delete n[t];
          }
        }
        function ry(e, t, n) {
          Cm(n).onDone(function () {
            return e.processLeaveNode(t);
          });
        }
        function iy(e, t) {
          for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r instanceof Hv ? iy(r.players, t) : t.push(r);
          }
        }
        function oy(e, t, n) {
          var r = n.get(e);
          if (!r) return !1;
          var i = t.get(e);
          return (
            i
              ? r.forEach(function (e) {
                  return i.add(e);
                })
              : t.set(e, r),
            n.delete(e),
            !0
          );
        }
        var ay = (function () {
          function t(e, n, r) {
            var i = this;
            p(this, t),
              (this.bodyNode = e),
              (this._driver = n),
              (this._triggerCache = {}),
              (this.onRemovalComplete = function (e, t) {}),
              (this._transitionEngine = new Zg(e, n, r)),
              (this._timelineEngine = new Hg(e, n, r)),
              (this._transitionEngine.onRemovalComplete = function (e, t) {
                return i.onRemovalComplete(e, t);
              });
          }
          return (
            g(t, [
              {
                key: "registerTrigger",
                value: function (e, t, n, r, i) {
                  var o = e + "-" + r,
                    a = this._triggerCache[o];
                  if (!a) {
                    var s = [],
                      u = pg(this._driver, i, s);
                    if (s.length)
                      throw new Error(
                        'The animation trigger "'
                          .concat(
                            r,
                            '" has failed to build due to the following errors:\n - '
                          )
                          .concat(s.join("\n - "))
                      );
                    (a = (function (e, t) {
                      return new Wg(e, t);
                    })(r, u)),
                      (this._triggerCache[o] = a);
                  }
                  this._transitionEngine.registerTrigger(t, r, a);
                },
              },
              {
                key: "register",
                value: function (e, t) {
                  this._transitionEngine.register(e, t);
                },
              },
              {
                key: "destroy",
                value: function (e, t) {
                  this._transitionEngine.destroy(e, t);
                },
              },
              {
                key: "onInsert",
                value: function (e, t, n, r) {
                  this._transitionEngine.insertNode(e, t, n, r);
                },
              },
              {
                key: "onRemove",
                value: function (e, t, n, r) {
                  this._transitionEngine.removeNode(e, t, r || !1, n);
                },
              },
              {
                key: "disableAnimations",
                value: function (e, t) {
                  this._transitionEngine.markElementAsDisabled(e, t);
                },
              },
              {
                key: "process",
                value: function (t, n, r, i) {
                  if ("@" == r.charAt(0)) {
                    var o = e(Om(r), 2),
                      a = o[0],
                      s = o[1];
                    this._timelineEngine.command(a, n, s, i);
                  } else this._transitionEngine.trigger(t, n, r, i);
                },
              },
              {
                key: "listen",
                value: function (t, n, r, i, o) {
                  if ("@" == r.charAt(0)) {
                    var a = e(Om(r), 2),
                      s = a[0],
                      u = a[1];
                    return this._timelineEngine.listen(s, n, u, o);
                  }
                  return this._transitionEngine.listen(t, n, r, i, o);
                },
              },
              {
                key: "flush",
                value: function () {
                  var e =
                    arguments.length > 0 && void 0 !== arguments[0]
                      ? arguments[0]
                      : -1;
                  this._transitionEngine.flush(e);
                },
              },
              {
                key: "players",
                get: function () {
                  return this._transitionEngine.players.concat(
                    this._timelineEngine.players
                  );
                },
              },
              {
                key: "whenRenderingDone",
                value: function () {
                  return this._transitionEngine.whenRenderingDone();
                },
              },
            ]),
            t
          );
        })();
        function sy(e, t) {
          var n = null,
            r = null;
          return (
            Array.isArray(t) && t.length
              ? ((n = ly(t[0])), t.length > 1 && (r = ly(t[t.length - 1])))
              : t && (n = ly(t)),
            n || r ? new uy(e, n, r) : null
          );
        }
        var uy = (function () {
          var e = (function () {
            function e(t, n, r) {
              p(this, e),
                (this._element = t),
                (this._startStyles = n),
                (this._endStyles = r),
                (this._state = 0);
              var i = e.initialStylesByElement.get(t);
              i || e.initialStylesByElement.set(t, (i = {})),
                (this._initialStyles = i);
            }
            return (
              g(e, [
                {
                  key: "start",
                  value: function () {
                    this._state < 1 &&
                      (this._startStyles &&
                        Ym(
                          this._element,
                          this._startStyles,
                          this._initialStyles
                        ),
                      (this._state = 1));
                  },
                },
                {
                  key: "finish",
                  value: function () {
                    this.start(),
                      this._state < 2 &&
                        (Ym(this._element, this._initialStyles),
                        this._endStyles &&
                          (Ym(this._element, this._endStyles),
                          (this._endStyles = null)),
                        (this._state = 1));
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    this.finish(),
                      this._state < 3 &&
                        (e.initialStylesByElement.delete(this._element),
                        this._startStyles &&
                          (Xm(this._element, this._startStyles),
                          (this._endStyles = null)),
                        this._endStyles &&
                          (Xm(this._element, this._endStyles),
                          (this._endStyles = null)),
                        Ym(this._element, this._initialStyles),
                        (this._state = 3));
                  },
                },
              ]),
              e
            );
          })();
          return (e.initialStylesByElement = new WeakMap()), e;
        })();
        function ly(e) {
          for (var t = null, n = Object.keys(e), r = 0; r < n.length; r++) {
            var i = n[r];
            cy(i) && ((t = t || {})[i] = e[i]);
          }
          return t;
        }
        function cy(e) {
          return "display" === e || "position" === e;
        }
        var hy = (function () {
          function e(t, n, r, i, o, a, s) {
            var u = this;
            p(this, e),
              (this._element = t),
              (this._name = n),
              (this._duration = r),
              (this._delay = i),
              (this._easing = o),
              (this._fillMode = a),
              (this._onDoneFn = s),
              (this._finished = !1),
              (this._destroyed = !1),
              (this._startTime = 0),
              (this._position = 0),
              (this._eventFn = function (e) {
                return u._handleCallback(e);
              });
          }
          return (
            g(e, [
              {
                key: "apply",
                value: function () {
                  var e, t, n;
                  (e = this._element),
                    (t = ""
                      .concat(this._duration, "ms ")
                      .concat(this._easing, " ")
                      .concat(this._delay, "ms 1 normal ")
                      .concat(this._fillMode, " ")
                      .concat(this._name)),
                    (n = gy(e, "").trim()).length &&
                      ((function (e, t) {
                        for (var n = 0; n < e.length; n++) e.charAt(n);
                      })(n),
                      (t = "".concat(n, ", ").concat(t))),
                    my(e, "", t),
                    py(this._element, this._eventFn, !1),
                    (this._startTime = Date.now());
                },
              },
              {
                key: "pause",
                value: function () {
                  fy(this._element, this._name, "paused");
                },
              },
              {
                key: "resume",
                value: function () {
                  fy(this._element, this._name, "running");
                },
              },
              {
                key: "setPosition",
                value: function (e) {
                  var t = dy(this._element, this._name);
                  (this._position = e * this._duration),
                    my(
                      this._element,
                      "Delay",
                      "-".concat(this._position, "ms"),
                      t
                    );
                },
              },
              {
                key: "getPosition",
                value: function () {
                  return this._position;
                },
              },
              {
                key: "_handleCallback",
                value: function (e) {
                  var t = e._ngTestManualTimestamp || Date.now(),
                    n = 1e3 * parseFloat(e.elapsedTime.toFixed(3));
                  e.animationName == this._name &&
                    Math.max(t - this._startTime, 0) >= this._delay &&
                    n >= this._duration &&
                    this.finish();
                },
              },
              {
                key: "finish",
                value: function () {
                  this._finished ||
                    ((this._finished = !0),
                    this._onDoneFn(),
                    py(this._element, this._eventFn, !0));
                },
              },
              {
                key: "destroy",
                value: function () {
                  var e, t, n, r;
                  this._destroyed ||
                    ((this._destroyed = !0),
                    this.finish(),
                    (e = this._element),
                    (t = this._name),
                    (n = gy(e, "").split(",")),
                    (r = vy(n, t)) >= 0 &&
                      (n.splice(r, 1), my(e, "", n.join(","))));
                },
              },
            ]),
            e
          );
        })();
        function fy(e, t, n) {
          my(e, "PlayState", n, dy(e, t));
        }
        function dy(e, t) {
          var n = gy(e, "");
          return n.indexOf(",") > 0 ? vy(n.split(","), t) : vy([n], t);
        }
        function vy(e, t) {
          for (var n = 0; n < e.length; n++) if (e[n].indexOf(t) >= 0) return n;
          return -1;
        }
        function py(e, t, n) {
          n
            ? e.removeEventListener("animationend", t)
            : e.addEventListener("animationend", t);
        }
        function my(e, t, n, r) {
          var i = "animation" + t;
          if (null != r) {
            var o = e.style[i];
            if (o.length) {
              var a = o.split(",");
              (a[r] = n), (n = a.join(","));
            }
          }
          e.style[i] = n;
        }
        function gy(e, t) {
          return e.style["animation" + t] || "";
        }
        var yy = (function () {
            function e(t, n, r, i, o, a, s, u) {
              p(this, e),
                (this.element = t),
                (this.keyframes = n),
                (this.animationName = r),
                (this._duration = i),
                (this._delay = o),
                (this._finalStyles = s),
                (this._specialStyles = u),
                (this._onDoneFns = []),
                (this._onStartFns = []),
                (this._onDestroyFns = []),
                (this._started = !1),
                (this.currentSnapshot = {}),
                (this._state = 0),
                (this.easing = a || "linear"),
                (this.totalTime = i + o),
                this._buildStyler();
            }
            return (
              g(e, [
                {
                  key: "onStart",
                  value: function (e) {
                    this._onStartFns.push(e);
                  },
                },
                {
                  key: "onDone",
                  value: function (e) {
                    this._onDoneFns.push(e);
                  },
                },
                {
                  key: "onDestroy",
                  value: function (e) {
                    this._onDestroyFns.push(e);
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    this.init(),
                      this._state >= 4 ||
                        ((this._state = 4),
                        this._styler.destroy(),
                        this._flushStartFns(),
                        this._flushDoneFns(),
                        this._specialStyles && this._specialStyles.destroy(),
                        this._onDestroyFns.forEach(function (e) {
                          return e();
                        }),
                        (this._onDestroyFns = []));
                  },
                },
                {
                  key: "_flushDoneFns",
                  value: function () {
                    this._onDoneFns.forEach(function (e) {
                      return e();
                    }),
                      (this._onDoneFns = []);
                  },
                },
                {
                  key: "_flushStartFns",
                  value: function () {
                    this._onStartFns.forEach(function (e) {
                      return e();
                    }),
                      (this._onStartFns = []);
                  },
                },
                {
                  key: "finish",
                  value: function () {
                    this.init(),
                      this._state >= 3 ||
                        ((this._state = 3),
                        this._styler.finish(),
                        this._flushStartFns(),
                        this._specialStyles && this._specialStyles.finish(),
                        this._flushDoneFns());
                  },
                },
                {
                  key: "setPosition",
                  value: function (e) {
                    this._styler.setPosition(e);
                  },
                },
                {
                  key: "getPosition",
                  value: function () {
                    return this._styler.getPosition();
                  },
                },
                {
                  key: "hasStarted",
                  value: function () {
                    return this._state >= 2;
                  },
                },
                {
                  key: "init",
                  value: function () {
                    this._state >= 1 ||
                      ((this._state = 1),
                      this._styler.apply(),
                      this._delay && this._styler.pause());
                  },
                },
                {
                  key: "play",
                  value: function () {
                    this.init(),
                      this.hasStarted() ||
                        (this._flushStartFns(),
                        (this._state = 2),
                        this._specialStyles && this._specialStyles.start()),
                      this._styler.resume();
                  },
                },
                {
                  key: "pause",
                  value: function () {
                    this.init(), this._styler.pause();
                  },
                },
                {
                  key: "restart",
                  value: function () {
                    this.reset(), this.play();
                  },
                },
                {
                  key: "reset",
                  value: function () {
                    this._styler.destroy(),
                      this._buildStyler(),
                      this._styler.apply();
                  },
                },
                {
                  key: "_buildStyler",
                  value: function () {
                    var e = this;
                    this._styler = new hy(
                      this.element,
                      this.animationName,
                      this._duration,
                      this._delay,
                      this.easing,
                      "forwards",
                      function () {
                        return e.finish();
                      }
                    );
                  },
                },
                {
                  key: "triggerCallback",
                  value: function (e) {
                    var t = "start" == e ? this._onStartFns : this._onDoneFns;
                    t.forEach(function (e) {
                      return e();
                    }),
                      (t.length = 0);
                  },
                },
                {
                  key: "beforeDestroy",
                  value: function () {
                    var e = this;
                    this.init();
                    var t = {};
                    if (this.hasStarted()) {
                      var n = this._state >= 3;
                      Object.keys(this._finalStyles).forEach(function (r) {
                        "offset" != r &&
                          (t[r] = n ? e._finalStyles[r] : cg(e.element, r));
                      });
                    }
                    this.currentSnapshot = t;
                  },
                },
              ]),
              e
            );
          })(),
          ky = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n),
                ((i = t.call(this)).element = e),
                (i._startingStyles = {}),
                (i.__initialized = !1),
                (i._styles = qm(r)),
                i
              );
            }
            return (
              g(n, [
                {
                  key: "init",
                  value: function () {
                    var e = this;
                    !this.__initialized &&
                      this._startingStyles &&
                      ((this.__initialized = !0),
                      Object.keys(this._styles).forEach(function (t) {
                        e._startingStyles[t] = e.element.style[t];
                      }),
                      s(v(n.prototype), "init", this).call(this));
                  },
                },
                {
                  key: "play",
                  value: function () {
                    var e = this;
                    this._startingStyles &&
                      (this.init(),
                      Object.keys(this._styles).forEach(function (t) {
                        return e.element.style.setProperty(t, e._styles[t]);
                      }),
                      s(v(n.prototype), "play", this).call(this));
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    var e = this;
                    this._startingStyles &&
                      (Object.keys(this._startingStyles).forEach(function (t) {
                        var n = e._startingStyles[t];
                        n
                          ? e.element.style.setProperty(t, n)
                          : e.element.style.removeProperty(t);
                      }),
                      (this._startingStyles = null),
                      s(v(n.prototype), "destroy", this).call(this));
                  },
                },
              ]),
              n
            );
          })(qv),
          by = (function () {
            function e() {
              p(this, e),
                (this._count = 0),
                (this._head = document.querySelector("head"));
            }
            return (
              g(e, [
                {
                  key: "validateStyleProperty",
                  value: function (e) {
                    return Fm(e);
                  },
                },
                {
                  key: "matchesElement",
                  value: function (e, t) {
                    return Lm(e, t);
                  },
                },
                {
                  key: "containsElement",
                  value: function (e, t) {
                    return Wm(e, t);
                  },
                },
                {
                  key: "query",
                  value: function (e, t, n) {
                    return zm(e, t, n);
                  },
                },
                {
                  key: "computeStyle",
                  value: function (e, t, n) {
                    return window.getComputedStyle(e)[t];
                  },
                },
                {
                  key: "buildKeyframeElement",
                  value: function (e, t, n) {
                    n = n.map(function (e) {
                      return qm(e);
                    });
                    var r = "@keyframes ".concat(t, " {\n"),
                      i = "";
                    n.forEach(function (e) {
                      i = " ";
                      var t = parseFloat(e.offset);
                      (r += "".concat(i).concat(100 * t, "% {\n")),
                        (i += " "),
                        Object.keys(e).forEach(function (t) {
                          var n = e[t];
                          switch (t) {
                            case "offset":
                              return;
                            case "easing":
                              return void (
                                n &&
                                (r += ""
                                  .concat(i, "animation-timing-function: ")
                                  .concat(n, ";\n"))
                              );
                            default:
                              return void (r += ""
                                .concat(i)
                                .concat(t, ": ")
                                .concat(n, ";\n"));
                          }
                        }),
                        (r += "".concat(i, "}\n"));
                    }),
                      (r += "}\n");
                    var o = document.createElement("style");
                    return (o.textContent = r), o;
                  },
                },
                {
                  key: "animate",
                  value: function (e, t, n, r, i) {
                    var o =
                        arguments.length > 5 && void 0 !== arguments[5]
                          ? arguments[5]
                          : [],
                      a = o.filter(function (e) {
                        return e instanceof yy;
                      }),
                      s = {};
                    sg(n, r) &&
                      a.forEach(function (e) {
                        var t = e.currentSnapshot;
                        Object.keys(t).forEach(function (e) {
                          return (s[e] = t[e]);
                        });
                      });
                    var u = (function (e) {
                      var t = {};
                      return (
                        e &&
                          (Array.isArray(e) ? e : [e]).forEach(function (e) {
                            Object.keys(e).forEach(function (n) {
                              "offset" != n && "easing" != n && (t[n] = e[n]);
                            });
                          }),
                        t
                      );
                    })((t = ug(e, t, s)));
                    if (0 == n) return new ky(e, u);
                    var l = "gen_css_kf_" + this._count++,
                      c = this.buildKeyframeElement(e, l, t);
                    document.querySelector("head").appendChild(c);
                    var h = sy(e, t),
                      f = new yy(e, t, l, n, r, i, u, h);
                    return (
                      f.onDestroy(function () {
                        var e;
                        (e = c).parentNode.removeChild(e);
                      }),
                      f
                    );
                  },
                },
              ]),
              e
            );
          })(),
          wy = (function () {
            function e(t, n, r, i) {
              p(this, e),
                (this.element = t),
                (this.keyframes = n),
                (this.options = r),
                (this._specialStyles = i),
                (this._onDoneFns = []),
                (this._onStartFns = []),
                (this._onDestroyFns = []),
                (this._initialized = !1),
                (this._finished = !1),
                (this._started = !1),
                (this._destroyed = !1),
                (this.time = 0),
                (this.parentPlayer = null),
                (this.currentSnapshot = {}),
                (this._duration = r.duration),
                (this._delay = r.delay || 0),
                (this.time = this._duration + this._delay);
            }
            return (
              g(e, [
                {
                  key: "_onFinish",
                  value: function () {
                    this._finished ||
                      ((this._finished = !0),
                      this._onDoneFns.forEach(function (e) {
                        return e();
                      }),
                      (this._onDoneFns = []));
                  },
                },
                {
                  key: "init",
                  value: function () {
                    this._buildPlayer(), this._preparePlayerBeforeStart();
                  },
                },
                {
                  key: "_buildPlayer",
                  value: function () {
                    var e = this;
                    if (!this._initialized) {
                      this._initialized = !0;
                      var t = this.keyframes;
                      (this.domPlayer = this._triggerWebAnimation(
                        this.element,
                        t,
                        this.options
                      )),
                        (this._finalKeyframe = t.length ? t[t.length - 1] : {}),
                        this.domPlayer.addEventListener("finish", function () {
                          return e._onFinish();
                        });
                    }
                  },
                },
                {
                  key: "_preparePlayerBeforeStart",
                  value: function () {
                    this._delay
                      ? this._resetDomPlayerState()
                      : this.domPlayer.pause();
                  },
                },
                {
                  key: "_triggerWebAnimation",
                  value: function (e, t, n) {
                    return e.animate(t, n);
                  },
                },
                {
                  key: "onStart",
                  value: function (e) {
                    this._onStartFns.push(e);
                  },
                },
                {
                  key: "onDone",
                  value: function (e) {
                    this._onDoneFns.push(e);
                  },
                },
                {
                  key: "onDestroy",
                  value: function (e) {
                    this._onDestroyFns.push(e);
                  },
                },
                {
                  key: "play",
                  value: function () {
                    this._buildPlayer(),
                      this.hasStarted() ||
                        (this._onStartFns.forEach(function (e) {
                          return e();
                        }),
                        (this._onStartFns = []),
                        (this._started = !0),
                        this._specialStyles && this._specialStyles.start()),
                      this.domPlayer.play();
                  },
                },
                {
                  key: "pause",
                  value: function () {
                    this.init(), this.domPlayer.pause();
                  },
                },
                {
                  key: "finish",
                  value: function () {
                    this.init(),
                      this._specialStyles && this._specialStyles.finish(),
                      this._onFinish(),
                      this.domPlayer.finish();
                  },
                },
                {
                  key: "reset",
                  value: function () {
                    this._resetDomPlayerState(),
                      (this._destroyed = !1),
                      (this._finished = !1),
                      (this._started = !1);
                  },
                },
                {
                  key: "_resetDomPlayerState",
                  value: function () {
                    this.domPlayer && this.domPlayer.cancel();
                  },
                },
                {
                  key: "restart",
                  value: function () {
                    this.reset(), this.play();
                  },
                },
                {
                  key: "hasStarted",
                  value: function () {
                    return this._started;
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    this._destroyed ||
                      ((this._destroyed = !0),
                      this._resetDomPlayerState(),
                      this._onFinish(),
                      this._specialStyles && this._specialStyles.destroy(),
                      this._onDestroyFns.forEach(function (e) {
                        return e();
                      }),
                      (this._onDestroyFns = []));
                  },
                },
                {
                  key: "setPosition",
                  value: function (e) {
                    void 0 === this.domPlayer && this.init(),
                      (this.domPlayer.currentTime = e * this.time);
                  },
                },
                {
                  key: "getPosition",
                  value: function () {
                    return this.domPlayer.currentTime / this.time;
                  },
                },
                {
                  key: "totalTime",
                  get: function () {
                    return this._delay + this._duration;
                  },
                },
                {
                  key: "beforeDestroy",
                  value: function () {
                    var e = this,
                      t = {};
                    this.hasStarted() &&
                      Object.keys(this._finalKeyframe).forEach(function (n) {
                        "offset" != n &&
                          (t[n] = e._finished
                            ? e._finalKeyframe[n]
                            : cg(e.element, n));
                      }),
                      (this.currentSnapshot = t);
                  },
                },
                {
                  key: "triggerCallback",
                  value: function (e) {
                    var t = "start" == e ? this._onStartFns : this._onDoneFns;
                    t.forEach(function (e) {
                      return e();
                    }),
                      (t.length = 0);
                  },
                },
              ]),
              e
            );
          })(),
          _y = (function () {
            function e() {
              p(this, e),
                (this._isNativeImpl = /\{\s*\[native\s+code\]\s*\}/.test(
                  Sy().toString()
                )),
                (this._cssKeyframesDriver = new by());
            }
            return (
              g(e, [
                {
                  key: "validateStyleProperty",
                  value: function (e) {
                    return Fm(e);
                  },
                },
                {
                  key: "matchesElement",
                  value: function (e, t) {
                    return Lm(e, t);
                  },
                },
                {
                  key: "containsElement",
                  value: function (e, t) {
                    return Wm(e, t);
                  },
                },
                {
                  key: "query",
                  value: function (e, t, n) {
                    return zm(e, t, n);
                  },
                },
                {
                  key: "computeStyle",
                  value: function (e, t, n) {
                    return window.getComputedStyle(e)[t];
                  },
                },
                {
                  key: "overrideWebAnimationsSupport",
                  value: function (e) {
                    this._isNativeImpl = e;
                  },
                },
                {
                  key: "animate",
                  value: function (e, t, n, r, i) {
                    var o =
                        arguments.length > 5 && void 0 !== arguments[5]
                          ? arguments[5]
                          : [],
                      a = arguments.length > 6 ? arguments[6] : void 0;
                    if (!a && !this._isNativeImpl)
                      return this._cssKeyframesDriver.animate(e, t, n, r, i, o);
                    var s = {
                      duration: n,
                      delay: r,
                      fill: 0 == r ? "both" : "forwards",
                    };
                    i && (s.easing = i);
                    var u = {},
                      l = o.filter(function (e) {
                        return e instanceof wy;
                      });
                    sg(n, r) &&
                      l.forEach(function (e) {
                        var t = e.currentSnapshot;
                        Object.keys(t).forEach(function (e) {
                          return (u[e] = t[e]);
                        });
                      });
                    var c = sy(
                      e,
                      (t = ug(
                        e,
                        (t = t.map(function (e) {
                          return Zm(e, !1);
                        })),
                        u
                      ))
                    );
                    return new wy(e, t, s, c);
                  },
                },
              ]),
              e
            );
          })();
        function Sy() {
          return (
            ("undefined" != typeof window &&
              void 0 !== window.document &&
              Element.prototype.animate) ||
            {}
          );
        }
        var Cy,
          Ey =
            (((Cy = (function (e) {
              u(n, e);
              var t = c(n);
              function n(e, r) {
                var i;
                return (
                  p(this, n),
                  ((i = t.call(this))._nextAnimationId = 0),
                  (i._renderer = e.createRenderer(r.body, {
                    id: "0",
                    encapsulation: ze.None,
                    styles: [],
                    data: { animation: [] },
                  })),
                  i
                );
              }
              return (
                g(n, [
                  {
                    key: "build",
                    value: function (e) {
                      var t = this._nextAnimationId.toString();
                      this._nextAnimationId++;
                      var n = Array.isArray(e) ? Mv(e) : e;
                      return (
                        Iy(this._renderer, null, t, "register", [n]),
                        new Ty(t, this._renderer)
                      );
                    },
                  },
                ]),
                n
              );
            })(Pv)).ɵfac = function (e) {
              return new (e || Cy)(fr(xa), fr(Gu));
            }),
            (Cy.ɵprov = Ee({ token: Cy, factory: Cy.ɵfac })),
            Cy),
          Ty = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e, r) {
              var i;
              return (
                p(this, n), ((i = t.call(this))._id = e), (i._renderer = r), i
              );
            }
            return (
              g(n, [
                {
                  key: "create",
                  value: function (e, t) {
                    return new xy(this._id, e, t || {}, this._renderer);
                  },
                },
              ]),
              n
            );
          })(
            (function () {
              return function e() {
                p(this, e);
              };
            })()
          ),
          xy = (function () {
            function e(t, n, r, i) {
              p(this, e),
                (this.id = t),
                (this.element = n),
                (this._renderer = i),
                (this.parentPlayer = null),
                (this._started = !1),
                (this.totalTime = 0),
                this._command("create", r);
            }
            return (
              g(e, [
                {
                  key: "_listen",
                  value: function (e, t) {
                    return this._renderer.listen(
                      this.element,
                      "@@".concat(this.id, ":").concat(e),
                      t
                    );
                  },
                },
                {
                  key: "_command",
                  value: function (e) {
                    for (
                      var t = arguments.length,
                        n = new Array(t > 1 ? t - 1 : 0),
                        r = 1;
                      r < t;
                      r++
                    )
                      n[r - 1] = arguments[r];
                    return Iy(this._renderer, this.element, this.id, e, n);
                  },
                },
                {
                  key: "onDone",
                  value: function (e) {
                    this._listen("done", e);
                  },
                },
                {
                  key: "onStart",
                  value: function (e) {
                    this._listen("start", e);
                  },
                },
                {
                  key: "onDestroy",
                  value: function (e) {
                    this._listen("destroy", e);
                  },
                },
                {
                  key: "init",
                  value: function () {
                    this._command("init");
                  },
                },
                {
                  key: "hasStarted",
                  value: function () {
                    return this._started;
                  },
                },
                {
                  key: "play",
                  value: function () {
                    this._command("play"), (this._started = !0);
                  },
                },
                {
                  key: "pause",
                  value: function () {
                    this._command("pause");
                  },
                },
                {
                  key: "restart",
                  value: function () {
                    this._command("restart");
                  },
                },
                {
                  key: "finish",
                  value: function () {
                    this._command("finish");
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    this._command("destroy");
                  },
                },
                {
                  key: "reset",
                  value: function () {
                    this._command("reset");
                  },
                },
                {
                  key: "setPosition",
                  value: function (e) {
                    this._command("setPosition", e);
                  },
                },
                {
                  key: "getPosition",
                  value: function () {
                    var e, t;
                    return null !==
                      (t =
                        null ===
                          (e = this._renderer.engine.players[+this.id]) ||
                        void 0 === e
                          ? void 0
                          : e.getPosition()) && void 0 !== t
                      ? t
                      : 0;
                  },
                },
              ]),
              e
            );
          })();
        function Iy(e, t, n, r, i) {
          return e.setProperty(t, "@@".concat(n, ":").concat(r), i);
        }
        var Ay,
          Oy,
          Ry,
          Py =
            (((Ay = (function () {
              function t(e, n, r) {
                p(this, t),
                  (this.delegate = e),
                  (this.engine = n),
                  (this._zone = r),
                  (this._currentId = 0),
                  (this._microtaskId = 1),
                  (this._animationCallbacksBuffer = []),
                  (this._rendererCache = new Map()),
                  (this._cdRecurDepth = 0),
                  (this.promise = Promise.resolve(0)),
                  (n.onRemovalComplete = function (e, t) {
                    t && t.parentNode(e) && t.removeChild(e.parentNode, e);
                  });
              }
              return (
                g(t, [
                  {
                    key: "createRenderer",
                    value: function (e, t) {
                      var n = this,
                        r = this.delegate.createRenderer(e, t);
                      if (!(e && t && t.data && t.data.animation)) {
                        var i = this._rendererCache.get(r);
                        return (
                          i ||
                            ((i = new Dy("", r, this.engine)),
                            this._rendererCache.set(r, i)),
                          i
                        );
                      }
                      var o = t.id,
                        a = t.id + "-" + this._currentId;
                      return (
                        this._currentId++,
                        this.engine.register(a, e),
                        t.data.animation.forEach(function t(r) {
                          Array.isArray(r)
                            ? r.forEach(t)
                            : n.engine.registerTrigger(o, a, e, r.name, r);
                        }),
                        new Ny(this, a, r, this.engine)
                      );
                    },
                  },
                  {
                    key: "begin",
                    value: function () {
                      this._cdRecurDepth++,
                        this.delegate.begin && this.delegate.begin();
                    },
                  },
                  {
                    key: "_scheduleCountTask",
                    value: function () {
                      var e = this;
                      this.promise.then(function () {
                        e._microtaskId++;
                      });
                    },
                  },
                  {
                    key: "scheduleListenerCallback",
                    value: function (t, n, r) {
                      var i = this;
                      t >= 0 && t < this._microtaskId
                        ? this._zone.run(function () {
                            return n(r);
                          })
                        : (0 == this._animationCallbacksBuffer.length &&
                            Promise.resolve(null).then(function () {
                              i._zone.run(function () {
                                i._animationCallbacksBuffer.forEach(function (
                                  t
                                ) {
                                  var n = e(t, 2);
                                  (0, n[0])(n[1]);
                                }),
                                  (i._animationCallbacksBuffer = []);
                              });
                            }),
                          this._animationCallbacksBuffer.push([n, r]));
                    },
                  },
                  {
                    key: "end",
                    value: function () {
                      var e = this;
                      this._cdRecurDepth--,
                        0 == this._cdRecurDepth &&
                          this._zone.runOutsideAngular(function () {
                            e._scheduleCountTask(),
                              e.engine.flush(e._microtaskId);
                          }),
                        this.delegate.end && this.delegate.end();
                    },
                  },
                  {
                    key: "whenRenderingDone",
                    value: function () {
                      return this.engine.whenRenderingDone();
                    },
                  },
                ]),
                t
              );
            })()).ɵfac = function (e) {
              return new (e || Ay)(fr(xa), fr(ay), fr(vu));
            }),
            (Ay.ɵprov = Ee({ token: Ay, factory: Ay.ɵfac })),
            Ay),
          Dy = (function () {
            function e(t, n, r) {
              p(this, e),
                (this.namespaceId = t),
                (this.delegate = n),
                (this.engine = r),
                (this.destroyNode = this.delegate.destroyNode
                  ? function (e) {
                      return n.destroyNode(e);
                    }
                  : null);
            }
            return (
              g(e, [
                {
                  key: "data",
                  get: function () {
                    return this.delegate.data;
                  },
                },
                {
                  key: "destroy",
                  value: function () {
                    this.engine.destroy(this.namespaceId, this.delegate),
                      this.delegate.destroy();
                  },
                },
                {
                  key: "createElement",
                  value: function (e, t) {
                    return this.delegate.createElement(e, t);
                  },
                },
                {
                  key: "createComment",
                  value: function (e) {
                    return this.delegate.createComment(e);
                  },
                },
                {
                  key: "createText",
                  value: function (e) {
                    return this.delegate.createText(e);
                  },
                },
                {
                  key: "appendChild",
                  value: function (e, t) {
                    this.delegate.appendChild(e, t),
                      this.engine.onInsert(this.namespaceId, t, e, !1);
                  },
                },
                {
                  key: "insertBefore",
                  value: function (e, t, n) {
                    var r =
                      !(arguments.length > 3 && void 0 !== arguments[3]) ||
                      arguments[3];
                    this.delegate.insertBefore(e, t, n),
                      this.engine.onInsert(this.namespaceId, t, e, r);
                  },
                },
                {
                  key: "removeChild",
                  value: function (e, t, n) {
                    this.engine.onRemove(this.namespaceId, t, this.delegate, n);
                  },
                },
                {
                  key: "selectRootElement",
                  value: function (e, t) {
                    return this.delegate.selectRootElement(e, t);
                  },
                },
                {
                  key: "parentNode",
                  value: function (e) {
                    return this.delegate.parentNode(e);
                  },
                },
                {
                  key: "nextSibling",
                  value: function (e) {
                    return this.delegate.nextSibling(e);
                  },
                },
                {
                  key: "setAttribute",
                  value: function (e, t, n, r) {
                    this.delegate.setAttribute(e, t, n, r);
                  },
                },
                {
                  key: "removeAttribute",
                  value: function (e, t, n) {
                    this.delegate.removeAttribute(e, t, n);
                  },
                },
                {
                  key: "addClass",
                  value: function (e, t) {
                    this.delegate.addClass(e, t);
                  },
                },
                {
                  key: "removeClass",
                  value: function (e, t) {
                    this.delegate.removeClass(e, t);
                  },
                },
                {
                  key: "setStyle",
                  value: function (e, t, n, r) {
                    this.delegate.setStyle(e, t, n, r);
                  },
                },
                {
                  key: "removeStyle",
                  value: function (e, t, n) {
                    this.delegate.removeStyle(e, t, n);
                  },
                },
                {
                  key: "setProperty",
                  value: function (e, t, n) {
                    "@" == t.charAt(0) && "@.disabled" == t
                      ? this.disableAnimations(e, !!n)
                      : this.delegate.setProperty(e, t, n);
                  },
                },
                {
                  key: "setValue",
                  value: function (e, t) {
                    this.delegate.setValue(e, t);
                  },
                },
                {
                  key: "listen",
                  value: function (e, t, n) {
                    return this.delegate.listen(e, t, n);
                  },
                },
                {
                  key: "disableAnimations",
                  value: function (e, t) {
                    this.engine.disableAnimations(e, t);
                  },
                },
              ]),
              e
            );
          })(),
          Ny = (function (t) {
            u(r, t);
            var n = c(r);
            function r(e, t, i, o) {
              var a;
              return (
                p(this, r),
                ((a = n.call(this, t, i, o)).factory = e),
                (a.namespaceId = t),
                a
              );
            }
            return (
              g(r, [
                {
                  key: "setProperty",
                  value: function (e, t, n) {
                    "@" == t.charAt(0)
                      ? "." == t.charAt(1) && "@.disabled" == t
                        ? this.disableAnimations(e, (n = void 0 === n || !!n))
                        : this.engine.process(
                            this.namespaceId,
                            e,
                            t.substr(1),
                            n
                          )
                      : this.delegate.setProperty(e, t, n);
                  },
                },
                {
                  key: "listen",
                  value: function (t, n, r) {
                    var i,
                      o,
                      a = this;
                    if ("@" == n.charAt(0)) {
                      var s,
                        u = (function (e) {
                          switch (e) {
                            case "body":
                              return document.body;
                            case "document":
                              return document;
                            case "window":
                              return window;
                            default:
                              return e;
                          }
                        })(t),
                        l = n.substr(1),
                        c = "";
                      return (
                        "@" != l.charAt(0) &&
                          ((o = (i = l).indexOf(".")),
                          (l = (s = e(
                            [i.substring(0, o), i.substr(o + 1)],
                            2
                          ))[0]),
                          (c = s[1])),
                        this.engine.listen(
                          this.namespaceId,
                          u,
                          l,
                          c,
                          function (e) {
                            a.factory.scheduleListenerCallback(
                              e._data || -1,
                              r,
                              e
                            );
                          }
                        )
                      );
                    }
                    return this.delegate.listen(t, n, r);
                  },
                },
              ]),
              r
            );
          })(Dy),
          My =
            (((Oy = (function (e) {
              u(n, e);
              var t = c(n);
              function n(e, r, i) {
                return p(this, n), t.call(this, e.body, r, i);
              }
              return n;
            })(ay)).ɵfac = function (e) {
              return new (e || Oy)(fr(Gu), fr(Vm), fr(Pg));
            }),
            (Oy.ɵprov = Ee({ token: Oy, factory: Oy.ɵfac })),
            Oy),
          jy = [
            {
              provide: Vm,
              useFactory: function () {
                return "function" == typeof Sy() ? new _y() : new by();
              },
            },
            {
              provide: new Jn("AnimationModuleType"),
              useValue: "BrowserAnimations",
            },
            { provide: Pv, useClass: Ey },
            {
              provide: Pg,
              useFactory: function () {
                return new Dg();
              },
            },
            { provide: ay, useClass: My },
            {
              provide: xa,
              useFactory: function (e, t, n) {
                return new Py(e, t, n);
              },
              deps: [sc, ay, vu],
            },
          ],
          Fy =
            (((Ry = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || Ry)();
            }),
            (Ry.ɵmod = st({ type: Ry })),
            (Ry.ɵinj = Te({ providers: jy, imports: [kc] })),
            Ry),
          Ly = function e() {
            p(this, e);
          },
          Wy = function e() {
            p(this, e);
          },
          zy = (function () {
            function e(t) {
              var n = this;
              p(this, e),
                (this.normalizedNames = new Map()),
                (this.lazyUpdate = null),
                t
                  ? (this.lazyInit =
                      "string" == typeof t
                        ? function () {
                            (n.headers = new Map()),
                              t.split("\n").forEach(function (e) {
                                var t = e.indexOf(":");
                                if (t > 0) {
                                  var r = e.slice(0, t),
                                    i = r.toLowerCase(),
                                    o = e.slice(t + 1).trim();
                                  n.maybeSetNormalizedName(r, i),
                                    n.headers.has(i)
                                      ? n.headers.get(i).push(o)
                                      : n.headers.set(i, [o]);
                                }
                              });
                          }
                        : function () {
                            (n.headers = new Map()),
                              Object.keys(t).forEach(function (e) {
                                var r = t[e],
                                  i = e.toLowerCase();
                                "string" == typeof r && (r = [r]),
                                  r.length > 0 &&
                                    (n.headers.set(i, r),
                                    n.maybeSetNormalizedName(e, i));
                              });
                          })
                  : (this.headers = new Map());
            }
            return (
              g(e, [
                {
                  key: "has",
                  value: function (e) {
                    return this.init(), this.headers.has(e.toLowerCase());
                  },
                },
                {
                  key: "get",
                  value: function (e) {
                    this.init();
                    var t = this.headers.get(e.toLowerCase());
                    return t && t.length > 0 ? t[0] : null;
                  },
                },
                {
                  key: "keys",
                  value: function () {
                    return (
                      this.init(), Array.from(this.normalizedNames.values())
                    );
                  },
                },
                {
                  key: "getAll",
                  value: function (e) {
                    return (
                      this.init(), this.headers.get(e.toLowerCase()) || null
                    );
                  },
                },
                {
                  key: "append",
                  value: function (e, t) {
                    return this.clone({ name: e, value: t, op: "a" });
                  },
                },
                {
                  key: "set",
                  value: function (e, t) {
                    return this.clone({ name: e, value: t, op: "s" });
                  },
                },
                {
                  key: "delete",
                  value: function (e, t) {
                    return this.clone({ name: e, value: t, op: "d" });
                  },
                },
                {
                  key: "maybeSetNormalizedName",
                  value: function (e, t) {
                    this.normalizedNames.has(t) ||
                      this.normalizedNames.set(t, e);
                  },
                },
                {
                  key: "init",
                  value: function () {
                    var t = this;
                    this.lazyInit &&
                      (this.lazyInit instanceof e
                        ? this.copyFrom(this.lazyInit)
                        : this.lazyInit(),
                      (this.lazyInit = null),
                      this.lazyUpdate &&
                        (this.lazyUpdate.forEach(function (e) {
                          return t.applyUpdate(e);
                        }),
                        (this.lazyUpdate = null)));
                  },
                },
                {
                  key: "copyFrom",
                  value: function (e) {
                    var t = this;
                    e.init(),
                      Array.from(e.headers.keys()).forEach(function (n) {
                        t.headers.set(n, e.headers.get(n)),
                          t.normalizedNames.set(n, e.normalizedNames.get(n));
                      });
                  },
                },
                {
                  key: "clone",
                  value: function (t) {
                    var n = new e();
                    return (
                      (n.lazyInit =
                        this.lazyInit && this.lazyInit instanceof e
                          ? this.lazyInit
                          : this),
                      (n.lazyUpdate = (this.lazyUpdate || []).concat([t])),
                      n
                    );
                  },
                },
                {
                  key: "applyUpdate",
                  value: function (e) {
                    var t = e.name.toLowerCase();
                    switch (e.op) {
                      case "a":
                      case "s":
                        var r = e.value;
                        if (("string" == typeof r && (r = [r]), 0 === r.length))
                          return;
                        this.maybeSetNormalizedName(e.name, t);
                        var i =
                          ("a" === e.op ? this.headers.get(t) : void 0) || [];
                        i.push.apply(i, n(r)), this.headers.set(t, i);
                        break;
                      case "d":
                        var o = e.value;
                        if (o) {
                          var a = this.headers.get(t);
                          if (!a) return;
                          0 ===
                          (a = a.filter(function (e) {
                            return -1 === o.indexOf(e);
                          })).length
                            ? (this.headers.delete(t),
                              this.normalizedNames.delete(t))
                            : this.headers.set(t, a);
                        } else
                          this.headers.delete(t),
                            this.normalizedNames.delete(t);
                    }
                  },
                },
                {
                  key: "forEach",
                  value: function (e) {
                    var t = this;
                    this.init(),
                      Array.from(this.normalizedNames.keys()).forEach(function (
                        n
                      ) {
                        return e(t.normalizedNames.get(n), t.headers.get(n));
                      });
                  },
                },
              ]),
              e
            );
          })(),
          qy = (function () {
            function e() {
              p(this, e);
            }
            return (
              g(e, [
                {
                  key: "encodeKey",
                  value: function (e) {
                    return Hy(e);
                  },
                },
                {
                  key: "encodeValue",
                  value: function (e) {
                    return Hy(e);
                  },
                },
                {
                  key: "decodeKey",
                  value: function (e) {
                    return decodeURIComponent(e);
                  },
                },
                {
                  key: "decodeValue",
                  value: function (e) {
                    return decodeURIComponent(e);
                  },
                },
              ]),
              e
            );
          })();
        function Hy(e) {
          return encodeURIComponent(e)
            .replace(/%40/gi, "@")
            .replace(/%3A/gi, ":")
            .replace(/%24/gi, "$")
            .replace(/%2C/gi, ",")
            .replace(/%3B/gi, ";")
            .replace(/%2B/gi, "+")
            .replace(/%3D/gi, "=")
            .replace(/%3F/gi, "?")
            .replace(/%2F/gi, "/");
        }
        var Uy = (function () {
          function t() {
            var n = this,
              r =
                arguments.length > 0 && void 0 !== arguments[0]
                  ? arguments[0]
                  : {};
            if (
              (p(this, t),
              (this.updates = null),
              (this.cloneFrom = null),
              (this.encoder = r.encoder || new qy()),
              r.fromString)
            ) {
              if (r.fromObject)
                throw new Error(
                  "Cannot specify both fromString and fromObject."
                );
              this.map = (function (t, n) {
                var r = new Map();
                return (
                  t.length > 0 &&
                    t
                      .replace(/^\?/, "")
                      .split("&")
                      .forEach(function (t) {
                        var i = t.indexOf("="),
                          o = e(
                            -1 == i
                              ? [n.decodeKey(t), ""]
                              : [
                                  n.decodeKey(t.slice(0, i)),
                                  n.decodeValue(t.slice(i + 1)),
                                ],
                            2
                          ),
                          a = o[0],
                          s = o[1],
                          u = r.get(a) || [];
                        u.push(s), r.set(a, u);
                      }),
                  r
                );
              })(r.fromString, this.encoder);
            } else
              r.fromObject
                ? ((this.map = new Map()),
                  Object.keys(r.fromObject).forEach(function (e) {
                    var t = r.fromObject[e];
                    n.map.set(e, Array.isArray(t) ? t : [t]);
                  }))
                : (this.map = null);
          }
          return (
            g(t, [
              {
                key: "has",
                value: function (e) {
                  return this.init(), this.map.has(e);
                },
              },
              {
                key: "get",
                value: function (e) {
                  this.init();
                  var t = this.map.get(e);
                  return t ? t[0] : null;
                },
              },
              {
                key: "getAll",
                value: function (e) {
                  return this.init(), this.map.get(e) || null;
                },
              },
              {
                key: "keys",
                value: function () {
                  return this.init(), Array.from(this.map.keys());
                },
              },
              {
                key: "append",
                value: function (e, t) {
                  return this.clone({ param: e, value: t, op: "a" });
                },
              },
              {
                key: "appendAll",
                value: function (e) {
                  var t = [];
                  return (
                    Object.keys(e).forEach(function (n) {
                      var r = e[n];
                      Array.isArray(r)
                        ? r.forEach(function (e) {
                            t.push({ param: n, value: e, op: "a" });
                          })
                        : t.push({ param: n, value: r, op: "a" });
                    }),
                    this.clone(t)
                  );
                },
              },
              {
                key: "set",
                value: function (e, t) {
                  return this.clone({ param: e, value: t, op: "s" });
                },
              },
              {
                key: "delete",
                value: function (e, t) {
                  return this.clone({ param: e, value: t, op: "d" });
                },
              },
              {
                key: "toString",
                value: function () {
                  var e = this;
                  return (
                    this.init(),
                    this.keys()
                      .map(function (t) {
                        var n = e.encoder.encodeKey(t);
                        return e.map
                          .get(t)
                          .map(function (t) {
                            return n + "=" + e.encoder.encodeValue(t);
                          })
                          .join("&");
                      })
                      .filter(function (e) {
                        return "" !== e;
                      })
                      .join("&")
                  );
                },
              },
              {
                key: "clone",
                value: function (e) {
                  var n = new t({ encoder: this.encoder });
                  return (
                    (n.cloneFrom = this.cloneFrom || this),
                    (n.updates = (this.updates || []).concat(e)),
                    n
                  );
                },
              },
              {
                key: "init",
                value: function () {
                  var e = this;
                  null === this.map && (this.map = new Map()),
                    null !== this.cloneFrom &&
                      (this.cloneFrom.init(),
                      this.cloneFrom.keys().forEach(function (t) {
                        return e.map.set(t, e.cloneFrom.map.get(t));
                      }),
                      this.updates.forEach(function (t) {
                        switch (t.op) {
                          case "a":
                          case "s":
                            var n =
                              ("a" === t.op ? e.map.get(t.param) : void 0) ||
                              [];
                            n.push(t.value), e.map.set(t.param, n);
                            break;
                          case "d":
                            if (void 0 === t.value) {
                              e.map.delete(t.param);
                              break;
                            }
                            var r = e.map.get(t.param) || [],
                              i = r.indexOf(t.value);
                            -1 !== i && r.splice(i, 1),
                              r.length > 0
                                ? e.map.set(t.param, r)
                                : e.map.delete(t.param);
                        }
                      }),
                      (this.cloneFrom = this.updates = null));
                },
              },
            ]),
            t
          );
        })();
        function Vy(e) {
          return "undefined" != typeof ArrayBuffer && e instanceof ArrayBuffer;
        }
        function By(e) {
          return "undefined" != typeof Blob && e instanceof Blob;
        }
        function Qy(e) {
          return "undefined" != typeof FormData && e instanceof FormData;
        }
        var Gy = (function () {
            function e(t, n, r, i) {
              var o;
              if (
                (p(this, e),
                (this.url = n),
                (this.body = null),
                (this.reportProgress = !1),
                (this.withCredentials = !1),
                (this.responseType = "json"),
                (this.method = t.toUpperCase()),
                (function (e) {
                  switch (e) {
                    case "DELETE":
                    case "GET":
                    case "HEAD":
                    case "OPTIONS":
                    case "JSONP":
                      return !1;
                    default:
                      return !0;
                  }
                })(this.method) || i
                  ? ((this.body = void 0 !== r ? r : null), (o = i))
                  : (o = r),
                o &&
                  ((this.reportProgress = !!o.reportProgress),
                  (this.withCredentials = !!o.withCredentials),
                  o.responseType && (this.responseType = o.responseType),
                  o.headers && (this.headers = o.headers),
                  o.params && (this.params = o.params)),
                this.headers || (this.headers = new zy()),
                this.params)
              ) {
                var a = this.params.toString();
                if (0 === a.length) this.urlWithParams = n;
                else {
                  var s = n.indexOf("?");
                  this.urlWithParams =
                    n + (-1 === s ? "?" : s < n.length - 1 ? "&" : "") + a;
                }
              } else (this.params = new Uy()), (this.urlWithParams = n);
            }
            return (
              g(e, [
                {
                  key: "serializeBody",
                  value: function () {
                    return null === this.body
                      ? null
                      : Vy(this.body) ||
                        By(this.body) ||
                        Qy(this.body) ||
                        "string" == typeof this.body
                      ? this.body
                      : this.body instanceof Uy
                      ? this.body.toString()
                      : "object" == typeof this.body ||
                        "boolean" == typeof this.body ||
                        Array.isArray(this.body)
                      ? JSON.stringify(this.body)
                      : this.body.toString();
                  },
                },
                {
                  key: "detectContentTypeHeader",
                  value: function () {
                    return null === this.body || Qy(this.body)
                      ? null
                      : By(this.body)
                      ? this.body.type || null
                      : Vy(this.body)
                      ? null
                      : "string" == typeof this.body
                      ? "text/plain"
                      : this.body instanceof Uy
                      ? "application/x-www-form-urlencoded;charset=UTF-8"
                      : "object" == typeof this.body ||
                        "number" == typeof this.body ||
                        "boolean" == typeof this.body
                      ? "application/json"
                      : null;
                  },
                },
                {
                  key: "clone",
                  value: function () {
                    var t =
                        arguments.length > 0 && void 0 !== arguments[0]
                          ? arguments[0]
                          : {},
                      n = t.method || this.method,
                      r = t.url || this.url,
                      i = t.responseType || this.responseType,
                      o = void 0 !== t.body ? t.body : this.body,
                      a =
                        void 0 !== t.withCredentials
                          ? t.withCredentials
                          : this.withCredentials,
                      s =
                        void 0 !== t.reportProgress
                          ? t.reportProgress
                          : this.reportProgress,
                      u = t.headers || this.headers,
                      l = t.params || this.params;
                    return (
                      void 0 !== t.setHeaders &&
                        (u = Object.keys(t.setHeaders).reduce(function (e, n) {
                          return e.set(n, t.setHeaders[n]);
                        }, u)),
                      t.setParams &&
                        (l = Object.keys(t.setParams).reduce(function (e, n) {
                          return e.set(n, t.setParams[n]);
                        }, l)),
                      new e(n, r, o, {
                        params: l,
                        headers: u,
                        reportProgress: s,
                        responseType: i,
                        withCredentials: a,
                      })
                    );
                  },
                },
              ]),
              e
            );
          })(),
          Ky = (function (e) {
            return (
              (e[(e.Sent = 0)] = "Sent"),
              (e[(e.UploadProgress = 1)] = "UploadProgress"),
              (e[(e.ResponseHeader = 2)] = "ResponseHeader"),
              (e[(e.DownloadProgress = 3)] = "DownloadProgress"),
              (e[(e.Response = 4)] = "Response"),
              (e[(e.User = 5)] = "User"),
              e
            );
          })({}),
          Zy = function e(t) {
            var n =
                arguments.length > 1 && void 0 !== arguments[1]
                  ? arguments[1]
                  : 200,
              r =
                arguments.length > 2 && void 0 !== arguments[2]
                  ? arguments[2]
                  : "OK";
            p(this, e),
              (this.headers = t.headers || new zy()),
              (this.status = void 0 !== t.status ? t.status : n),
              (this.statusText = t.statusText || r),
              (this.url = t.url || null),
              (this.ok = this.status >= 200 && this.status < 300);
          },
          Jy = (function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              var e,
                r =
                  arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : {};
              return (
                p(this, n), ((e = t.call(this, r)).type = Ky.ResponseHeader), e
              );
            }
            return (
              g(n, [
                {
                  key: "clone",
                  value: function () {
                    var e =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : {};
                    return new n({
                      headers: e.headers || this.headers,
                      status: void 0 !== e.status ? e.status : this.status,
                      statusText: e.statusText || this.statusText,
                      url: e.url || this.url || void 0,
                    });
                  },
                },
              ]),
              n
            );
          })(Zy),
          $y = (function (e) {
            u(n, e);
            var t = c(n);
            function n() {
              var e,
                r =
                  arguments.length > 0 && void 0 !== arguments[0]
                    ? arguments[0]
                    : {};
              return (
                p(this, n),
                ((e = t.call(this, r)).type = Ky.Response),
                (e.body = void 0 !== r.body ? r.body : null),
                e
              );
            }
            return (
              g(n, [
                {
                  key: "clone",
                  value: function () {
                    var e =
                      arguments.length > 0 && void 0 !== arguments[0]
                        ? arguments[0]
                        : {};
                    return new n({
                      body: void 0 !== e.body ? e.body : this.body,
                      headers: e.headers || this.headers,
                      status: void 0 !== e.status ? e.status : this.status,
                      statusText: e.statusText || this.statusText,
                      url: e.url || this.url || void 0,
                    });
                  },
                },
              ]),
              n
            );
          })(Zy),
          Yy = (function (e) {
            u(n, e);
            var t = c(n);
            function n(e) {
              var r;
              return (
                p(this, n),
                ((r = t.call(this, e, 0, "Unknown Error")).name =
                  "HttpErrorResponse"),
                (r.ok = !1),
                (r.message =
                  r.status >= 200 && r.status < 300
                    ? "Http failure during parsing for ".concat(
                        e.url || "(unknown url)"
                      )
                    : "Http failure response for "
                        .concat(e.url || "(unknown url)", ": ")
                        .concat(e.status, " ")
                        .concat(e.statusText)),
                (r.error = e.error || null),
                r
              );
            }
            return n;
          })(Zy);
        function Xy(e, t) {
          return {
            body: t,
            headers: e.headers,
            observe: e.observe,
            params: e.params,
            reportProgress: e.reportProgress,
            responseType: e.responseType,
            withCredentials: e.withCredentials,
          };
        }
        var ek,
          tk,
          nk,
          rk,
          ik,
          ok,
          ak,
          sk,
          uk,
          lk =
            (((ek = (function () {
              function e(t) {
                p(this, e), (this.handler = t);
              }
              return (
                g(e, [
                  {
                    key: "request",
                    value: function (e, t) {
                      var n,
                        r,
                        i,
                        o = this,
                        a =
                          arguments.length > 2 && void 0 !== arguments[2]
                            ? arguments[2]
                            : {};
                      e instanceof Gy
                        ? (n = e)
                        : ((r =
                            a.headers instanceof zy
                              ? a.headers
                              : new zy(a.headers)),
                          a.params &&
                            (i =
                              a.params instanceof Uy
                                ? a.params
                                : new Uy({ fromObject: a.params })),
                          (n = new Gy(e, t, void 0 !== a.body ? a.body : null, {
                            headers: r,
                            params: i,
                            reportProgress: a.reportProgress,
                            responseType: a.responseType || "json",
                            withCredentials: a.withCredentials,
                          })));
                      var s = Ec(n).pipe(
                        ch(function (e) {
                          return o.handler.handle(e);
                        })
                      );
                      if (e instanceof Gy || "events" === a.observe) return s;
                      var u = s.pipe(
                        ih(function (e) {
                          return e instanceof $y;
                        })
                      );
                      switch (a.observe || "body") {
                        case "body":
                          switch (n.responseType) {
                            case "arraybuffer":
                              return u.pipe(
                                H(function (e) {
                                  if (
                                    null !== e.body &&
                                    !(e.body instanceof ArrayBuffer)
                                  )
                                    throw new Error(
                                      "Response is not an ArrayBuffer."
                                    );
                                  return e.body;
                                })
                              );
                            case "blob":
                              return u.pipe(
                                H(function (e) {
                                  if (
                                    null !== e.body &&
                                    !(e.body instanceof Blob)
                                  )
                                    throw new Error("Response is not a Blob.");
                                  return e.body;
                                })
                              );
                            case "text":
                              return u.pipe(
                                H(function (e) {
                                  if (
                                    null !== e.body &&
                                    "string" != typeof e.body
                                  )
                                    throw new Error(
                                      "Response is not a string."
                                    );
                                  return e.body;
                                })
                              );
                            case "json":
                            default:
                              return u.pipe(
                                H(function (e) {
                                  return e.body;
                                })
                              );
                          }
                        case "response":
                          return u;
                        default:
                          throw new Error(
                            "Unreachable: unhandled observe type ".concat(
                              a.observe,
                              "}"
                            )
                          );
                      }
                    },
                  },
                  {
                    key: "delete",
                    value: function (e) {
                      var t =
                        arguments.length > 1 && void 0 !== arguments[1]
                          ? arguments[1]
                          : {};
                      return this.request("DELETE", e, t);
                    },
                  },
                  {
                    key: "get",
                    value: function (e) {
                      var t =
                        arguments.length > 1 && void 0 !== arguments[1]
                          ? arguments[1]
                          : {};
                      return this.request("GET", e, t);
                    },
                  },
                  {
                    key: "head",
                    value: function (e) {
                      var t =
                        arguments.length > 1 && void 0 !== arguments[1]
                          ? arguments[1]
                          : {};
                      return this.request("HEAD", e, t);
                    },
                  },
                  {
                    key: "jsonp",
                    value: function (e, t) {
                      return this.request("JSONP", e, {
                        params: new Uy().append(t, "JSONP_CALLBACK"),
                        observe: "body",
                        responseType: "json",
                      });
                    },
                  },
                  {
                    key: "options",
                    value: function (e) {
                      var t =
                        arguments.length > 1 && void 0 !== arguments[1]
                          ? arguments[1]
                          : {};
                      return this.request("OPTIONS", e, t);
                    },
                  },
                  {
                    key: "patch",
                    value: function (e, t) {
                      var n =
                        arguments.length > 2 && void 0 !== arguments[2]
                          ? arguments[2]
                          : {};
                      return this.request("PATCH", e, Xy(n, t));
                    },
                  },
                  {
                    key: "post",
                    value: function (e, t) {
                      var n =
                        arguments.length > 2 && void 0 !== arguments[2]
                          ? arguments[2]
                          : {};
                      return this.request("POST", e, Xy(n, t));
                    },
                  },
                  {
                    key: "put",
                    value: function (e, t) {
                      var n =
                        arguments.length > 2 && void 0 !== arguments[2]
                          ? arguments[2]
                          : {};
                      return this.request("PUT", e, Xy(n, t));
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || ek)(fr(Ly));
            }),
            (ek.ɵprov = Ee({ token: ek, factory: ek.ɵfac })),
            ek),
          ck = (function () {
            function e(t, n) {
              p(this, e), (this.next = t), (this.interceptor = n);
            }
            return (
              g(e, [
                {
                  key: "handle",
                  value: function (e) {
                    return this.interceptor.intercept(e, this.next);
                  },
                },
              ]),
              e
            );
          })(),
          hk = new Jn("HTTP_INTERCEPTORS"),
          fk =
            (((tk = (function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "intercept",
                    value: function (e, t) {
                      return t.handle(e);
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || tk)();
            }),
            (tk.ɵprov = Ee({ token: tk, factory: tk.ɵfac })),
            tk),
          dk = /^\)\]\}',?\n/,
          vk = function e() {
            p(this, e);
          },
          pk =
            (((rk = (function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, [
                  {
                    key: "build",
                    value: function () {
                      return new XMLHttpRequest();
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || rk)();
            }),
            (rk.ɵprov = Ee({ token: rk, factory: rk.ɵfac })),
            rk),
          mk =
            (((nk = (function () {
              function e(t) {
                p(this, e), (this.xhrFactory = t);
              }
              return (
                g(e, [
                  {
                    key: "handle",
                    value: function (e) {
                      var t = this;
                      if ("JSONP" === e.method)
                        throw new Error(
                          "Attempted to construct Jsonp request without HttpClientJsonpModule installed."
                        );
                      return new D(function (n) {
                        var r = t.xhrFactory.build();
                        if (
                          (r.open(e.method, e.urlWithParams),
                          e.withCredentials && (r.withCredentials = !0),
                          e.headers.forEach(function (e, t) {
                            return r.setRequestHeader(e, t.join(","));
                          }),
                          e.headers.has("Accept") ||
                            r.setRequestHeader(
                              "Accept",
                              "application/json, text/plain, */*"
                            ),
                          !e.headers.has("Content-Type"))
                        ) {
                          var i = e.detectContentTypeHeader();
                          null !== i && r.setRequestHeader("Content-Type", i);
                        }
                        if (e.responseType) {
                          var o = e.responseType.toLowerCase();
                          r.responseType = "json" !== o ? o : "text";
                        }
                        var a = e.serializeBody(),
                          s = null,
                          u = function () {
                            if (null !== s) return s;
                            var t = 1223 === r.status ? 204 : r.status,
                              n = r.statusText || "OK",
                              i = new zy(r.getAllResponseHeaders()),
                              o =
                                (function (e) {
                                  return "responseURL" in e && e.responseURL
                                    ? e.responseURL
                                    : /^X-Request-URL:/m.test(
                                        e.getAllResponseHeaders()
                                      )
                                    ? e.getResponseHeader("X-Request-URL")
                                    : null;
                                })(r) || e.url;
                            return (s = new Jy({
                              headers: i,
                              status: t,
                              statusText: n,
                              url: o,
                            }));
                          },
                          l = function () {
                            var t = u(),
                              i = t.headers,
                              o = t.status,
                              a = t.statusText,
                              s = t.url,
                              l = null;
                            204 !== o &&
                              (l =
                                void 0 === r.response
                                  ? r.responseText
                                  : r.response),
                              0 === o && (o = l ? 200 : 0);
                            var c = o >= 200 && o < 300;
                            if (
                              "json" === e.responseType &&
                              "string" == typeof l
                            ) {
                              var h = l;
                              l = l.replace(dk, "");
                              try {
                                l = "" !== l ? JSON.parse(l) : null;
                              } catch (f) {
                                (l = h),
                                  c && ((c = !1), (l = { error: f, text: l }));
                              }
                            }
                            c
                              ? (n.next(
                                  new $y({
                                    body: l,
                                    headers: i,
                                    status: o,
                                    statusText: a,
                                    url: s || void 0,
                                  })
                                ),
                                n.complete())
                              : n.error(
                                  new Yy({
                                    error: l,
                                    headers: i,
                                    status: o,
                                    statusText: a,
                                    url: s || void 0,
                                  })
                                );
                          },
                          c = function (e) {
                            var t = u().url,
                              i = new Yy({
                                error: e,
                                status: r.status || 0,
                                statusText: r.statusText || "Unknown Error",
                                url: t || void 0,
                              });
                            n.error(i);
                          },
                          h = !1,
                          f = function (t) {
                            h || (n.next(u()), (h = !0));
                            var i = {
                              type: Ky.DownloadProgress,
                              loaded: t.loaded,
                            };
                            t.lengthComputable && (i.total = t.total),
                              "text" === e.responseType &&
                                r.responseText &&
                                (i.partialText = r.responseText),
                              n.next(i);
                          },
                          d = function (e) {
                            var t = {
                              type: Ky.UploadProgress,
                              loaded: e.loaded,
                            };
                            e.lengthComputable && (t.total = e.total),
                              n.next(t);
                          };
                        return (
                          r.addEventListener("load", l),
                          r.addEventListener("error", c),
                          r.addEventListener("timeout", c),
                          r.addEventListener("abort", c),
                          e.reportProgress &&
                            (r.addEventListener("progress", f),
                            null !== a &&
                              r.upload &&
                              r.upload.addEventListener("progress", d)),
                          r.send(a),
                          n.next({ type: Ky.Sent }),
                          function () {
                            r.removeEventListener("error", c),
                              r.removeEventListener("abort", c),
                              r.removeEventListener("load", l),
                              r.removeEventListener("timeout", c),
                              e.reportProgress &&
                                (r.removeEventListener("progress", f),
                                null !== a &&
                                  r.upload &&
                                  r.upload.removeEventListener("progress", d)),
                              r.readyState !== r.DONE && r.abort();
                          }
                        );
                      });
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || nk)(fr(vk));
            }),
            (nk.ɵprov = Ee({ token: nk, factory: nk.ɵfac })),
            nk),
          gk = new Jn("XSRF_COOKIE_NAME"),
          yk = new Jn("XSRF_HEADER_NAME"),
          kk = function e() {
            p(this, e);
          },
          bk =
            (((uk = (function () {
              function e(t, n, r) {
                p(this, e),
                  (this.doc = t),
                  (this.platform = n),
                  (this.cookieName = r),
                  (this.lastCookieString = ""),
                  (this.lastToken = null),
                  (this.parseCount = 0);
              }
              return (
                g(e, [
                  {
                    key: "getToken",
                    value: function () {
                      if ("server" === this.platform) return null;
                      var e = this.doc.cookie || "";
                      return (
                        e !== this.lastCookieString &&
                          (this.parseCount++,
                          (this.lastToken = bl(e, this.cookieName)),
                          (this.lastCookieString = e)),
                        this.lastToken
                      );
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || uk)(fr(Gu), fr(Xs), fr(gk));
            }),
            (uk.ɵprov = Ee({ token: uk, factory: uk.ɵfac })),
            uk),
          wk =
            (((sk = (function () {
              function e(t, n) {
                p(this, e), (this.tokenService = t), (this.headerName = n);
              }
              return (
                g(e, [
                  {
                    key: "intercept",
                    value: function (e, t) {
                      var n = e.url.toLowerCase();
                      if (
                        "GET" === e.method ||
                        "HEAD" === e.method ||
                        n.startsWith("http://") ||
                        n.startsWith("https://")
                      )
                        return t.handle(e);
                      var r = this.tokenService.getToken();
                      return (
                        null === r ||
                          e.headers.has(this.headerName) ||
                          (e = e.clone({
                            headers: e.headers.set(this.headerName, r),
                          })),
                        t.handle(e)
                      );
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || sk)(fr(kk), fr(yk));
            }),
            (sk.ɵprov = Ee({ token: sk, factory: sk.ɵfac })),
            sk),
          _k =
            (((ak = (function () {
              function e(t, n) {
                p(this, e),
                  (this.backend = t),
                  (this.injector = n),
                  (this.chain = null);
              }
              return (
                g(e, [
                  {
                    key: "handle",
                    value: function (e) {
                      if (null === this.chain) {
                        var t = this.injector.get(hk, []);
                        this.chain = t.reduceRight(function (e, t) {
                          return new ck(e, t);
                        }, this.backend);
                      }
                      return this.chain.handle(e);
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || ak)(fr(Wy), fr(Io));
            }),
            (ak.ɵprov = Ee({ token: ak, factory: ak.ɵfac })),
            ak),
          Sk =
            (((ok = (function () {
              function e() {
                p(this, e);
              }
              return (
                g(e, null, [
                  {
                    key: "disable",
                    value: function () {
                      return {
                        ngModule: e,
                        providers: [{ provide: wk, useClass: fk }],
                      };
                    },
                  },
                  {
                    key: "withOptions",
                    value: function () {
                      var t =
                        arguments.length > 0 && void 0 !== arguments[0]
                          ? arguments[0]
                          : {};
                      return {
                        ngModule: e,
                        providers: [
                          t.cookieName
                            ? { provide: gk, useValue: t.cookieName }
                            : [],
                          t.headerName
                            ? { provide: yk, useValue: t.headerName }
                            : [],
                        ],
                      };
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || ok)();
            }),
            (ok.ɵmod = st({ type: ok })),
            (ok.ɵinj = Te({
              providers: [
                wk,
                { provide: hk, useExisting: wk, multi: !0 },
                { provide: kk, useClass: bk },
                { provide: gk, useValue: "XSRF-TOKEN" },
                { provide: yk, useValue: "X-XSRF-TOKEN" },
              ],
            })),
            ok),
          Ck =
            (((ik = function e() {
              p(this, e);
            }).ɵfac = function (e) {
              return new (e || ik)();
            }),
            (ik.ɵmod = st({ type: ik })),
            (ik.ɵinj = Te({
              providers: [
                lk,
                { provide: Ly, useClass: _k },
                mk,
                { provide: Wy, useExisting: mk },
                pk,
                { provide: vk, useExisting: pk },
              ],
              imports: [
                [
                  Sk.withOptions({
                    cookieName: "XSRF-TOKEN",
                    headerName: "X-XSRF-TOKEN",
                  }),
                ],
              ],
            })),
            ik),
          Ek = ["nsmContent"],
          Tk = ["nsmDialog"],
          xk = ["nsmOverlay"],
          Ik = ["dynamicContent"];
        function Ak(e, t) {}
        function Ok(e, t) {
          if (1 & e) {
            var n = Bo();
            Ho(0, "button", 10),
              Go("click", function () {
                return Ut(n), Jo(2).close();
              }),
              (Wt.lFrame.currentNamespace = "http://www.w3.org/2000/svg"),
              Ho(1, "svg", 11),
              Ho(2, "g"),
              Vo(3, "path", 12),
              Uo(),
              Ho(4, "g"),
              Vo(5, "path", 13),
              Uo(),
              Uo(),
              Uo();
          }
        }
        var Rk = function (e, t) {
            return { "transparent": e, "overlay": !0, "nsm-overlay-open": t };
          },
          Pk = function (e, t) {
            return ["nsm-dialog", e, t];
          };
        function Dk(e, t) {
          if (1 & e) {
            var n = Bo();
            Ho(0, "div", 1, 2),
              Go("mousedown", function (e) {
                return Ut(n), Jo().dismiss(e);
              }),
              Ho(2, "div", 3, 4),
              Ho(4, "div", 5, 6),
              Ho(6, "div", 7),
              Fo(7, Ak, 0, 0, "ng-template", null, 8, Qs),
              Xo(9),
              Uo(),
              Fo(10, Ok, 6, 0, "button", 9),
              Uo(),
              Uo(),
              Uo();
          }
          if (2 & e) {
            var r = Jo();
            na("z-index", r.visible ? r.layerPosition - 1 : -1),
              zo("ngClass", Cs(11, Rk, !r.backdrop, r.openedClass)),
              yi(2),
              na("z-index", r.visible ? r.layerPosition : -1),
              zo(
                "ngClass",
                Cs(
                  14,
                  Pk,
                  r.customClass,
                  r.openedClass ? "nsm-dialog-open" : "nsm-dialog-close"
                )
              ),
              jo("aria-hidden", !r.openedClass)("aria-label", r.ariaLabel)(
                "aria-labelledby",
                r.ariaLabelledBy
              )("aria-describedby", r.ariaDescribedBy),
              yi(8),
              zo("ngIf", r.closable);
          }
        }
        var Nk,
          Mk,
          jk,
          Fk,
          Lk,
          Wk = ["*"],
          zk =
            (((Lk = (function () {
              function e(t, n, r, i, o) {
                p(this, e),
                  (this._renderer = t),
                  (this._changeDetectorRef = n),
                  (this.componentFactoryResolver = r),
                  (this._document = i),
                  (this._platformId = o),
                  (this.closable = !0),
                  (this.escapable = !0),
                  (this.dismissable = !0),
                  (this.identifier = ""),
                  (this.customClass = "nsm-dialog-animation-fade"),
                  (this.visible = !1),
                  (this.backdrop = !0),
                  (this.force = !0),
                  (this.hideDelay = 500),
                  (this.autostart = !1),
                  (this.target = ""),
                  (this.ariaLabel = null),
                  (this.ariaLabelledBy = null),
                  (this.ariaDescribedBy = null),
                  (this.refocus = !0),
                  (this.visibleChange = new xs()),
                  (this.onClose = new xs()),
                  (this.onCloseFinished = new xs()),
                  (this.onDismiss = new xs()),
                  (this.onDismissFinished = new xs()),
                  (this.onAnyCloseEvent = new xs()),
                  (this.onAnyCloseEventFinished = new xs()),
                  (this.onOpen = new xs()),
                  (this.onOpenFinished = new xs()),
                  (this.onEscape = new xs()),
                  (this.onDataAdded = new xs()),
                  (this.onDataRemoved = new xs()),
                  (this.layerPosition = 1041),
                  (this.overlayVisible = !1),
                  (this.openedClass = !1),
                  (this.createFrom = "html");
              }
              return (
                g(e, [
                  {
                    key: "ngOnInit",
                    value: function () {
                      if (!this.identifier || !this.identifier.length)
                        throw new Error(
                          "identifier field isn\u2019t set. Please set one before calling <ngx-smart-modal> in a template."
                        );
                      this._sendEvent("create");
                    },
                  },
                  {
                    key: "ngAfterViewInit",
                    value: function () {
                      var e = this;
                      if (this.contentComponent) {
                        var t =
                          this.componentFactoryResolver.resolveComponentFactory(
                            this.contentComponent
                          );
                        this.createDynamicContent(
                          this.dynamicContentContainer,
                          t
                        ),
                          this.dynamicContentContainer.changes.subscribe(
                            function (n) {
                              e.createDynamicContent(n, t);
                            }
                          );
                      }
                    },
                  },
                  {
                    key: "ngOnDestroy",
                    value: function () {
                      this._sendEvent("delete");
                    },
                  },
                  {
                    key: "open",
                    value: function (e) {
                      return this._sendEvent("open", { top: e }), this;
                    },
                  },
                  {
                    key: "close",
                    value: function () {
                      return this._sendEvent("close"), this;
                    },
                  },
                  {
                    key: "dismiss",
                    value: function (e) {
                      return this.dismissable &&
                        e.target.classList.contains("overlay")
                        ? (this._sendEvent("dismiss"), this)
                        : this;
                    },
                  },
                  {
                    key: "toggle",
                    value: function (e) {
                      return this._sendEvent("toggle", { top: e }), this;
                    },
                  },
                  {
                    key: "addCustomClass",
                    value: function (e) {
                      return (
                        this.customClass.length
                          ? (this.customClass += " " + e)
                          : (this.customClass = e),
                        this
                      );
                    },
                  },
                  {
                    key: "removeCustomClass",
                    value: function (e) {
                      return (
                        (this.customClass = e
                          ? this.customClass.replace(e, "").trim()
                          : ""),
                        this
                      );
                    },
                  },
                  {
                    key: "isVisible",
                    value: function () {
                      return this.visible;
                    },
                  },
                  {
                    key: "hasData",
                    value: function () {
                      return void 0 !== this._data;
                    },
                  },
                  {
                    key: "setData",
                    value: function (e, t) {
                      return (
                        (!this.hasData() || (this.hasData() && t)) &&
                          ((this._data = e),
                          this.onDataAdded.emit(this._data),
                          this.markForCheck()),
                        this
                      );
                    },
                  },
                  {
                    key: "getData",
                    value: function () {
                      return this._data;
                    },
                  },
                  {
                    key: "removeData",
                    value: function () {
                      return (
                        (this._data = void 0),
                        this.onDataRemoved.emit(!0),
                        this.markForCheck(),
                        this
                      );
                    },
                  },
                  {
                    key: "addBodyClass",
                    value: function () {
                      return (
                        this._renderer.addClass(
                          this._document.body,
                          "dialog-open"
                        ),
                        this
                      );
                    },
                  },
                  {
                    key: "removeBodyClass",
                    value: function () {
                      return (
                        this._renderer.removeClass(
                          this._document.body,
                          "dialog-open"
                        ),
                        this
                      );
                    },
                  },
                  {
                    key: "markForCheck",
                    value: function () {
                      try {
                        this._changeDetectorRef.detectChanges();
                      } catch (e) {}
                      this._changeDetectorRef.markForCheck();
                    },
                  },
                  {
                    key: "targetPlacement",
                    value: function () {
                      if (
                        !(
                          this.isBrowser &&
                          this.nsmDialog.length &&
                          this.nsmContent.length &&
                          this.nsmOverlay.length &&
                          this.target
                        )
                      )
                        return !1;
                      var e = this._document.querySelector(this.target);
                      if (!e) return !1;
                      var t = e.getBoundingClientRect(),
                        n =
                          this.nsmOverlay.first.nativeElement.getBoundingClientRect(),
                        r =
                          this.nsmContent.first.nativeElement.getBoundingClientRect(),
                        i =
                          this.nsmDialog.first.nativeElement.getBoundingClientRect(),
                        o = parseInt(
                          getComputedStyle(this.nsmContent.first.nativeElement)
                            .marginLeft,
                          10
                        ),
                        a = parseInt(
                          getComputedStyle(this.nsmContent.first.nativeElement)
                            .marginTop,
                          10
                        ),
                        s = t.top - i.top - (r.height - t.height) / 2,
                        u = t.left - i.left - (r.width - t.width) / 2;
                      u + i.left + r.width + 2 * o > n.width
                        ? (u = n.width - (i.left + r.width) - 2 * o)
                        : u + i.left < 0 && (u = -i.left),
                        s + i.top + r.height + a > n.height &&
                          (s = n.height - (i.top + r.height) - a),
                        this._renderer.setStyle(
                          this.nsmContent.first.nativeElement,
                          "top",
                          (s < 0 ? 0 : s) + "px"
                        ),
                        this._renderer.setStyle(
                          this.nsmContent.first.nativeElement,
                          "left",
                          u + "px"
                        );
                    },
                  },
                  {
                    key: "_sendEvent",
                    value: function (e, t) {
                      if (!this.isBrowser) return !1;
                      var n = new CustomEvent("ngx-smart-modal." + e, {
                        detail: {
                          extraData: t,
                          instance: { id: this.identifier, modal: this },
                        },
                      });
                      return window.dispatchEvent(n);
                    },
                  },
                  {
                    key: "isBrowser",
                    get: function () {
                      return Dl(this._platformId);
                    },
                  },
                  {
                    key: "createDynamicContent",
                    value: function (e, t) {
                      var n = this;
                      e.forEach(function (e) {
                        e.clear(), e.createComponent(t), n.markForCheck();
                      });
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Lk)(Wo(Ia), Wo($a), Wo(wa), Wo(Gu), Wo(Xs));
            }),
            (Lk.ɵcmp = rt({
              type: Lk,
              selectors: [["ngx-smart-modal"]],
              viewQuery: function (e, t) {
                var n;
                1 & e && (zs(Ek, 1), zs(Tk, 1), zs(xk, 1), zs(Ik, 1, us)),
                  2 & e &&
                    (Ws((n = Hs())) && (t.nsmContent = n),
                    Ws((n = Hs())) && (t.nsmDialog = n),
                    Ws((n = Hs())) && (t.nsmOverlay = n),
                    Ws((n = Hs())) && (t.dynamicContentContainer = n));
              },
              hostBindings: function (e, t) {
                1 & e &&
                  Go(
                    "resize",
                    function () {
                      return t.targetPlacement();
                    },
                    !1,
                    Or
                  );
              },
              inputs: {
                closable: "closable",
                escapable: "escapable",
                dismissable: "dismissable",
                identifier: "identifier",
                customClass: "customClass",
                visible: "visible",
                backdrop: "backdrop",
                force: "force",
                hideDelay: "hideDelay",
                autostart: "autostart",
                target: "target",
                ariaLabel: "ariaLabel",
                ariaLabelledBy: "ariaLabelledBy",
                ariaDescribedBy: "ariaDescribedBy",
                refocus: "refocus",
              },
              outputs: {
                visibleChange: "visibleChange",
                onClose: "onClose",
                onCloseFinished: "onCloseFinished",
                onDismiss: "onDismiss",
                onDismissFinished: "onDismissFinished",
                onAnyCloseEvent: "onAnyCloseEvent",
                onAnyCloseEventFinished: "onAnyCloseEventFinished",
                onOpen: "onOpen",
                onOpenFinished: "onOpenFinished",
                onEscape: "onEscape",
                onDataAdded: "onDataAdded",
                onDataRemoved: "onDataRemoved",
              },
              ngContentSelectors: Wk,
              decls: 1,
              vars: 1,
              consts: [
                [3, "z-index", "ngClass", "mousedown", 4, "ngIf"],
                [3, "ngClass", "mousedown"],
                ["nsmOverlay", ""],
                [3, "ngClass"],
                ["nsmDialog", ""],
                [1, "nsm-content"],
                ["nsmContent", ""],
                [1, "nsm-body"],
                ["dynamicContent", ""],
                [
                  "type",
                  "button",
                  "aria-label",
                  "Close",
                  "class",
                  "nsm-dialog-btn-close",
                  3,
                  "click",
                  4,
                  "ngIf",
                ],
                [
                  "type",
                  "button",
                  "aria-label",
                  "Close",
                  1,
                  "nsm-dialog-btn-close",
                  3,
                  "click",
                ],
                [
                  "xmlns",
                  "http://www.w3.org/2000/svg",
                  "version",
                  "1.1",
                  "id",
                  "Layer_1",
                  "x",
                  "0px",
                  "y",
                  "0px",
                  "viewBox",
                  "0 0 512 512",
                  0,
                  "xml",
                  "space",
                  "preserve",
                  "width",
                  "16px",
                  "height",
                  "16px",
                ],
                [
                  "d",
                  "M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249    C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306    C514.019,27.23,514.019,14.135,505.943,6.058z",
                  "fill",
                  "currentColor",
                ],
                [
                  "d",
                  "M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636    c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z",
                  "fill",
                  "currentColor",
                ],
              ],
              template: function (e, t) {
                1 & e && (Yo(), Fo(0, Dk, 11, 17, "div", 0)),
                  2 & e && zo("ngIf", t.overlayVisible);
              },
              directives: [Il, Cl],
              encapsulation: 2,
            })),
            Lk),
          qk =
            (((Fk = (function () {
              function e() {
                p(this, e), (this._modalStack = []);
              }
              return (
                g(e, [
                  {
                    key: "addModal",
                    value: function (e, t) {
                      if (t) {
                        var n = this._modalStack.findIndex(function (t) {
                          return t.id === e.id;
                        });
                        n > -1
                          ? (this._modalStack[n].modal = e.modal)
                          : this._modalStack.push(e);
                      } else this._modalStack.push(e);
                    },
                  },
                  {
                    key: "getModal",
                    value: function (e) {
                      var t = this._modalStack.find(function (t) {
                        return t.id === e;
                      });
                      if (void 0 !== t) return t.modal;
                      throw new Error(
                        "Cannot find modal with identifier ".concat(e)
                      );
                    },
                  },
                  {
                    key: "getModalStack",
                    value: function () {
                      return this._modalStack;
                    },
                  },
                  {
                    key: "getOpenedModals",
                    value: function () {
                      return this._modalStack.filter(function (e) {
                        return e.modal.visible;
                      });
                    },
                  },
                  {
                    key: "getTopOpenedModal",
                    value: function () {
                      if (!this.getOpenedModals().length)
                        throw new Error("No modal is opened");
                      return this.getOpenedModals()
                        .map(function (e) {
                          return e.modal;
                        })
                        .reduce(function (e, t) {
                          return t.layerPosition > e.layerPosition ? t : e;
                        }, this.getOpenedModals()[0].modal);
                    },
                  },
                  {
                    key: "getHigherIndex",
                    value: function () {
                      return (
                        Math.max.apply(
                          Math,
                          n(
                            this._modalStack.map(function (e) {
                              return e.modal.layerPosition;
                            })
                          ).concat([1041])
                        ) + 1
                      );
                    },
                  },
                  {
                    key: "getModalStackCount",
                    value: function () {
                      return this._modalStack.length;
                    },
                  },
                  {
                    key: "removeModal",
                    value: function (e) {
                      var t = this._modalStack.findIndex(function (t) {
                        return t.id === e;
                      });
                      t > -1 && this._modalStack.splice(t, 1);
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Fk)();
            }),
            (Fk.ɵprov = Ee({ token: Fk, factory: Fk.ɵfac })),
            Fk),
          Hk =
            (((jk = (function () {
              function e(t, n, r, i, o, a, s) {
                var u = this;
                p(this, e),
                  (this._componentFactoryResolver = t),
                  (this._appRef = n),
                  (this._injector = r),
                  (this._modalStack = i),
                  (this.applicationRef = o),
                  (this._document = a),
                  (this._platformId = s),
                  (this._escapeKeyboardEvent = function (e) {
                    if ("Escape" === e.key)
                      try {
                        var t = u.getTopOpenedModal();
                        return (
                          !!t.escapable &&
                          (t.onEscape.emit(t), u.closeLatestModal(), !0)
                        );
                      } catch (n) {
                        return !1;
                      }
                    return !1;
                  }),
                  (this._trapFocusModal = function (e) {
                    if ("Tab" === e.key)
                      try {
                        var t = u.getTopOpenedModal();
                        return (
                          t.nsmDialog.first.nativeElement.contains(
                            document.activeElement
                          ) ||
                            (e.preventDefault(),
                            e.stopPropagation(),
                            t.nsmDialog.first.nativeElement.focus()),
                          !0
                        );
                      } catch (n) {
                        return !1;
                      }
                    return !1;
                  }),
                  this._addEvents();
              }
              return (
                g(e, [
                  {
                    key: "addModal",
                    value: function (e, t) {
                      this._modalStack.addModal(e, t);
                    },
                  },
                  {
                    key: "getModal",
                    value: function (e) {
                      return this._modalStack.getModal(e);
                    },
                  },
                  {
                    key: "get",
                    value: function (e) {
                      return this.getModal(e);
                    },
                  },
                  {
                    key: "open",
                    value: function (e) {
                      var t =
                        arguments.length > 1 &&
                        void 0 !== arguments[1] &&
                        arguments[1];
                      return this._openModal(this.get(e), t);
                    },
                  },
                  {
                    key: "close",
                    value: function (e) {
                      return this._closeModal(this.get(e));
                    },
                  },
                  {
                    key: "closeAll",
                    value: function () {
                      var e = this;
                      this.getOpenedModals().forEach(function (t) {
                        e._closeModal(t.modal);
                      });
                    },
                  },
                  {
                    key: "toggle",
                    value: function (e) {
                      var t =
                        arguments.length > 1 &&
                        void 0 !== arguments[1] &&
                        arguments[1];
                      return this._toggleModal(this.get(e), t);
                    },
                  },
                  {
                    key: "getModalStack",
                    value: function () {
                      return this._modalStack.getModalStack();
                    },
                  },
                  {
                    key: "getOpenedModals",
                    value: function () {
                      return this._modalStack.getOpenedModals();
                    },
                  },
                  {
                    key: "getTopOpenedModal",
                    value: function () {
                      return this._modalStack.getTopOpenedModal();
                    },
                  },
                  {
                    key: "getHigherIndex",
                    value: function () {
                      return this._modalStack.getHigherIndex();
                    },
                  },
                  {
                    key: "getModalStackCount",
                    value: function () {
                      return this._modalStack.getModalStackCount();
                    },
                  },
                  {
                    key: "removeModal",
                    value: function (e) {
                      this._modalStack.removeModal(e);
                    },
                  },
                  {
                    key: "setModalData",
                    value: function (e, t, n) {
                      var r;
                      return !!(r = this.get(t)) && (r.setData(e, n), !0);
                    },
                  },
                  {
                    key: "getModalData",
                    value: function (e) {
                      var t;
                      return (t = this.get(e)) ? t.getData() : null;
                    },
                  },
                  {
                    key: "resetModalData",
                    value: function (e) {
                      if (
                        this._modalStack.getModalStack().find(function (t) {
                          return t.id === e;
                        })
                      ) {
                        var t = this.getModal(e).getData();
                        return this.getModal(e).removeData(), t;
                      }
                      return !1;
                    },
                  },
                  {
                    key: "closeLatestModal",
                    value: function () {
                      this.getTopOpenedModal().close();
                    },
                  },
                  {
                    key: "create",
                    value: function (e, t) {
                      var n =
                        arguments.length > 2 && void 0 !== arguments[2]
                          ? arguments[2]
                          : {};
                      try {
                        return this.getModal(e);
                      } catch (a) {
                        var r =
                            this._componentFactoryResolver.resolveComponentFactory(
                              zk
                            ),
                          i = this._resolveNgContent(t),
                          o = r.create(this._injector, i);
                        return (
                          t instanceof Yn && (o.instance.contentComponent = t),
                          (o.instance.identifier = e),
                          (o.instance.createFrom = "service"),
                          "boolean" == typeof n.closable &&
                            (o.instance.closable = n.closable),
                          "boolean" == typeof n.escapable &&
                            (o.instance.escapable = n.escapable),
                          "boolean" == typeof n.dismissable &&
                            (o.instance.dismissable = n.dismissable),
                          "string" == typeof n.customClass &&
                            (o.instance.customClass = n.customClass),
                          "boolean" == typeof n.backdrop &&
                            (o.instance.backdrop = n.backdrop),
                          "boolean" == typeof n.force &&
                            (o.instance.force = n.force),
                          "number" == typeof n.hideDelay &&
                            (o.instance.hideDelay = n.hideDelay),
                          "boolean" == typeof n.autostart &&
                            (o.instance.autostart = n.autostart),
                          "string" == typeof n.target &&
                            (o.instance.target = n.target),
                          "string" == typeof n.ariaLabel &&
                            (o.instance.ariaLabel = n.ariaLabel),
                          "string" == typeof n.ariaLabelledBy &&
                            (o.instance.ariaLabelledBy = n.ariaLabelledBy),
                          "string" == typeof n.ariaDescribedBy &&
                            (o.instance.ariaDescribedBy = n.ariaDescribedBy),
                          "boolean" == typeof n.refocus &&
                            (o.instance.refocus = n.refocus),
                          this._appRef.attachView(o.hostView),
                          this._document.body.appendChild(
                            o.hostView.rootNodes[0]
                          ),
                          o.instance
                        );
                      }
                    },
                  },
                  {
                    key: "_addEvents",
                    value: function () {
                      var e = this;
                      return (
                        !!this.isBrowser &&
                        (window.addEventListener(
                          "ngx-smart-modal.create",
                          function (t) {
                            e._initModal(t.detail.instance);
                          }
                        ),
                        window.addEventListener(
                          "ngx-smart-modal.delete",
                          function (t) {
                            e._deleteModal(t.detail.instance);
                          }
                        ),
                        window.addEventListener(
                          "ngx-smart-modal.open",
                          function (t) {
                            e._openModal(t.detail.instance.modal, t.detail.top);
                          }
                        ),
                        window.addEventListener(
                          "ngx-smart-modal.toggle",
                          function (t) {
                            e._toggleModal(
                              t.detail.instance.modal,
                              t.detail.top
                            );
                          }
                        ),
                        window.addEventListener(
                          "ngx-smart-modal.close",
                          function (t) {
                            e._closeModal(t.detail.instance.modal);
                          }
                        ),
                        window.addEventListener(
                          "ngx-smart-modal.dismiss",
                          function (t) {
                            e._dismissModal(t.detail.instance.modal);
                          }
                        ),
                        window.addEventListener(
                          "keyup",
                          this._escapeKeyboardEvent
                        ),
                        !0)
                      );
                    },
                  },
                  {
                    key: "_initModal",
                    value: function (e) {
                      (e.modal.layerPosition += this.getModalStackCount()),
                        this.addModal(e, e.modal.force),
                        e.modal.autostart && this.open(e.id);
                    },
                  },
                  {
                    key: "_openModal",
                    value: function (e, t) {
                      return (
                        !e.visible &&
                        ((this.lastElementFocused = document.activeElement),
                        e.escapable &&
                          window.addEventListener(
                            "keyup",
                            this._escapeKeyboardEvent
                          ),
                        e.backdrop &&
                          window.addEventListener(
                            "keydown",
                            this._trapFocusModal
                          ),
                        t && (e.layerPosition = this.getHigherIndex()),
                        e.addBodyClass(),
                        (e.overlayVisible = !0),
                        (e.visible = !0),
                        e.onOpen.emit(e),
                        e.markForCheck(),
                        setTimeout(function () {
                          (e.openedClass = !0),
                            e.target && e.targetPlacement(),
                            e.nsmDialog.first.nativeElement.setAttribute(
                              "role",
                              "dialog"
                            ),
                            e.nsmDialog.first.nativeElement.setAttribute(
                              "tabIndex",
                              "-1"
                            ),
                            e.nsmDialog.first.nativeElement.setAttribute(
                              "aria-modal",
                              "true"
                            ),
                            e.nsmDialog.first.nativeElement.focus(),
                            e.markForCheck(),
                            e.onOpenFinished.emit(e);
                        }),
                        !0)
                      );
                    },
                  },
                  {
                    key: "_toggleModal",
                    value: function (e, t) {
                      return e.visible
                        ? this._closeModal(e)
                        : this._openModal(e, t);
                    },
                  },
                  {
                    key: "_closeModal",
                    value: function (e) {
                      var t = this;
                      return (
                        !!e.openedClass &&
                        ((e.openedClass = !1),
                        e.onClose.emit(e),
                        e.onAnyCloseEvent.emit(e),
                        this.getOpenedModals().length < 2 &&
                          (e.removeBodyClass(),
                          window.removeEventListener(
                            "keyup",
                            this._escapeKeyboardEvent
                          ),
                          window.removeEventListener(
                            "keydown",
                            this._trapFocusModal
                          )),
                        setTimeout(function () {
                          e.visibleChange.emit(e.visible),
                            (e.visible = !1),
                            (e.overlayVisible = !1),
                            e.nsmDialog.first.nativeElement.removeAttribute(
                              "tabIndex"
                            ),
                            e.markForCheck(),
                            e.onCloseFinished.emit(e),
                            e.onAnyCloseEventFinished.emit(e),
                            e.refocus && t.lastElementFocused.focus();
                        }, e.hideDelay),
                        !0)
                      );
                    },
                  },
                  {
                    key: "_dismissModal",
                    value: function (e) {
                      return (
                        !!e.openedClass &&
                        ((e.openedClass = !1),
                        e.onDismiss.emit(e),
                        e.onAnyCloseEvent.emit(e),
                        this.getOpenedModals().length < 2 &&
                          e.removeBodyClass(),
                        setTimeout(function () {
                          (e.visible = !1),
                            e.visibleChange.emit(e.visible),
                            (e.overlayVisible = !1),
                            e.markForCheck(),
                            e.onDismissFinished.emit(e),
                            e.onAnyCloseEventFinished.emit(e);
                        }, e.hideDelay),
                        !0)
                      );
                    },
                  },
                  {
                    key: "_deleteModal",
                    value: function (e) {
                      this.removeModal(e.id),
                        this.getModalStack().length ||
                          e.modal.removeBodyClass();
                    },
                  },
                  {
                    key: "_resolveNgContent",
                    value: function (e) {
                      if ("string" == typeof e)
                        return [[this._document.createTextNode(e)]];
                      if (e instanceof ns) {
                        var t = e.createEmbeddedView(null);
                        return this.applicationRef.attachView(t), [t.rootNodes];
                      }
                      return [];
                    },
                  },
                  {
                    key: "isBrowser",
                    get: function () {
                      return Dl(this._platformId);
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || jk)(
                fr(wa),
                fr(Nu),
                fr(Io),
                fr(qk),
                fr(Nu),
                fr(Gu),
                fr(Xs)
              );
            }),
            (jk.ɵprov = Ee({ token: jk, factory: jk.ɵfac })),
            jk),
          Uk =
            (((Mk = (function () {
              function e(t) {
                p(this, e), (this.serivce = t);
              }
              return (
                g(e, null, [
                  {
                    key: "forRoot",
                    value: function () {
                      return { ngModule: e, providers: [Hk, qk] };
                    },
                  },
                  {
                    key: "forChild",
                    value: function () {
                      return { ngModule: e, providers: [Hk, qk] };
                    },
                  },
                ]),
                e
              );
            })()).ɵfac = function (e) {
              return new (e || Mk)(fr(Hk));
            }),
            (Mk.ɵmod = st({ type: Mk })),
            (Mk.ɵinj = Te({ imports: [[Pl]] })),
            Mk),
          Vk =
            (((Nk = (function () {
              function e(t) {
                p(this, e), (this.injector = t);
                var n,
                  r,
                  i,
                  o,
                  a,
                  s,
                  l =
                    ((n = _m),
                    (r = { injector: this.injector }),
                    (i = (function (e, t) {
                      return t.get(wa).resolveComponentFactory(e).inputs;
                    })(n, r.injector)),
                    (o = r.strategyFactory || new Lc(n, r.injector)),
                    (a = (function (e) {
                      var t = {};
                      return (
                        e.forEach(function (e) {
                          var n,
                            r = e.propName,
                            i = e.templateName;
                          t[
                            ((n = i),
                            n.replace(/[A-Z]/g, function (e) {
                              return "-".concat(e.toLowerCase());
                            }))
                          ] = r;
                        }),
                        t
                      );
                    })(i)),
                    ((s = (function (e) {
                      u(n, e);
                      var t = c(n);
                      function n(e) {
                        var r;
                        return p(this, n), ((r = t.call(this)).injector = e), r;
                      }
                      return (
                        g(n, [
                          {
                            key: "ngElementStrategy",
                            get: function () {
                              var e = this;
                              if (!this._ngElementStrategy) {
                                var t = (this._ngElementStrategy = o.create(
                                  this.injector || r.injector
                                ));
                                i.forEach(function (n) {
                                  var r = n.propName;
                                  if (e.hasOwnProperty(r)) {
                                    var i = e[r];
                                    delete e[r], t.setInputValue(r, i);
                                  }
                                });
                              }
                              return this._ngElementStrategy;
                            },
                          },
                          {
                            key: "attributeChangedCallback",
                            value: function (e, t, n, r) {
                              this.ngElementStrategy.setInputValue(a[e], n);
                            },
                          },
                          {
                            key: "connectedCallback",
                            value: function () {
                              var e = !1;
                              this.ngElementStrategy.events &&
                                (this.subscribeToEvents(), (e = !0)),
                                this.ngElementStrategy.connect(this),
                                e || this.subscribeToEvents();
                            },
                          },
                          {
                            key: "disconnectedCallback",
                            value: function () {
                              this._ngElementStrategy &&
                                this._ngElementStrategy.disconnect(),
                                this.ngElementEventsSubscription &&
                                  (this.ngElementEventsSubscription.unsubscribe(),
                                  (this.ngElementEventsSubscription = null));
                            },
                          },
                          {
                            key: "subscribeToEvents",
                            value: function () {
                              var e = this;
                              this.ngElementEventsSubscription =
                                this.ngElementStrategy.events.subscribe(
                                  function (t) {
                                    var n = (function (e, t, n) {
                                      if ("function" != typeof CustomEvent) {
                                        var r = e.createEvent("CustomEvent");
                                        return (
                                          r.initCustomEvent(t, !1, !1, n), r
                                        );
                                      }
                                      return new CustomEvent(t, {
                                        bubbles: !1,
                                        cancelable: !1,
                                        detail: n,
                                      });
                                    })(e.ownerDocument, t.name, t.value);
                                    e.dispatchEvent(n);
                                  }
                                );
                            },
                          },
                        ]),
                        n
                      );
                    })(zc)).observedAttributes = Object.keys(a)),
                    i.forEach(function (e) {
                      var t = e.propName;
                      Object.defineProperty(s.prototype, t, {
                        get: function () {
                          return this.ngElementStrategy.getInputValue(t);
                        },
                        set: function (e) {
                          this.ngElementStrategy.setInputValue(t, e);
                        },
                        configurable: !0,
                        enumerable: !0,
                      });
                    }),
                    s);
                customElements.define("digitaler-stuhlkreis", l);
              }
              return g(e, [{ key: "ngDoBootstrap", value: function () {} }]), e;
            })()).ɵfac = function (e) {
              return new (e || Nk)(fr(Io));
            }),
            (Nk.ɵmod = st({ type: Nk, bootstrap: [_m] })),
            (Nk.ɵinj = Te({
              providers: [{ provide: cl, useValue: "/" }],
              imports: [[kc, om, Fy, up, Ck, qp, Uk.forRoot()]],
            })),
            Nk);
        (function () {
          if (Tu)
            throw new Error("Cannot enable prod mode after platform setup.");
          Eu = !1;
        })(),
          gc()
            .bootstrapModule(Vk)
            .catch(function (e) {
              return console.error(e);
            });
      },
      zn8P: function (e, t) {
        function n(e) {
          return Promise.resolve().then(function () {
            var t = new Error("Cannot find module '" + e + "'");
            throw ((t.code = "MODULE_NOT_FOUND"), t);
          });
        }
        (n.keys = function () {
          return [];
        }),
          (n.resolve = n),
          (e.exports = n),
          (n.id = "zn8P");
      },
    },
    [[0, 0]],
  ]);
})();
