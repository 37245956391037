export enum WindowType {
  QrCode = "qr-code",
  WorkPhase = "work-phase",
  Timer = "timer",
  RandomGenerator = "random-generator",
  Notepad = "notepad",
  Soundboard = "soundboard",
  Whiteboard = "whiteboard",
  Positioning = "gallery",
  Stuhlkreis = "stuhlkreis",
  GuessingGame = "guessing-game",
}
